import createRequestAction from "./handlers/createRequestActionHandler";

export const clearSuccessMessage = createRequestAction(
  "CLEARSUCCESSMESSAGE",
  () => ({})
);

export const clearErrorMessage = createRequestAction(
  "CLEARERRORMESSAGE",
  () => ({})
);

export const clearWarningMessage = createRequestAction(
  "CLEARWARNINGMESSAGE",
  () => ({})
);

export const newSuccessMessage = createRequestAction(
  "NEWSUCCESSMESSAGE",
  ({ message }) => ({ message })
);

export const newErrorMessage = createRequestAction(
  "NEWERRORMESSAGE",
  ({ message }) => ({ message })
);

export const newWarningMessage = createRequestAction(
  "NEWWARNINGMESSAGE",
  ({ message }) => ({ message })
);

// get
export const getPeople = createRequestAction("PEOPLE", () => ({
  request: {
    method: "get",
    url: "/api/v1/people",
  },
}));
export const getTeams = createRequestAction("TEAMS", () => ({
  request: {
    method: "get",
    url: "/api/v1/invite",
  },
}));
export const getDepartments = createRequestAction("DEPARTMENTS", () => ({
  request: {
    method: "get",
    url: "/api/v1/invite",
  },
}));
export const getActiveInvites = createRequestAction("ACTIVEINVITES", () => ({
  request: {
    method: "get",
    url: "/api/v1/invite",
  },
}));

export const getMaturityDict = createRequestAction("MATURITY", () => ({
  request: {
    method: "get",
    url: "/api/v1/maturity_level_dict",
  },
}));

export const getSet = createRequestAction("SET", () => ({
  request: {
    method: "get",
    url: "/api/v1/universal_item_set",
  },
}));

export const getBPRelations = createRequestAction("BPRELATIONS", () => ({
  request: {
    method: "get",
    url: "/api/v1/bp_app_relations",
  },
}));

export const getRelationship = createRequestAction("RELATIONSHIP", () => ({
  request: {
    method: "get",
    url: "/api/v1/relationship_dict",
  },
}));

export const getDepartment = createRequestAction("DEPARTMENT", () => ({
  request: {
    method: "get",
    url: "/api/v1/department",
  },
}));

export const getOrganization = createRequestAction(
  "ORGANIZATION",
  ({ id }) => ({
    request: {
      method: "get",
      url: "/api/v1/organization/" + id,
    },
  })
);

export const getBusiness = createRequestAction("BUSINESS", () => ({
  request: {
    method: "get",
    url: "/api/v1/bp_list",
  },
}));

export const getApplication = createRequestAction("APPLICATION", () => ({
  request: {
    method: "get",
    url: "/api/v1/application",
  },
}));

export const getScenario = createRequestAction("SCENARIO", () => ({
  request: {
    method: "get",
    url: "/api/v1/scenario",
  },
}));

export const getProduct = createRequestAction("PRODUCT", () => ({
  request: {
    method: "get",
    url: "/api/v1/product",
  },
}));

export const getGoal = createRequestAction("GOAL", () => ({
  request: {
    method: "get",
    url: "/api/v1/goal",
  },
}));

export const getGoalType = createRequestAction("GOALTYPE", () => ({
  request: {
    method: "get",
    url: "/api/v1/goal_type",
  },
}));

export const getActivityType = createRequestAction("ACTIVITIYTYPE", () => ({
  request: {
    method: "get",
    url: "/api/v1/activity_type",
  },
}));

export const getActivity = createRequestAction("ACTIVITY", () => ({
  request: {
    method: "get",
    url: "/api/v1/activity",
  },
}));

export const getCapabilityType = createRequestAction("CAPABILITYTYPE", () => ({
  request: {
    method: "get",
    url: "/api/v1/property",
  },
}));

export const getCapabilityTypeType = createRequestAction(
  "CAPABILITYTYPETYPE",
  () => ({
    request: {
      method: "get",
      url: "/api/v1/property_type",
    },
  })
);

export const getCapability = createRequestAction("CAPABILITY", () => ({
  request: {
    method: "get",
    url: "/api/v1/capability",
  },
}));

// post
export const newPerson = createRequestAction(
  "NEWPERSON",
  ({ name, position, role, startDate, endDate, systemUser, email }) => ({
    request: {
      method: "post",
      url: "/api/v1/people",
      data: {
        name,
        position,
        role,
        startDate,
        endDate,
        systemUser,
        email,
      },
    },
  })
);
export const newTeam = createRequestAction(
  "NEWTEAM",
  ({ name, departmentId }) => ({
    request: {
      method: "post",
      url: "/api/v1/team",
      data: {
        name,
        departmentId,
      },
    },
  })
);
export const newDepartment = createRequestAction(
  "NEWDEPARTMENT",
  ({ name }) => ({
    request: {
      method: "post",
      url: "/api/v1/department",
      data: {
        name,
      },
    },
  })
);
export const clearBase = createRequestAction("CLEARBASE", () => ({
  request: {
    method: "post",
    url: "/api/v1/clear",
  },
}));

export const newLogout = createRequestAction("NEWLOGOUT", () => ({
  request: {
    method: "post",
    url: "/api/v1/logout",
  },
}));

export const newFeedback = createRequestAction(
  "NEWFEEDBACK",
  ({ userName, screenshot, text, title, url }) => ({
    request: {
      method: "post",
      url: "/api/v1/feedback",
      data: {
        userName,
        url,
        text,
        title,
        screenshot,
      },
    },
  })
);

export const newInvite = createRequestAction("NEWINVITE", ({ email }) => ({
  request: {
    method: "post",
    url: "/api/v1/invite",
    data: {
      email,
    },
  },
}));

export const deleteInvite = createRequestAction(
  "DELETEINVITE",
  ({ deleteId }) => ({
    request: {
      method: "delete",
      url: "/api/v1/invite",
      data: {
        email: deleteId,
      },
    },
  })
);

export const newSet = createRequestAction("NEWSET", ({ list_name, name }) => ({
  request: {
    method: "post",
    url: "/api/v1/universal_item_set",
    data: {
      list_name,
      name,
    },
  },
}));

export const newBPRelations = createRequestAction(
  "NEWBPRELATIONS",
  ({
    application_id,
    bp_list_id,
    relationship_dict_id,
    start_date,
    end_date,
  }) => ({
    request: {
      method: "post",
      url: "/api/v1/bp_app_relations",
      data: {
        application_id,
        bp_list_id,
        relationship_dict_id,
        start_date,
        end_date,
      },
    },
  })
);

export const newScenario = createRequestAction(
  "NEWSCENARIO",
  ({ name, list_of_activities, active_flag }) => ({
    request: {
      method: "post",
      url: "/api/v1/scenario",
      data: {
        name,
        list_of_activities,
        active_flag,
      },
    },
  })
);

export const newBusiness = createRequestAction(
  "NEWBUSINESS",
  ({ name, property_id, start_date, end_date, tag, link }) => ({
    request: {
      method: "post",
      url: "/api/v1/bp_list",
      data: {
        name,
        property_id,
        start_date,
        end_date,
        tag,
        link,
      },
    },
  })
);

export const newApplication = createRequestAction(
  "NEWAPPLICATION",
  ({ name, start_date, end_date, tag, link, description }) => ({
    request: {
      method: "post",
      url: "/api/v1/application",
      data: {
        name,
        start_date,
        end_date,
        tag,
        link,
        description,
      },
    },
  })
);

export const newProduct = createRequestAction(
  "NEWPRODUCT",
  ({
    name,
    activity_id,
    list_of_products,
    parent_process,
    parent_type,
    portfolio_flag,
  }) => ({
    request: {
      method: "post",
      url: "/api/v1/product",
      data: {
        name,
        activity_id,
        list_of_products,
        parent_process,
        parent_type,
        portfolio_flag,
      },
    },
  })
);

export const newGoal = createRequestAction(
  "NEWGOAL",
  ({ name, type, parent_id, link, level, tag, description }) => ({
    request: {
      method: "post",
      url: "/api/v1/goal",
      data: {
        name,
        type,
        parent_id,
        link,
        level,
        tag,
        description,
      },
    },
  })
);

export const newGoalType = createRequestAction(
  "NEWGOALTYPE",
  ({ name, level }) => ({
    request: {
      method: "post",
      url: "/api/v1/goal_type",
      data: {
        name,
        level,
      },
    },
  })
);

export const newActivityType = createRequestAction(
  "NEWACTIVITIYTYPE",
  ({ name }) => ({
    request: {
      method: "post",
      url: "/api/v1/activity_type",
      data: {
        name,
      },
    },
  })
);

export const newActivity = createRequestAction(
  "NEWACTIVITY",
  ({
    name,
    activity_type_id,
    goal_id,
    sorting_number,
    predecessor,
    parent,
    owner,
    score,
    start_date,
    end_date,
    tag,
    labor_cost,
    resources_cost,
    effort,
    link,
    description,
  }) => ({
    request: {
      method: "post",
      url: "/api/v1/activity",
      data: {
        name,
        activity_type_id,
        goal_id,
        sorting_number,
        predecessor,
        parent,
        owner,
        score,
        start_date,
        end_date,
        tag,
        labor_cost,
        resources_cost,
        effort,
        link,
        description,
      },
    },
  })
);

export const newCapabilityType = createRequestAction(
  "NEWCAPABILITYTYPE",
  ({ name }) => ({
    request: {
      method: "post",
      url: "/auth/v1/create_user",
      data: {
        name,
      },
    },
  })
);

export const newCapability = createRequestAction("NEWCAPABILITY", (props) => ({
  request: {
    method: "post",
    url: "/api/v1/capability",
    data: { ...props },
  },
}));

// put
export const editPerson = createRequestAction(
  "EDITPERSON",
  ({ id, name, position, role, startDate, endDate, systemUser, email }) => ({
    request: {
      method: "put",
      url: "/api/v1/people" + id,
      data: {
        name,
        position,
        role,
        startDate,
        endDate,
        systemUser,
        email,
      },
    },
  })
);
export const editTeam = createRequestAction("EDITTEAM", ({ id, name }) => ({
  request: {
    method: "put",
    url: "/api/v1/team" + id,
    data: {
      name,
    },
  },
}));
export const editDepartment = createRequestAction(
  "EDITDEPARTMENT",
  ({ id, name }) => ({
    request: {
      method: "put",
      url: "/api/v1/department" + id,
      data: {
        name,
      },
    },
  })
);
export const editOrganization = createRequestAction(
  "EDITORGANIZATION",
  ({ id, name, start_date, end_date }) => ({
    request: {
      method: "put",
      url: "/api/v1/organization/" + id,
      data: {
        name,
        start_date,
        end_date,
      },
    },
  })
);

export const editSet = createRequestAction(
  "EDITSET",
  ({ id, list_name, name }) => ({
    request: {
      method: "put",
      url: "/api/v1/universal_item_set/" + id,
      data: {
        list_name,
        name,
      },
    },
  })
);

export const editBPRelations = createRequestAction(
  "EDITBPRELATIONS",
  ({
    id,
    application_id,
    bp_list_id,
    start_date,
    end_date,
    relationship_dict_id,
  }) => ({
    request: {
      method: "put",
      url: "/api/v1/bp_app_relations/" + id,
      data: {
        application_id,
        bp_list_id,
        relationship_dict_id,
        start_date,
        end_date,
      },
    },
  })
);

export const editBusiness = createRequestAction(
  "EDITBUSINESS",
  ({ id, name, property_id, start_date, end_date, tag, link }) => ({
    request: {
      method: "put",
      url: "/api/v1/bp_list/" + id,
      data: {
        name,
        property_id,
        start_date,
        end_date,
        tag,
        link,
      },
    },
  })
);

export const editApplication = createRequestAction(
  "EDITAPPLICATION",
  ({ id, name, start_date, end_date, tag, link, description }) => ({
    request: {
      method: "put",
      url: "/api/v1/application/" + id,
      data: {
        name,
        start_date,
        end_date,
        tag,
        link,
        description,
      },
    },
  })
);

export const editScenario = createRequestAction(
  "EDITSCENARIO",
  ({ id, name, list_of_activities, active_flag }) => ({
    request: {
      method: "put",
      url: "/api/v1/scenario/" + id,
      data: {
        name,
        list_of_activities,
        active_flag,
      },
    },
  })
);

export const editProduct = createRequestAction(
  "EDITPRODUCT",
  ({
    id,
    name,
    activity_id,
    list_of_products,
    parent_process,
    parent_type,
    portfolio_flag,
  }) => ({
    request: {
      method: "put",
      url: "/api/v1/product/" + id,
      data: {
        name,
        activity_id,
        list_of_products,
        parent_process,
        parent_type,
        portfolio_flag,
      },
    },
  })
);

export const editGoal = createRequestAction(
  "EDITGOAL",
  ({ id, name, type, parent_id, link, level, tag, description }) => ({
    request: {
      method: "put",
      url: "/api/v1/goal/" + id,
      data: {
        name,
        type,
        parent_id,
        link,
        level,
        tag,
        description,
      },
    },
  })
);

export const editGoalType = createRequestAction(
  "EDITGOALTYPE",
  ({ id, name, level }) => ({
    request: {
      method: "put",
      url: "/api/v1/goal_type/" + id,
      data: {
        name,
        level,
      },
    },
  })
);

export const editActivityType = createRequestAction(
  "EDITACTIVITIYTYPE",
  ({ id, name }) => ({
    request: {
      method: "put",
      url: "/api/v1/activity_type/" + id,
      data: {
        name,
      },
    },
  })
);

export const editActivity = createRequestAction(
  "EDITACTIVITY",
  ({
    id,
    name,
    activity_type_id,
    goal_id,
    sorting_number,
    predecessor,
    parent,
    owner,
    score,
    start_date,
    end_date,
    tag,
    labor_cost,
    resources_cost,
    effort,
    link,
    description,
  }) => ({
    request: {
      method: "put",
      url: "/api/v1/activity/" + id,
      data: {
        name,
        activity_type_id,
        goal_id,
        sorting_number,
        predecessor,
        parent,
        owner,
        score,
        start_date,
        end_date,
        tag,
        labor_cost,
        resources_cost,
        effort,
        link,
        description,
      },
    },
  })
);

export const editCapabilityType = createRequestAction(
  "EDITCAPABILITYTYPE",
  ({ id, name }) => ({
    request: {
      method: "put",
      url: "/auth/v1/create_user/" + id,
      data: {
        name,
      },
    },
  })
);

export const editCapability = createRequestAction(
  "EDITCAPABILITY",
  (props) => ({
    request: {
      method: "put",
      url: "/api/v1/capability/" + props.id,
      data: { ...props },
    },
  })
);

// delete
export const deletePerson = createRequestAction(
  "DELETEPERSON",
  ({ deleteId }) => ({
    request: {
      method: "delete",
      url: "/api/v1/person" + deleteId,
    },
  })
);
export const deleteTeam = createRequestAction("DELETETEAM", ({ deleteId }) => ({
  request: {
    method: "delete",
    url: "/api/v1/team" + deleteId,
  },
}));
export const deleteDepartment = createRequestAction(
  "DELETEDEPARTMENT",
  ({ deleteId }) => ({
    request: {
      method: "delete",
      url: "/api/v1/department" + deleteId,
    },
  })
);
export const deleteSet = createRequestAction("DELETESET", ({ deleteId }) => ({
  request: {
    method: "delete",
    url: "/api/v1/universal_item_set/" + deleteId,
  },
}));

export const deleteBPRelation = createRequestAction(
  "DELETEBPRELATION",
  ({ deleteId }) => ({
    request: {
      method: "delete",
      url: "/api/v1/bp_app_relations/" + deleteId,
    },
  })
);

export const deleteBusiness = createRequestAction(
  "DELETEBUSINESS",
  ({ deleteId }) => ({
    request: {
      method: "delete",
      url: "/api/v1/bp_list/" + deleteId,
    },
  })
);

export const deleteApplication = createRequestAction(
  "DELETEAPPLICATION",
  ({ deleteId }) => ({
    request: {
      method: "delete",
      url: "/api/v1/application/" + deleteId,
    },
  })
);

export const deleteScenario = createRequestAction(
  "DELETESCENARIO",
  ({ deleteId }) => ({
    request: {
      method: "delete",
      url: "/api/v1/scenario/" + deleteId,
    },
  })
);

export const deleteProduct = createRequestAction(
  "DELETEPRODUCT",
  ({ deleteId }) => ({
    request: {
      method: "delete",
      url: "/api/v1/product/" + deleteId,
    },
  })
);

export const deleteGoal = createRequestAction("DELETEGOAL", ({ deleteId }) => ({
  request: {
    method: "delete",
    url: "/api/v1/goal/" + deleteId,
  },
}));

export const deleteGoalType = createRequestAction(
  "DELETEGOALTYPE",
  ({ deleteId }) => ({
    request: {
      method: "delete",
      url: "/api/v1/goal_type/" + deleteId,
    },
  })
);

export const deleteActivityType = createRequestAction(
  "DELETEACTIVITIYTYPE",
  ({ deleteId }) => ({
    request: {
      method: "delete",
      url: "/api/v1/activity_type/" + deleteId,
    },
  })
);

export const deleteActivity = createRequestAction(
  "DELETEACTIVITY",
  ({ deleteId }) => ({
    request: {
      method: "delete",
      url: "/api/v1/activity/" + deleteId,
    },
  })
);

export const deleteCapabilityType = createRequestAction(
  "DELETECAPABILITYTYPE",
  ({ deleteId }) => ({
    request: {
      method: "delete",
      url: "/api/v1/create_user/" + deleteId,
    },
  })
);

export const deleteCapability = createRequestAction(
  "DELETECAPABILITY",
  ({ deleteId }) => ({
    request: {
      method: "delete",
      url: "/api/v1/capability/" + deleteId,
    },
  })
);

export const deleteCapabilities = createRequestAction(
  "DELETECAPABILITIES",
  ({ deleteArray }) => ({
    request: {
      method: "delete",
      url: "/api/v1/capability/batch/delete",
      data: deleteArray,
    },
  })
);

export const deleteActivities = createRequestAction(
  "DELETEACTIVITIES",
  ({ deleteArray }) => ({
    request: {
      method: "delete",
      url: "/api/v1/activity/batch/delete",
      data: deleteArray,
    },
  })
);

export const deleteApplications = createRequestAction(
  "DELETEAPPLICATIONS",
  ({ deleteArray }) => ({
    request: {
      method: "delete",
      url: "/api/v1/application/batch/delete",
      data: deleteArray,
    },
  })
);

export const deleteProcesses = createRequestAction(
  "DELETEPROCESSES",
  ({ deleteArray }) => ({
    request: {
      method: "delete",
      url: "/api/v1/bp_list/batch/delete",
      data: deleteArray,
    },
  })
);

export const deleteBPRelations = createRequestAction(
  "DELETEBPRELATIONS",
  ({ deleteArray }) => ({
    request: {
      method: "delete",
      url: "/api/v1/bp_app_relations/batch/delete",
      data: deleteArray,
    },
  })
);

export const deleteGoals = createRequestAction(
  "DELETEGOALS",
  ({ deleteArray }) => ({
    request: {
      method: "delete",
      url: "/api/v1/goal/batch/delete",
      data: deleteArray,
    },
  })
);

export const deleteProducts = createRequestAction(
  "DELETEPRODUCTS",
  ({ deleteArray }) => ({
    request: {
      method: "delete",
      url: "/api/v1/product/batch/delete",
      data: deleteArray,
    },
  })
);

export const deleteScenarios = createRequestAction(
  "DELETESCENARIOS",
  ({ deleteArray }) => ({
    request: {
      method: "delete",
      url: "/api/v1/scenario/batch/delete",
      data: deleteArray,
    },
  })
);

export const deleteTeams = createRequestAction(
  "DELETETEAMS",
  ({ deleteArray }) => ({
    request: {
      method: "delete",
      url: "/api/v1/team/batch/delete",
      data: deleteArray,
    },
  })
);

// patch
export const importCapability = createRequestAction(
  "IMPORTCAPABILITY",
  ({ capabilities }) => ({
    request: {
      method: "PATCH",
      url: "/api/v1/capability/all",
      data: capabilities,
    },
  })
);

export const importGoal = createRequestAction("IMPORTGOAL", ({ goals }) => ({
  request: {
    method: "PATCH",
    url: "/api/v1/goal/all",
    data: goals,
  },
}));

export const importActivity = createRequestAction(
  "IMPORTACTIVITY",
  ({ activities }) => ({
    request: {
      method: "PATCH",
      url: "/api/v1/activity/all",
      data: activities,
    },
  })
);

export const importApplication = createRequestAction(
  "IMPORTAPPLICATION",
  ({ applications }) => ({
    request: {
      method: "PATCH",
      url: "/api/v1/application/all",
      data: applications,
    },
  })
);

export const importBusiness = createRequestAction(
  "IMPORTBUSINESS",
  ({ businesses }) => ({
    request: {
      method: "PATCH",
      url: "/api/v1/bp_list/all",
      data: businesses,
    },
  })
);

export const importProduct = createRequestAction(
  "IMPORTPRODUCT",
  ({ products }) => ({
    request: {
      method: "PATCH",
      url: "/api/v1/product/all",
      data: products,
    },
  })
);

export const importScenario = createRequestAction(
  "IMPORTSCENARIO",
  ({ scenarios }) => ({
    request: {
      method: "PATCH",
      url: "/api/v1/scenario/all",
      data: scenarios,
    },
  })
);

export const importBPRelations = createRequestAction(
  "IMPORTBPRELATIONS",
  ({ bpRelations }) => ({
    request: {
      method: "PATCH",
      url: "/api/v1/bp_app_relations/all",
      data: bpRelations,
    },
  })
);

// auth
export const signUp = createRequestAction(
  "SIGNUP",
  ({
    name,
    email,
    department_id,
    phone,
    password,
    organization_id,
    organization_name,
  }) => ({
    request: {
      method: "post",
      url: "/auth/v1/create_user",
      data: {
        name,
        email,
        department_id,
        phone,
        password,
        organization_id,
        organization_name,
      },
    },
  })
);

export const login = createRequestAction("LOGIN", ({ email, password }) => ({
  request: {
    method: "post",
    url: "/auth/v1/login",
    data: {
      email,
      password,
    },
  },
}));

export const forgotPassword = createRequestAction(
  "FORGOTPASSWORD",
  ({ email }) => ({
    request: {
      method: "post",
      url: "/auth/v1/forgot_password",
      data: {
        email,
      },
    },
  })
);

export const newPassword = createRequestAction(
  "NEWPASSWORD",
  ({ password, token }) => ({
    request: {
      method: "post",
      url: "/auth/v1/new_password",
      data: {
        password,
        token,
      },
    },
  })
);

export const changePassword = createRequestAction(
  "CHANGEPASSWORD",
  ({ password }) => ({
    request: {
      method: "post",
      url: "/api/v1/change_password",
      data: {
        password,
      },
    },
  })
);

export const getUserRole = createRequestAction("ROLE", () => ({
  request: {
    method: "get",
    url: "/api/v1/role",
  },
}));

// user
export const getUser = createRequestAction("USER", () => ({
  request: {
    method: "get",
    url: "/api/v1/user",
  },
}));

export const deleteUser = createRequestAction("DELETEUSER", ({ deleteId }) => ({
  request: {
    method: "delete",
    url: "/api/v1/user",
    data: {
      id: deleteId,
    },
  },
}));

export const getAllUsers = createRequestAction("ALLUSERS", () => ({
  request: {
    method: "get",
    url: "/api/v1/user/all",
  },
}));

export const editUser = createRequestAction("EDITUSER", ({ name, phone }) => ({
  request: {
    method: "put",
    url: "/api/v1/user",
    data: {
      name,
      phone,
    },
  },
}));
