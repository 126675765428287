import React, { useState, useEffect } from 'react'

import ActivitiesManagement from '../../components/Activities/ActivitiesManagement'
import SuccessNotice from '../../components/SuccessNotice'
import ErrorNotice from '../../components/ErrorNotice'

import Left from "../../assets/left.svg"
import Right from "../../assets/right.svg"

import { AiOutlineFileAdd } from "react-icons/ai"
import { MdDeleteForever } from 'react-icons/md'

import ActivitiesTable from '../../components/Activities/ActivitiesTable'
import PERT2 from '../../components/Reports/PERT2'

import SkeletonTable from '../../components/ui/skeleton/SkeletonTable'
import SkeletonTabs from '../../components/ui/skeleton/SkeletonTabs'
import IconButton from '../../components/ui/buttons/IconButton'
import ActivitiesImport from '../../components/Activities/ActivitiesImport'
import ActivitiesExport from '../../components/Activities/ActivitiesExport'
import WarningNotice from '../../components/WarningNotice'
import Modal from '../../components/Modal'


const ActivitiesPage = ({
	loading,
	fetched,
	getActivity,
	getActivityType,
	newActivity,
	newActivityType,
	editActivity,
	editActivityType,
	activity,
	activityPerPage,
	activityType,
	goal,
	getGoal,
	successMessage,
	errorMessage,
	warningMessage,
	newSuccessMessage,
	newErrorMessage,
	newWarningMessage,
	clearSuccessMessage,
	clearErrorMessage,
	clearWarningMessage,
	access
}) => {
	const [active, setActive] = useState("Activities")
	// const [activities, setActivities] = useState([]);
	const [updateChosen, setUpdateChosen] = useState(false);
	// const [deletedChosen, setDeleteChosen] = useState(false);
	// const [selected, setSelected] = useState(null);
	const [addChosen, setAddChosen] = useState(false);
	const [showDelete, setShowDelete] = useState(false)

	const [deleteItemsType, setDeleteItemsType] = useState('activitiesArray')
	
	const [success, setSuccess] = useState("")
	const [error, setError] = useState("")
	const [warning, setWarning] = useState("")
	const [errorField, setErrorField] = useState('')

	// const [dataArray, setDataArray] = useState([])

	const [name, setName] = useState("")
	const [type, setType] = useState(activityType[0].name)
	const [goalField, setGoalField] = useState('')
	const [sort, setSort] = useState('')
	const [predecessor, setPredecessor] = useState(0)
	const [parent, setParent] = useState(0)
	const [owner, setOwner] = useState('')
	const [score, setScore] = useState('')
	const [startDate, setStartDate] = useState("")
	const [endDate, setEndDate] = useState("")
	const [tag, setTag] = useState('')
	const [lCost, setLCost] = useState('')
	const [rCost, setRCost] = useState('')
	const [effort, setEffort] = useState('')
	const [link, setLink] = useState('')
	const [description, setDescription] = useState('')

	const [updateId, setUpdateId] = useState('')
	const [updateName, setUpdateName] = useState("")
	const [updateType, setUpdateType] = useState("")
	const [updateGoalField, setUpdateGoalField] = useState('')
	const [updateSort, setUpdateSort] = useState('')
	const [updatePredecessor, setUpdatePredecessor] = useState(0)
	const [updateParent, setUpdateParent] = useState(0)
	const [updateOwner, setUpdateOwner] = useState('')
	const [updateScore, setUpdateScore] = useState('')
	const [updateStartDate, setUpdateStartDate] = useState("")
	const [updateEndDate, setUpdateEndDate] = useState("")
	const [updateTag, setUpdateTag] = useState('')
	const [updateLCost, setUpdateLCost] = useState('')
	const [updateRCost, setUpdateRCost] = useState('')
	const [updateEffort, setUpdateEffort] = useState('')
	const [updateLink, setUpdateLink] = useState("")
	const [updateDescription, setUpdateDescription] = useState('')

	const [current, setCurrent] = useState(0);
	const [paginationLevels, setPaginationLevels] = useState(activityPerPage.length)

	const {
		Activities_Add,
		Activities_Edit,
		Activities_Delete,
		Activities_Previous_page,
		Activities_Next_page,
		Activities_Report_Download_report
	} = access.Buttons

	const getTypeId = (input) => {
		for (const t of activityType) {
			if (input === t.name) {
				return t.id
			}
		}

		return 0
	}

	const goalFieldChangeHandler = (e) => {
		setGoalField(e.target.value)
	}

	const updateGoalFieldChangeHandler = (e) => {
		setUpdateGoalField(e.target.value)
	}
	useEffect(() => {
		getActivity()
		getActivityType()
		getGoal()
	}, [])

	const clearFields = () => {
		setName('')
		setType(activityType[0].name)
		setGoalField('')
		setSort('')
		setPredecessor('')
		setParent(0)
		setOwner('')
		setScore('')
		setStartDate('')
		setEndDate('')
		setTag('')
		setLCost('')
		setRCost('')
		setEffort('')
		setLink('')
		setDescription('')
	}

	const deleteAllActivities = () => {
		// setDeleteItems(capability)
		setDeleteItemsType('activitiesArray')
		setShowDelete(true)
	}

	const addData = () => {
		if (!name) {
		setErrorField('name')
		setTimeout(() => {
			// newErrorMessage('potato')
			setErrorField('')
		}, 2000);
		return
		}
		if (new Date(startDate) > new Date(endDate) ) {
		setError("Start Date can't be lower than End Date")
		setTimeout(() => {
			setError("");
		}, 2000);
		return
		}

		const currentGoal = goal && goal.find(item => item.name === goalField)
		newActivity({
			name,
			activity_type_id: getTypeId(type),
			goal_id: currentGoal ? currentGoal.id : 0,
			sorting_number: Number(sort),
			predecessor: Number(predecessor),
			parent: Number(parent),
			owner,
			score: Number(score),
			start_date: startDate,
			end_date: endDate,
			tag,
			labor_cost: Number(lCost),
			resources_cost: Number(rCost),
			effort: Number(effort),
			link,
			description
		})
		.then(() => {
			setAddChosen(false)
			clearFields()
		})
	}

	const updateData = () => {
		if (!updateName) {
		setErrorField('name')
		setTimeout(() => {
			setErrorField('')
		}, 2000);
		return
		}
		if (new Date(startDate) > new Date(endDate) ) {
		setError("Start Date can't be lower than End Date")
		setTimeout(() => {
			setError("")
		}, 2000);
		return
		}
		editActivity({
			id: updateId,
			name: updateName,
			activity_type_id: getTypeId(updateType),
			goal_id: Number(updateGoalField),
			sorting_number: Number(updateSort),
			predecessor: Number(updatePredecessor),
			parent: Number(updateParent),
			owner: updateOwner,
			score: Number(updateScore),
			start_date: updateStartDate,
			end_date: updateEndDate,
			tag: updateTag,
			labor_cost: Number(updateLCost),
			resources_cost: Number(updateRCost),
			effort: Number(updateEffort),
			link: updateLink,
			description: updateDescription
		})
		.then(() => {
			setUpdateChosen(false)
		})
	}
	return (
		<>
			<div className="notices">
				{(success || successMessage) && <SuccessNotice message={success || successMessage} clearMessage={clearSuccessMessage} />}
				{warning && <WarningNotice  message={warning} />}
				{(errorMessage || error) && <ErrorNotice message={errorMessage || error} clearMessage={clearErrorMessage}/>}
			</div>
			{loading || !fetched ? <SkeletonTabs /> : <ActivitiesManagement active={active} setActive={setActive} access={access.Tabs} />}
			{active === 'Activities' &&
			<>
			{showDelete && (
				<Modal
					setShowDelete={setShowDelete}
					deleteItemType={deleteItemsType}
					deleteItems={activity}
					setSuccess={setSuccess}
					setError={setError}
				/>
			)}
			{addChosen && (
				<div>
				<div className="top-inputs">
					<div className="top-inputs__container">
					<p>Name</p>
						<input
						value={name}
						onChange={(e) => setName(e.target.value)}
						placeholder="Name"
						type="text"
						id="name"
						autoFocus
						name="name"
						className={errorField === 'name' ? "top-inputs__field bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4 form-error" : "top-inputs__field bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"}
						/>
					</div>
					<div className="top-inputs__container">
					<p>Link</p>
					<input
						value={link}
						onChange={(e) => setLink(e.target.value)}
						placeholder="Link"
						type="text"
						id="name"
						name="name"
						className="top-inputs__field bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
					/>
					</div>
					<div className="top-inputs__container">
					<p>Parent</p>
					<input
						value={parent}
						onChange={(e) => setParent(e.target.value)}
						placeholder="Parent"
						type="number"
						id="name"
						name="name"
						min="0"
						className="top-inputs__field bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
					/>
					</div>
					<div className="top-inputs__container">
						<p>Type of activity</p>
						<select
							value={type }
							onChange={(e) => setType(e.target.value)}
							className="top-inputs__field bg-white h-11 shadow rounded py-2 px-3"
							name="properties"
							id="properties"
						>
							{activityType.length > 0 &&
							activityType.map((item) => {
								return (
								<option value={item.name} key={item.id}>
									{item.name}
								</option>
								);
							})}
						</select>
					</div>
				</div>
				<div className="top-inputs">
					<div className="top-inputs__container">
						<p>Start Date</p>
						<input
							value={startDate}
							onChange={(e) => setStartDate(e.target.value)}
							type="date"
							id="start-date"
							name="start-date"
							className="top-inputs__field bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
						/>
					</div>
					<div className="top-inputs__container">
						<p>End Date</p>
						<input
							value={endDate}
							onChange={(e) => setEndDate(e.target.value)}
							type="date"
							id="end-date"
							name="end-date"
							className="top-inputs__field bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
						/>
					</div>        
					<div className="top-inputs__container">
						<p>Goal</p>
						<select
							value={goalField}
							onChange={(e) => goalFieldChangeHandler(e)}
							className="h-11 top-inputs__field  shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
							name="type"
							id="type"
							disabled={!goal.length}
						>
							{goal.length > 0 &&
								goal.map((item) => {
									return (
										<option value={item.name} key={item.id}>
											{item.level} - {item.name}
										</option>
									)
								})
							}
						</select>
					</div>
					<div className="top-inputs__container">
						<p>Predecessor</p>
						<input
							value={predecessor}
							onChange={(e) => setPredecessor(e.target.value)}
							placeholder="Predecessor"
							type="number"
							id="predecessor"
							name="predecessor"
							min="0"
							className="top-inputs__field bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
							/>
					</div>
				</div>
				<div className='top-inputs'>
					<div className="top-inputs__container">
						<p>Owner</p>
						<input
							value={owner}
							onChange={(e) => setOwner(e.target.value)}
							placeholder="Owner"
							type="text"
							id="owner"
							name="owner"
							min="0"
							className="top-inputs__field bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
						/>
					</div>
					<div className="top-inputs__container">
						<p>Score</p>
						<input
							value={score}
							onChange={(e) => setScore(e.target.value)}
							placeholder="Score"
							type="number"
							id="score"
							name="score"
							min="0"
							className="top-inputs__field bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
						/>
					</div>
					<div className="top-inputs__container">
						<p>Sort</p>
						<input
							value={sort}
							onChange={(e) => setSort(e.target.value)}
							placeholder="Sort"
							type="number"
							id="sort"
							name="sort"
							min="0"
							className="top-inputs__field bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
						/>
					</div>
					<div className="top-inputs__container">
						<p>Tag</p>
						<input
							value={tag}
							onChange={(e) => setTag(e.target.value)}
							placeholder="Tag"
							type="text"
							id="tag"
							name="tag"
							min="0"
							className="top-inputs__field bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
						/>
					</div>
			</div>
			<div className='top-inputs'>
				<div className="top-inputs__container">
					<p>Labor cost</p>
					<input
						value={lCost}
						onChange={(e) => setLCost(e.target.value)}
						placeholder="Labor cost"
						type="number"
						id="lCost"
						name="lCost"
						min="0"
						className="top-inputs__field bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
					/>
				</div>
				<div className="top-inputs__container">
					<p>Resources cost</p>
					<input
						value={rCost}
						onChange={(e) => setRCost(e.target.value)}
						placeholder="Resources cost"
						type="number"
						id="rCost"
						name="rCost"
						min="0"
						className="top-inputs__field bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
					/>
				</div>
				<div className="top-inputs__container">
					<p>Effort</p>
					<input
						value={effort}
						onChange={(e) => setEffort(e.target.value)}
						placeholder="Effort"
						type="number"
						id="effort"
						name="effort"
						min="0"
						className="top-inputs__field bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
					/>
				</div>
				<div className="top-inputs__container">
					<p>Description</p>
					<input
						value={description}
						onChange={(e) => setDescription(e.target.value)}
						placeholder="Note"
						type="text"
						id="description"
						name="description"
						className="top-inputs__field bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
					/>
				</div>
			</div>
				
				<div className="w-3/4 flex justify-between align-middle">
					<button
					onClick={() => {
						setAddChosen(false);
					}}
					className="text-white bg-red-500 border-0 py-2 mt-6 px-12 focus:outline-none hover:bg-red-600 rounded text-lg"
					>
					Cancel
					</button>
					<button
					onClick={() => {
						addData();
					}}
					className="text-white bg-second-color border-0 py-2 mt-6 px-12 focus:outline-none rounded text-lg"
					>
					Add Activity
					</button>
				</div>
				</div>
			)}
			{updateChosen && (
				<div>
				<div className="top-inputs">
					<div className="top-inputs__container">
					<p>Name</p>
						<input
						value={updateName}
						onChange={(e) => setUpdateName(e.target.value)}
						placeholder="Name"
						type="text"
						id="name"
						autoFocus
						name="name"
						className={errorField === 'name' ? "top-inputs__field bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4 form-error" : "top-inputs__field bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"}
						/>
					</div>
					<div className="top-inputs__container">
					<p>Link</p>
					<input
						value={updateLink}
						onChange={(e) => setUpdateLink(e.target.value)}
						placeholder="Link"
						type="text"
						id="name"
						name="name"
						className="top-inputs__field bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
					/>
					</div>
					<div className="top-inputs__container">
					<p>Parent</p>
					<input
						value={updateParent}
						onChange={(e) => setUpdateParent(e.target.value)}
						placeholder="Parent"
						type="number"
						id="name"
						name="name"
						min="0"
						className="top-inputs__field bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
					/>
					</div>
					<div className="top-inputs__container">
						<p>Type of activity</p>
						<select
							value={updateType}
							onChange={(e) => setUpdateType(e.target.value)}
							className="top-inputs__field bg-white h-11 shadow rounded py-2 px-3"
							name="type"
							id="type"
						>
							{activityType.length > 0 &&
							activityType.map((item) => {
								return (
									<option value={item.name} key={item.id}>
										{item.name}
									</option>
								);
							})}
						</select>
					</div>
				</div>
				<div className="top-inputs">
					<div className="top-inputs__container">
					<p>Start Date</p>
					<input
						value={updateStartDate}
						onChange={(e) => setUpdateStartDate(e.target.value)}
						type="date"
						id="start-date"
						name="start-date"
						className="top-inputs__field bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
					/>
					</div>
					<div className="top-inputs__container">
					<p>End Date</p>
					<input
						value={updateEndDate}
						onChange={(e) => setUpdateEndDate(e.target.value)}
						type="date"
						id="end-date"
						name="end-date"
						className="top-inputs__field bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
					/>
					</div>
					<div className="top-inputs__container">
						<p>Goal</p>
						<select
							value={updateGoalField}
							onChange={(e) => updateGoalFieldChangeHandler(e)}
							className="h-11 top-inputs__field shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
							name="type"
							id="type"
							disabled={!goal.length}
						>
							{goal.length > 0 &&
								goal.map((item) => {
									return (
										<option value={item.id} key={item.id}>
											{item.level} - {item.name}
										</option>
									)
								})
							}
						</select>
					</div>
					<div className="top-inputs__container">
						<p>Predecessor</p>
						<input
							value={updatePredecessor}
							onChange={(e) => setUpdatePredecessor(e.target.value)}
							placeholder="Predecessor"
							type="number"
							id="predecessor"
							name="predecessor"
							min="0"
							className="top-inputs__field bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
							/>
					</div>
				</div>
				<div className='top-inputs'>
				<div className="top-inputs__container">
					<p>Owner</p>
					<input
						value={updateOwner}
						onChange={(e) => setUpdateOwner(e.target.value)}
						placeholder="Owner"
						type="text"
						id="owner"
						name="owner"
						min="0"
						className="top-inputs__field bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
					/>
				</div>
				<div className="top-inputs__container">
					<p>Score</p>
					<input
						value={updateScore}
						onChange={(e) => setUpdateScore(e.target.value)}
						placeholder="Score"
						type="number"
						id="score"
						name="score"
						min="0"
						className="top-inputs__field bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
					/>
				</div>
				<div className="top-inputs__container">
					<p>Sort</p>
					<input
						value={updateSort}
						onChange={(e) => setUpdateSort(e.target.value)}
						placeholder="Sort"
						type="number"
						id="sort"
						name="sort"
						min="0"
						className="top-inputs__field bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
					/>
				</div>
				<div className="top-inputs__container">
					<p>Tag</p>
					<input
						value={updateTag}
						onChange={(e) => setUpdateTag(e.target.value)}
						placeholder="Tag"
						type="text"
						id="tag"
						name="tag"
						min="0"
						className="top-inputs__field bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
					/>
				</div>
			</div>
			<div className='top-inputs'>
				<div className="top-inputs__container">
					<p>Labor cost</p>
					<input
						value={updateLCost}
						onChange={(e) => setUpdateLCost(e.target.value)}
						placeholder="Labor cost"
						type="number"
						id="lCost"
						name="lCost"
						min="0"
						className="top-inputs__field bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
					/>
				</div>
				<div className="top-inputs__container">
					<p>Resources cost</p>
					<input
						value={updateRCost}
						onChange={(e) => setUpdateRCost(e.target.value)}
						placeholder="Resources cost"
						type="number"
						id="rCost"
						name="rCost"
						min="0"
						className="top-inputs__field bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
					/>
				</div>
				<div className="top-inputs__container">
					<p>Effort</p>
					<input
						value={updateEffort}
						onChange={(e) => setUpdateEffort(e.target.value)}
						placeholder="Effort"
						type="number"
						id="effort"
						name="effort"
						min="0"
						className="top-inputs__field bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
					/>
				</div>
				<div className="top-inputs__container">
					<p>Description</p>
					<input
						value={updateDescription}
						onChange={(e) => setUpdateDescription(e.target.value)}
						placeholder="Note"
						type="text"
						id="description"
						name="description"
						className="top-inputs__field bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
					/>
				</div>
			</div>
				<div className="w-3/4 flex justify-between align-middle">
					<button
					onClick={() => {
						setUpdateChosen(false);
					}}
					className="text-white bg-red-500 border-0 py-2 mt-6 px-12 focus:outline-none hover:bg-red-600 rounded text-lg"
					>
					Cancel
					</button>
					<button
					onClick={() => {
						updateData();
					}}
					className="text-white bg-second-color border-0 py-2 mt-6 px-12 focus:outline-none rounded text-lg"
					>
					Update Activity
					</button>
				</div>
				</div>
			)}
			<div className="tables">
				<div className="top-table">
				<div className="plus-minus">
					<IconButton
						className="icon-img"
						onClick={() => {
							setUpdateChosen(false);
							setAddChosen(!addChosen);
						}}
						notRestricted={Activities_Add}
						stateActive={addChosen}
						tooltip={'Add new'}
					>
						<AiOutlineFileAdd alt="add" />
					</IconButton>
				</div>
				<div className="plus-minus ml-5">
					<IconButton
							className="icon-img"
							onClick={deleteAllActivities}
							notRestricted={Activities_Delete && (activity && activity.length)}
							stateActive={showDelete}
							tooltip={'Delete all table data'}
						>
							<MdDeleteForever />
					</IconButton>
				</div>
				<div className="w-full flex flex-row mb-5 mt-6 justify-end items-center px-10">
				<div
					className={current !== 0 ? "cursor-pointer bg-gray-100 px-2 py-1.5 rounded mr-2" : "cursor-pointer bg-gray-100 px-2 py-1.5 rounded mr-2 disabled"}
					onClick={() => {
					if (current > 0) {
						setCurrent(current - 1);
					}
					}}
				>
					<img src={Left} alt="left" />
				</div>

				<div
					className={current < paginationLevels - 2 ? "cursor-pointer bg-gray-100 px-2 py-1.5 rounded" : "cursor-pointer bg-gray-100 px-2 py-1.5 rounded disabled"}
					onClick={() => {
					if (current < paginationLevels - 2) {
						setCurrent(current + 1);
					}
					}}
				>
					<img src={Right} alt="right" />
				</div>
				</div>
				</div>
				<div className="table-div">
				{loading || !fetched ? 
				<SkeletonTable />
				:
				<ActivitiesTable
					data={activityPerPage}
					getData={getActivity}
					deletedChosen={false}
					setSelected={null}
					setUpdateName={setUpdateName}
					setUpdateLink={setUpdateLink}
					setUpdateParent={setUpdateParent}
					setUpdateStartDate={setUpdateStartDate}
					setUpdateEndDate={setUpdateEndDate}
					setUpdateId={setUpdateId}
					setSuccess={setSuccess}
					setError={setError}
					setUpdateChosen={setUpdateChosen}
					setAddChosen={setAddChosen}
					current={current}
					setPaginationLevels={setPaginationLevels}
					setUpdateType={setUpdateType}
					setUpdateGoal={setUpdateGoalField}
					setUpdateOwner={setUpdateOwner}
					setUpdatePredecessor={setUpdatePredecessor}
					setUpdateScore={setUpdateScore}
					setUpdateTag={setUpdateTag}
					setUpdateSort={setUpdateSort}
					setUpdateLCost={setUpdateLCost}
					setUpdateRCost={setUpdateRCost}
					setUpdateEffort={setUpdateEffort}
					setUpdateDescription={setUpdateDescription}

					setShowDelete={setShowDelete}

					activityType={activityType}

					access={access.Buttons}
				/> }
				</div>
				
			</div>
			</>
			}
			{active === 'Report' && <PERT2 dataArray={activity} access={access.Buttons} />}
			{active === 'Import' && <ActivitiesImport dataArray={activity} access={access.Buttons} setWarning={setWarning} setError={setError} setSuccess={setSuccess} />}
			{active === 'Export' && <ActivitiesExport dataArray={activity} access={access.Buttons} setWarning={setWarning} setError={setError} setSuccess={setSuccess} dataArrayGoals={goal} activityType={activityType} />}
		</>
	);
};

export default ActivitiesPage;