import React, {useState} from 'react'
import { Navigate, useNavigate  } from 'react-router-dom'
import FormInput from '../components/ui/inputs/FormInput'
import FacebookSpinner from '../components/ui/spinners/FacebookSpinner'
import ErrorNotice from '../components/ErrorNotice'
import SuccessNotice from '../components/SuccessNotice'
// import WarningNotice from '../components/WarningNotice'

const NewPassword = ({ newPassword, token, fetched, loading, errorMessage, successMessage, newPasswordToken, clearErrorMessage, clearSuccessMessage }) => {
  const navigate = useNavigate()
  const [values, setValues] = useState({
      password: "",
      confirmPassword: "",
  })

  if (token) return <Navigate to="/" />    

  const inputs = [
    {
      id: 1,
      name: "password",
      type: "password",
      placeholder: "Password",
      errorMessage:
        "Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special character",
      label: "Password",
      pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[@#$%^&+=!\\_\\-\\[\\]{}();:'",<>/?\\|])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
      required: true,
    },
    {
			id: 2,
			name: "confirmPassword",
			type: "password",
			placeholder: "Confirm Password",
			errorMessage: "Passwords don't match",
			label: "Confirm Password",
			pattern: values.password.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
			required: true,
		},
  ]

  const handleSubmit = async (e) => {
    e.preventDefault()
    const { payload } = await newPassword({ password: values.password, token: newPasswordToken })
    
    if (payload && payload.status === 200) {
      setTimeout(() => {
        navigate('/login')
      }, 1000)
    }
  }

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  if (!fetched || loading) {
    return (
      <div className="spinner-container">
        <FacebookSpinner />
      </div>
    )
  }

  return (
    <div className="sign-up mt-10">
      {successMessage && <SuccessNotice message={successMessage} clearMessage={clearSuccessMessage} />}
      {/* {warning && <WarningNotice message={warning} />} */}
      {errorMessage && <ErrorNotice message={errorMessage} clearMessage={clearErrorMessage} />}
      <form className="form" onSubmit={handleSubmit}>
        <h4 className='form__title'>Enter new password </h4>
        {inputs.map((input) => (
          <FormInput
            key={input.id}
            {...input}
            value={values[input.name]}
            onChange={onChange}
          />
        ))}
        <button className='form__button'>Submit</button>
      </form>
    </div>
  );

}

export default NewPassword