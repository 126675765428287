import React from "react";
import Tab from "../Tab";

const CapabilitiesManagement = ({ active, setActive, access }) => {
  const {
    Capabilities,
    Capabilities_Visual_editor,
    Capabilities_Report,
    Capabilities_Import,
    Capabilities_Export,
  } = access;
  return (
    <div className="tabs-container">
      <div className="flex row mt-5 justify-start items-start">
        <Tab
          section={"Capabilities"}
          active={active}
          setActive={setActive}
          isDisabled={!Capabilities}
        />
        <Tab
          section={"Visual Editor"}
          active={active}
          setActive={setActive}
          isDisabled={!Capabilities_Visual_editor}
        />
        <Tab
          section={"Report"}
          active={active}
          setActive={setActive}
          isDisabled={!Capabilities_Report}
        />
        <Tab
          section={"Import"}
          active={active}
          setActive={setActive}
          isDisabled={!Capabilities_Import}
        />
        <Tab
          section={"Export"}
          active={active}
          setActive={setActive}
          isDisabled={!Capabilities_Export}
        />
      </div>
    </div>
  );
};

export default CapabilitiesManagement;
