import React from 'react';

import { AiOutlineUser } from "react-icons/ai";
import logOutHandler from '../layout/handlers/logOutHandler';


const Header = ({ userName, newLogout }) => {
		
		return (
				<header
				style={{
					width: "100%",
				}}
				className="body-font"
			>
				<div className="flex justify-between items-center">
					<span></span>
					<nav className="flex flex-wrap items-center text-base justify-center">
						<h3>You are logged in as <strong>{userName}</strong></h3>
						<AiOutlineUser
							style={{
								marginRight: 20,
								fontSize: 30,
							}}
						/>
						<h2
							className="accent-color font-bold cursor-pointer"
							onClick={() => {logOutHandler(); newLogout()}}
						>
							Log out
						</h2>
						<div
							style={{
								marginLeft: 30,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "row",
								background: "white",
								paddingLeft: 10,
								paddingRight: 10,
								borderRadius: 10,
							}}
						>
						</div>
					</nav>
				</div>
			</header>
		);
};

export default Header;