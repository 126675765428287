import React from 'react';
import { BsTrash3 } from 'react-icons/bs'
import { FiEdit } from 'react-icons/fi'


const RelationshipsItem = ({
        item
    }) => {
    return (
        <tr key={item.id} className="Scenario-item">
            <td className="px-4 py-3">{item.name}</td>
            <td className="px-4 py-3">{item.class}</td>
        </tr>
    );
};

export default RelationshipsItem;