import * as xlsx from "xlsx" 
import gsap, { Elastic } from "gsap"
import { useState, useEffect  } from "react"
import SelectSet from "../../components/ui/inputs/SelectSet"
import RegularButton from "../ui/buttons/RegularButton"

function CapabilityExport({ capability, capabilityBySetForExport, activeSet, setActiveSet, set, access }) {
	const [downloadEverything, setDownloadEverything] = useState(false)

	const { 
		Capabilities_Export_Select_set,
		Capabilities_Export_Download_every_set_toggle,
		Capabilities_Export_Download,
	} = access
	useEffect(() => {
		document.querySelectorAll('.export__download-button').forEach(button => {  
			let duration = 3000,
				svg = button.querySelector('svg'),
				svgPath = new Proxy({
					y: null,
					smoothing: null
				}, {
					set(target, key, value) {
						target[key] = value;
						if(target.y !== null && target.smoothing !== null) {
							svg.innerHTML = getPath(target.y, target.smoothing, null);
						}
						return true;
					},
					get(target, key) {
						return target[key];
					}
				});
	
			button.style.setProperty('--duration', duration)
	
			svgPath.y = 20;
			svgPath.smoothing = 0
	
			button.addEventListener('click', e => {
				
				e.preventDefault()
	
				if(!button.classList.contains('loading')) {
	
					button.classList.add('loading')
	
					gsap.to(svgPath, {
						smoothing: .3,
						duration: duration * .065 / 1000
					});
	
					gsap.to(svgPath, {
						y: 12,
						duration: duration * .265 / 1000,
						delay: duration * .065 / 1000,
						ease: Elastic.easeOut.config(1.12, .4)
					});
	
					setTimeout(() => {
						svg.innerHTML = getPath(0, 0, [
							[3, 14],
							[8, 19],
							[21, 6]
						]);
					}, duration / 2);
	
				}
	
			});
	
		})
	}, [])

	function getPoint(point, i, a, smoothing) {
		let cp = (current, previous, next, reverse) => {
				let p = previous || current,
					n = next || current,
					o = {
						length: Math.sqrt(Math.pow(n[0] - p[0], 2) + Math.pow(n[1] - p[1], 2)),
						angle: Math.atan2(n[1] - p[1], n[0] - p[0])
					},
					angle = o.angle + (reverse ? Math.PI : 0),
					length = o.length * smoothing;
				return [current[0] + Math.cos(angle) * length, current[1] + Math.sin(angle) * length]
			},
			cps = cp(a[i - 1], a[i - 2], point, false),
			cpe = cp(point, a[i - 1], a[i + 1], true);
		return `C ${cps[0]},${cps[1]} ${cpe[0]},${cpe[1]} ${point[0]},${point[1]}`
	}

	function getPath(update, smoothing, pointsNew) {
		let points = pointsNew ? pointsNew : [
				[4, 12],
				[12, update],
				[20, 12]
			],
			d = points.reduce((acc, point, i, a) => i === 0 ? `M ${point[0]},${point[1]}` : `${acc} ${getPoint(point, i, a, smoothing)}`, '')
		return `<path d="${d}" />`
	}

    const findCapabilityParent = (cap) => {
        let level1,
            level2,
            level3
          
        if (cap.first_level > 0 && cap.second_level === 0 && cap.third_level === 0) {
          level1 = cap.name
    
          return {...cap, level1, level2, level3}
        }
    
        if (cap.second_level > 0 && cap.third_level === 0) {
          level2 = cap.name
          const capL1 = capability.find(item => item.first_level === cap.first_level && item.second_level === 0 && item.third_level === 0)
          level1 = capL1 && capL1.name
    
          return {...cap, level1, level2, level3}
        }
        if (cap.third_level > 0) {
          level3 = cap.name
          const capL2 = capability.find(item => item.first_level === cap.first_level && item.second_level === cap.second_level)
          const capL1 = capability.find(item => item.first_level === cap.first_level && item.second_level === 0 && item.third_level === 0)
          level2 = capL2 && capL2.name
          level1 = capL1 && capL1.name
    
          return {...cap, level1, level2, level3}
        }
    
        return {...cap, level1, level2, level3 }
    }

	const getSetName = (setId) => {
		const result = set.find(item => item.id === setId)
		return result === undefined ? null : result.name
	}
	
	const formatForExport = (data) => {
		for (const obj of data) {
			obj.set = getSetName(obj.universal_item_set_id)
			delete obj.universal_item_set_id
			delete obj.first_level
			delete obj.second_level
			delete obj.third_level
			delete obj.maturity
			delete obj.parent
			delete obj.level
		}
	}

    const excelExport = () => {
        const extendedCapability = []

		if (downloadEverything) {
			capability.forEach(item => extendedCapability.push(findCapabilityParent(item)))
		} else {
			capabilityBySetForExport.forEach(item => extendedCapability.push(findCapabilityParent(item)))
		}

		formatForExport(extendedCapability)

        const wb = xlsx.utils.book_new()
        const ws = xlsx.utils.json_to_sheet(extendedCapability)
    
        xlsx.utils.book_append_sheet(wb, ws, 'capabilities-export')
    
        xlsx.writeFile(wb, 'Exported-capabilities.xlsx')
    }

  return (
    <div className="reports">
		<div className="top-table mt-4">
            <div className="plus-minus">
				<SelectSet
					activeSet={activeSet} 
					onChange={(e) => setActiveSet(e.target.value)} 
					notRestricted={Capabilities_Export_Select_set}
					set={set}
				/>
                <h3 className="ml-4">Select a set to download</h3>
            </div>
        </div>
		<div className="signup-terms mb-5">
			<input 
				disabled={!Capabilities_Export_Download_every_set_toggle} 
				className={!Capabilities_Export_Download_every_set_toggle ? "disabled" : ""} 
				type="checkbox" 
				id="terms" 
				name="terms" 
				onChange={() => setDownloadEverything(!downloadEverything)} 
				checked={downloadEverything ? true : false}
			/>
			<label htmlFor="">Download every set available combined as one file?</label>
		</div>
        <div className="form-container flex justify-start items-center flex-col flex-wrap w-94">
			<RegularButton
				className="export__download-button"
				onClick={excelExport}
				notRestricted={Capabilities_Export_Download && capability.length}
			>
				<ul>
					<li>&#68;ownload</li>
					<li>&#68;ownloading</li>
					<li>Downloaded</li>
				</ul>
				<div>
					<svg viewBox="0 0 24 24"></svg>
				</div>
			</RegularButton>
		</div>
	</div>
  );
}

export default CapabilityExport;