import { useEffect, useState, Fragment, useRef } from "react";

import { GoPlus } from "react-icons/go";
import { TbZoomInArea, TbZoomOutArea } from "react-icons/tb";
import { HiOutlineFolderAdd } from "react-icons/hi";
import { BsPinAngle } from "react-icons/bs";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import RingSpinner from "../ui/spinners/RingSpinner";
import Modal from "../Modal/Modal";

import { formatValueIntoNumOrNull } from "../../handlers/formatValueIntoNumOrNull";

import IconButton from "../ui/buttons/IconButton";
import SelectSet from "../ui/inputs/SelectSet";
import {
  CardsContainer,
  Input,
  TitleBar,
  Title,
  Cross,
  Up,
  Down,
  CardContainer,
  TaskList,
  NewTaskBar,
  NewTaskButton,
  TaskContainer,
  TaskContent,
  CardsContainerHorizontal,
  CardContainerHorizontal,
  TaskHorizontalContainer,
  LaneContainer,
  TaskListHorizontal,
  TitleBarHorizontal,
  TitleHorizontal,
  CrossHorizontal,
  DownHorizontal,
  CardContainerHorizontalContent,
} from "./components/styledComponents";

const NewVisualEditor = ({
  loading,
  fetched,
  dataArray,
  organizationName,
  set,
  setError,
  activeSet,
  setActiveSet,
  importCapability,
  deleteCapabilities,
  access,
}) => {
  const widthValue = JSON.parse(localStorage.getItem("settings-column-width"));
  const backlogExcists = dataArray.find(
    (i) => i.first_level && i.second_level === 0
  );
  const [columnWidth, setColumnWidth] = useState(
    widthValue ? widthValue.width : 150
  );
  const [toggleBacklog, setToggleBacklog] = useState(true);
  const [saved, setSaved] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [dataBySet, setDataBySet] = useState({
    cardOrder: [],
    cards: {},
    tasks: {},
    taskLevels: {},
    // cardOrderHorizontal: [],
    // cardsHorizontal: {},
    // tasksHorizontal: {},
    // taskLevelsHorizontal: {},
  });
  const [showDelete, setShowDelete] = useState(false);
  const [deleteItem, setDeleteItem] = useState("");
  const [deleteItemType, setDeleteItemType] = useState("");

  const wideDivRef = useRef(null);
  const backlogRef = useRef(null);

  const [isScreenFreezed, setIsScreenFreezed] = useState(false);

  const {
    Capabilities_Visual_editor_Add_capability,
    Capabilities_Visual_editor_Toggle_backlog,
    Capabilities_Visual_editor_Select_set,
    // Capabilities_Visual_editor_Delete_capability,
    // Capabilities_Visual_editor_Edit_capability,
    // Capabilities_Visual_editor_Restore,
    // Capabilities_Visual_editor_Save,
    // Capabilities_Visual_editor_Zoom_in,
    // Capabilities_Visual_editor_Zoom_out,
  } = access;

  useEffect(() => {
    const getActiveSet = set.find((item) => item.name === activeSet);
    const setId = getActiveSet ? getActiveSet.id : 0;
    if (dataArray.length > 0)
      setDataBySet(
        createDataset(
          dataArray.filter((item) => item.universal_item_set_id === setId)
        )
      );
  }, [set, dataArray, activeSet]);

  const handleMoveToRight = () => {
    const divElement = wideDivRef.current;
    if (divElement) {
      setTimeout(() => {
        const distance = divElement.scrollWidth + divElement.clientWidth;
        let scrollAmount = 0;
        const slideTimer = setInterval(function () {
          divElement.scrollLeft += 50;
          scrollAmount += 50;
          if (scrollAmount >= distance) {
            window.clearInterval(slideTimer);
          }
        }, 5);

        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 100);
    }
  };

  const handleMoveToBot = () => {
    const divElement = wideDivRef.current;
    if (divElement) {
      setTimeout(() => {
        const distance = divElement.scrollHeight - divElement.clientHeight;
        let scrollAmount = 0;
        const slideTimer = setInterval(function () {
          divElement.scrollTop += 50;
          scrollAmount += 50;
          if (scrollAmount >= distance) {
            window.clearInterval(slideTimer);
          }
        }, 5);

        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      }, 100);
    }
  };

  const onRemoveCardConfirmed = (cardID) => {
    const card = cards[cardID];
    const { taskIds } = card;
    if (card.oldId) {
      const { caps } = JSON.parse(
        localStorage.getItem("deleting-capabilities")
      );
      const itemsToRemove = [...caps];
      for (const taskId of taskIds) {
        const task = tasks[taskId];
        const { oldId } = task;
        oldId && itemsToRemove.push(oldId);

        const { levelsIds } = task;
        for (const levelId of levelsIds) {
          const { oldId } = taskLevels[levelId];
          oldId && itemsToRemove.push(oldId);
        }
      }

      localStorage.setItem(
        "deleting-capabilities",
        JSON.stringify({ caps: [...itemsToRemove, card.oldId] })
      );
    }
    taskIds.forEach((taskID) => delete tasks[taskID]);
    const newCardOrder = cardOrder.filter((id) => id !== cardID);
    setCardOrder(newCardOrder);
    delete cards[cardID];
    reCalcCards(newCardOrder);
    setCards(cards);
    setTasks(tasks);
  };

  const onRemoveTaskConfirmed = (taskID, cardID) => {
    const newTaskIds = cards[cardID].taskIds.filter((id) => id !== taskID);
    const task = tasks[taskID];
    setCards({ ...cards, [cardID]: { ...cards[cardID], taskIds: newTaskIds } });
    if (task.oldId) {
      const { caps } = JSON.parse(
        localStorage.getItem("deleting-capabilities")
      );
      const itemsToRemove = [...caps];
      const { levelsIds } = task;
      for (const levelId of levelsIds) {
        const { oldId } = taskLevels[levelId];
        oldId && itemsToRemove.push(oldId);
      }
      localStorage.setItem(
        "deleting-capabilities",
        JSON.stringify({ caps: [...itemsToRemove, task.oldId] })
      );
    }

    delete tasks[taskID];
    reCalcTasks(cards[cardID], newTaskIds);
    setTasks(tasks);
  };

  const reCalcCards = (cardsOrder) => {
    setSaved(false);
    setIsChanged(true);
    for (const cardId of cardsOrder) {
      const isBacklog = cardsOrder.find((item) => item === "card-0");
      const cardPosition = !isBacklog
        ? cardsOrder.indexOf(cardId) + 1
        : cardsOrder.indexOf(cardId);
      const card = cards[cardId];
      if (cardPosition === 0 && isBacklog) continue;
      // if (cardPosition === 1 && !isBacklog) continue
      if (card.first_level === cardPosition) continue;
      // if (card.first_level === cardPosition && card.first_level === 0) continue
      if (card.id === "card-0") continue;

      card.first_level = cardPosition;

      setCards({ ...cards, [cardId]: card });
      reCalcTasks(card, card.taskIds);

      for (const taskId of card.taskIds) {
        reCalcLevels(tasks[taskId], tasks[taskId].levelsIds);
      }
    }
  };

  const columnWidthIncrease = () => {
    if (columnWidth < 500) {
      setColumnWidth((columnWidth) => columnWidth + 20);
      localStorage.setItem(
        "settings-column-width",
        JSON.stringify({ width: columnWidth })
      );
    }
  };

  const columnWidthDecrease = () => {
    if (columnWidth > 100) {
      setColumnWidth((columnWidth) => columnWidth - 20);
      localStorage.setItem(
        "settings-column-width",
        JSON.stringify({ width: columnWidth })
      );
    }
  };

  const prepareDataForUpload = () => {
    const getActiveSet = set.find((item) => item.name === activeSet);
    const setId = getActiveSet ? getActiveSet.id : 0;
    const result = [];
    const cardsIdsArray = Object.keys(cards);
    const tasksIdsArray = Object.keys(tasks);
    const taskLevelsIdsArray = Object.keys(taskLevels);

    for (const cardId of cardsIdsArray) {
      const card = cards[cardId];
      const id = card.oldId ? card.oldId : null;
      result.push({
        id,
        name: card.title,
        first_level: card.first_level,
        second_level: card.second_level,
        third_level: card.third_level,
        universal_item_set_id: setId,
        parent: card.parent,
        layout: card.layout,
        lane: formatValueIntoNumOrNull(card.lane),
      });
    }

    for (const taskId of tasksIdsArray) {
      const task = tasks[taskId];
      const id = task.oldId ? task.oldId : null;
      result.push({
        id,
        name: task.content,
        first_level: task.first_level,
        second_level: task.second_level,
        third_level: task.third_level,
        universal_item_set_id: setId,
        parent: task.parent,
        layout: task.layout,
        lane: Number(task.lane),
      });
    }

    for (const levelId of taskLevelsIdsArray) {
      const level = taskLevels[levelId];
      const id = level.oldId ? level.oldId : null;
      result.push({
        id,
        name: level.content,
        first_level: level.first_level,
        second_level: level.second_level,
        third_level: level.third_level,
        universal_item_set_id: setId,
        parent: level.parent,
        layout: level.layout,
        lane: Number(level.lane),
      });
    }

    return result;
  };

  const uploadData = () => {
    const formatDataWithoutParents = (data) => {
      const parentChangeHandler = (l1, l2, l3, capabilities) => {
        // backlog capability
        if (Number(l1) === 0) {
          return -1;
        }

        // first level capability
        if (Number(l2) === 0 && Number(l3) === 0) {
          return 0;
        }

        let parentL1 = l1,
          parentL2 = l2,
          parentL3 = 0;

        // capability has has l1, l2 or l3
        if (Number(l2) !== 0) {
          if (Number(l3) !== 0) {
            parentL3 = 0;
          } else {
            parentL2 = 0;
          }
        } else {
          return -1;
        }

        const isParent = capabilities.find(
          (item) =>
            "" + item.first_level + item.second_level + item.third_level ===
            "" + parentL1 + parentL2 + parentL3
        );

        // capability has no parent
        if (!isParent) {
          return -2;
        }

        return isParent.id;
      };

      for (const i of data) {
        i["parent"] = parentChangeHandler(
          i.first_level,
          i.second_level,
          i.third_level,
          data
        );
      }

      return data;
    };

    const dataWithoutParents = prepareDataForUpload();
    const data = formatDataWithoutParents(dataWithoutParents);
    const { caps } = JSON.parse(localStorage.getItem("deleting-capabilities"));
    caps && caps.length && deleteCaps(caps);
    importCapability({ capabilities: data });
    setSaved(true);
    setIsChanged(false);
  };

  const deleteCaps = (deleteArray) => {
    deleteCapabilities({ deleteArray });
    localStorage.setItem("deleting-capabilities", JSON.stringify({ caps: [] }));
  };

  const createDataset = (dataArray) => {
    const cardOrderArray = [];
    let cardsObject = {};
    let tasksObject = {};
    let taskLevelsObject = {};

    const formatLevels = (dataArray) => {
      const firstLevelsArray = [];
      const secondLevelsArray = [];
      const thirdLevelsArray = [];

      for (const item of dataArray) {
        if (item.second_level === 0 && item.third_level === 0) {
          firstLevelsArray.push(item);
          continue;
        }
        if (item.second_level !== 0 && item.third_level === 0) {
          secondLevelsArray.push(item);
          continue;
        }
        if (item.second_level !== 0 && item.third_level !== 0) {
          thirdLevelsArray.push(item);
          continue;
        }
      }

      return {
        firstLevelsVertical: firstLevelsArray,
        secondLevelsVertical: secondLevelsArray,
        thirdLevelsVertical: thirdLevelsArray,
      };
    };

    const { firstLevelsVertical, secondLevelsVertical, thirdLevelsVertical } =
      formatLevels(dataArray);

    firstLevelsVertical.sort((a, b) => a.first_level - b.first_level);
    secondLevelsVertical.sort((a, b) => a.second_level - b.second_level);
    thirdLevelsVertical.sort((a, b) => a.third_level - b.third_level);

    const createCard = (card) => {
      const { first_level, name, layout, lane } = card;
      const id = "card-" + first_level;
      const oldId = card.id;

      return {
        id,
        oldId,
        first_level,
        second_level: 0,
        third_level: 0,
        title: name,
        taskIds: [],
        layout,
        lane,
      };
    };

    const createTask = (task) => {
      const { first_level, second_level, name, layout, lane } = task;
      const parent = "card-" + first_level;
      const id = "task-" + first_level + second_level;
      const oldId = task.id;
      const taskObj = {
        id,
        oldId,
        first_level,
        second_level,
        third_level: 0,
        content: name,
        levelsIds: [],
        layout,
        lane,
      };
      return { parent, taskObj };
    };

    const createTaskLevel = (taskLevel) => {
      const { first_level, second_level, third_level, name, layout, lane } =
        taskLevel;
      const id = "task-level-" + first_level + second_level + third_level;
      const parent = "task-" + first_level + second_level;
      const oldId = taskLevel.id;

      const level = {
        id,
        oldId,
        content: name,
        first_level,
        second_level,
        third_level,
        layout,
        lane,
      };
      return { parent, level };
    };

    for (const item of firstLevelsVertical) {
      const card = createCard(item);
      const { id } = card;
      cardOrderArray.push(id);

      cardsObject = {
        ...cardsObject,
        [id]: card,
      };
    }

    for (const item of secondLevelsVertical) {
      const { parent, taskObj } = createTask(item);
      const { id } = taskObj;
      cardsObject[parent] && cardsObject[parent].taskIds.push(id);
      tasksObject = {
        ...tasksObject,
        [id]: taskObj,
      };
    }

    for (const item of thirdLevelsVertical) {
      const { parent, level } = createTaskLevel(item);
      const { id } = level;
      tasksObject[parent] && tasksObject[parent].levelsIds.push(id);
      taskLevelsObject = {
        ...taskLevelsObject,
        [id]: level,
      };
    }

    localStorage.setItem(
      "backup-capabilities",
      JSON.stringify({
        cardOrder: [...cardOrderArray],
        cards: { ...cardsObject },
        tasks: { ...tasksObject },
        taskLevels: { ...taskLevelsObject },
      })
    );
    return {
      cardOrder: [...cardOrderArray],
      cards: { ...cardsObject },
      tasks: { ...tasksObject },
      taskLevels: { ...taskLevelsObject },
    };
  };

  const restoreCapabilities = () => {
    const restoredData = localStorage.getItem("backup-capabilities");
    localStorage.setItem("editing-capabilities", restoredData);
    localStorage.setItem("deleting-capabilities", JSON.stringify({ caps: [] }));
    setDataBySet(JSON.parse(restoredData));
    setIsChanged(false);
    setSaved(true);
    setCardOrder(JSON.parse(restoredData).cardOrder);
    setCards(JSON.parse(restoredData).cards);
    setTasks(JSON.parse(restoredData).tasks);
    setTaskLevels(JSON.parse(restoredData).taskLevels);
  };

  const ITEM_TYPES = {
    CARD: "card",
    TASK: "task",
    LEVEL: "level",
  };

  const [dataset, _] = useState(() => {
    const DATASET = {
      cardOrder: [],
      cards: {},
      tasks: {},
      taskLevels: {},
    };
    const savedDataset = localStorage.getItem("editing-capabilities");
    const deletingCaps = localStorage.getItem("deleting-capabilities");

    !deletingCaps &&
      localStorage.setItem(
        "deleting-capabilities",
        JSON.stringify({ caps: [] })
      );

    const initialValue = JSON.parse(savedDataset);

    return initialValue || DATASET;
  });

  const [cardOrder, setCardOrder] = useState(dataset.cardOrder);
  const [cards, setCards] = useState(dataset.cards);
  const [tasks, setTasks] = useState(dataset.tasks);
  const [taskLevels, setTaskLevels] = useState(dataset.taskLevels);

  function getRandomID() {
    return (Math.random() + 1).toString(36).substring(7);
  }

  useEffect(() => {
    localStorage.setItem("editing-capabilities", JSON.stringify(dataBySet));
    setCardOrder(dataBySet.cardOrder);
    setCards(dataBySet.cards);
    setTasks(dataBySet.tasks);
    setTaskLevels(dataBySet.taskLevels);
  }, [dataBySet]);

  useEffect(() => {
    localStorage.setItem(
      "editing-capabilities",
      JSON.stringify({
        cardOrder,
        cards,
        tasks,
        taskLevels,
      })
    );
  }, [cardOrder, cards, tasks, taskLevels]);

  if (dataArray.length === 0) {
    return (
      <div className="chart-notification">
        <p className="chart-notification__text">No data for that chart</p>
      </div>
    );
  }

  const reCalcTasks = (card, taskIds) => {
    setSaved(false);
    setIsChanged(true);
    for (const taskId of taskIds) {
      const taskIndex = taskIds.indexOf(taskId) + 1;
      const task = tasks[taskId];
      if (task.second_level !== taskIndex) {
        task.second_level = taskIndex;
        reCalcLevels(task, task.levelsIds);
      }
      if (task.first_level !== card.first_level) {
        task.first_level = card.first_level;
        reCalcLevels(task, task.levelsIds);
      }
    }
  };

  const reCalcLevels = (task, levelsIds) => {
    setSaved(false);
    setIsChanged(true);
    for (const levelId of levelsIds) {
      const levelIndex = levelsIds.indexOf(levelId) + 1;
      const level = taskLevels[levelId];

      if (level.first_level !== task.first_level) {
        level.first_level = task.first_level;
      }
      if (level.second_level !== task.second_level) {
        level.second_level = task.second_level;
      }
      if (level.third_level !== levelIndex) {
        level.third_level = levelIndex;
      }
      if (level.lane !== task.lane) {
        level.lane = task.lane;
      }
      if (level.layout !== task.layout) {
        level.layout = task.layout;
      }
    }
  };

  const onAddNewTask = (cardID, content, first_level, layout, lane) => {
    setSaved(false);
    setIsChanged(true);
    const getLastSecondLevel = (tasksIds, tasks) => {
      let hiestLevel = 0;
      for (const taskId of tasksIds) {
        if (tasks[taskId].second_level > hiestLevel) {
          hiestLevel = tasks[taskId].second_level;
        }
      }
      return hiestLevel + 1;
    };
    const second_level = getLastSecondLevel(cards[cardID].taskIds, tasks);
    const newTask = {
      id: "task-" + getRandomID(),
      content,
      first_level,
      second_level,
      third_level: 0,
      levelsIds: [],
      layout: layout ? layout : "vertical",
      lane: lane ? lane : null,
    };

    const { id } = newTask;
    setTasks({
      ...tasks,
      [id]: newTask,
    });
    const newTaskIds = Array.from(cards[cardID].taskIds);
    newTaskIds.push(newTask.id);
    setCards({ ...cards, [cardID]: { ...cards[cardID], taskIds: newTaskIds } });
  };

  const onRemoveLevel = (levelID, taskID) => {
    const currentTask = tasks[taskID];
    const newLevelsIds = currentTask.levelsIds.filter((id) => id !== levelID);
    reCalcLevels(currentTask, newLevelsIds);
    currentTask.levelsIds = newLevelsIds;
    const level = taskLevels[levelID];
    if (level.oldId) {
      const { caps } = JSON.parse(
        localStorage.getItem("deleting-capabilities")
      );
      localStorage.setItem(
        "deleting-capabilities",
        JSON.stringify({ caps: [...caps, level.oldId] })
      );
    }
    const updateTasks = { ...tasks, [taskID]: currentTask };
    setTasks(updateTasks);
    delete taskLevels[levelID];
    setTaskLevels(taskLevels);
  };

  const onUpgradeLevel = (card, task, taskLevel) => {
    // create
    const cardID = card.id;
    const taskID = task.id;
    const levelID = taskLevel.id;
    const content = taskLevel.content;
    const first_level = taskLevel.first_level;
    setSaved(false);
    setIsChanged(true);

    const getLastSecondLevel = (tasksIds, tasks) => {
      let hiestLevel = 0;
      for (const taskId of tasksIds) {
        if (tasks[taskId].second_level > hiestLevel) {
          hiestLevel = tasks[taskId].second_level;
        }
      }
      return hiestLevel + 1;
    };
    const second_level = getLastSecondLevel(cards[cardID].taskIds, tasks);
    const id = "task-" + getRandomID();
    const newTask = {
      id,
      content,
      first_level,
      second_level,
      third_level: 0,
      levelsIds: [],
      layout: "vertical",
      lane: null,
    };

    const newTasks = {
      ...tasks,
      [id]: newTask,
    };
    const newTaskIds = Array.from(cards[cardID].taskIds);
    newTaskIds.push(newTask.id);
    setCards({ ...cards, [cardID]: { ...cards[cardID], taskIds: newTaskIds } });

    // remove
    const currentTask = tasks[taskID];
    const newLevelsIds = currentTask.levelsIds.filter((id) => id !== levelID);
    reCalcLevels(currentTask, newLevelsIds);
    currentTask.levelsIds = newLevelsIds;
    const level = taskLevels[levelID];
    if (level.oldId) {
      const { caps } = JSON.parse(
        localStorage.getItem("deleting-capabilities")
      );
      localStorage.setItem(
        "deleting-capabilities",
        JSON.stringify({ caps: [...caps, level.oldId] })
      );
    }
    const updateTasks = { ...newTasks, [taskID]: currentTask };
    setTasks(updateTasks);
    delete taskLevels[levelID];
    setTaskLevels(taskLevels);

    return id;
  };

  const addBacklogCard = (taskIds = []) => {
    return {
      id: "card-0",
      title: "Backlog",
      first_level: 0,
      second_level: 0,
      third_level: 0,
      taskIds,
      oldId: null,
      layout: "backlog",
      lane: null,
    };
  };

  const onUpgradeTask = (taskItem, card) => {
    if (taskItem.levelsIds.length > 0) {
      setError(
        "You can't change the level of a capability that includes other capabilities"
      );
      setTimeout(() => {
        setError("");
      }, 2000);
      return;
    }

    const title = taskItem.content;
    const taskID = taskItem.id;
    const { taskIds } = card;

    setSaved(false);
    setIsChanged(true);

    const cardID = "card-" + getRandomID();

    const findLastFirstLevel = (data) => {
      let length = data.length;
      if (data[0] !== "card-backlog") {
        length++;
      }
      return length;
    };

    const newCard = {
      id: cardID,
      title,
      first_level: findLastFirstLevel(cardOrder),
      second_level: 0,
      third_level: 0,
      taskIds: [],
      oldId: taskItem.oldId,
      layout: "vertical",
      lane: null,
    };
    const newCardOrder = Array.from(cardOrder);

    if (cards[newCardOrder[0]].first_level === 0) {
      newCardOrder.splice(newCardOrder.length, 0, cardID);
    } else {
      newCardOrder.push(cardID);
    }

    const newTaskIds = taskIds.filter((id) => id !== taskID);
    let newCards = { ...cards, [cardID]: { ...newCard } };
    const task = tasks[taskID];
    const { levelsIds } = task;

    if (levelsIds.length > 0) {
      if (newCards[newCardOrder[0]].first_level !== 0) {
        const backlog = addBacklogCard([taskID]);
        newCardOrder.unshift(backlog.id);
        newCards = { ...newCards, [backlog.id]: { ...backlog } };
      } else {
        newCards[newCardOrder[0]].taskIds.push(taskID);
      }

      task.content += "-duplicate";

      reCalcTasks(newCards[newCardOrder[0]], newCards[newCardOrder[0]].taskIds);
    } else {
      if (task.oldId) {
        const { caps } = JSON.parse(
          localStorage.getItem("deleting-capabilities")
        );
        const itemsToRemove = [...caps];
        for (const levelId of levelsIds) {
          const { oldId } = taskLevels[levelId];
          oldId && itemsToRemove.push(oldId);
        }

        localStorage.setItem(
          "deleting-capabilities",
          JSON.stringify({ caps: [...itemsToRemove, task.oldId] })
        );
      }

      delete tasks[taskID];
    }

    setTasks(tasks);
    setCardOrder(newCardOrder);
    setCards({ ...newCards, [card.id]: { ...card, taskIds: newTaskIds } });
    reCalcTasks(card, newTaskIds);
    handleMoveToRight();
  };

  const onDegradeTask = (task, card) => {
    if (task.levelsIds.length > 0) {
      setError(
        "You can't change the level of a capability that includes other capabilities"
      );
      setTimeout(() => {
        setError("");
      }, 2000);
      return;
    }

    const { content } = task;
    const { first_level } = task;
    const { second_level } = task;
    const lastSecondLevelElement = card.taskIds.length;
    const taskIds = card.taskIds.filter((item) => item !== task.id);
    taskIds.push(task.id);

    // create

    setSaved(false);
    setIsChanged(true);

    const third_level = 1;
    const newLevel = {
      id: "level-" + getRandomID(),
      content,
      first_level,
      second_level: lastSecondLevelElement,
      third_level,
    };
    setTaskLevels({
      ...taskLevels,
      [newLevel.id]: newLevel,
    });

    const newLevelsIds = Array.from(tasks[task.id].levelsIds);
    newLevelsIds.push(newLevel.id);

    setCards({ ...cards, [card.id]: { ...card, taskIds } });
    setTasks({
      ...tasks,
      [task.id]: {
        ...task,
        levelsIds: newLevelsIds,
        second_level: lastSecondLevelElement,
        content: content + "-duplicate",
      },
    });
    reCalcTasks(card, taskIds);
  };

  const onDegradeCard = (card) => {
    if (card.taskIds.length > 0) {
      setError(
        "You can't change the level of a capability that includes other capabilities"
      );
      setTimeout(() => {
        setError("");
      }, 2000);
      return;
    }

    const cardID = card.id;

    setSaved(false);
    setIsChanged(true);

    const getLastSecondLevel = (tasksIds, tasks) => {
      let hiestLevel = 0;
      for (const taskId of tasksIds) {
        if (tasks[taskId].second_level > hiestLevel) {
          hiestLevel = tasks[taskId].second_level;
        }
      }
      return hiestLevel + 1;
    };

    let newCards = cards;
    if (cardOrder[0] !== "card-0") {
      const backlog = addBacklogCard();
      const { id } = backlog;
      newCards = { ...newCards, [id]: backlog };
      cardOrder.unshift(id);
    }

    const second_level = getLastSecondLevel(newCards["card-0"].taskIds, tasks);
    const id = "task-" + getRandomID();
    const newTask = {
      id,
      content: card.title,
      first_level: 0,
      second_level,
      third_level: 0,
      levelsIds: [],
      layout: "vertical",
      lane: null,
    };

    const newTasks = {
      ...tasks,
      [id]: newTask,
    };
    const newTaskIds = Array.from(newCards["card-0"].taskIds);
    newTaskIds.push(id);

    const { caps } = JSON.parse(localStorage.getItem("deleting-capabilities"));
    if (card.oldId)
      localStorage.setItem(
        "deleting-capabilities",
        JSON.stringify({ caps: [...caps, card.oldId] })
      );

    const newCardOrder = cardOrder.filter((id) => id !== cardID);
    setCardOrder(newCardOrder);
    delete newCards[cardID];
    reCalcCards(newCardOrder);
    setTasks(newTasks);
    setCards({
      ...newCards,
      "card-0": { ...newCards["card-0"], taskIds: newTaskIds },
    });
  };

  function DragDropCards({
    cardOrder,
    setCardOrder,
    cards,
    setCards,
    tasks,
    setTasks,
    levels,
  }) {
    const [editing, setEditing] = useState(null);

    const onDragEnd = (result) => {
      const twoParts = (str) => {
        const arrayOfParts = str.split("&");
        return {
          cardId: arrayOfParts[0],
          verticalFirstLevel: arrayOfParts[1],
        };
      };
      const { destination, source, draggableId, type } = result;
      if (
        !destination ||
        (destination.index === 0 && !toggleBacklog && type === "card") ||
        (destination.droppableId === source.droppableId &&
          destination.index === source.index)
      ) {
        return;
      }

      if (type === ITEM_TYPES.CARD) {
        reorderCards(source, destination, draggableId);
      } else if (type === ITEM_TYPES.TASK) {
        const { cardId: startCardId } = twoParts(source?.droppableId);
        const {
          cardId: finishCardId,
          verticalFirstLevel: finishCardVerticalFirstLevel,
        } = twoParts(destination?.droppableId);
        const start = cards[startCardId];
        const finish = cards[finishCardId];
        const updatedLane =
          finishCardVerticalFirstLevel && finish.layout === "horizontal"
            ? finishCardVerticalFirstLevel
            : null;
        if (start.id === finish.id) {
          reCalcTasks(finish, finish.taskIds);
          reorderTasksWithinCard(
            start,
            source.index,
            destination.index,
            draggableId
          );
          const updatedTask = {
            ...tasks[draggableId],
            lane: Number(updatedLane),
          };
          setTasks({ ...tasks, [draggableId]: updatedTask });
        } else {
          reCalcTasks(finish, finish.taskIds);
          moveTask(start, finish, source.index, destination.index, draggableId);
          const updatedTask = {
            ...tasks[draggableId],
            lane: Number(updatedLane),
            layout: finish.layout,
          };
          setTasks({ ...tasks, [draggableId]: updatedTask });
        }
      } else {
        // type === level
        const start = tasks[source.droppableId];
        const finish = tasks[destination.droppableId];
        if (start.id === finish.id) {
          reorderLevelsWithinTask(
            start,
            source.index,
            destination.index,
            draggableId
          );
        } else {
          moveLevel(
            start,
            finish,
            source.index,
            destination.index,
            draggableId
          );
        }
      }
    };

    const reorderCards = (source, destination, draggableId) => {
      const newCardOrder = Array.from(cardOrder);
      newCardOrder.splice(source.index, 1);
      newCardOrder.splice(destination.index, 0, draggableId);
      reCalcCards(newCardOrder);
      setCardOrder(newCardOrder);
    };

    const reorderTasksWithinCard = (
      card,
      sourceIdx,
      destinationIdx,
      draggableId
    ) => {
      const newTaskIds = Array.from(card.taskIds);
      newTaskIds.splice(sourceIdx, 1);
      newTaskIds.splice(destinationIdx, 0, draggableId);
      reCalcTasks(card, newTaskIds);
      setCards({
        ...cards,
        [card.id]: {
          ...card,
          taskIds: newTaskIds,
        },
      });
    };

    const reorderLevelsWithinTask = (
      task,
      sourceIdx,
      destinationIdx,
      draggableId
    ) => {
      const newLevelsIds = Array.from(task.levelsIds);
      newLevelsIds.splice(sourceIdx, 1);
      newLevelsIds.splice(destinationIdx, 0, draggableId);
      reCalcLevels(task, newLevelsIds);
      setTasks({
        ...tasks,
        [task.id]: {
          ...task,
          levelsIds: newLevelsIds,
        },
      });
    };

    const moveTask = (
      start,
      finish,
      sourceIdx,
      destinationIdx,
      draggableId
    ) => {
      const startTaskIds = Array.from(start.taskIds);
      startTaskIds.splice(sourceIdx, 1);
      const newStart = {
        ...start,
        taskIds: startTaskIds,
      };

      const finishTaskIds = Array.from(finish.taskIds);
      finishTaskIds.splice(destinationIdx, 0, draggableId);
      const newFinish = {
        ...finish,
        taskIds: finishTaskIds,
      };

      reCalcTasks(newStart, newStart.taskIds);
      reCalcTasks(newFinish, newFinish.taskIds);
      setCards({
        ...cards,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      });
    };

    const moveLevel = (
      start,
      finish,
      sourceIdx,
      destinationIdx,
      draggableId
    ) => {
      const startTaskIds = Array.from(start.levelsIds);
      startTaskIds.splice(sourceIdx, 1);
      const newStart = {
        ...start,
        levelsIds: startTaskIds,
      };

      const finishTaskIds = Array.from(finish.levelsIds);
      finishTaskIds.splice(destinationIdx, 0, draggableId);
      const newFinish = {
        ...finish,
        levelsIds: finishTaskIds,
      };
      reCalcLevels(newStart, newStart.levelsIds);
      reCalcLevels(newFinish, newFinish.levelsIds);
      setTasks({
        ...tasks,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      });
    };

    const onSaveTitleEdit = (cardID, newTitle) => {
      if (newTitle.trim() === "") {
        onRemoveCard(cardID);
      } else if (newTitle !== cards[cardID].title) {
        setCards({
          ...cards,
          [cardID]: {
            ...cards[cardID],
            title: newTitle,
          },
        });
      }
      setEditing(null);
      setSaved(false);
      setIsChanged(true);
    };

    const onRemoveCard = (cardID) => {
      const cardItem = cards[cardID];
      if (cardItem.taskIds.length) {
        setDeleteItem({ ...cardItem, name: cardItem.title });
        setDeleteItemType("visualEditorCard");
        setShowDelete(true);
        return;
      }
      onRemoveCardConfirmed(cardID);
    };

    const onRemoveCardConfirmed = (cardID) => {
      const card = cards[cardID];
      const { taskIds } = card;
      if (card.oldId) {
        const { caps } = JSON.parse(
          localStorage.getItem("deleting-capabilities")
        );
        const itemsToRemove = [...caps];
        for (const taskId of taskIds) {
          const task = tasks[taskId];
          const { oldId } = task;
          oldId && itemsToRemove.push(oldId);

          const { levelsIds } = task;
          for (const levelId of levelsIds) {
            const { oldId } = taskLevels[levelId];
            oldId && itemsToRemove.push(oldId);
          }
        }

        localStorage.setItem(
          "deleting-capabilities",
          JSON.stringify({ caps: [...itemsToRemove, card.oldId] })
        );
      }
      taskIds.forEach((taskID) => delete tasks[taskID]);
      const newCardOrder = cardOrder.filter((id) => id !== cardID);
      setCardOrder(newCardOrder);
      delete cards[cardID];
      reCalcCards(newCardOrder);
      setCards(cards);
      setTasks(tasks);
    };

    const onRemoveTask = (taskID, cardID) => {
      const taskItem = tasks[taskID];
      if (taskItem.levelsIds.length) {
        setDeleteItem({ ...taskItem, name: taskItem.content, cardID });
        setDeleteItemType("visualEditorTask");
        setShowDelete(true);
        return;
      }
      onRemoveTaskConfirmed(taskID, cardID);
    };

    const onRemoveTaskConfirmed = (taskID, cardID) => {
      const newTaskIds = cards[cardID].taskIds.filter((id) => id !== taskID);
      const task = tasks[taskID];
      setCards({
        ...cards,
        [cardID]: { ...cards[cardID], taskIds: newTaskIds },
      });
      if (task.oldId) {
        const { caps } = JSON.parse(
          localStorage.getItem("deleting-capabilities")
        );
        const itemsToRemove = [...caps];
        const { levelsIds } = task;
        for (const levelId of levelsIds) {
          const { oldId } = taskLevels[levelId];
          oldId && itemsToRemove.push(oldId);
        }
        localStorage.setItem(
          "deleting-capabilities",
          JSON.stringify({ caps: [...itemsToRemove, task.oldId] })
        );
      }

      delete tasks[taskID];
      reCalcTasks(cards[cardID], newTaskIds);
      setTasks(tasks);
    };

    const onSaveTaskEdit = (taskID, cardID, newContent) => {
      if (newContent.trim() === "") {
        onRemoveTask(taskID, cardID);
      } else if (newContent !== tasks[taskID].content) {
        setTasks({
          ...tasks,
          [taskID]: { ...tasks[taskID], content: newContent },
        });
      }
      setEditing(null);
      setSaved(false);
      setIsChanged(true);
    };
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="flex flex-col w-full ml-10">
          <Droppable
            droppableId="vertical-cards"
            direction="horizontal"
            type="card"
          >
            {(provided) => (
              <CardsContainer
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {cardOrder.map((id, index) => {
                  const card = cards[id];
                  if (card.layout === "vertical" || card.layout === "backlog") {
                    const cardTasks = card.taskIds.map(
                      (taskId) => tasks[taskId]
                    );
                    const ref = card.id === "card-0" ? backlogRef : null;
                    const isBacklogVisible =
                      card.id === "card-0" && toggleBacklog && true;

                    return (
                      <Card
                        key={card.id}
                        card={card}
                        tasks={cardTasks}
                        levels={levels}
                        index={index}
                        ref={ref}
                        backlog={isBacklogVisible}
                        // onFocusClick={() => onFocusClick(card.id)}
                        onSaveTitleEdit={(title) =>
                          onSaveTitleEdit(card.id, title)
                        }
                        onRemoveCard={() => onRemoveCard(card.id)}
                        onRemoveTask={(taskId) => onRemoveTask(taskId, card.id)}
                        onAddNewTask={(content) =>
                          onAddNewTask(card.id, content, card.first_level)
                        }
                        onSaveTaskEdit={(taskID, newContent) =>
                          onSaveTaskEdit(taskID, card.id, newContent)
                        }
                        onTitleDoubleClick={() => setEditing(card.id)}
                        onTaskDoubleClick={(task) => setEditing(task.id)}
                        isTitleEditing={editing === card.id}
                        isTaskEditing={(task) => editing === task.id}
                        setEditing={setEditing}
                        editing={editing}
                      />
                    );
                  }
                  return null;
                })}
                {provided.placeholder}
              </CardsContainer>
            )}
          </Droppable>
          <Droppable
            droppableId="horizontal-cards"
            direction="vertical"
            type="card"
          >
            {(provided) => (
              <div className="flex flex-col w-full overflow-visible">
                <CardsContainerHorizontal
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  backlog={!toggleBacklog}
                  columnWidth={columnWidth}
                >
                  {cardOrder.map((id, index) => {
                    const card = cards[id];
                    if (card.layout === "horizontal") {
                      const cardTasks = card.taskIds.map(
                        (taskId) => tasks[taskId]
                      );
                      const ref = card.id === "card-0" ? backlogRef : null;
                      const isBacklogVisible =
                        card.id === "card-0" && toggleBacklog && true;

                      return (
                        <CardHorizontal
                          key={card.id}
                          card={card}
                          tasks={cardTasks}
                          levels={levels}
                          index={index}
                          ref={ref}
                          backlog={isBacklogVisible}
                          width={cardOrder.length * columnWidth}
                          // onFocusClick={() => onFocusClick(card.id)}
                          onSaveTitleEdit={(title) =>
                            onSaveTitleEdit(card.id, title)
                          }
                          onRemoveCard={() => onRemoveCard(card.id)}
                          onRemoveTask={(taskId) =>
                            onRemoveTask(taskId, card.id)
                          }
                          onAddNewTask={(content, layout, lane) =>
                            onAddNewTask(
                              card.id,
                              content,
                              card.first_level,
                              layout,
                              lane
                            )
                          }
                          onSaveTaskEdit={(taskID, newContent) =>
                            onSaveTaskEdit(taskID, card.id, newContent)
                          }
                          onTitleDoubleClick={() => setEditing(card.id)}
                          onTaskDoubleClick={(task) => setEditing(task.id)}
                          isTitleEditing={editing === card.id}
                          isTaskEditing={(task) => editing === task.id}
                          setEditing={setEditing}
                          editing={editing}
                        />
                      );
                    }
                    return null;
                  })}
                  {provided.placeholder}
                </CardsContainerHorizontal>
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    );
  }

  function Card(props) {
    const [isAddingNewTask, setIsAddingNewTask] = useState(false);
    const onSaveTask = (content) => {
      if (content.trim() !== "") {
        props.onAddNewTask(content);
      }
      setIsAddingNewTask(false);
    };
    const tasks = props.tasks.filter((task) => task);
    const ifBacklogThenDisable = props.card.id === "card-0";

    return (
      <Draggable
        draggableId={props.card.id}
        index={props.index}
        isDragDisabled={ifBacklogThenDisable}
      >
        {(provided) => (
          <CardContainer
            ref={provided.innerRef}
            {...provided.draggableProps}
            id={props.card.id}
            {...provided.dragHandleProps}
            backlog={props.backlog}
            columnWidth={columnWidth}
          >
            <TitleBar>
              <div className="text-sm flex w-full justify-center relative">
                <p className="align-center">
                  {props.card.layout[0] +
                    "." +
                    props.card.first_level +
                    "." +
                    props.card.second_level +
                    "." +
                    props.card.third_level +
                    "/" +
                    props.card.lane}
                </p>
                <Cross onClick={props.onRemoveCard}>x</Cross>
              </div>
              <div className="flex flex-col items-center justify-center">
                {props.isTitleEditing ? (
                  <EditInput
                    key={props.card.id}
                    value={props.card.title}
                    onSave={props.onSaveTitleEdit}
                    fontSize=".8em"
                  />
                ) : (
                  <Title onDoubleClick={props.onTitleDoubleClick}>
                    {props.card.title}
                  </Title>
                )}
              </div>
            </TitleBar>
            <Droppable droppableId={props.card.id} type="task">
              {(provided, snapshot) => (
                <Fragment>
                  <TaskList
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    isDraggingOver={snapshot.isDraggingOver}
                  >
                    {tasks.map((task, index) => (
                      <Task
                        key={task.id}
                        card={props.card}
                        task={task}
                        levels={props.levels}
                        levelsIds={task.levelsIds}
                        index={index}
                        onSaveTaskEdit={(content) =>
                          props.onSaveTaskEdit(task.id, content)
                        }
                        onRemoveTask={() => props.onRemoveTask(task.id)}
                        onTaskDoubleClick={() => props.onTaskDoubleClick(task)}
                        isTaskEditing={props.isTaskEditing(task)}
                        editing={props.editing}
                        setEditing={props.setEditing}
                      />
                    ))}
                  </TaskList>
                  {provided.placeholder}
                </Fragment>
              )}
            </Droppable>
            <NewTaskBar>
              {isAddingNewTask ? (
                <EditInput
                  key="newtask"
                  value=""
                  onSave={onSaveTask}
                  margin-top="8px"
                />
              ) : (
                <NewTaskButton onClick={() => setIsAddingNewTask(true)}>
                  + Add new
                </NewTaskButton>
              )}
            </NewTaskBar>
            <Down onClick={() => onDegradeCard(props.card)}>v</Down>
          </CardContainer>
        )}
      </Draggable>
    );
  }

  const LaneHorizontal = (props) => {
    const [isAddingNewTask, setIsAddingNewTask] = useState(false);
    const onSaveTask = (content) => {
      if (content.trim() !== "") {
        props.onAddNewTask(content, "horizontal", props.first_level);
      }
      setIsAddingNewTask(false);
    };

    return (
      <LaneContainer columnWidth={columnWidth}>
        <Droppable
          droppableId={`${props.card.id}&${props.first_level}`}
          type="task"
        >
          {(provided, snapshot) => (
            <TaskListHorizontal
              ref={provided.innerRef}
              {...provided.droppableProps}
              isDraggingOver={snapshot.isDraggingOver}
              columnWidth={props.columnWidth}
            >
              {props.tasks.map((task, index) => {
                if (task.lane === props.first_level) {
                  return (
                    <TaskHorizontal
                      key={task.id}
                      columnWidth={props.columnWidth}
                      card={props.card}
                      task={task}
                      levels={props.levels}
                      levelsIds={task.levelsIds}
                      index={index}
                      onSaveTaskEdit={(content) =>
                        props.onSaveTaskEdit(task.id, content)
                      }
                      onRemoveTask={() => props.onRemoveTask(task.id)}
                      onTaskDoubleClick={() => props.onTaskDoubleClick(task)}
                      isTaskEditing={props.isTaskEditing(task)}
                      editing={props.editing}
                      setEditing={props.setEditing}
                    />
                  );
                }
                return null;
              })}
              {provided.placeholder}
            </TaskListHorizontal>
          )}
        </Droppable>
        <NewTaskBar>
          {isAddingNewTask ? (
            <EditInput
              key="newtask"
              value=""
              onSave={onSaveTask}
              margin-top="8px"
            />
          ) : (
            <NewTaskButton
              onClick={() => {
                setIsAddingNewTask(true);
              }}
            >
              + Add new
            </NewTaskButton>
          )}
        </NewTaskBar>
      </LaneContainer>
    );
  };
  function CardHorizontal(props) {
    const tasks = props.tasks.filter((task) => task);
    const ifBacklogThenDisable = props.card.id === "card-0";

    return (
      <Draggable
        key={Math.random()}
        draggableId={props.card.id}
        index={props.index}
        isDragDisabled={ifBacklogThenDisable}
      >
        {(provided) => (
          <CardContainerHorizontal
            key={props.card.id}
            ref={provided.innerRef}
            {...provided.draggableProps}
            id={props.card.id}
            {...provided.dragHandleProps}
            backlog={props.backlog}
            columnWidth={columnWidth}
            onAddNewTask={props.onAddNewTask}
            firstLevelsAmount={cards.length}
          >
            <TitleBarHorizontal>
              <CrossHorizontal onClick={props.onRemoveCard}>x</CrossHorizontal>
              <div className="flex flex-col justify-center items-center whitespace-nowrap [writing-mode:vertical-rl] rotate-180">
                <p className="flex align-center w-full mr-2">
                  {"h." + props.card.first_level + ".0.0/" + props.card.lane}
                </p>
                {props.isTitleEditing ? (
                  <EditInput
                    key={props.card.id}
                    value={props.card.title}
                    onSave={props.onSaveTitleEdit}
                    fontSize=".8em"
                  />
                ) : (
                  <TitleHorizontal onDoubleClick={props.onTitleDoubleClick}>
                    {props.card.title}
                  </TitleHorizontal>
                )}
              </div>
              <DownHorizontal onClick={() => onDegradeCard(props.card)}>
                v
              </DownHorizontal>
            </TitleBarHorizontal>
            <CardContainerHorizontalContent>
              {Object.values(cards).map((firstLevel) => {
                const { first_level, layout } = firstLevel;
                if (layout !== "vertical" || first_level === 0) return null;

                return (
                  <LaneHorizontal
                    first_level={first_level}
                    tasks={tasks}
                    columWidth={columnWidth}
                    card={props.card}
                    isTaskEditing={props.isTaskEditing}
                    onAddNewTask={props.onAddNewTask}
                  />
                );
              })}
            </CardContainerHorizontalContent>
          </CardContainerHorizontal>
        )}
      </Draggable>
    );
  }

  const Level = (props) => {
    return (
      <TaskContainer>
        <Draggable draggableId={props.item.id} index={props.index}>
          {(provided, snapshot) => (
            <div
              className="third-level-capability gap-0 mb-2 flex-col w-full"
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              isdragging={snapshot.isdragging}
              onDoubleClick={props.onLevelDoubleClick}
            >
              <div className="leading-title">
                <Up
                  onClick={() =>
                    onUpgradeLevel(props.card, props.task, props.item)
                  }
                >
                  ^
                </Up>
                {props.item.layout[0] +
                  "." +
                  props.item.first_level +
                  "." +
                  props.item.second_level +
                  "." +
                  props.item.third_level +
                  "/" +
                  props.item.lane}
                <Cross onClick={props.onRemoveLevel}>x</Cross>
              </div>
              <div>
                {props.editing === props.item.id ? (
                  <EditInput
                    key={props.item.id}
                    value={props.item.content}
                    onSave={props.onSaveLevelEdit}
                  />
                ) : (
                  props.item.content
                )}
              </div>
              {provided.placeholder}
            </div>
          )}
        </Draggable>
      </TaskContainer>
    );
  };

  function Task(props) {
    const onAddNewLevel = (
      content,
      first_level,
      second_level,
      lane,
      layout
    ) => {
      setSaved(false);
      setIsChanged(true);
      const third_level = props.task.levelsIds.length + 1;
      const newLevel = {
        id: "level-" + getRandomID(),
        content,
        first_level,
        second_level,
        third_level,
        layout: layout ? layout : "vertical",
        lane: lane ? lane : null,
      };
      setTaskLevels({
        ...taskLevels,
        [newLevel.id]: newLevel,
      });
      const newLevelsIds = Array.from(tasks[props.task.id].levelsIds);
      newLevelsIds.push(newLevel.id);
      setTasks({
        ...tasks,
        [props.task.id]: { ...tasks[props.task.id], levelsIds: newLevelsIds },
      });
    };

    const [isAddingNewLevel, setIsAddingNewLevel] = useState(false);
    const onSaveLevel = (content) => {
      if (content.trim() !== "") {
        onAddNewLevel(
          content,
          props.task.first_level,
          props.task.second_level,
          props.task.lane,
          props.task.layout
        );
      }
      setIsAddingNewLevel(false);
    };

    const onSaveLevelEdit = (levelID, taskID, newContent) => {
      if (newContent.trim() === "") {
        onRemoveLevel(levelID, taskID);
      } else if (newContent !== taskLevels[levelID].content) {
        setTaskLevels({
          ...taskLevels,
          [levelID]: { ...taskLevels[levelID], content: newContent },
        });
      }
      props.setEditing(null);
      setSaved(false);
      setIsChanged(true);
    };

    const levels1 = props.levelsIds.map((levelId) => taskLevels[levelId]);
    const levels = levels1.filter((i) => i);

    return (
      <TaskContainer>
        <Draggable draggableId={props.task.id} index={props.index}>
          {(provided, snapshot) => (
            <TaskContent
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              isdragging={snapshot.isdragging}
            >
              <div className="flex flex-col items-center w-full">
                <div className="text-sm">
                  <Up onClick={() => onUpgradeTask(props.task, props.card)}>
                    ^
                  </Up>
                  {props.task.layout[0] +
                    "." +
                    props.task.first_level +
                    "." +
                    props.task.second_level +
                    "." +
                    props.task.third_level +
                    "/" +
                    props.task.lane}
                  <Cross onClick={props.onRemoveTask}>x</Cross>
                </div>
                {props.isTaskEditing ? (
                  <EditInput
                    key={props.task.id}
                    value={props.task.content}
                    onSave={props.onSaveTaskEdit}
                  />
                ) : (
                  <>
                    <p
                      className="task-title"
                      onDoubleClick={props.onTaskDoubleClick}
                    >
                      {props.task.content}
                    </p>
                    <Down onClick={() => onDegradeTask(props.task, props.card)}>
                      v
                    </Down>
                  </>
                )}
                <Droppable droppableId={props.task.id} type="level">
                  {(provided, snapshot) => (
                    <Fragment>
                      <TaskList
                        className="level-container"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        isDraggingOver={snapshot.isDraggingOver}
                      >
                        {levels.map((item, index) => (
                          <Level
                            key={item.id}
                            card={props.card}
                            task={props.task}
                            item={item}
                            index={index}
                            onSaveLevelEdit={(content) =>
                              onSaveLevelEdit(item.id, props.task.id, content)
                            }
                            onLevelDoubleClick={() => props.setEditing(item.id)}
                            editing={props.editing}
                            onRemoveLevel={() => {
                              onRemoveLevel(item.id, props.task.id);
                            }}
                          />
                        ))}
                      </TaskList>
                      {provided.placeholder}
                    </Fragment>
                  )}
                </Droppable>
              </div>
              <NewTaskBar>
                {isAddingNewLevel ? (
                  <EditInput
                    key="newlevel"
                    value=""
                    onSave={onSaveLevel}
                    margin-top="8px"
                  />
                ) : (
                  <NewTaskButton onClick={() => setIsAddingNewLevel(true)}>
                    + Add new
                  </NewTaskButton>
                )}
              </NewTaskBar>
            </TaskContent>
          )}
        </Draggable>
      </TaskContainer>
    );
  }
  function TaskHorizontal(props) {
    const onAddNewLevel = (
      content,
      first_level,
      second_level,
      lane,
      layout
    ) => {
      setSaved(false);
      setIsChanged(true);
      const third_level = props.task.levelsIds.length + 1;
      const newLevel = {
        id: "level-" + getRandomID(),
        content,
        first_level,
        second_level,
        third_level,
        layout: layout ? layout : "vertical",
        lane: lane ? lane : null,
      };
      setTaskLevels({
        ...taskLevels,
        [newLevel.id]: newLevel,
      });
      const newLevelsIds = Array.from(tasks[props.task.id].levelsIds);
      newLevelsIds.push(newLevel.id);
      setTasks({
        ...tasks,
        [props.task.id]: { ...tasks[props.task.id], levelsIds: newLevelsIds },
      });
    };

    const [isAddingNewLevel, setIsAddingNewLevel] = useState(false);
    const onSaveLevel = (content) => {
      if (content.trim() !== "") {
        onAddNewLevel(
          content,
          props.task.first_level,
          props.task.second_level,
          props.task.lane,
          props.task.layout
        );
      }
      setIsAddingNewLevel(false);
    };

    const onSaveLevelEdit = (levelID, taskID, newContent) => {
      if (newContent.trim() === "") {
        onRemoveLevel(levelID, taskID);
      } else if (newContent !== taskLevels[levelID].content) {
        setTaskLevels({
          ...taskLevels,
          [levelID]: { ...taskLevels[levelID], content: newContent },
        });
      }
      props.setEditing(null);
      setSaved(false);
      setIsChanged(true);
    };

    const levels1 = props.levelsIds.map((levelId) => taskLevels[levelId]);
    const levels = levels1.filter((i) => i);

    return (
      <TaskHorizontalContainer columnWidth={columnWidth}>
        <Draggable draggableId={props.task.id} index={props.index}>
          {(provided, snapshot) => (
            <TaskContent
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              isdragging={snapshot.isdragging}
            >
              <div className="flex flex-col items-center w-full">
                <div className="flex justify-between text-sm">
                  <Up onClick={() => onUpgradeTask(props.task, props.card)}>
                    ^
                  </Up>
                  {props.task.layout[0] +
                    "." +
                    props.task.first_level +
                    "." +
                    props.task.second_level +
                    "." +
                    props.task.third_level +
                    "/" +
                    props.task.lane}
                  <Cross onClick={props.onRemoveTask}>x</Cross>
                </div>
                {props.isTaskEditing ? (
                  <EditInput
                    key={props.task.id}
                    value={props.task.content}
                    onSave={props.onSaveTaskEdit}
                  />
                ) : (
                  <>
                    <p
                      className="task-title"
                      onDoubleClick={props.onTaskDoubleClick}
                    >
                      {props.task.content}
                    </p>
                    <Down onClick={() => onDegradeTask(props.task, props.card)}>
                      v
                    </Down>
                  </>
                )}
                <Droppable droppableId={props.task.id} type="level">
                  {(provided, snapshot) => (
                    <Fragment>
                      <TaskList
                        className="level-container"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        isDraggingOver={snapshot.isDraggingOver}
                      >
                        {levels.map((item, index) => (
                          <Level
                            key={item.id}
                            card={props.card}
                            task={props.task}
                            item={item}
                            index={index}
                            onSaveLevelEdit={(content) =>
                              onSaveLevelEdit(item.id, props.task.id, content)
                            }
                            onLevelDoubleClick={() => props.setEditing(item.id)}
                            editing={props.editing}
                            onRemoveLevel={() => {
                              onRemoveLevel(item.id, props.task.id);
                            }}
                          />
                        ))}
                      </TaskList>
                      {provided.placeholder}
                    </Fragment>
                  )}
                </Droppable>
              </div>
              <NewTaskBar>
                {isAddingNewLevel ? (
                  <EditInput
                    key="newlevel"
                    value=""
                    onSave={onSaveLevel}
                    margin-top="8px"
                  />
                ) : (
                  <NewTaskButton onClick={() => setIsAddingNewLevel(true)}>
                    + Add new
                  </NewTaskButton>
                )}
              </NewTaskBar>
            </TaskContent>
          )}
        </Draggable>
      </TaskHorizontalContainer>
    );
  }

  function EditInput(props) {
    const [val, setVal] = useState(props.value);
    return (
      <Input
        type="text"
        autoFocus
        value={val}
        onChange={(e) => setVal(e.target.value)}
        onKeyPress={(event) => {
          if (event.key === "Enter" || event.key === "Escape") {
            props.onSave(val);
            event.preventDefault();
            event.stopPropagation();
          }
        }}
        onBlur={() => props.onSave(val)}
        fontSize={props.fontSize}
        margin={props.margin}
      />
    );
  }

  const onAddNewCard = (title = "**New**", taskIds = []) => {
    setSaved(false);
    setIsChanged(true);
    const findLastFirstLevel = (data) => {
      let length = data.length;
      if (data[0] !== "card-0") {
        length++;
      }
      return length;
    };

    const id = "card-" + findLastFirstLevel(cardOrder);
    const newCard = {
      id,
      title,
      first_level: findLastFirstLevel(cardOrder),
      second_level: 0,
      third_level: 0,
      taskIds,
      layout: "vertical",
      lane: null,
    };
    const newCardOrder = Array.from(cardOrder);
    if (cards[newCardOrder[0]].first_level === 0) {
      newCardOrder.splice(newCardOrder.length, 0, id);
    } else {
      newCardOrder.push(id);
    }
    setCards({
      ...cards,
      [id]: newCard,
    });
    setCardOrder(newCardOrder);
  };
  const onAddNewCardHorizontal = (title = "**New**", taskIds = []) => {
    setSaved(false);
    setIsChanged(true);
    const findLastFirstLevel = (data) => {
      let length = data.length;
      if (data[0] !== "card-0") {
        length++;
      }
      return length;
    };

    const id = "card-" + findLastFirstLevel(cardOrder);
    const newCard = {
      id,
      title,
      first_level: findLastFirstLevel(cardOrder),
      second_level: 0,
      third_level: 0,
      taskIds,
      layout: "horizontal",
      lane: null,
    };
    const newCardOrder = Array.from(cardOrder);
    if (cards[newCardOrder[0]].first_level === 0) {
      newCardOrder.splice(newCardOrder.length, 0, id);
    } else {
      newCardOrder.push(id);
    }
    setCards({
      ...cards,
      [id]: newCard,
    });
    setCardOrder(newCardOrder);
  };

  if (loading || !fetched) {
    return (
      <div className="flex justify-center items-center mt-10">
        <RingSpinner />
      </div>
    );
  }

  return (
    <>
      {showDelete && (
        <Modal
          setShowDelete={setShowDelete}
          deleteId={deleteItem.id}
          deleteItem={deleteItem}
          deleteItemType={deleteItemType}
          onRemoveCardConfirmed={onRemoveCardConfirmed}
          onRemoveTaskConfirmed={onRemoveTaskConfirmed}
        />
      )}
      <div className="reports">
        <div className="top-report">
          <div className="plus-minus">
            <IconButton
              className="icon-img"
              onClick={() => {
                onAddNewCard();
                handleMoveToRight();
              }}
              notRestricted={
                Capabilities_Visual_editor_Add_capability && !isScreenFreezed
              }
              stateActive={isScreenFreezed}
              tooltip="New vertical capability"
            >
              <HiOutlineFolderAdd className="-rotate-90" />
            </IconButton>
            <IconButton
              className="icon-img"
              onClick={() => {
                onAddNewCardHorizontal();
                handleMoveToBot();
              }}
              notRestricted={
                Capabilities_Visual_editor_Add_capability && !isScreenFreezed
              }
              stateActive={isScreenFreezed}
              tooltip="New horizontal capability"
            >
              <HiOutlineFolderAdd />
            </IconButton>
            <IconButton
              className="icon-img"
              onClick={() => setToggleBacklog(!toggleBacklog)}
              notRestricted={Capabilities_Visual_editor_Toggle_backlog}
              stateActive={!toggleBacklog}
              tooltip="Toggle backlog"
            >
              <GoPlus />
            </IconButton>
            <IconButton
              className="icon-img"
              onClick={() => setIsScreenFreezed(!isScreenFreezed)}
              notRestricted={Capabilities_Visual_editor_Add_capability}
              stateActive={isScreenFreezed}
              tooltip="Freeze elements on the screen"
            >
              <BsPinAngle />
            </IconButton>
            <SelectSet
              activeSet={activeSet}
              onChange={(e) => setActiveSet(e.target.value)}
              notRestricted={Capabilities_Visual_editor_Select_set}
              set={set}
              tooltip="Select active set"
            />
          </div>
          <div className="plus-minus ml-10">
            <IconButton
              className="icon-img"
              onClick={columnWidthIncrease}
              notRestricted={columnWidth < 500}
              tooltip="Increase width"
            >
              <TbZoomInArea />
            </IconButton>
            <IconButton
              className="icon-img"
              onClick={columnWidthDecrease}
              notRestricted={columnWidth > 100}
              tooltip="Decrease width"
            >
              <TbZoomOutArea />
            </IconButton>
          </div>
          <div className="flex flex-row mb-5 mt-6 justify-end items-center px-10">
            <IconButton
              className="text-white bg-red-500 border-0 mr-2 py-1.5 px-9 focus:outline-none hover:bg-red-600 rounded"
              onClick={restoreCapabilities}
              notRestricted={!saved}
            >
              Restore
            </IconButton>
            <IconButton
              className="text-white bg-second-color border-0 py-1.5 px-12 focus:outline-none rounded"
              onClick={uploadData}
              notRestricted={isChanged}
            >
              Save
            </IconButton>
          </div>
        </div>
        <div
          className={
            isScreenFreezed
              ? "capabilities-report overflow-x-hidden overflow-y-hidden"
              : "capabilities-report overflow-x-scroll  overflow-y-scroll"
          }
          ref={wideDivRef}
        >
          <div className="capabilities-report__chart">
            <div className="flex w-full flex-col">
              <DragDropCards
                cardOrder={cardOrder}
                setCardOrder={setCardOrder}
                cards={cards}
                setCards={setCards}
                tasks={tasks}
                setTasks={setTasks}
                levels={taskLevels}
                toggleBacklog={toggleBacklog}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewVisualEditor;
