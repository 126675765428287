import React, { useState, useEffect } from "react";

import ReportsManagement from "../../components/Reports/ReportsManagement";
import NewBCM from "../../components/Reports/NewBCM";
import PERT2 from "../../components/Reports/PERT2";
import GoalsTreeChart from "../../components/Goals/GoalsTreeChart";

const ReportsPage = ({
  getCapability,
  getActivity,
  getGoal,
  getOrganization,
  capability,
  activity,
  goal,
  organization,
  userOrganization,
  set,
  access,
}) => {
  const [active, setActive] = useState("BCM");
  const [activeSet, setActiveSet] = useState("-");
  const [error, setError] = useState("");

  useEffect(() => {
    getCapability();
    getActivity();
    getGoal();
    getOrganization({ id: userOrganization });
  }, []);

  return (
    <>
      <ReportsManagement
        active={active}
        setActive={setActive}
        access={access.Tabs}
      />
      {active === "BCM" && (
        <NewBCM
          dataArray={capability}
          organizationName={organization && organization.name}
          set={set}
          setActive={setActive}
          activeSet={activeSet}
          setActiveSet={setActiveSet}
          access={access.Buttons}
          setError={setError}
        />
      )}
      {active === "PERT" && (
        <PERT2 dataArray={activity} access={access.Buttons} />
      )}
      {active === "Goals Tree" && (
        <GoalsTreeChart dataArray={goal} access={access.Buttons} />
      )}
    </>
  );
};

export default ReportsPage;
