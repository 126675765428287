import React, { useContext } from 'react';
import { BsTrash3 } from 'react-icons/bs'

import IconButton from '../../ui/buttons/IconButton'
import { PeopleTabContext } from '../../../contexts/PeopleTabContext'

const Person = ({ data, item, showDelete, setShowDelete, setDeleteId, setDeleteItem, access }) => {
	const { Settings_Organization_Edit_organization } = access
	const [
		setUpdateChosen,
		setAddChosen,
		setUpdateId,
		setUpdateName,
		setUpdateEmail,
		setUpdateEndDate,
		setUpdatePosition,
		setUpdateRole,
		setUpdateStartDate,
		setUpdateSystemUser
	] = useContext(PeopleTabContext)

	const handleDoubleClick = event => {
        if (event.detail === 2) {
            setUpdateChosen(true);
            setAddChosen(false);
            setUpdateId(item.id);
			setUpdateName(item.name);
			setUpdateEmail(item.email);
			setUpdateEndDate(item.endDate);
			setUpdatePosition(item.position);
			setUpdateRole(item.role);
			setUpdateStartDate(item.startDate);
			setUpdateSystemUser(item.systemUser)
		}
    }

	return (
		<tr key={item.id} className="Scenario-item" onClick={(e) => {handleDoubleClick(e)}}>
			<td className="px-4 py-3">{item.id}</td>
			<td className="px-4 py-3">{item.name}</td>
			<td className="px-4 py-3">{item.position}</td>
			<td className="px-4 py-3">{item.role}</td>
			<td className="px-4 py-3">{item.startDate}</td>
			<td className="px-4 py-3">{item.endDate}</td>
			<td className="px-4 py-3">{item.systemUser ? 'yes' : 'no'}</td>
			<td className="px-4 py-3 flex items-center justify-between mt-2">{item.email}
				<IconButton
					className="ml-5 capability-controls__button"
					onClick={() => {
						setShowDelete(!showDelete)
						setDeleteId(item.id)
						setDeleteItem({name: item.name})
					}}
					notRestricted={Settings_Organization_Edit_organization && (data && data.length > 1)}
				>
					<BsTrash3 alt="delete" />
				</IconButton>
			</td>
		</tr>
	)
}

export default Person