const IconButton = ({ id = null, tooltip, children, notRestricted, className, onClick, stateActive = false }) => {
    const findSuitedClassName = () => {
        if (!notRestricted) return className + " disabled"
        if (stateActive) return className + "-active"

        return className
    }

    const cName = findSuitedClassName()
    return (
        <button id={id} data-tooltip={tooltip} className={cName} onClick={onClick} disabled={!notRestricted}>
            { children }
        </button>
    )
}

export default IconButton