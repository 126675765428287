import React from 'react';
import Tab from '../Tab';

const ScenarioManagement = ({ active, setActive, access}) => {
    const { Scenarios, Scenarios_Report, Scenarios_Import, Scenarios_Export } = access

    return (
        <div className="tabs-container">
            <div className="flex row mt-5 justify-start items-start">
                <Tab
                    section={"Scenario"}
                    active={active}
                    setActive={setActive}
                    isDisabled={!Scenarios}
                />
                <Tab
                    section={"Report"}
                    active={active}
                    setActive={setActive}
                    isDisabled={true}
                    // isDisabled={!Scenarios_Report}
                />
                <Tab
                    section={"Import"}
                    active={active}
                    setActive={setActive}
                    // isDisabled={true}
                    isDisabled={!Scenarios_Import}
                />
                <Tab
                    section={"Export"}
                    active={active}
                    setActive={setActive}
                    isDisabled={!Scenarios_Export}
                />
            </div>
        </div>
    )
};

export default ScenarioManagement;