import React from 'react'
import Tab from '../Tab'

const ApplicationsManagement = ({ active, setActive, access }) => {
    const { Applications, Applications_Report, Applications_Import, Applications_Export } = access
    return (
        <div className="tabs-container">
            <div className="flex row mt-5 justify-start items-start">
                <Tab
                    section={"Applications"}
                    active={active}
                    setActive={setActive}
                    isDisabled={!Applications}
                />
                <Tab
                    section={"Report"}
                    active={active}
                    setActive={setActive}
                    isDisabled={true}
                    // isDisabled={!Applications_Report}
                />
                <Tab
                    section={"Import"}
                    active={active}
                    setActive={setActive}
                    // isDisabled={true}
                    isDisabled={!Applications_Import}
                />
                <Tab
                    section={"Export"}
                    active={active}
                    setActive={setActive}
                    // isDisabled={true}
                    isDisabled={!Applications_Export}
                />
            </div>
        </div>
    )
}

export default ApplicationsManagement