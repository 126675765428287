import React, { useState } from 'react';

import { VscGear } from "react-icons/vsc";
import { VscClearAll } from 'react-icons/vsc'


const TableHeaderItem = ({ 
    sortData, 
    name, 
    value, 
    activeSort, 
    setSortingName, 
    setSortingType, 
    setSortingActivity, 
    setSortingProcess, 
    setSortingPortfolio, 
    setSortingProducts 
}) => {
    const [filter, setFilter] = useState(false)
    const [sortName, setSortName] = useState('')
    const [portfolio, setPortfolio] = useState('')
    const [products, setProducts] = useState('')
    const [type, setType] = useState('')
    const [activity, setActivity] = useState('')
    const [process, setProcess] = useState('')

    let headerItemType = ''
    let headerItemFilterActive = ''

    const Checkbox = ({ type = "checkbox", name, checked = false, onChange }) => {
        return (
          <input type={type} name={name} checked={checked} onChange={onChange} />
        )
    }

    const checkboxesActive = [
        {
            name: 'Yes',
            key: '1',
            label: 'Yes',
        },
        {
            name: 'No',
            key: '0',
            label: 'No',
        },
    ]

    const setFieldClear = () => {
        setFilter(!filter)
    }

    const handleKeyPress = (e) => {
        if(e.key === 'Enter' || e.key === 'Escape'){
            setFilter(!filter)
        }
    }

    const closeFilter = (e) => {
        if(e.key === 'Escape'){
            setFilter(!filter)
        }
    }
    
    const nameChangeHandler = (e) => {
        setSortName(e.target.value)
        setSortingName(e.target.value)
    }

    const productsChangeHandler = (e) => {
        setProducts(e.target.value)
        setSortingProducts(e.target.value)
    }

    const portfolioChangeHandler = (e) => {
        setPortfolio({...type, [e.target.name] : e.target.checked }) 
        setSortingPortfolio({...type, [e.target.name] : e.target.checked })
    }

    const typeChangeHandler = (e) => {
        setType(e.target.value)
        setSortingType(e.target.value)
    }

    const activityChangeHandler = (e) => {
        setActivity(e.target.value)
        setSortingActivity(e.target.value)
    }

    const processChangeHandler = (e) => {
        setProcess(e.target.value)
        setSortingProcess(e.target.value)
    }


    if (value === 'name') {
        headerItemFilterActive = sortName
        headerItemType = <div className='h-11 w-72 shadow rounded py-2 px-3 filter-input'>
                            <input
                                value={sortName} 
                                className="" 
                                type="text" 
                                placeholder="Start typing here"
                                onChange={nameChangeHandler}>
                            </input>
                            <button className='clear-field' onClick={() => {setFieldClear(); setSortName(''); setSortingName('') }}><VscClearAll /></button>
                        </div>
    }
   
    if (value === 'portfolio-flag') {
        headerItemFilterActive = portfolio
        headerItemType = 
                    <div className="filter-select">
                        <form className="filter-select__form" onChange={portfolioChangeHandler}>
                            {checkboxesActive.map(item => (
                                <label key={item.key}><br/>
                                    <Checkbox name={item.name} checked={portfolio[item.name]} onChange={portfolioChangeHandler} />
                                    {item.name}
                                </label>
                                ))}

                        </form>
                        <button className='clear-field' onClick={() => {setFieldClear(); setPortfolio(''); setSortingPortfolio('') }}><VscClearAll /></button>
                    </div>
    }
    if (value === 'list-of-products') {
        headerItemFilterActive = products
        headerItemType = <div className='h-11 w-72 shadow rounded py-2 px-3 filter-input'>
                            <input 
                                value={products}
                                type="text"
                                min="1"
                                placeholder="Product name" 
                                onChange={productsChangeHandler}
                                onKeyDown={handleKeyPress}>
                            </input>
                            <button className='clear-field' onClick={() => {setFieldClear(); setProducts(''); setSortingProducts('') }}><VscClearAll /></button>
                        </div>
    }
    if (value === 'parent-type') {
        headerItemFilterActive = type
        headerItemType = <div className='h-11 w-72 shadow rounded py-2 px-3 filter-input'>
                            <input 
                                value={type}
                                type="number"
                                min="1"
                                placeholder="Type number" 
                                onChange={typeChangeHandler}
                                onKeyDown={handleKeyPress}>
                            </input>
                            <button className='clear-field' onClick={() => {setFieldClear(); setType(''); setSortingType('') }}><VscClearAll /></button>
                        </div>
    }
    if (value === 'activity-id') {
        headerItemFilterActive = activity
        headerItemType = <div className='h-11 w-72 shadow rounded py-2 px-3 filter-input'>
                            <input 
                                value={activity}
                                type="number"
                                min="1"
                                placeholder="Activity number" 
                                onChange={activityChangeHandler}
                                onKeyDown={handleKeyPress}>
                            </input>
                            <button className='clear-field' onClick={() => {setFieldClear(); setActivity(''); setSortingActivity('') }}><VscClearAll /></button>
                        </div>
    }
    if (value === 'parent-process') {
        headerItemFilterActive = process
        headerItemType = <div className='h-11 w-72 shadow rounded py-2 px-3 filter-input link'>
                            <input 
                                value={process}
                                type="number"
                                min="1"
                                placeholder="Process number" 
                                onChange={processChangeHandler}
                                onKeyDown={handleKeyPress}>
                            </input>
                            <button className='clear-field' onClick={() => {setFieldClear(); setProcess(''); setSortingProcess('') }}><VscClearAll /></button>
                        </div>
    }
    let uniqueStyling = ''

    if (value === 'id') {
        uniqueStyling = 'header-item_id'
    }
    if (value === 'name') {
        uniqueStyling = 'header-item_name'
    }
    if (value === 'link') {
        uniqueStyling = 'header-item_link'
    }

    return (
        <th className={"table-header-item "  + uniqueStyling}>
            <div className='table-header-item__container' tabIndex={-1} onKeyDown={closeFilter}>
                {filter && headerItemType}
                <button onClick={() => {sortData(value)}}>{name}</button>
                {(activeSort.key !== value) && <div style={{marginRight: 12}}></div>}
                {(activeSort.key === value && activeSort.inc) && <div style={{marginBottom: 2}}>▲</div>}
                {(activeSort.key === value && !activeSort.inc) && <div style={{marginBottom: 3}}>▼</div>}
                {(value !== 'id' && value !== 'link') && <VscGear className={headerItemFilterActive ? "text-2xl cursor-pointer capability-filter text-red-400" : (filter ? "text-2xl cursor-pointer capability-filter filter-active" : "text-2xl cursor-pointer capability-filter")} onClick={() => {setFilter(!filter)}} />}
            </div>
        </th>
    );
};

export default TableHeaderItem;