import { connect } from "react-redux";
import { fetched, loading, relationship, goalType, activityType, capabilityType, capabilityTypeType, organization, successMessage, errorMessage, access, userRole, userName, userOrganization, user, allUsers, activeInvites } from '../../store/reducers/selectors'
import { getRelationship, getGoalType, getActivityType, getCapabilityType, getCapabilityTypeType, getOrganization, editOrganization, newInvite, clearSuccessMessage, clearErrorMessage, getUserRole, changePassword, getUser, getAllUsers, editUser, deleteUser, getActiveInvites } from "../../api/actions"
import { createStructuredSelector } from "reselect";
import SettingsPage from './SettingsPage'

const mapStateToProp = createStructuredSelector({
    fetched,
    loading,
    relationship,
    goalType,
    activityType,
    capabilityType,
    capabilityTypeType,
    organization,
    successMessage,
    errorMessage,
    access,
    userRole,
    userOrganization,
    userName,
    user,
    allUsers,
    activeInvites
})

const mapDispatchToProps = {
    getRelationship,
    getGoalType,
    getActivityType,
    getCapabilityType,
    getCapabilityTypeType,
    getOrganization,
    editOrganization,
    newInvite,
    clearSuccessMessage,
    clearErrorMessage,
    getUserRole,
    changePassword,
    getUser,
    getAllUsers,
    editUser,
    deleteUser,
    getActiveInvites,
}

export default connect(mapStateToProp, mapDispatchToProps)(SettingsPage);