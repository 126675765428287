import React from 'react'
import Goals from "../../assets/goal.svg"
import CardItem from './CardItem'


const DirectoryGoals = ({ goal }) => {
    const strategicIntentions = goal && goal.filter(item => item.type === 'Strategic intention')
    const strategicGoals = goal && goal.filter(item => item.type === 'Strategic goal')
    const businessGoals = goal && goal.filter(item => item.type === 'Business goal')
    const businessObjectives = goal && goal.filter(item => item.type === 'Business objective')
    const projectGoals = goal && goal.filter(item => item.type === 'Project goal')
    const sprintGoals = goal && goal.filter(item => item.type === 'Sprint goal')
    const taskGoals = goal && goal.filter(item => item.type === 'Task goal')

    return (
        <div className="directory">
            <CardItem linkRoute={'goals'}
                    cardColor={'green'}
                    image={Goals}
                    dataArray={goal} >
                    Goals
            </CardItem>
            <CardItem linkRoute={'goals'}
                    cardColor={'green'}
                    image={Goals}
                    dataArray={strategicIntentions} >
                    Goals of Strategic intention level
            </CardItem>
            <CardItem linkRoute={'goals'}
                    cardColor={'green'}
                    image={Goals}
                    dataArray={strategicGoals} >
                    Goals of Strategic goal level
            </CardItem>
            <CardItem linkRoute={'goals'}
                    cardColor={'green'}
                    image={Goals}
                    dataArray={businessGoals} >
                    Goals of Business goal level
            </CardItem>
            <CardItem linkRoute={'goals'}
                    cardColor={'green'}
                    image={Goals}
                    dataArray={businessObjectives} >
                    Goals of Business objective level
            </CardItem>
            <CardItem linkRoute={'goals'}
                    cardColor={'green'}
                    image={Goals}
                    dataArray={projectGoals} >
                    Goals of Project goal level
            </CardItem>
            <CardItem linkRoute={'goals'}
                    cardColor={'green'}
                    image={Goals}
                    dataArray={sprintGoals} >
                    Goals of Sprint goal level
            </CardItem>
            <CardItem linkRoute={'goals'}
                    cardColor={'green'}
                    image={Goals}
                    dataArray={taskGoals} >
                    Goals of Task goal level
            </CardItem>
        </div>
    );
};

export default DirectoryGoals;