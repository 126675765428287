import React, { useState, useEffect } from 'react'

import groupArrayIntoSixes from '../../handlers/groupArrayHandler'
import BusinessTableHeaderItem from './BusinessTableHeaderItem'
import Modal from '../Modal'
import BusinessItem from './BusinessItem'


const BusinessTable = ({
		data,
		setUpdateName,
		setUpdateLink,
		setUpdateProperty,
		setUpdateStartDate,
		setUpdateEndDate,
		setUpdateId,
		setUpdateChosen,
		setAddChosen,
		current,
		setPaginationLevels,
		setUpdateTag,
		propertyType,
		bpRelations,
		setUpdateApplications,
		access
	}) => {
		const [showDelete, setShowDelete] = useState(false);
		const [deleteId, setDeleteId] = useState("");
		const [deleteItem, setDeleteItem] = useState({name: ''});
		const [sortedData, setSortedData] = useState([])
		const [activeSort, setActiveSort] = useState({key: '', inc: true})
		const [sortingStartDate, setSortingStartDate] = useState("")
		const [sortingEndDate, setSortingEndDate] = useState("")
		const [sortingName, setSortingName] = useState("")
		const [sortingProperty, setSortingProperty] = useState("")
		const [sortingTag, setSortingTag] = useState('')
	
		useEffect(() => {
			let result = []
	
			for (const array of data) {
				result.push(...array)
			}
	
			if (sortingStartDate) {
				result = result.filter(item => item.start_date.includes(sortingStartDate))
			}
			if (sortingEndDate) {
				result = result.filter(item => item.end_date.includes(sortingEndDate))
			}
			if (sortingName) {
				result = result.filter(item => item.name.includes(sortingName))
			}
			if (sortingTag) {
				result = result.filter(item => item.tag.includes(sortingTag))
			}
			if (sortingProperty) {
				const getKeyByValue = (object) => {
					const result = []
					const keys = Object.keys(object)
					for (const key of keys) {
						if (sortingProperty[key] === true) {result.push(key)}
					}
					return result
				}
				
				const properties = getKeyByValue(sortingProperty)
	
				if (properties.length > 0) {
					const originalProperties = [
							{id: 99, name: ''},
							{id: 1, name: 'Support'},
							{id: 2, name: 'MDM'},
							{id: 3, name: 'ESG'},
							{id: 4, name: 'Strategic'},
							{id: 5, name: 'Planning'},
							{id: 6, name: 'Execution'},
							{id: 7, name: 'Learning'}
					]
					const filtederProperties = originalProperties.filter(n => !properties.includes(n.name))

					for (const p of filtederProperties) {
						result = result.filter(item => item.property_id !== p.id)
					}
				}
			}
	
			setSortedData(groupArrayIntoSixes(result, 20))
			setPaginationLevels(sortedData.length)
		}, [data, sortingStartDate, sortingEndDate, sortingName, sortingProperty, setPaginationLevels, sortedData.length, sortingTag])
	
	
		const sortData = (sortKey) => {
			let result = []
	
			if (sortedData.length > 1) {
				for (const array of sortedData) {
					result.push(...array)
				}
	
			} else {
				for (const array of data) {
					result.push(...array)
				}
			}
	
			const reverseSort = (sortKey) => {
					setActiveSort({key: sortKey, inc: false })
					result.reverse()
			}
			
			if (sortKey === 'id') {
				result.sort((a, b) => parseFloat(a.id) - parseFloat(b.id))
	
				if (activeSort.key === sortKey && activeSort.inc) {
					reverseSort(sortKey)
				}
				 else {
					setActiveSort({key: 'id', inc: true})
				}
			}
			if (sortKey === 'name') {
				result.sort((a, b) => a.name.localeCompare(b.name))
	
				if (activeSort.key === sortKey && activeSort.inc) {
					reverseSort(sortKey)
				} else {
					setActiveSort({key: 'name', inc: true})
				}
			}
			if (sortKey === 'start-date') {
				const emptyDates = result.filter(item => item.start_date === "")
				const actuallDates = result.filter(item => item.start_date !== "")
				actuallDates.sort((a, b) => new Date(a.start_date) - new Date(b.start_date))
				result = [...actuallDates, ...emptyDates]
	
				if (activeSort.key === sortKey && activeSort.inc) {
					reverseSort(sortKey)
				} else {
				setActiveSort({key: 'start-date', inc: true})
				}
			}
			if (sortKey === 'end-date') {
				const emptyDates = result.filter(item => item.end_date === "")
				const actuallDates = result.filter(item => item.end_date !== "")
				actuallDates.sort((a, b) => new Date(a.end_date) - new Date(b.end_date))
				result = [...actuallDates, ...emptyDates]
	
				if (activeSort.key === sortKey && activeSort.inc) {
					reverseSort(sortKey)
				} else {
				setActiveSort({key: 'end-date', inc: true})
				}
			}
			if (sortKey === 'tag') {
				result.sort((a, b) => a.tag.localeCompare(b.tag))
	
				if (activeSort.key === sortKey && activeSort.inc) {
					reverseSort(sortKey)
				} else {
					setActiveSort({key: 'tag', inc: true})
				}
			}
			if (sortKey === 'property') {
				result.sort((a, b) => parseFloat(a.property_id) - parseFloat(b.property_id))
	
				if (activeSort.key === sortKey && activeSort.inc) {
					reverseSort(sortKey)
				} else {
				setActiveSort({key: 'property', inc: true})
				}
			}
			if (sortKey === 'link') {
				result.sort((a, b) => parseFloat(a.link) - parseFloat(b.link))
	
				if (activeSort.key === sortKey && activeSort.inc) {
					reverseSort(sortKey)
				} else {
				setActiveSort({key: 'link', inc: true})
				}
			}
			setSortedData(groupArrayIntoSixes(result, 20))
			setPaginationLevels(sortedData.length)
		}
	
		return (
			<div className="flex flex-col w-[100%]">
				{showDelete && 
					<Modal
						setShowDelete={setShowDelete}
						deleteId={deleteId}
						deleteItem={deleteItem}
						deleteItemType={'business'}
					/>
				}
				<table className="table-auto w-full text-left whitespace-no-wrap">
					<thead className="table-header">
						<tr>
							<BusinessTableHeaderItem name={'ID'} sortData={sortData} value={'id'} activeSort={activeSort}/>
							<BusinessTableHeaderItem name={'NAME'} sortData={sortData} value={'name'} activeSort={activeSort} setSortingName={setSortingName}/>
							<BusinessTableHeaderItem name={'APPLICATIONS'} sortData={sortData} value={'applications'} activeSort={activeSort} setSortingName={setSortingName}/>
							<BusinessTableHeaderItem name={'START DATE'} sortData={sortData} value={'start-date'} activeSort={activeSort} setSortingStartDate={setSortingStartDate}/>
							<BusinessTableHeaderItem name={'END DATE'} sortData={sortData} value={'end-date'} activeSort={activeSort} setSortingEndDate={setSortingEndDate}/>
							<BusinessTableHeaderItem name={'TAG'} sortData={sortData} value={'tag'} activeSort={activeSort} setSortingTag={setSortingTag}/>
							<BusinessTableHeaderItem name={'PROCESS LEVEL'} sortData={sortData} value={'property'} activeSort={activeSort} setSortingProperty={setSortingProperty}/>
							<BusinessTableHeaderItem name={'LINK'} sortData={sortData} value={'link'} activeSort={activeSort}/>
						</tr>
					</thead>
					<tbody className="table-body">
					{sortedData.length > 0 &&
								sortedData[current].map((item) => {
									return (
										<BusinessItem 
											key={item.id} 
											item={item}
											showDelete={showDelete} 
											deleteId={deleteId}
											setDeleteItem={setDeleteItem}  
											setShowDelete={setShowDelete} 
											setDeleteId={setDeleteId} 
											setUpdateChosen={setUpdateChosen} 
											setAddChosen={setAddChosen} 
											setUpdateId={setUpdateId} 
											setUpdateName={setUpdateName} 
											setUpdateStartDate={setUpdateStartDate} 
											setUpdateEndDate={setUpdateEndDate} 
											setUpdateLink={setUpdateLink}
											setUpdateProperty={setUpdateProperty}
											setUpdateTag={setUpdateTag}

											propertyType={propertyType}
											bpRelations={bpRelations}
											setUpdateApplications={setUpdateApplications}
											
											access={access}
										/>
								)
							}
						)
					}
					</tbody>
				</table>
			</div>
		);
};

export default BusinessTable;