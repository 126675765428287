import React, { useState } from 'react'
import { SlArrowLeft } from "react-icons/sl"
import { ReactComponent as ReactLogo } from "../assets/main-logo-small.svg"
import { NavLink } from 'react-router-dom'

import ModalFeedback from "./ModalFeedback"

import CapabilitiesIcon from "../assets/box-open_9239283.png"
import GoalsIcon from "../assets/bullseye-arrow_9795295.png"
import ProcessesIcon from "../assets/process.png"
import DashboardIcon from "../assets/square-poll-vertical_9795430.png"
import SettingsIcon from "../assets/setting-the-table.png"
import AppsIcon from "../assets/categories.png"
import ReportIcon from "../assets/alert-sign.png"
import { PiInfoThin, PiSlidersThin, PiChartLineUpThin, PiStackLight, PiPackageThin } from "react-icons/pi"


const MaxNavigation = ({ setNav, newFeedback, access}) => {
    const [isFeedbackActive, setIsFeedbackActive] = useState(false)
    const { Menu } = access
	const { Activities, Applications, Capabilities, Dashboard, Feedback, Goals, Processes, Products, Reports, Scenarios, Settings } = Menu
    const linkStyle = "relative flex flex-row items-center h-11 focus:outline-none text-gray-600 border-l-4 border-transparent pr-6 cursor-pointer"
    
    return (
        <div className="max-navigation">
            {isFeedbackActive && <ModalFeedback newFeedback={newFeedback} closeModal={() => { setIsFeedbackActive(false) }} />}
            <div className="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased">
                <div className="fixed flex flex-col top-0 left-0 h-full border-r w-[290px] z-999 bg-white">
                    <div className="flex items-center justify-end">
                        <SlArrowLeft className="text-3xl mt-5 mr-1 text-black cursor-pointer" onClick={() => {
                            setNav("mini")
                        }} />
                    </div>
                    <div className="logo-size">
                        <ReactLogo />
                    </div>
                    <div className="overflow-y-auto overflow-x-hidden flex-grow">
                        <ul className="nav_max">
                            <li>
                                <NavLink className={!Dashboard ? linkStyle + ' disabled' : linkStyle} to='/'>
                                    <span className="inline-flex justify-center items-center ml-4">
									    <img src={DashboardIcon} className="h-6 cursor-pointer" alt="dashboard" />
                                    </span>
                                    <span className="ml-2 tracking-wide truncate text-black">Dashboard</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className={!Goals ? linkStyle + ' disabled' : linkStyle} to='goals'>
                                    <span className="inline-flex justify-center items-center ml-4">
                                        <img src={GoalsIcon} className="h-6 cursor-pointer" alt="goals" />
                                    </span>
                                    <span className="ml-2 tracking-wide truncate text-black">Goals Tree Management</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className={!Capabilities ? linkStyle + ' disabled' : linkStyle} to='capabilities'>
                                    <span className="inline-flex justify-center items-center ml-4">
                                        <img src={CapabilitiesIcon} className="h-6 cursor-pointer" alt="capabilities" />
                                    </span>
                                    <span className="ml-2 tracking-wide truncate text-black">Capabilities Management</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className={!Activities ? linkStyle + ' disabled' : linkStyle} to='activities'>
                                    <span className="inline-flex justify-center items-center ml-4">
                                        <PiSlidersThin className="h-9 w-7 text-black" alt="activities" />
                                    </span>
                                    <span className="ml-2 tracking-wide truncate text-black">Activities Management</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className={!Products? linkStyle + ' disabled' : linkStyle} to='products'>
                                    <span className="inline-flex justify-center items-center ml-4">
                                        <PiPackageThin className="h-8 w-7 text-black" alt="products" />
                                    <div className="flex flex-col">
                                        <span className="ml-2 tracking-wide truncate text-black">Product Portfolio</span>
                                        <span className="ml-2 tracking-wide truncate text-black">Management</span>
                                    </div>
                                    </span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className={!Scenarios ? linkStyle + ' disabled' : linkStyle} to='scenarios'>
                                    <span className="inline-flex justify-center items-center ml-4">
                                        <PiStackLight className="h-8 w-7 text-black" alt="scenarios" />
                                    </span>
                                    <span className="ml-2 tracking-wide truncate text-black">Scenario Management</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className={!Processes ? linkStyle + ' disabled' : linkStyle} to='processes'>
                                    <span className="inline-flex justify-center items-center ml-4">
                                        <img src={ProcessesIcon} className="h-7 cursor-pointer" alt="processes"/>
                                    </span>
                                    <div className="flex flex-col">
                                        <span className="ml-2 tracking-wide truncate text-black">Business Processes</span>
                                        <span className="ml-2 tracking-wide truncate text-black">Management</span>
                                    </div>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className={!Applications ? linkStyle + ' disabled' : linkStyle} to='applications'>
                                    <span className="inline-flex justify-center items-center ml-4">
                                        <img src={AppsIcon} className="h-7 cursor-pointer" alt="application" />
                                    </span>
                                    <span className="ml-2 tracking-wide truncate text-black">Applications Management</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className={!Reports ? linkStyle + ' disabled' : linkStyle} to='reports'>
                                    <span className="inline-flex justify-center items-center ml-4">
                                        <PiChartLineUpThin className="h-8 w-7 text-black" alt="reports" />
                                    </span>
                                    <span className="ml-2 tracking-wide truncate text-black">Reports</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className={!Settings ? linkStyle + ' disabled' : linkStyle} to='settings'>
                                    <span className="inline-flex justify-center items-center ml-4">
                                        <img src={SettingsIcon} className="h-7 cursor-pointer" alt="settings" />
                                    </span>
                                    <span className="ml-2 tracking-wide truncate text-black">Settings</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="relative flex flex-row items-center h-11 focus:outline-none text-gray-600 border-l-4 border-transparent" to='about'>
                                    <span className="inline-flex justify-center items-center ml-4">
                                        <PiInfoThin className="h-8 w-8 text-black" alt="about" />
                                    </span>
                                    <span className="ml-2 tracking-wide truncate text-black">About</span>
                                </NavLink>
                            </li>
                            <li>
                                <div className={!Feedback ? linkStyle + ' disabled' : linkStyle} onClick={() => setIsFeedbackActive(() => !isFeedbackActive)} >
                                    <span className="inline-flex justify-center items-center ml-4">
                                        <img src={ReportIcon} className="h-8 cursor-pointer" alt="feedback" />
                                    </span>
                                    <span className="ml-2 tracking-wide truncate text-black">Feedback</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default MaxNavigation