import { connect } from "react-redux"
import { fetched, loading, goal } from '../../../store/reducers/selectors'
import { importGoal, newSuccessMessage, newWarningMessage, newErrorMessage } from "../../../api/actions"
import { createStructuredSelector } from "reselect"
import GoalImport from "./GoalImport"


const mapStateToProp = createStructuredSelector({
    fetched,
    loading,
    goal,
})

const mapDispatchToProps = {
    importGoal,
    newSuccessMessage,
    newWarningMessage,
    newErrorMessage
}

export default connect(mapStateToProp, mapDispatchToProps)(GoalImport);