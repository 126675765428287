import React from 'react';


const DateFilter = ({date, onChangeFunc}) => {
    // headerItemFilterActive = endDate

    return (
        <input
            value={date} 
            className="h-11 w-72 shadow rounded py-2 px-3 filter-input" 
            type="date" 
            onChange={onChangeFunc}>
        </input>
    )
}

export default DateFilter