import { connect } from "react-redux"
import { fetched, loading, scenario, activity } from '../../../store/reducers/selectors'
import { importScenario } from "../../../api/actions"
import { createStructuredSelector } from "reselect"
import ScenarioImport from "./ScenarioImport"


const mapStateToProp = createStructuredSelector({
    fetched,
    loading,
    scenario,
    activity
})

const mapDispatchToProps = {
    importScenario
}

export default connect(mapStateToProp, mapDispatchToProps)(ScenarioImport);