import React from 'react';
import Tab from '../Tab';

const ProductManagement = ({ active, setActive, access }) => {
    const { Products, Products_Report, Products_Import, Products_Export } = access
    return (
        <div className="tabs-container">
            <div className="flex row mt-5 justify-start items-start">
                <Tab
                    section={"Product"}
                    active={active}
                    setActive={setActive}
                    isDisabled={!Products}
                />
                <Tab
                    section={"Report"}
                    active={active}
                    setActive={setActive}
                    isDisabled={true}
                    // isDisabled={!Products_Report}
                />
                <Tab
                    section={"Import"}
                    active={active}
                    setActive={setActive}
                    // isDisabled={true}
                    isDisabled={!Products_Import}
                />
                <Tab
                    section={"Export"}
                    active={active}
                    setActive={setActive}
                    // isDisabled={true}
                    isDisabled={!Products_Export}
                />
            </div>
        </div>
    )
};

export default ProductManagement;