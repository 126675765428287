import React from 'react'

const Modal = ({ 
	setShowDelete, 
	deleteId,
	deleteItem,
	deleteItems,
	deleteItemType,
	deleteCapability,
	deleteActivity,
	deleteGoal,
	deleteProduct,
	deleteScenario,
	deleteBusiness,
	deleteApplication,
	deleteBPRelation,
	deleteBPRelations,
	deleteSet,
	deleteCapabilities,
	deleteActivities, 
    deleteApplications, 
    deleteProcesses, 
    deleteProducts, 
    deleteScenarios, 
    deleteGoals,
	deleteDepartment, 
	deletePerson, 
	deleteTeam, 
	deleteTeams,
	setDeleteItems,
	setSelected,
	setSelectAll,
	onRemoveCardConfirmed,
	onRemoveTaskConfirmed,
	deleteUser,
	deleteInvite,
	getActiveInvites,

	goal,
    capability,
    activity,
    product,
    scenario,
    business,
    application,
	bpRelations
}) => {

	const itemTypes = {
		capability: 'capability',
		goal: 'goal',
		activity: 'activity',
		product: 'product',
		scenario: 'scenario',
		business: 'business',
		application: 'application',
		bprelation: 'relation',
		set: 'set',
		team: 'team',
		department: 'department',
		person: 'person',
		capabilitiesArray: 'capabilities',
		capabilitiesArrayAll: 'all capabilities',
		gealsArray: 'goals',
		activitiesArray: 'activities',
		scenariosArray: 'scenarios',
		processesArray: 'processes',
		applicationsArray: 'applications',
		relationsArray: 'relations',
		teamsArray: 'teams',
		visualEditorCard: 'capability',
		visualEditorTask: 'capability',
		organizationUser: 'user',

		organizationInvite: 'invite',
		organizationDatabase: ''
	}

	const composeUserWarningMessage = () => {
		if (deleteItem && deleteItemType === 'bprelation') return deleteItem.application.name + ' ' + itemTypes[deleteItemType]
		if (deleteItem) return deleteItem.name + ' ' + itemTypes[deleteItemType]
		if (deleteItems && deleteItems.length > 0) return deleteItems.length + ' ' + itemTypes[deleteItemType]

		return itemTypes[deleteItemType]
	}

	const userWarningMessage = composeUserWarningMessage()

	const cancelDelete = () => setShowDelete(false)

	const deleteArrayOfItems = (deleteMethod, arrayOfItems) => {
		const deleteArray = []
			
		for (const element of arrayOfItems) {
			deleteArray.push(element.id)
		}

		return deleteMethod({deleteArray})
	}

	const deleteItemConfirmed =  () => {
		if (deleteItemType === 'capability') deleteCapability({deleteId: Number(deleteId)})
		if (deleteItemType === 'goal') deleteGoal({deleteId: Number(deleteId)})
		if (deleteItemType === 'activity') deleteActivity({deleteId: Number(deleteId)})
		if (deleteItemType === 'product') deleteProduct({deleteId: Number(deleteId)})
		if (deleteItemType === 'scenario') deleteScenario({deleteId: Number(deleteId)})
		if (deleteItemType === 'business') deleteBusiness({deleteId: Number(deleteId)})
		if (deleteItemType === 'application') deleteApplication({deleteId: Number(deleteId)})
		if (deleteItemType === 'bprelation') deleteBPRelation({deleteId: Number(deleteId)})
		if (deleteItemType === 'set') deleteSet({deleteId: Number(deleteItem.id)})
		if (deleteItemType === 'person') deletePerson({deleteId: Number(deleteItem.id)})
		if (deleteItemType === 'department') deleteDepartment({deleteId: Number(deleteItem.id)})
		if (deleteItemType === 'team') deleteTeam({deleteId: Number(deleteItem.id)})
		if (deleteItemType === 'capabilitiesArray') deleteArrayOfItems(deleteCapabilities, deleteItems)
		if (deleteItemType === 'activitiesArray') deleteArrayOfItems(deleteActivities, deleteItems)
		if (deleteItemType === 'applicationsArray') deleteArrayOfItems(deleteApplications, deleteItems)
		if (deleteItemType === 'processesArray') deleteArrayOfItems(deleteProcesses, deleteItems)
		if (deleteItemType === 'productsArray') deleteArrayOfItems(deleteProducts, deleteItems)
		if (deleteItemType === 'scenariosArray') deleteArrayOfItems(deleteScenarios, deleteItems)
		if (deleteItemType === 'goalsArray') deleteArrayOfItems(deleteGoals, deleteItems)
		if (deleteItemType === 'teamsArray') deleteArrayOfItems(deleteTeams, deleteItems)
		if (deleteItemType === 'visualEditorCard') onRemoveCardConfirmed(deleteId)
		if (deleteItemType === 'visualEditorTask') onRemoveTaskConfirmed(deleteId, deleteItem.cardID)
		if (deleteItemType === 'organizationUser') deleteUser({deleteId: deleteId})
		if (deleteItemType === 'organizationInvite') {deleteInvite({deleteId: deleteId}).then(() => getActiveInvites())}
		if (deleteItemType === 'organizationDatabase') {
			deleteArrayOfItems(deleteBPRelations, bpRelations).then(() => {
				deleteArrayOfItems(deleteCapabilities, capability)
				deleteArrayOfItems(deleteActivities, activity)
				deleteArrayOfItems(deleteApplications, application)
				deleteArrayOfItems(deleteProcesses, business)
				deleteArrayOfItems(deleteProducts, product)
				deleteArrayOfItems(deleteScenarios, scenario)
				deleteArrayOfItems(deleteGoals, goal)
			})
		}
		setShowDelete(false)
	}

	return (
		<div className="fixed inset-0 z-10">
			<div className="overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
				<div className="flex items-end justify-center min- px-4 pt-4 pb-20 text-center sm:block sm:p-0">
					<div className="transition-opacity bg-gray-500 bg-opacity-75" aria-hidden="true"></div>
					<span className="hidden sm:inline-block sm:align-middle sm:" aria-hidden="true">​</span>
					<div className="inline-block p-5 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-2xl lg:p-16 sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
						<div>
							<div className="mt-3 text-left sm:mt-5">
								<div className="py-6 text-center">
									<p className="mb-8 text-2xl font-semibold leading-none tracking-tighter text-neutral-600">Are you sure you want to delete?</p>
									{/* <p className="mb-8 text-2xl font-semibold leading-none tracking-tighter text-neutral-600">{deleteId ? deleteItem.name : deleteItem.length + ' capabilities'}</p> */}
									<p className="mb-8 text-2xl font-semibold leading-none tracking-tighter text-neutral-600">{userWarningMessage}</p>
									{deleteItemType === ('visualEditorCard' || 'visualEditorTask') && <p className="mb-8 text-2xl leading-none tracking-tighter text-neutral-600">There are some nested elements</p>}
								</div>
								<div className="py-6 text-center">
								</div>
							</div>
							<div className="flex flex-row justify-between align-middle">
								<div className="bg-red px-10 py-2 rounded bg-green-600 text-white font-bold cursor-pointer" onClick={cancelDelete}>No</div>
								<div className="bg-red px-10 py-2 rounded bg-red-600 text-white font-bold cursor-pointer" onClick={deleteItemConfirmed}>Yes</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Modal