import React, { useState } from "react"

import UserTableHeaderItem from "./UserTableHeaderItem"

import Modal from '../../Modal'
import Person from "./Person"


const PeopleTable = ({ data, access, setError, setSuccess }) => {
	const [showDelete, setShowDelete] = useState(false)
    const [deleteId, setDeleteId] = useState('')
    const [deleteItem, setDeleteItem] = useState({name: ''})

	return (
		<div className="flex flex-col min-w-[50%] w-[100%]">
			{showDelete && 
				<Modal
					setShowDelete={setShowDelete}
					deleteId={deleteId}
					deleteItem={deleteItem}
					deleteItemType={'organizationUser'}
					setSuccess={setSuccess}
					setError={setError}
				/>
			}
			<table className="table-auto w-full text-left whitespace-no-wrap">
				<thead className="table-header">
					<tr>
						<UserTableHeaderItem name="ID" value="id" />
						<UserTableHeaderItem name="NAME" value="name" />
						<UserTableHeaderItem name="POSITION" value="positon" />
						<UserTableHeaderItem name="ROLE" value="role" />
						<UserTableHeaderItem name="START DATE"  value="start-date" />
						<UserTableHeaderItem name="END DATE" value="end-date" />
						<UserTableHeaderItem name="USER" value="user" />
						<UserTableHeaderItem name="EMAIL" value="email" />
					</tr>
				</thead>
				<tbody className="table-body">
					{data && data.map((item) => {
						return (
							<Person
								key={item.id}
								data={data}
								item={item}
								showDelete={showDelete}
								setShowDelete={setShowDelete}
								setDeleteId={setDeleteId}
								setDeleteItem={setDeleteItem}
								access={access}
							/>
						)
					})}
				</tbody>
			</table>
		</div>
	);
};

export default PeopleTable
