import { connect } from "react-redux"
import { fetched, loading, business, application, bpRelations, capabilityType, relationship} from '../../../store/reducers/selectors'
import { importBusiness, importBPRelations, importApplication } from "../../../api/actions"
import { createStructuredSelector } from "reselect"
import BusinessImport from "./BusinessImport"


const mapStateToProp = createStructuredSelector({
    fetched,
    loading,
    business,
    application,
    bpRelations,
    capabilityType,
    relationship
})

const mapDispatchToProps = {
    importBusiness,
    importApplication,
    importBPRelations
}

export default connect(mapStateToProp, mapDispatchToProps)(BusinessImport)