export const token = state => state.token
export const fetched = state => state.fetched
export const loading = state => state.loading
export const successMessage = state => state.successMessage
export const errorMessage = state => state.errorMessage
export const warningMessage = state => state.warningMessage
export const userName = state => state.userName
export const capability = state => state.capability
export const capabilityPerPage = state => state.capabilityPerPage
export const capabilityType = state => state.capabilityType
export const capabilityTypeType = state => state.capabilityTypeType
export const activity = state => state.activity
export const activityPerPage = state => state.activityPerPage
export const activityType = state => state.activityType
export const goal = state => state.goal
export const goalPerPage = state => state.goalPerPage
export const goalType = state => state.goalType
export const scenario = state => state.scenario
export const scenarioPerPage = state => state.scenarioPerPage
export const product = state => state.product
export const productPerPage = state => state.productPerPage
export const business = state => state.business
export const businessPerPage = state => state.businessPerPage
export const application = state => state.application
export const applicationPerPage = state => state.applicationPerPage
export const department = state => state.department
export const organization = state => state.organization
export const userOrganization = state => state.userOrganization
export const relationship = state => state.relationship
export const bpRelations = state => state.bpRelations
export const bpRelationsPerPage = state => state.bpRelationsPerPage
export const set = state => state.set
export const maturity = state => state.maturity
export const access = state => state.access
export const userRole = state => state.userRole
export const user = state => state.user
export const allUsers = state => state.allUsers
export const activeInvites = state => state.activeInvites
export const team = state => state.team
export const people = state => state.people