import { React, useEffect } from 'react'
import CapabilitiesReportDownload from "../Reports/CapabilitiesReportDownload"
import { toSvg } from 'html-to-image'

const BCMNewPageSVG11 = ({borderSize = 20, capability, getCapability}) => {
	const firstLevels = capability.filter(item => item.second_level === 0 && item.third_level === 0 && item.first_level !== 0)

	const downloadSVG = () => {
		const filter = (node) => {
			return (node.tagName !== 'i')
		}

		toSvg(document.getElementById('saveChart'), { filter: filter })
		.then((dataUrl) => {
			const link = document.createElement("a")
			link.download = "reports.svg"
			link.href = dataUrl
			
			link.click()
		})
	}

	useEffect(() => {
		getCapability().then(() => downloadSVG())
	  }, [])

	return <div id='saveChart' className='capabilities modal-save' style={{border: borderSize + "px solid #EEEEF2"}}>
				{/* <div className="capabilities-report-info">
					<p>{organizationName}</p>
					<p>{nameOfTheSet}</p>
					<p>{date}</p>
				</div> */}
				<div className='capabilities'>
					{firstLevels.map((item) => {
						return <CapabilitiesReportDownload
									key={item.id} 
									item={item} 
									data={capability} 
									// heatmapActive={heatmapActive} 
									// isHeatmapNotDot={isHeatmapNotDot} 
								/>
					})}
				</div>
			</div>
}
 
export default BCMNewPageSVG11