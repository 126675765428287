import React from 'react';

const SkeletonTable = () => {
    const data = [
        {id: 1},
        {id: 2},
        {id: 3},
        {id: 4},
        {id: 5},
        {id: 6},
        {id: 7},
        {id: 8},
        {id: 9},
        {id: 10},
        {id: 11},
        {id: 12},
        {id: 13},
        {id: 14},
        {id: 15},
        {id: 16},
        {id: 17},
        {id: 18},
        {id: 19},
        {id: 20}

    ]
    return (
        <table className="table-auto w-full text-left whitespace-no-wrap">
          <thead className="table-header">
            <tr>
                <th className="table-header-item skeleton">
                </th>
            </tr>
          </thead>
          <tbody className="table-body">
          {data.map((item) => {
                return (
                    <tr
                        key={item.id}
                        className="capability-item skeleton"
                    >
                        <td></td>
                    </tr>
                )
              }
            )
          }
          </tbody>
        </table>
    );
};

export default SkeletonTable;