import { connect} from "react-redux"
import { fetched, token, loading, business, businessPerPage, application, capabilityType, capabilityTypeType, relationship, bpRelations, bpRelationsPerPage, successMessage, errorMessage, userOrganization, organization, access } from '../../store/reducers/selectors'
import { getBusiness, newBusiness, editBusiness, getApplication, getCapabilityType,getCapabilityTypeType, getBPRelations, newBPRelations, editBPRelations, clearSuccessMessage, clearErrorMessage, clearWarningMessage, newErrorMessage, getOrganization } from "../../api/actions"
import { createStructuredSelector}  from "reselect"
import BusinessPage from './BusinessPage'

const mapStateToProp = createStructuredSelector({
    token,
    fetched,
    loading,
    business,
    businessPerPage,
    application,
    capabilityType,
    capabilityTypeType,
    relationship,
    bpRelations,
    bpRelationsPerPage,
    successMessage,
    errorMessage,
    userOrganization,
    organization,
    access
})

const mapDispatchToProps = {
    getBusiness,
    newBusiness,
    editBusiness,
    getApplication,
    getCapabilityType,
    getCapabilityTypeType,
    getBPRelations,
    newBPRelations,
    editBPRelations,
    clearSuccessMessage,
    clearErrorMessage,
    clearWarningMessage,
    newErrorMessage,
    getOrganization
}

export default connect(mapStateToProp, mapDispatchToProps)(BusinessPage)