import React, { useState } from 'react';

import { VscGear } from "react-icons/vsc";
import { VscClearAll } from 'react-icons/vsc'


const ScenarioTableHeaderItem = ({ sortData, name, value, activeSort, setSortingActivities, setSortingActive, setSortingName, setSortingType}) => {
    const [filter, setFilter] = useState(false)
    const [sortName, setSortName] = useState('')
    const [activities, setActivities] = useState('')
    const [active, setActive] = useState('')

    let headerItemType = ''
    let headerItemFilterActive = ''

    const Checkbox = ({ type = "checkbox", name, checked = false, onChange }) => {
        return (
          <input type={type} name={name} checked={checked} onChange={onChange} />
        )
    }

    const checkboxesActive = [
        {
            name: 'Yes',
            key: '1',
            label: 'Yes',
        },
        {
            name: 'No',
            key: '0',
            label: 'No',
        },
    ]

    const setFieldClear = () => {
        setFilter(!filter)
    }

    const handleKeyPress = (e) => {
        if(e.key === 'Enter' || e.key === 'Escape'){
            setFilter(!filter)
        }
    }

    const closeFilter = (e) => {
        if(e.key === 'Escape'){
            setFilter(!filter)
        }
    }
    
    const nameChangeHandler = (e) => {
        setSortName(e.target.value)
        setSortingName(e.target.value)
    }

    const activitiesChangeHandler = (e) => {
        setActivities(e.target.value)
        setSortingActivities(e.target.value)
    }

    const activeChangeHandler = (e) => {
        setActive({...active, [e.target.name] : e.target.checked }) 
        setSortingActive({...active, [e.target.name] : e.target.checked })
    }

    
    if (value === 'name') {
        headerItemFilterActive = sortName
        headerItemType = <div className='h-11 w-72 shadow rounded py-2 px-3 filter-input'>
                            <input
                                value={sortName} 
                                className="" 
                                type="text" 
                                placeholder="Start typing here"
                                onChange={nameChangeHandler}>
                            </input>
                            <button className='clear-field' onClick={() => {setFieldClear(); setSortName(''); setSortingName('') }}><VscClearAll /></button>
                        </div>
    }

    if (value === 'activities') {
        headerItemFilterActive = activities
        headerItemType = <div className='h-11 w-72 shadow rounded py-2 px-3 filter-input'>
                            <input
                                value={activities} 
                                className="" 
                                type="text" 
                                placeholder="Start typing here"
                                onChange={activitiesChangeHandler}>
                            </input>
                            <button className='clear-field' onClick={() => {setFieldClear(); setActivities(''); setSortingActivities('') }}><VscClearAll /></button>
                        </div>
    }

    if (value === 'active') {
        headerItemFilterActive = active
        headerItemType = 
                    <div className="filter-select">
                        <form className="h-11 w-72 rounded py-2 px-3" onChange={activeChangeHandler}>
                            {checkboxesActive.map(item => (
                                <label key={item.key}><br/>
                                    <Checkbox name={item.name} checked={active[item.name]} onChange={activeChangeHandler} />
                                    {item.name}
                                </label>
                                ))}
                        </form>
                        <button className='clear-field' onClick={() => {setFieldClear(); setActive(''); setSortingActive('') }}><VscClearAll /></button>
                    </div>
    }

    let uniqueStyling

    if (value === 'id') {
        uniqueStyling = 'header-item_id'
    }
    if (value === 'name') {
        uniqueStyling = 'header-item_name'
    }
    if (value === 'link') {
        uniqueStyling = 'header-item_link'
    }

    return (
        <th className={"table-header-item "  + uniqueStyling}>
            <div className='table-header-item__container' tabIndex={-1} onKeyDown={closeFilter}>
                {filter && headerItemType}
                <button onClick={() => {sortData(value)}}>{name}</button>
                {(activeSort.key !== value) && <div style={{marginRight: 12}}></div>}
                {(activeSort.key === value && activeSort.inc) && <div style={{marginBottom: 2}}>▲</div>}
                {(activeSort.key === value && !activeSort.inc) && <div style={{marginBottom: 3}}>▼</div>}
                {(value !== 'id' && value !== 'link') && <VscGear className={headerItemFilterActive ? "text-2xl cursor-pointer capability-filter text-red-400" : (filter ? "text-2xl cursor-pointer capability-filter filter-active" : "text-2xl cursor-pointer capability-filter")} onClick={() => {setFilter(!filter)}} />}
            </div>
        </th>
    );
};

export default ScenarioTableHeaderItem;