import { React, useEffect } from 'react'
import CapabilitiesReportDownload from "../Reports/CapabilitiesReportDownloadSVG"

const BCMNewPage = ({borderSize = 20, capability, getCapability}) => {
	const firstLevels = capability.filter(item => item.second_level === 0 && item.third_level === 0 && item.first_level !== 0)
	let xPos = -120

	const findLongestChain = (firstLevels, data) => {
		let longestChain = 0
		firstLevels.forEach((item) => {
			const relatedSecondLevel = data.filter(i2 => i2.first_level === item.first_level && i2.second_level !== 0 && i2.third_level === 0)
			const relatedThirdLevel = data.filter(i3 => i3.first_level === item.first_level && i3.second_level === item.second_level && i3.third_level !== 0)
			const relatedSecondLevelLength = relatedSecondLevel.length
			const relatedThirdLevelLength = relatedThirdLevel.length
			if (relatedSecondLevelLength > longestChain) {
				longestChain = relatedSecondLevelLength
			}
			if (relatedThirdLevelLength > longestChain) {
				longestChain = relatedThirdLevelLength
			}
		})

		return longestChain
	}

	const longestChain = findLongestChain(firstLevels, capability)
	const svgWidth = firstLevels.length * 130 + 100
	const svgHeight = longestChain * 100 + 600

	const downloadSVG = () => {
		const svgImg = document.getElementById('saveChart')
		const svgData = new XMLSerializer().serializeToString(svgImg)
		const svgBlob = new Blob([svgData], {type:"image/svg+xml;charset=utf-8"})
		const svgUrl = URL.createObjectURL(svgBlob)
		const downloadLink = document.createElement("a")
		downloadLink.href = svgUrl
		downloadLink.download = "reports.svg"
		downloadLink.click()
	}

	useEffect(() => {
		getCapability().then(() => downloadSVG())
	  }, [])

	return <div className="">
				<div className="dialog">
				<svg width={svgWidth} height={svgHeight} fill="#333" strokeWidth="0" fontSize="12" fontWeight="normal" rx="8" id='saveChart' className='capabilities modal-save' style={{border: borderSize + "px solid #EEEEF2"}}>
					<rect width={svgWidth} height={svgHeight} fill="#eeeef2" rx="8" />
					{/* <div className="capabilities-report-info">
						<p>{organizationName}</p>
						<p>{nameOfTheSet}</p>
						<p>{date}</p>
					</div> */}
						{firstLevels.map((item) => {
							xPos += 130
							return <CapabilitiesReportDownload
										key={item.id}
										item={item} 
										data={capability} 
										xPos={xPos}
									/>
						})}
				</svg>
				</div>
			</div>
}
 
export default BCMNewPage