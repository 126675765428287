import { connect } from "react-redux";
import { fetched, token, loading, capability, capabilityPerPage, capabilityType, set, organization, userOrganization, maturity, errorMessage, successMessage, warningMessage, access } from '../../store/reducers/selectors'
import { getCapability, getCapabilityType, newCapability, newCapabilityType, editCapability, editCapabilityType, getSet, importCapability, getMaturityDict, newSet, deleteCapabilities, getOrganization, clearErrorMessage, clearSuccessMessage, clearWarningMessage, newSuccessMessage, newErrorMessage, newWarningMessage } from "../../api/actions"
import { createStructuredSelector } from "reselect";
import CapabilitiesPage from './CapabilitiesPage'

const mapStateToProp = createStructuredSelector({
    token,
    fetched,
    loading,
    capability,
    capabilityPerPage,
    capabilityType,
    set,
    organization,
    userOrganization,
    maturity,
    access
})

const mapDispatchToProps = {
    getCapability,
    getCapabilityType,
    newCapability,
    newCapabilityType,
    editCapability,
    editCapabilityType,
    getSet,
    importCapability,
    getMaturityDict,
    newSet,
    deleteCapabilities,
    getOrganization,
}

export default connect(mapStateToProp, mapDispatchToProps)(CapabilitiesPage)