import React from 'react';
import { BsTrash3 } from 'react-icons/bs'
import { FiEdit } from 'react-icons/fi'
import IconButton from '../ui/buttons/IconButton';


const ProductItem = ({
        item, 
        showDelete, 
        deleteId,
        setDeleteItem, 
        setShowDelete, 
        setDeleteId, 
        setUpdateChosen, 
        setAddChosen, 
        setUpdateId, 
        setUpdateName, 
        setUpdatePortfolio,
        setUpdateProducts,
        setUpdateType,
        setUpdateActivityField,
        setUpdateProcess,
        access
}) => {
    const {
        Products_Add,
		Products_Edit,
		Products_Delete
    } = access

    const handleDoubleClick = event => {
        if (!Products_Edit) return
        
        if (event.detail === 2) {
            setUpdateChosen(true)
            setAddChosen(false)
            setUpdateId(item.id)
            setUpdateName(item.name)
            setUpdateProducts(item.list_of_products)
            setUpdatePortfolio(item.portfolio_flag > 0 ? 'Yes' : 'No')
            setUpdateType(item.parent_type)
            setUpdateActivityField(item.activity_id)
            setUpdateProcess(item.parent_process)
        }
    }
    
    return (
        <tr
            key={item.id}
            className="Product-item"
            style={{
                backgroundColor:
                showDelete && deleteId === item.id && "#A3AAC4",
            }}
            onClick={(e) => {handleDoubleClick(e)}}
            >
            <td className="px-4 py-3">{item.id}</td>
            <td className="px-4 py-3">{item.name}</td>
            <td className="px-4 py-3">{item.portfolio_flag ? 'Yes' : 'No'}</td>
            <td className="px-4 py-3">{item.list_of_products && item.list_of_products.toString()}</td>
            <td className="px-4 py-3">{item.parent_type ? 'Process' : 'Activity'
            }</td>
            <td className="px-4 py-3">{item.activity_id > 0 && item.activity_id}</td>
            <td className="px-4 py-3 flex link">{item.parent_process > 0 && item.parent_process}
                <div className='capability-controls'>
                    <IconButton
						className="ml-5"
						onClick={() => {
							setShowDelete(!showDelete)
							setDeleteId(item.id)
							setDeleteItem({name: item.name})
						}}
						notRestricted={Products_Delete}
					>
						<BsTrash3 alt="delete" />
					</IconButton>
                    <IconButton
						className="ml-5"
						onClick={() => {
                            setUpdateChosen(true)
                            setAddChosen(false)
                            setUpdateId(item.id)
                            setUpdateName(item.name)
                            setUpdateProducts(item.list_of_products)
                            setUpdatePortfolio(item.portfolio_flag > 0 ? 'Yes' : 'No')
                            setUpdateType(item.parent_type)
                            setUpdateActivityField(item.activity_id)
                            setUpdateProcess(item.parent_process)
                        }}
						notRestricted={Products_Edit}
					>
						<FiEdit alt="update" />
					</IconButton>
                </div>
            </td>
        </tr>
    );
};

export default ProductItem;