import { connect } from "react-redux";
import { fetched, token, loading, activity, activityPerPage, activityType, goal, successMessage, errorMessage, warningMessage, access } from '../../store/reducers/selectors'
import { getActivity, getActivityType, newActivity, newActivityType, editActivity, editActivityType, getGoal, clearErrorMessage, clearSuccessMessage, newSuccessMessage, newErrorMessage, newWarningMessage } from "../../api/actions";
import { createStructuredSelector } from "reselect";
import ActivitiesPage from './ActivitiesPage'

const mapStateToProp = createStructuredSelector({
    token,
    fetched,
    loading,
    activity,
    activityPerPage,
    activityType,
    goal,
    successMessage,
    errorMessage,
    warningMessage,
    access
})

const mapDispatchToProps = {
    getActivity,
    getActivityType,
    newActivity,
    newActivityType,
    editActivity,
    editActivityType,
    getGoal,
    clearSuccessMessage,
    clearErrorMessage,
    newSuccessMessage,
    newErrorMessage,
    newWarningMessage
}

export default connect(mapStateToProp, mapDispatchToProps)(ActivitiesPage);