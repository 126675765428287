import React, { useState, useContext } from 'react'
import Team from './Team'
import { LiaEditSolid } from "react-icons/lia"
import CRUDChecklist from './CRUDChecklist'
import { OrganizationTabContext } from '../../contexts/OrganizationTabContext'
import { MdDeleteOutline } from "react-icons/md"


export default function Department({goalType, department, teams}) {
    const [
        setShowDelete,
        setUpdateChosen,
        setAddChosen,
        setUpdateId,
        setUpdateName,
        setUpdateType,
        setUpdateParent,
        setDeleteId, 
        setDeleteItemsType,
        updateChosenHandler,
        setUpdateTeam,
        setUpdateCollaborators,
        people
    ] = useContext(OrganizationTabContext)

    const [isOpen, setIsOpen] = useState(false)
    const detailsToggle = () => setIsOpen(prev => !prev)
    const summaryStyle = isOpen ? "transition-all mt-[5em] question py-3 pl-4 cursor-pointer select-none w-full outline-none" : "transiton-all question py-3 pl-4 cursor-pointer select-none w-full outline-none"
    const departmentTeams = teams.filter(team => department.teams.includes(team.name))
    const editDepartmentHandler = () => {
        setAddChosen(false)
        setShowDelete(false)
        updateChosenHandler(true)
        setUpdateId(department.id)
        setUpdateName(department.name)
        setUpdateType('department')
        setUpdateParent('')
        setUpdateTeam(departmentTeams)
    }

    const deleteDepartmentHandler = () => {
        setShowDelete(true)
        setAddChosen(false)
        setUpdateChosen(false)
        setDeleteItemsType('department')
        setDeleteId(department.id)
    }

    return (
        <details className={isOpen ? "rounded-xl bg-gray-100 flex w-[95%] py-5 items-center ml-[5%]" : "rounded-xl flex w-[95%] py-5 items-center ml-[5%]"}>
            <summary className={summaryStyle} onClick={detailsToggle}><span className="bg-gray-100 w-[50px]" />
                {department.name}
                <div className="flex items-center">
                    <LiaEditSolid className="m-1" onClick={editDepartmentHandler} />
                    <MdDeleteOutline className="m-1" onClick={deleteDepartmentHandler} />
                </div>
            </summary>
            <div className="flex transition-all mt-[-10em] w-full justify-center pt-1 pb-3 px-4 border-l-black mr-11"><CRUDChecklist item={department} goalType={goalType} /></div>
            {teams.map((team) => {
                if (department.teams.includes(team.name)) return <Team key={team.id} team={team} goalType={goalType} />
            })}
        </details>
    )
}