import React, { useState } from "react";
import {Eye, EyeSlash} from 'react-bootstrap-icons';


const FormInput = (props) => {
  const [focused, setFocused] = useState(false)
  const [visible, setVisible] = useState(false)
  const { label, errorMessage, onChange, id, ...inputProps } = props  

  const eye = visible ? 'text' : 'password'

  const togglePasswordVisibility = (e) => {
    setVisible(!visible)
  }

  const handleFocus = (e) => {
    setFocused(true);
  }

  return (
    <div className="input-container">
      <label>{label}</label>
      {inputProps.type === 'password' && <span className="input-basic-container__eye" onClick={togglePasswordVisibility}>{visible ? <Eye /> : <EyeSlash />}</span>}
      <input
        className="input-basic-container__input h-11 shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mb-4"
        {...inputProps}
        type={inputProps.type === "password" ? eye : inputProps.type}
        onChange={onChange}
        onBlur={handleFocus}
        onFocus={() =>
          inputProps.name === "confirmPassword" && setFocused(true)
        }
        focused={focused.toString()}
      />
      <span className="input-basic-container__input-error">{errorMessage}</span>
    </div>
  );
};

export default FormInput;