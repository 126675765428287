import React, { useState } from 'react'


export default function SetOfCheckboxes({values, item}) {
    const [createMethod, setCreateMethod] = useState(values.create)
    const [readMethod, setReadMethod] = useState(values.read)
    const [updateMethod, setUpdateMethod] = useState(values.update)
    const [deleteMethod, setDeleteMethod] = useState(values.delete)

    const createMethodChangeHandler = (e) => setCreateMethod(e.target.checked)
    const readMethodChangeHandler = (e) => setReadMethod(e.target.checked)
    const updateMethodChangeHandler = (e) => setUpdateMethod(e.target.checked)
    const deleteMethodChangeHandler = (e) => setDeleteMethod(e.target.checked)

    return (
        <div className="flex my-3 justify-start">
            <div className="flex w-1/6 justify-between">
                <input checked={createMethod} type="checkbox" onChange={createMethodChangeHandler} name="check-create" />
                <input checked={readMethod} type="checkbox" onChange={readMethodChangeHandler} name="check-read" />					
                <input checked={updateMethod} type="checkbox" onChange={updateMethodChangeHandler} name="check-update" />
                <input checked={deleteMethod} type="checkbox" onChange={deleteMethodChangeHandler} name="check-delete" />
            </div>
            <p className="ml-4">{item.level + ". " + item.name}</p>
        </div>
    )
}