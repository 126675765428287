import React from 'react'

import Capabilities from "../../assets/capabilities.svg"
import CardItem from './CardItem';

const DirectoryCapabilities = ({ capability }) => {
    const supportCapabilities = capability && capability.filter(item => item.property === 'Support')
    const strategicCapabilities = capability && capability.filter(item => item.property === 'Strategic')
    const mdmCapabilities = capability && capability.filter(item => item.property === 'MDM')
    const esgCapabilities = capability && capability.filter(item => item.property === 'ESG')
    const planningCapabilities = capability && capability.filter(item => item.property === 'Planning')
    const executionCapabilities = capability && capability.filter(item => item.property === 'Execution')
    const learningCapabilities = capability && capability.filter(item => item.property === 'Learning')
    
    return (
        <div className="directory">
            <CardItem linkRoute={'capabilities'}
                    image={Capabilities}
                    dataArray={capability} >
                    Capabilities
            </CardItem>
            <CardItem linkRoute={'capabilities'}
                    image={Capabilities}
                    dataArray={strategicCapabilities} >
                    Capabilities of Strategic level
            </CardItem>
            <CardItem linkRoute={'capabilities'}
                    image={Capabilities}
                    dataArray={supportCapabilities} >
                    Capabilities of Support level
            </CardItem>
            <CardItem linkRoute={'capabilities'}
                    image={Capabilities}
                    dataArray={mdmCapabilities} >
                    Capabilities of MDM level
            </CardItem>
            <CardItem linkRoute={'capabilities'}
                    image={Capabilities}
                    dataArray={esgCapabilities} >
                    Capabilities of ESG level
            </CardItem>
            <CardItem linkRoute={'capabilities'}
                    image={Capabilities}
                    dataArray={planningCapabilities} >
                    Capabilities of Planning level
            </CardItem>
            <CardItem linkRoute={'capabilities'}
                    image={Capabilities}
                    dataArray={executionCapabilities} >
                    Capabilities of Execution level
            </CardItem>
            <CardItem linkRoute={'capabilities'}
                    image={Capabilities}
                    dataArray={learningCapabilities} >
                    Capabilities of Learning level
            </CardItem>
        </div>
    );
};

export default DirectoryCapabilities;