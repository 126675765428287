import React from 'react';
import { BsTrash3 } from 'react-icons/bs'

import IconButton from '../../ui/buttons/IconButton'

const User = ({ data, item, showDelete, setShowDelete, setDeleteId, setDeleteItem, access }) => {
	const { Settings_Organization_Edit_organization } = access

	return (
		<tr key={item.id} className="Scenario-item">
			<td className="px-4 py-3">{item.name}</td>
			<td className="px-4 py-3">{item.role}</td>
			<td className="px-4 py-3 flex items-center justify-between">{item.email}
				<IconButton
					className="ml-5 capability-controls__button"
					onClick={() => {
						setShowDelete(!showDelete)
						setDeleteId(item.id)
						setDeleteItem({name: item.name})
					}}
					notRestricted={Settings_Organization_Edit_organization && (data && data.length > 1)}
				>
					<BsTrash3 alt="delete" />
				</IconButton>
			</td>
		</tr>
	);
};

export default User;