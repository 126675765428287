import React, { useState, useRef, useEffect } from "react";
import html2canvas from "html2canvas";
import Draggable from "react-draggable";

import useOnClickOutside from "../hooks/useOnClickOutside";
import NewCapabilitiesReportHorizontalDownload from "./Reports/NewCapabilitiesReportHorizontalDownload";
import CapabilitiesReportVerticalDownload from "./Reports/CapabilitiesReportVerticalDownload";

const ModalSave = ({
  closeModal,
  active,
  data,
  date,
  organizationName,
  firstLevels,
  heatmapActive,
  isHeatmapNotDot,
  itemWidth,
  nameOfTheSet,
  timeline,
  toggleHeatmap,
  firstHorizontalLevels,
  verticalData,
}) => {
  const [borderSize, setBorderSize] = useState(50);
  const [extention, setExtention] = useState("png");
  const ref = useRef();
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleOpenWindow = () => {
    const newWindow = window.open("/bcm-new-page", "_blank");

    if (newWindow && newWindow.opener) {
      // Checking if newWindow and its opener property are not null
      setTimeout(() => {
        newWindow.close();
        closeModal();
      }, 2000); // Adjust the timeout as needed
    } else {
      console.error("Unable to open the new window.");
    }
  };

  const handleOpenWindowSVG = () => {
    const newWindow = window.open("/bcm-new-page-svg", "_blank");

    if (newWindow && newWindow.opener) {
      // Checking if newWindow and its opener property are not null
      setTimeout(() => {
        newWindow.close();
        closeModal();
      }, 2000); // Adjust the timeout as needed
    } else {
      console.error("Unable to open the new window.");
    }
  };

  useOnClickOutside(ref, () => {
    closeModal();
  });

  const borderSizeChangeHandler = (e) => {
    setBorderSize(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Escape") {
      closeModal();
    }
  };

  const downloadImage = async () => {
    const canvas = await html2canvas(document.getElementById("saveChart"));
    const link = document.createElement("a");
    link.href = canvas.toDataURL("image/png", 1.0);
    link.download = "reports.png";

    link.click();
  };

  if (!active) {
    return <></>;
  }

  return (
    <div id="openModal" className="modalbg">
      <div className="relative flex justify-center items-start flex-col rounded-xl ease-in h-full">
        <Draggable
          handle=".save-controls"
          defaultPosition={{
            x: windowDimensions.width / 2 - 200,
            y: windowDimensions.height / 2,
          }}
          position={null}
          grid={[1, 1]}
          scale={1}
        >
          <div id="dialog-window" className="save-controls">
            <h5 className="save-controls__title">Save report</h5>
            <button className="close" onClick={closeModal}>
              X
            </button>
            <div className="flex flex-col w-[90%]">
              <p>Border</p>
              <input
                value={borderSize}
                onChange={borderSizeChangeHandler}
                placeholder="Size"
                type="number"
                min="30"
                autoFocus
                onKeyDown={handleKeyPress}
                className="h-11 shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div className="flex flex-col w-[90%] mb-10">
              <p>Export extention</p>
              <select
                value={extention}
                onChange={(e) => setExtention(e.target.value)}
                className="h-11 w-32 shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              >
                <option value={"png"}>PNG</option>
                <option value={"svg"}>SVG 1.0</option>
                <option value={"svgNextGen"}>SVG 1.1</option>
              </select>
            </div>
            {extention === "png" && (
              <button
                onClick={downloadImage}
                className="text-white bg-second-color border-0 py-2 mt-6 px-12 focus:outline-none rounded text-lg"
              >
                Download
              </button>
            )}
            {extention === "svg" && (
              <button
                onClick={handleOpenWindow}
                className="text-white bg-second-color border-0 py-2 mt-6 px-12 focus:outline-none rounded text-lg"
              >
                Download
              </button>
            )}
            {extention === "svgNextGen" && (
              <button
                onClick={handleOpenWindowSVG}
                className="text-white bg-second-color border-0 py-2 mt-6 px-12 focus:outline-none rounded text-lg"
              >
                Download
              </button>
            )}
          </div>
        </Draggable>
        <Draggable
          handle=".modal-save"
          defaultPosition={{ x: 0, y: 0 }}
          position={null}
          grid={[1, 1]}
          scale={1}
        >
          <div
            id="saveChart"
            className="capabilities modal-save"
            style={{
              border: borderSize + "px solid #EEEEF2",
              position: "relative",
            }}
          >
            <div>
              <div className="flex justify-center items-center p-5 w-full h-5 gap-10">
                <p>{organizationName}</p>
                <p>{nameOfTheSet}</p>
                <p>{date}</p>
              </div>
              <div className="flex w-full h-full flex-col ml-5">
                <div className="flex w-full h-full gap-[1%]">
                  {firstLevels.map((item) => {
                    return (
                      <CapabilitiesReportVerticalDownload
                        key={item.id}
                        item={item}
                        data={data}
                        heatmapActive={toggleHeatmap}
                        isHeatmapNotDot={isHeatmapNotDot}
                        itemWidth={itemWidth}
                        timeline={timeline}
                      />
                    );
                  })}
                </div>
                <div className="flex flex-col gap-3 h-full min-w-[500px] z-[5] bottom-5">
                  {firstHorizontalLevels &&
                    firstHorizontalLevels.map((item) => {
                      return (
                        <NewCapabilitiesReportHorizontalDownload
                          key={item.id}
                          item={item}
                          data={data}
                          verticalData={verticalData}
                          heatmapActive={toggleHeatmap}
                          itemWidth={itemWidth}
                          timeline={timeline}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </Draggable>
      </div>
    </div>
  );
};

export default ModalSave;
