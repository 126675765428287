import React, { useState } from 'react';

import { VscGear } from "react-icons/vsc";
import { VscClearAll } from 'react-icons/vsc'


const GoalsTableHeaderItem = ({ 
    sortData, 
    name, 
    value, 
    activeSort, 
    setSortingName, 
    setSortingParent, 
    setSortingLevel, 
    setSortingTag, 
    setSortingType,
 }) => {
    const [filter, setFilter] = useState(false)
    const [sortName, setSortName] = useState('')
    const [parent, setParent] = useState('')
    const [level, setLevel] = useState('')
    const [type, setType] = useState('')
    const [tag, setTag] = useState('')

    let headerItemType = ''
    let headerItemFilterActive = ''

    const Checkbox = ({ type = "checkbox", name, checked = false, onChange }) => {
        return (
          <input type={type} name={name} checked={checked} onChange={onChange} />
        )
    }

    const checkboxesTypes = [
        {
            name: 'Strategy',
            key: 'Strategy',
            label: 'Strategy',
        },
        {
            name: 'Strategic intention',
            key: 'Strategic intention',
            label: 'Strategic intention',
        },
        {
            name: 'Strategic goal',
            key: 'Strategic goal',
            label: 'Strategic goal',
        },
        {
            name: 'Business goal',
            key: 'Business goal',
            label: 'Business goal',
        },
        {
            name: 'Project goal',
            key: 'Project goal',
            label: 'Project goal',
        },
        {
            name: 'Sprint goal',
            key: 'Sprint goal',
            label: 'Sprint goal',
        },
        {
            name: 'Task goal',
            key: 'Task goal',
            label: 'Task goal',
        },
    ]

    const setFieldClear = () => {
        setFilter(!filter)
    }

    const handleKeyPress = (e) => {
        if(e.key === 'Enter' || e.key === 'Escape'){
            setFilter(!filter)
        }
    }

    const closeFilter = (e) => {
        if(e.key === 'Escape'){
            setFilter(!filter)
        }
    }
    
    const nameChangeHandler = (e) => {
        setSortName(e.target.value)
        setSortingName(e.target.value)
    }
 
    const parentChangeHandler = (e) => {
        setParent(e.target.value)
        setSortingParent(e.target.value)
    }

    const tagChangeHandler = (e) => {
        setTag(e.target.value)
        setSortingTag(e.target.value)
    }
    
    const levelChangeHandler = (e) => {
        setLevel(e.target.value)
        setSortingLevel(e.target.value)
    }

    const typeChangeHandler = (e) => {
        setType({...type, [e.target.name] : e.target.checked }) 
        setSortingType({...type, [e.target.name] : e.target.checked })
    }

 
    if (value === 'name') {
        headerItemFilterActive = sortName
        headerItemType = <div className='h-11 w-72 shadow rounded py-2 px-3 filter-input'>
                            <input
                                value={sortName} 
                                className="" 
                                type="text" 
                                placeholder="Start typing here"
                                onChange={nameChangeHandler}>
                            </input>
                            <button className='clear-field' onClick={() => {setFieldClear(); setSortName(''); setSortingName('') }}><VscClearAll /></button>
                        </div>
    }
 
    if (value === 'parent') {
        headerItemFilterActive = parent
        headerItemType = <div className='h-11 w-72 shadow rounded py-2 px-3 filter-input'>
                            <input 
                                value={parent}
                                type="number"
                                min="1"
                                placeholder="Parent number" 
                                onChange={parentChangeHandler}
                                onKeyDown={handleKeyPress}>
                            </input>
                            <button className='clear-field' onClick={() => {setFieldClear(); setParent(''); setSortingParent('') }}><VscClearAll /></button>
                        </div>
    }
    if (value === 'level') {
        headerItemFilterActive = level
        headerItemType = <div className='h-11 w-72 shadow rounded py-2 px-3 filter-input'>
                            <input 
                                value={level}
                                type="number"
                                min="1"
                                placeholder="Level number" 
                                onChange={levelChangeHandler}
                                onKeyDown={handleKeyPress}>
                            </input>
                            <button className='clear-field' onClick={() => {setFieldClear(); setLevel(''); setSortingLevel('') }}><VscClearAll /></button>
                        </div>
    }
    if (value === 'type') {
        headerItemFilterActive = type
        headerItemType = 
                    <div className="filter-select">
                        <form className="filter-select__form" onChange={typeChangeHandler}>
                            {checkboxesTypes.map(item => (
                                <label key={item.key}><br/>
                                    <Checkbox name={item.name} checked={type[item.name]} onChange={typeChangeHandler} />
                                    {item.name}
                                </label>
                                ))}
                        </form>
                        <button className='clear-field' onClick={() => {setFieldClear(); setType(''); setSortingType('') }}><VscClearAll /></button>
                    </div>
    }
    if (value === 'tag') {
        headerItemFilterActive = tag
        headerItemType = <div className='h-11 w-72 shadow rounded py-2 px-3 filter-input'>
                            <input
                                value={tag} 
                                className="" 
                                type="text" 
                                placeholder="Start typing here"
                                onChange={tagChangeHandler}
                                onKeyDown={handleKeyPress}>
                            </input>
                            <button className='clear-field' onClick={() => {setFieldClear(); setTag(''); setSortingTag('') }}><VscClearAll /></button>
                        </div>
    }

    let uniqueStyling

    if (value === 'id') {
        uniqueStyling = 'header-item_id'
    }
    if (value === 'name') {
        uniqueStyling = 'header-item_name'
    }
    if (value === 'link') {
        uniqueStyling = 'header-item_link'
    }

    return (
        <th className={"table-header-item "  + uniqueStyling}>
            <div className='table-header-item__container' tabIndex={-1} onKeyDown={closeFilter}>
                {filter && headerItemType}
                <button onClick={() => {sortData(value)}}>{name}</button>
                {(activeSort.key !== value) && <div style={{marginRight: 12}}></div>}
                {(activeSort.key === value && activeSort.inc) && <div style={{marginBottom: 2}}>▲</div>}
                {(activeSort.key === value && !activeSort.inc) && <div style={{marginBottom: 3}}>▼</div>}
                {(value !== 'id' && value !== 'link') && <VscGear className={headerItemFilterActive ? "text-2xl cursor-pointer capability-filter text-red-400" : (filter ? "text-2xl cursor-pointer capability-filter filter-active" : "text-2xl cursor-pointer capability-filter")} onClick={() => {setFilter(!filter)}} />}
            </div>
        </th>
    );
};

export default GoalsTableHeaderItem;