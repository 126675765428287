import React, { useEffect, useState } from "react";
import groupArrayIntoSixes from "../../handlers/groupArrayHandler";

import Modal from "../Modal";
import CapabilitiesTableHeaderItem from "./CapabilitiesTableHeaderItem";
import CapabilityItem from "./CapabilityItem";

const CapabilitiesTable = ({
  data,
  deletedChosen,
  setSelected,
  setUpdateName,
  setUpdateLink,
  setUpdateProperty,
  setUpdateLevel,
  setUpdateStartDate,
  setUpdateEndDate,
  setUpdateId,
  setSuccess,
  setError,
  getData,
  setUpdateChosen,
  setAddChosen,
  current,
  setUpdateFirstLevel,
  setUpdateSecondLevel,
  setUpdateThirdLevel,
  setUpdateLane,
  setPaginationLevels,
  setFirstLevel,
  setSecondLevel,
  setThirdLevel,
  setLane,
  setLayout,
  setUpdateLayout,
  setUpdateSetId,
  setMaturity,
  setSet,
  set,
  selectAll,
  selectedItems,
  setUpdateDescription,
  access,
  capabilities,
}) => {
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [deleteItem, setDeleteItem] = useState({ name: "" });
  const [sortedData, setSortedData] = useState([]);
  const [activeSort, setActiveSort] = useState({ key: "", inc: true });
  const [sortingStartDate, setSortingStartDate] = useState("");
  const [sortingEndDate, setSortingEndDate] = useState("");
  const [sortingName, setSortingName] = useState("");
  const [sortingLane, setSortingLane] = useState("");
  const [sortingLayout, setSortingLayout] = useState("");
  const [sortingParent, setSortingParent] = useState("");
  const [sortingProperty, setSortingProperty] = useState("");
  const [sortingAddress, setSortingAddress] = useState("");
  const [sortingDescription, setSortingDescription] = useState("");

  useEffect(() => {
    let result = [];

    for (const array of data) {
      result.push(...array);
    }

    if (sortingStartDate) {
      result = result.filter((item) =>
        item.start_date.includes(sortingStartDate)
      );
    }
    if (sortingEndDate) {
      result = result.filter((item) => item.end_date.includes(sortingEndDate));
    }
    if (sortingName) {
      result = result.filter((item) => item.name.includes(sortingName));
    }
    if (sortingDescription) {
      result = result.filter((item) =>
        item.description.includes(sortingDescription)
      );
    }
    if (sortingParent) {
      result = result.filter((item) => item.parent === Number(sortingParent));
    }
    if (sortingLane) {
      result = result.filter((item) => item.lane === Number(sortingLane));
    }
    if (sortingProperty) {
      const getKeyByValue = (object) => {
        const result = [];
        const keys = Object.keys(object);
        for (const key of keys) {
          if (sortingProperty[key] === true) {
            result.push(key);
          }
        }
        return result;
      };

      const properties = getKeyByValue(sortingProperty);

      if (properties.length > 0) {
        const originalProperties = [
          "",
          "Support",
          "MDM",
          "ESG",
          "Strategic",
          "Learning",
          "Planning",
          "Execution",
        ];
        const originalPropertiesFiltered = originalProperties.filter(
          (n) => !properties.includes(n)
        );
        for (const p of originalPropertiesFiltered) {
          result = result.filter((item) => item.property !== p);
        }
      }
    }
    if (sortingLayout) {
      const getKeyByValue = (object) => {
        const result = [];
        const keys = Object.keys(object);
        for (const key of keys) {
          if (sortingLayout[key] === true) {
            result.push(key);
          }
        }
        return result;
      };

      const layouts = getKeyByValue(sortingLayout);

      if (layouts.length > 0) {
        const originalLayouts = ["backlog", "horizontal", "vertical"];
        const originalLayoutsFiltered = originalLayouts.filter(
          (n) => !layouts.includes(n)
        );
        for (const p of originalLayoutsFiltered) {
          result = result.filter((item) => item.layout !== p);
        }
      }
    }
    if (sortingAddress) {
      if (sortingAddress.l1 && sortingAddress.l2 && sortingAddress.l3) {
        result = result.filter(
          (item) =>
            Number(
              "" + item.first_level + item.second_level + item.third_level
            ) ===
            Number(
              "" + sortingAddress.l1 + sortingAddress.l2 + sortingAddress.l3
            )
        );
      }
      if (sortingAddress.l1 && sortingAddress.l2) {
        result = result.filter(
          (item) =>
            Number("" + item.first_level + item.second_level) ===
            Number("" + sortingAddress.l1 + sortingAddress.l2)
        );
      }
      if (sortingAddress.l1 && !sortingAddress.l2) {
        result = result.filter(
          (item) =>
            Number("" + item.first_level) === Number("" + sortingAddress.l1)
        );
      }
    }

    setSortedData(groupArrayIntoSixes(result, 20));
    setPaginationLevels(sortedData.length);
  }, [
    data,
    sortingStartDate,
    sortingEndDate,
    sortingName,
    sortingParent,
    sortingProperty,
    sortingAddress,
    sortingDescription,
    sortingLane,
    sortingLayout,
    setPaginationLevels,
    sortedData.length,
  ]);

  const sortData = (sortKey) => {
    let result = [];

    if (sortedData.length > 1) {
      for (const array of sortedData) {
        result.push(...array);
      }
    } else {
      for (const array of data) {
        result.push(...array);
      }
    }

    const reverseSort = (sortKey) => {
      setActiveSort({ key: sortKey, inc: false });
      result.reverse();
    };

    if (sortKey === "id") {
      result.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));

      if (activeSort.key === sortKey && activeSort.inc) {
        reverseSort(sortKey);
      } else {
        setActiveSort({ key: "id", inc: true });
      }
    }
    if (sortKey === "name") {
      result.sort((a, b) => a.name.localeCompare(b.name));

      if (activeSort.key === sortKey && activeSort.inc) {
        reverseSort(sortKey);
      } else {
        setActiveSort({ key: "name", inc: true });
      }
    }
    if (sortKey === "address") {
      result.sort(
        (a, b) =>
          parseFloat(
            Number("" + a.first_level + a.second_level + a.third_level)
          ) -
          parseFloat(
            Number("" + b.first_level + b.second_level + b.third_level)
          )
      );

      if (activeSort.key === sortKey && activeSort.inc) {
        reverseSort(sortKey);
      } else {
        setActiveSort({ key: "address", inc: true });
      }
    }
    if (sortKey === "lane") {
      result.sort((a, b) => a.lane - b.lane);

      if (activeSort.key === sortKey && activeSort.inc) {
        reverseSort(sortKey);
      } else {
        setActiveSort({ key: "lane", inc: true });
      }
    }
    if (sortKey === "layout") {
      result.sort((a, b) => a.layout.localeCompare(b.layout));

      if (activeSort.key === sortKey && activeSort.inc) {
        reverseSort(sortKey);
      } else {
        setActiveSort({ key: "layout", inc: true });
      }
    }
    if (sortKey === "start-date") {
      const emptyDates = result.filter((item) => item.start_date === "");
      const actuallDates = result.filter((item) => item.start_date !== "");
      actuallDates.sort(
        (a, b) => new Date(a.start_date) - new Date(b.start_date)
      );
      result = [...actuallDates, ...emptyDates];

      if (activeSort.key === sortKey && activeSort.inc) {
        reverseSort(sortKey);
      } else {
        setActiveSort({ key: "start-date", inc: true });
      }
    }
    if (sortKey === "end-date") {
      const emptyDates = result.filter((item) => item.end_date === "");
      const actuallDates = result.filter((item) => item.end_date !== "");
      actuallDates.sort((a, b) => new Date(a.end_date) - new Date(b.end_date));
      result = [...actuallDates, ...emptyDates];

      if (activeSort.key === sortKey && activeSort.inc) {
        reverseSort(sortKey);
      } else {
        setActiveSort({ key: "end-date", inc: true });
      }
    }
    if (sortKey === "property") {
      result.sort((a, b) => a.property.localeCompare(b.property));

      if (activeSort.key === sortKey && activeSort.inc) {
        reverseSort(sortKey);
      } else {
        setActiveSort({ key: "property", inc: true });
      }
    }
    if (sortKey === "parent") {
      result.sort((a, b) => parseFloat(a.parent) - parseFloat(b.parent));

      if (activeSort.key === sortKey && activeSort.inc) {
        reverseSort(sortKey);
      } else {
        setActiveSort({ key: "parent", inc: true });
      }
    }
    if (sortKey === "description") {
      result.sort(
        (a, b) => parseFloat(a.description) - parseFloat(b.description)
      );

      if (activeSort.key === sortKey && activeSort.inc) {
        reverseSort(sortKey);
      } else {
        setActiveSort({ key: "description", inc: true });
      }
    }
    if (sortKey === "link") {
      result.sort((a, b) => parseFloat(a.link) - parseFloat(b.link));

      if (activeSort.key === sortKey && activeSort.inc) {
        reverseSort(sortKey);
      } else {
        setActiveSort({ key: "link", inc: true });
      }
    }
    setSortedData(groupArrayIntoSixes(result, 20));
    setPaginationLevels(sortedData.length);
  };

  return (
    <div className="flex flex-col w-[100%]">
      {showDelete && (
        <Modal
          setShowDelete={setShowDelete}
          deleteId={deleteId}
          deleteItem={deleteItem}
          deleteItemType={"capability"}
          setSuccess={setSuccess}
          setError={setError}
          getData={getData}
        />
      )}
      <table className="table-auto w-full text-left whitespace-no-wrap">
        <thead className="table-header">
          <tr>
            <CapabilitiesTableHeaderItem
              name={"ID"}
              sortData={sortData}
              value={"id"}
              activeSort={activeSort}
            />
            <CapabilitiesTableHeaderItem
              name={"NAME"}
              sortData={sortData}
              value={"name"}
              activeSort={activeSort}
              setSortingName={setSortingName}
            />
            <CapabilitiesTableHeaderItem
              name={"LAYOUT"}
              sortData={sortData}
              value={"layout"}
              activeSort={activeSort}
              setSortingLayout={setSortingLayout}
            />
            <CapabilitiesTableHeaderItem
              name={"ADDRESS"}
              sortData={sortData}
              value={"address"}
              activeSort={activeSort}
              setSortingAddress={setSortingAddress}
            />
            <CapabilitiesTableHeaderItem
              name={"LANE"}
              sortData={sortData}
              value={"lane"}
              activeSort={activeSort}
              setSortingLane={setSortingLane}
            />
            <CapabilitiesTableHeaderItem
              name={"START DATE"}
              sortData={sortData}
              value={"start-date"}
              activeSort={activeSort}
              setSortingStartDate={setSortingStartDate}
            />
            <CapabilitiesTableHeaderItem
              name={"END DATE"}
              sortData={sortData}
              value={"end-date"}
              activeSort={activeSort}
              setSortingEndDate={setSortingEndDate}
            />
            <CapabilitiesTableHeaderItem
              name={"PROPERTY"}
              sortData={sortData}
              value={"property"}
              activeSort={activeSort}
              setSortingProperty={setSortingProperty}
            />
            <CapabilitiesTableHeaderItem
              name={"PARENT"}
              sortData={sortData}
              value={"parent"}
              activeSort={activeSort}
              setSortingParent={setSortingParent}
            />
            <CapabilitiesTableHeaderItem
              name={"NOTE"}
              sortData={sortData}
              value={"description"}
              activeSort={activeSort}
              setSortingDescription={setSortingDescription}
            />
            <CapabilitiesTableHeaderItem
              name={"LINK"}
              sortData={sortData}
              value={"link"}
              activeSort={activeSort}
            />
          </tr>
        </thead>
        <tbody className="table-body">
          {sortedData.length > 0 &&
            sortedData[current].map((item) => {
              return (
                <CapabilityItem
                  key={item.id}
                  item={item}
                  showDelete={showDelete}
                  deleteId={deleteId}
                  setDeleteItem={setDeleteItem}
                  setShowDelete={setShowDelete}
                  setDeleteId={setDeleteId}
                  setUpdateChosen={setUpdateChosen}
                  setAddChosen={setAddChosen}
                  setUpdateId={setUpdateId}
                  setUpdateName={setUpdateName}
                  setUpdateLevel={setUpdateLevel}
                  setUpdateStartDate={setUpdateStartDate}
                  setUpdateEndDate={setUpdateEndDate}
                  setUpdateProperty={setUpdateProperty}
                  setUpdateLink={setUpdateLink}
                  setUpdateFirstLevel={setUpdateFirstLevel}
                  setUpdateSecondLevel={setUpdateSecondLevel}
                  setUpdateThirdLevel={setUpdateThirdLevel}
                  setUpdateLane={setUpdateLane}
                  setUpdateLayout={setUpdateLayout}
                  setFirstLevel={setFirstLevel}
                  setSecondLevel={setSecondLevel}
                  setThirdLevel={setThirdLevel}
                  setLane={setLane}
                  setLayout={setLayout}
                  setUpdateSetId={setUpdateSetId}
                  setMaturity={setMaturity}
                  setUpdateDescription={setUpdateDescription}
                  setSet={setSet}
                  set={set}
                  setSelected={setSelected}
                  selectAll={selectAll}
                  selectedItems={selectedItems}
                  access={access}
                  capabilities={capabilities}
                />
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default CapabilitiesTable;
