import { connect } from "react-redux";
import { fetched, token, loading, goal, goalPerPage, goalType, successMessage, errorMessage, warningMessage, access } from '../../store/reducers/selectors'
import { getGoal, getGoalType, newGoal, newGoalType, editGoal, editGoalType, newSuccessMessage, newWarningMessage, newErrorMessage, clearSuccessMessage, clearWarningMessage, clearErrorMessage, importGoal, deleteGoals } from "../../api/actions";
import { createStructuredSelector } from "reselect";
import GoalsPage from './GoalsPage'

const mapStateToProp = createStructuredSelector({
    token,
    fetched,
    loading,
    goal,
    goalPerPage,
    goalType,
    successMessage,
    warningMessage,
    errorMessage,
    access
})

const mapDispatchToProps = {
    getGoal,
    getGoalType,
    newGoal,
    newGoalType,
    editGoal,
    editGoalType,
    importGoal,
    deleteGoals,
    newSuccessMessage,
    newWarningMessage,
    newErrorMessage,
    clearSuccessMessage,
    clearWarningMessage,
    clearErrorMessage
}

export default connect(mapStateToProp, mapDispatchToProps)(GoalsPage);