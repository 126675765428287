import React, { useState, useRef } from 'react'
import * as xlsx from 'xlsx'

import SelectSet from '../../../components/ui/inputs/SelectSet'
import RegularButton from '../../../components/ui/buttons/RegularButton'                                                                                                                                                                                                                                                                                                                                                                                    

import { TbUpload } from 'react-icons/tb'

const  CapabilityImport = ({ loading, set, newSet, capability, importCapability, newErrorMessage, newWarningMessage, clearWarningMessage, activeSet, setActiveSet, access }) => {
    const [file, setFile] = useState(null)
    const [dragActive, setDragActive] = useState(false)
    const defaultSetId = set && set.find(item => item.name === activeSet).id
    const inputRef = useRef(null)
    const specifiedSet = capability && capability.filter(item => item.universal_item_set_id === defaultSetId)
    const backLogArray = specifiedSet && specifiedSet.filter(item => item.first_level === 0)

    const {
        Capabilities_Import_Select_set,
        Capabilities_Import_Download_empty_template,
        Capabilities_Import_Browse_file,
        Capabilities_Import_Upload
    } = access

    if (backLogArray && backLogArray.length) {
        backLogArray.sort((a, b) => parseFloat(Number("" + a.first_level + a.second_level + a.third_level)) 
                                    - parseFloat(Number("" + b.first_level + b.second_level + b.third_level)))
    }

    const checkSets = (newData) => {
        const newSets = []
        for (const d of newData) {
            const setName = String(d.set).trim() // name of a set has to be a string also trimed whitespaces
            const oldSet = set&& set.find(item => item.name === setName)
            
            if (!oldSet) {
                const alreadyAdded = newSets && newSets.find(item => item === setName)

                if (!alreadyAdded && setName) {
                    newSets.push(setName)
                }
            }
        }

        return newSets.length ? newSets : null
    }

    const onlyImportantFields = (data) => {
        const result = []

        for (const item of data) {
            result.push({
                id: item.id,
                name: item.name,
                start_date: item.start_date,
                end_date: item.end_date,
                property: item.property,
                link: item.link,
                tag: item.tag,
                level1: item.level1,
                level2: item.level2,
                level3: item.level3,
                set: String(item.set).trim(),
                layout: item.layout,
                lane: item.lane
            })
        }
        return result
    }

    const createTree = (sets, data) => {
        const checkLevels1 = (firstLevelElements, secondLevelElements) => {
            const result = []
            for (const l2Item of secondLevelElements) {
                const isFirstElement = firstLevelElements.find(l1Item => l1Item.level1 === l2Item.level1)
                if (!isFirstElement) {
                    const isAlreadyAdded = result.find(item => item.name === l2Item.level1)
                    if (!isAlreadyAdded) {
                        result.push({name: l2Item.level1, level1: l2Item.level1, level2: '', level3: '', set: l2Item.set})
                    }
                }
            }

            return result
        }

        const checkLevels2 = (secondLevelElements, thirdLevelElements) => {
            const result = []
            for (const l3Item of thirdLevelElements) {
                const isSecondElement = secondLevelElements.find(l2Item => l2Item.level2 === l3Item.level2)
                if (!isSecondElement) {
                    const isAlreadyAdded = result.find(item => item.name === l3Item.level2)
                    if (!isAlreadyAdded) {
                        result.push({name: l3Item.level2, level1: l3Item.level1, level2: l3Item.level2, level3: '', set: l3Item.set})
                    }
                }
            }

            return result
        }

        const newData = onlyImportantFields(data)
        const columns = []
        const backLog = []
        let greatestLevelNumber = 1

        for (const n of specifiedSet) {
            if (n.first_level > greatestLevelNumber) {
                greatestLevelNumber = n.first_level
            }
        }

        let l1Count = greatestLevelNumber

        // naming caps
        for (const d of newData) {
            if (!d.name)  {
                if (d.level3) {
                    d.name = d.level3
                } else if (d.level2) {
                    d.name = d.level2
                } else if (d.level1) {
                    d.name = d.level1
                }
            }
        }

        const firstLevelElements = newData.filter(item => item.level1 && !item.level2 && !item.level3)
        const secondLevelElements = newData.filter(item => item.level1 && item.level2 && !item.level3)
        const thirdLevelElements = newData.filter(item => item.level1 && item.level2 && item.level3)

        const missingLevels1 = checkLevels1(firstLevelElements, secondLevelElements)
        const missingLevels2 = checkLevels2(secondLevelElements, thirdLevelElements)

        firstLevelElements.push(...missingLevels1)
        secondLevelElements.push(...missingLevels2)

        for (const firstLevelElement of firstLevelElements) {
            const setItem = sets.find(item => item.name === firstLevelElement.set)
            const setId = setItem ? setItem.id : defaultSetId
            columns.push({name: firstLevelElement.level1, firstLevel: ++l1Count, children: [{...firstLevelElement, universal_item_set_id: setId, first_level: l1Count, second_level: 0, third_level: 0}]})
        }

        for (const secondLevelElement of secondLevelElements) {
            const setItem = sets.find(item => item.name === secondLevelElement.set)
            const setId = setItem ? setItem.id : defaultSetId
            const parentName = secondLevelElement.level1
            const parentItem = columns.find(item => item.name === parentName)
            const itemIndex = columns.indexOf(parentItem)

            if (columns[itemIndex]) {
                columns[itemIndex].children.push({...secondLevelElement, universal_item_set_id: setId, first_level: parentItem.firstLevel, second_level: columns[itemIndex].children.length, third_level: 0})
            } else {
                backLog.push(secondLevelElement)
            }
        }

        for (const thirdLevelElement of thirdLevelElements) {
            const parentName = thirdLevelElement.level1
            const secondLevel = thirdLevelElement.level2
            const parentItem = columns.find(item => item.name === parentName)
            const itemIndex = columns.indexOf(parentItem)

            if (columns[itemIndex]) {
                const setItem = sets.find(item => item.name === thirdLevelElement.set)
                const setId = setItem ? setItem.id : defaultSetId
                const lvl3Elements = columns[itemIndex].children.filter(item => item.third_level)
                const secondLevelAddress = columns[itemIndex].children.find(item => item.name === secondLevel)
                if (secondLevelAddress) {
                    columns[itemIndex].children.push({...thirdLevelElement, universal_item_set_id: setId, first_level: parentItem.firstLevel, second_level: secondLevelAddress.second_level, third_level: lvl3Elements.length + 1})
                } else {
                    backLog.push(thirdLevelElement)
                }
            } else {
                backLog.push(thirdLevelElement)
            }
        }

        const result = []

        for (const i of columns) {
            result.push(...i.children)
        }         

        const backLogLastItem = backLogArray.length > 0 ? backLogArray[backLogArray.length - 1].second_level : -1
        let tmp = backLogLastItem

        for (const i of backLog) {
            const setItem = sets && sets.find(item => item.name === i.set)
            const setId = setItem ? setItem.id : defaultSetId
            tmp++
            result.push({...i, first_level: 0, second_level: tmp, third_level: 0, parent: -1, universal_item_set_id: setId})
        }
        return result
    }
    
    // handle drag events
    const handleDrag = function(e) {
        e.preventDefault()
        e.stopPropagation()
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true)
        } else if (e.type === "dragleave") {
            setDragActive(false)
        }
    }
    
    // triggers when file is dropped
    const handleDrop = function(e) {
        e.preventDefault()
        e.stopPropagation()
        setDragActive(false)
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFileChange(e.dataTransfer.files[0])
        }
    }
    
    // triggers when file is selected with click
    const handleChange = function(e) {
        e.preventDefault()
        if (e.target.files && e.target.files[0]) {
            handleFileChange(e.target.files[0])
        }
    }
    
    // triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click()
    }

    const handleFileChange = (file) => {
        setFile(file)
    }

    const formatDataWithoutParents = data => {
        const parentChangeHandler = (l1, l2, l3, capabilities) => {
            // backlog capability
            if (Number(l1) === 0) {
                return -1
            }
    
            // first level capability
            if ((Number(l2) === 0) && (Number(l3) === 0)) {
                return 0
            }
    
            let parentL1 = l1,
                parentL2 = l2,
                parentL3 = 0
    
            // capability has has l1, l2 or l3
            if (Number(l2) !== 0) {
                if (Number(l3) !== 0) {
                    parentL3 = 0
                } else {
                    parentL2 = 0
                }
            
            } else {
                return -1
            }
    
            const isParent = capabilities && capabilities.find(item => '' + item.first_level + item.second_level + item.third_level === 
            '' + parentL1 + parentL2 + parentL3)
    
            // capability has no parent
            if (!isParent) {
                return -2
            }
    
            return isParent.id
        }
        
        if (!data) {
            return
        }

        for (const i of data) {
            i['parent'] = parentChangeHandler(i.first_level, i.second_level, i.third_level, data)
        }

        return data
    }

    const uploadNewCaps = async (sets, json) => {
        if (!json.length) {   
            newErrorMessage({message: 'The file is empty or has no data to import'})
            return
        }        

        const dataForUpload = createTree(sets, json)

        if (!dataForUpload.length) {
            newErrorMessage({message: 'The file is empty or has no data to import'})
            return
        }

        const caps = await importCapability({capabilities: dataForUpload})
        await importCapability({capabilities: formatDataWithoutParents(caps.payload.data.data)})
    }

    const handleUploadClick = () => {
        if (!file) {
            newErrorMessage({message: 'Nothing to import'})
            return
        }

        const importAndUploadSets = async (newSets, json) => {
            newWarningMessage({message: 'Work in progress...'})

            if (newSets) {
                let sets 
                for (const nSet of newSets) {
                    sets = await newSet({
                        name: nSet,
                        list_name: '',
                    })
                }

                await uploadNewCaps([{ name: "-", id: 0, list_name: 'capability' }, ...sets.payload.data.data], json)
                clearWarningMessage()
                return
            }

            uploadNewCaps([{ name: "-", id: 0, list_name: 'capability' }, ...set], json)
            clearWarningMessage()
        }


        const readerCaps = new FileReader()
        readerCaps.onload = async (e) => {
            const data = e.target.result
            const workbook = xlsx.read(data, { type: "array" })
            const sheetName = workbook.SheetNames[0]
            const worksheet = workbook.Sheets[sheetName]
            const json = xlsx.utils.sheet_to_json(worksheet)

            setFile()

            const newSets = checkSets(json)
            
            importAndUploadSets(newSets, json)
        }

        readerCaps.readAsArrayBuffer(file)
    }

    const excelImport = () => {
        const wb = xlsx.utils.book_new()
        const ws = xlsx.utils.json_to_sheet([{ 
            id: null, 
            name: "Example name", 
            start_date: "2020-01-01", 
            end_date: "2029-01-01", 
            property: "Support", 
            link: "", 
            tag: "", 
            description: "", 
            level1: "Example name", 
            level2: "", 
            level3: "", 
            set: "",
            layout: 'horizontal',
            lane: '' 
        }])

        xlsx.utils.book_append_sheet(wb, ws, 'capabilities-export')
    
        xlsx.writeFile(wb, 'Exported-capabilities.xlsx')
    }

    return (
        <div className="reports">
            <div className="top-table mt-4">
                <div className="plus-minus">
                    <SelectSet
                        activeSet={activeSet} 
                        onChange={(e) => setActiveSet(e.target.value)} 
                        notRestricted={Capabilities_Import_Select_set}
                        set={set}
                    />
                    <h3 className="ml-4">Select a set to upload if the capability missing a set property</h3>
                </div>
                <div className="empty-template">
                    <RegularButton                    
                        className="text-white bg-second-color border-0 py-2 px-2 focus:outline-none rounded w-72"
                        onClick={excelImport}
                        notRestricted={Capabilities_Import_Download_empty_template}
                    >
                        Download empty template
                    </RegularButton>
                </div>
            </div>
            <div className={loading ? "form-container disabled flex justify-center items-center flex-col flex-wrap" : "form-container flex justify-center items-center flex-col flex-wrap"}>
                <form id="form-file-upload" className={!Capabilities_Import_Browse_file ? "upload-files-container disabled" : "upload-files-container"} onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                        <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
                        <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" }>
                        <div className="drag-file-area">
                            {
                                !file ? <>
                                        <TbUpload className="upload-icon" />
                                        <h3>Drag and drop your file here</h3>
                                        <p> or <span className="upload-button">browse file</span> from the device</p>
                                    </> 
                                :
                                    <>
                                        <p>{file.name} - {file.type}</p>
                                        <button className="upload-button" onClick={onButtonClick}>Change file</button>
                                    </>
                            }
                        </div> 
                        </label>
                        { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
                        <RegularButton                    
                            className="text-white bg-second-color border-0 py-2 mt-6 px-12 focus:outline-none rounded text-lg w-64"
                            onClick={handleUploadClick}
                            notRestricted={Capabilities_Import_Upload}
                        >
                            Upload
                        </RegularButton>
                    </form>
                </div>
        </div>
    );
}

export default CapabilityImport;