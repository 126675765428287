import React from "react"
import ProcessesReportItem from "./ProcessesReportItem"


const TypeRow = ({ item, dataArray, application, bpRelations, itemWidth }) => {
    const assembleData = (dataArray, bpRelations, application) => {
        if (!dataArray || !bpRelations || !application) return dataArray

        for (const process of dataArray) {
            const relations = bpRelations.filter(r => r.bp_list.id === process.id)
            if (relations.length) {
                const applications = []

                for (const relation of relations) {
                    applications.push(relation.application)
                }

                process.applications = applications
            }
        }

        return dataArray
    }

    const duplicatedDataArray = JSON.parse(JSON.stringify(dataArray))
    const assembledDataArray = assembleData(duplicatedDataArray, bpRelations, application)
    const filteredProcesses = assembledDataArray.filter(p => p.property_id === item.order)

    return (
        <div className="flex items-center h-40 border w-full">
            <div className="flex items-center h-40 border">
                <div className="rotated-container">
                    <span className="rotated-element">{item.name}</span>
                </div>
                <div className="flex justify-start h-[80%] w-full">
                    {filteredProcesses.map(i => {
                        return <ProcessesReportItem item={i} key={i.id} itemWidth={itemWidth} />
                    })}
                </div>
            </div>
        </div>
    )
}

export default TypeRow