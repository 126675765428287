import React, { useState, useEffect, useRef, useLayoutEffect, useContext } from 'react'
import Tree from 'react-d3-tree'
import html2canvas from "html2canvas"
import IconButton from '../ui/buttons/IconButton'
import { GoalsLegend } from './GoalsLegend'
import { GoalsPageContext } from '../../contexts/GoalsPageContext'


const GoalsTreeChart = ({ dataArray, downloadAccess }) => {
	// const [
	// 	setUpdateChosen,
	// 	setAddChosen,
	// 	setUpdateId,
	// 	setUpdateName,
	// 	setUpdateLevel,
	// 	setUpdateTag,
	// 	setUpdateType,
	// 	setUpdateParent,
	// 	setUpdateLink,
	// 	setUpdateDescription,
	// 	setActive,
	// ] = useContext(GoalsPageContext)

	const [screenWidth, setScreenWidth] = useState(window.innerWidth)
	const titleFontSize = screenWidth * .005
	const nodeSize = { x: screenWidth * 0.075, y: 75 }
	const reportsRef = useRef(null)
	
	const svgEl = useRef(null)
	const [reportsWidth, setReportsWidth] = useState(0)

	useEffect(() => {
		const handleResize = () => {
			setScreenWidth(window.innerWidth)
		}
		
		setReportsWidth(reportsRef.current.offsetWidth)

		if (!dataArray.length) return
		window.addEventListener('resize', handleResize);
		const svg = document.getElementsByClassName("rd3t-svg")[0]
		const bb = svg?.getBBox()
		svg.style.height = (bb.height + 300)
		svgEl.current = {y: bb.height + 100}
		const treeWrapper = document.getElementById("treeWrapper")
		treeWrapper.style.height = (bb.y + bb.height + 500)
		
		return () => {
			window.removeEventListener('resize', handleResize);
		}
	}, [])

	if (dataArray.length === 0) {
		return (
			<div className="chart-notification" ref={reportsRef}>
				<p className="chart-notification__text">No data for that chart</p>
			</div>
		)
	}

	if (!dataArray.find(item => item.parent_id === 0)) {
		return (
		<div className="chart-notification" ref={reportsRef}>
			<p className="chart-notification__text">Data structured incorrectly, there should be an element with a type "Strategy"</p>
		</div>
		)
	}

	const formatDataToResult = (dataArray) => {
		const arrayData = [...dataArray]
		let resultData = {};
		
		// Create a mapping of id to object for efficient lookups
		let idToObjectMap = {};
		
		// Loop through the arrayData to populate idToObjectMap
		for (let i = 0; i < arrayData.length; i++) {
			let obj = arrayData[i];
			obj.children = [];
			idToObjectMap[obj.id] = obj;
		}
		
		// Loop through the arrayData again to populate resultData
		for (let i = 0; i < arrayData.length; i++) {
			let obj = arrayData[i];
		
			// If the object has a parent_id, add it as a child to the corresponding parent object in resultData
			if (obj.parent_id !== 0 && idToObjectMap.hasOwnProperty(obj.parent_id)) {
				let parentObj = idToObjectMap[obj.parent_id];
			
				// Add the current object as a child to the parent object
				parentObj.children.push(obj);
			// If the object has a parent_id of 0, add it as a top-level object in resultData
			} else if (obj.parent_id === 0) {
				resultData = obj;
			}
		}
		return resultData;
	}

	const preparedDataArray = formatDataToResult(dataArray)

	const countLevels = (node, levelCounts = {}) => {
		if (!levelCounts[node.level]) {
			levelCounts[node.level] = 0;
		}
		
		levelCounts[node.level]++;
		
		if (node.children && node.children.length > 0) {
			for (const child of node.children) {
				countLevels(child, levelCounts);
			}
		}
		
		return levelCounts;
	}
	
	const levelCountsResult = countLevels(preparedDataArray, {});

	const calculateLevels = (dataObj) => {
		const dataKeys = Object.keys(dataObj)

		let level0 = 0,
			level1 = 0,
			level2 = 0,
			level3 = 0,
			level4 = 0,
			level5 = 0,
			level6 = 0,
			level7 = 0
			
		let strategy = 0,
			business = 0,
			project = 0

		for (const i of dataKeys) {
			if (i === '0') {
				strategy++
				level0 += dataObj[i]
			}
			if (i === '1') {
				strategy++
				level1 += dataObj[i]
			}
			if (i === '2') {
				strategy++
				level2 += dataObj[i]
			}
			if (i === '3') {
				business++
				level3 += dataObj[i]
			}
			if (i === '4') {
				business++
				level4 += dataObj[i]
			}
			if (i === '5') {
				project++
				level5 += dataObj[i]
			}
			if (i === '6') {
				project++
				level6 += dataObj[i]
			}
			if (i === '7') {
				project++
				level7 += dataObj[i]
			}
		}
		const processObject = (obj, results) => {
			if (obj.children && obj.children.length > 1) {
				// Check the first child element
				results.push({
					level: obj.children[0].level,
					count: obj.children[0].children.length,
					name: obj.children[0].name
				})
				
			
				// Check the last child element
				const lastChildIndex = obj.children.length - 1
				results.push({
					level: obj.children[lastChildIndex].level,
					count: obj.children[lastChildIndex].children.length,
					name: obj.children[lastChildIndex].name
				})
				
			
				// Recursively process each child
				for (const child of obj.children) {
					processObject(child, results)
				}
			}
	
			if (obj.children && obj.children.length === 1) {
				results.push({
					level: obj.children[0].level,
					count: obj.children[0].children.length,
					name: obj.children[0].name
				})
	
				for (const child of obj.children) {
					processObject(child, results)
				}
			}
		}
			
		const results = []
		processObject(preparedDataArray, results)

		const levelsArray = [level0, level1, level2, level3, level4, level5, level6, level7]
		let longestVerticalType = levelsArray.sort(function(a, b) { return b - a; })[0]
		const totalLength = strategy + business + project

		// for (const i of results) {
		// 	if (i.count > 1) longestVerticalType += i.count / 2
		// }

		return {strategy, business, project, longestVerticalType, totalLength}
	}

	const {strategy, business, project, longestVerticalType, totalLength} = calculateLevels(levelCountsResult)
	const nodeLength = 100 / totalLength

	const getNodeColor = (node) => {
		const defaultColor = '#a8a8a8'
		const { level } = node
		const colors = {
			1: '#fee0e0',
            2: '#fee0e0',
            3: '#ffffcb',
            4: '#ffffcb',
            5: '#c2f0ff',
            6: '#c2f0ff',
			7: '#c2f0ff'
		}

		return colors[level] ? colors[level] : defaultColor
	}

	const renderSvgNode = ({ nodeDatum, toggleNode }) => {
		const { name, description, children } = nodeDatum
		function handleClick(event, toggleNode, d) {
			if (event.detail === 2) {
				// console.log(d)
				// setUpdateChosen(true);
				// setAddChosen(false);
				// setUpdateName(d?.name);
				// setUpdateLevel(d?.level);
				// setUpdateTag(d?.tag);
				// setUpdateType(d?.type);
				// setUpdateParent(d?.parent_id);
				// setUpdateLink(d?.link)
				// setUpdateDescription(d?.description);
				// setUpdateId(d?.id)
				// setActive('Goals')

				return
			}
			toggleNode()
		}

		const nodeNameLong = name.length > 25 ? true : false
		const nodeNameFirstLine = name.slice(0, 25)
		const nodeNameSecondLine = name.slice(25, name.length)

		return (
			<g className="tooltip" title="123">
				<circle cx="0" cy="0" r="15"  
					stroke='#ccc'
					onClick={(e) => handleClick(e, toggleNode, nodeDatum)}
					fill={getNodeColor(nodeDatum)} 
					strokeWidth={children?.length > 0 ? 4 : 1}
				/>
				{!nodeNameLong && <text className="flex flex-col" fill="black" strokeWidth="0.1" x="-5" dy="-25" fontSize={titleFontSize} >
					{name}
				</text>}
				{nodeNameLong && <text className="flex flex-col" fill="black" strokeWidth="0.1" x="-5" dy="-35" fontSize={titleFontSize} onClick={handleClick} >
					<tspan>{nodeNameFirstLine}</tspan>
					<tspan x="-5" dy={titleFontSize} >{nodeNameSecondLine}</tspan>
				</text>}
				{description &&
					<>
						<rect className="tooltiptext" strokeWidth=".1" x="15" y="20"  width={110} height={(description.length / 18) * 14 + 15} rx="5" fill="black" fillOpacity="0.75" strokeOpacity="0.8" />
						<foreignObject x="20" y="22" fontSize={10} width="100" height="150" className="tooltiptext">
							<p style={{textAlign: 'left'}}>
								{description}
							</p>
						</foreignObject>
					</>
				}
			</g>

		)
	}

	const downloadImage = async () => {
		const canvas = await html2canvas(document.getElementById('treeWrapper'))
		const link = document.createElement("a")
		link.download = "Goal-tree.png"
		link.href = canvas.toDataURL('image/png', 1.0)  
		link.click()
	}	

	return (
		<div className="reports" ref={reportsRef}>
		  <div className="top-report">
				<div className="plus-minus">
					<IconButton
						className="download"
						onClick={downloadImage}
						notRestricted={downloadAccess}
					/>
				<IconButton downloadAccess/>
				</div>
			</div>
			<div className="tree-wrapper" id="treeWrapper">
				<Tree 	
					data={preparedDataArray}
					renderCustomNodeElement={ renderSvgNode }
					translate={{ x: nodeSize.x / 3, y:(svgEl?.current ? svgEl.current?.y : 0) / 2 }}
					shouldCollapseNeighborNodes={ false }
					initialDepth={ undefined }
					depthFactor={ undefined }
					zoom={ 1.5 }
					scaleExtent={{ min: 1, max: 3 }}
					separation={{ siblings: .9, nonSiblings: 1 }}
					nodeSize={{ x: nodeSize.x, y: nodeSize.y }}
					pathClassFunc={() => 'custom-link'}
					branchNodeClassName="node__branch"
					transitionDuration={ 200 }
					enableLegacyTransitions={ true }
					collapsible={ true }
					draggable={ true }
					zoomable={ false }
					dimensions={ undefined }
				/>
			</div>
			<GoalsLegend strategy={strategy} business={business} project={project} nodeLength={nodeLength} parentWidth={reportsWidth}/>
		</div>
	);
};

export default GoalsTreeChart