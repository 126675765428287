import React, { useEffect, useState } from 'react'
import SettingsManagement from '../../components/Settings/SettingsManagement'

import SuccessNotice from "../../components/SuccessNotice"
import ErrorNotice from "../../components/ErrorNotice"

import SkeletonTabs from '../../components/ui/skeleton/SkeletonTabs'

import SettingsDictionaries from '../../components/Settings/SettingsDictionaries'
import OrganizationParameters from '../../components/Settings/OrganizationParameters'
import AccountParameters from '../../components/Settings/AccountParameters'
import Organization from '../../components/Settings/Organization'
import People from '../../components/Settings/People'


const SettingsPage = ({ 
    loading, 
    fetched, 
    getRelationship, 
    getGoalType, 
    getActivityType, 
    getCapabilityType,
    getCapabilityTypeType,
    relationship, 
    goalType, 
    activityType,
    capabilityType,
    capabilityTypeType,
    organization,
    userName,
    userOrganization,
    getOrganization,
    editOrganization,
    newInvite,
    clearSuccessMessage,
    clearErrorMessage,
    successMessage,
    errorMessage,
    access,
    getUserRole,
    userRole,
    changePassword,
    user,
    getUser,
    getAllUsers,
    editUser,
    deleteUser,
    allUsers,
    getActiveInvites,
    activeInvites,
}) => {
    const [active, setActive] = useState('Dictionaries')
    const [success, setSuccess] = useState('')
	const [error, setError] = useState('')

    useEffect(() => {
        getRelationship()
        getGoalType()
        getActivityType()
        getCapabilityType()
        getCapabilityTypeType()
        getUser()
        getAllUsers()
        getActiveInvites()
    }, [])

    return (
        <>
            <div className="notices">
                {success && <SuccessNotice message={success} clearMessage={clearSuccessMessage}/>}
                {error && <ErrorNotice message={error} clearMessage={clearErrorMessage}/>}
                {successMessage && <SuccessNotice message={successMessage} clearMessage={clearSuccessMessage}/>}
                {errorMessage && <ErrorNotice message={errorMessage} clearMessage={clearErrorMessage}/>}
		    </div>        
            {loading || !fetched ? 
                <SkeletonTabs /> :
                <SettingsManagement active={active} setActive={setActive} access={access.Tabs} />            
            }
            <div className='flex flex-wrap mt-10 place-content-between gap-1'>
                {active === 'Dictionaries' && 
                    <SettingsDictionaries 
                        relationship={relationship} 
                        goal={goalType} 
                        activity={activityType} 
                        capability={capabilityType}
                        capabilityType={capabilityTypeType}
                        fetched={fetched} 
                        loading={loading} 
                        access={access}
                    /> 
                }
                {active === 'Organization' && 
                    <OrganizationParameters 
                        editOrganization={editOrganization} 
                        getOrganization={getOrganization} 
                        organization={organization} 
                        fetched={fetched} 
                        loading={loading} 
                        setSuccess={setSuccess} 
                        setError={setError} 
                        newInvite={newInvite}
                        access={access.Buttons}
                        getAllUsers={getAllUsers}
                        allUsers={allUsers}
                        deleteUser={deleteUser}
                        activeInvites={activeInvites}
                    /> 
                }
                {active === 'Organization Structure' && 
                    <Organization
                        editOrganization={editOrganization} 
                        getOrganization={getOrganization} 
                        organization={organization} 
                        fetched={fetched} 
                        loading={loading} 
                        setSuccess={setSuccess} 
                        setError={setError} 
                        newInvite={newInvite}
                        access={access.Buttons}
                        getAllUsers={getAllUsers}
                        allUsers={allUsers}
                        deleteUser={deleteUser}
                        activeInvites={activeInvites}
                        goalType={goalType}
                    /> 
                }
                {active === 'Account' && 
                    <AccountParameters 
                        editOrganization={editOrganization} 
                        getOrganization={getOrganization} 
                        organization={organization} 
                        fetched={fetched} 
                        loading={loading} 
                        setSuccess={setSuccess} 
                        setError={setError} 
                        newInvite={newInvite}
                        getUserRole={getUserRole}
                        userRole={userRole}
                        userName={userName}
                        userOrganization={userOrganization}
                        changePassword={changePassword}
                        getUser={getUser}
                        user={user}
                        editUser={editUser}
                        access={access.Buttons}
                    />
                }
                {active === 'People' && 
                    <People 
                        editOrganization={editOrganization} 
                        getOrganization={getOrganization} 
                        organization={organization} 
                        fetched={fetched} 
                        loading={loading} 
                        setSuccess={setSuccess} 
                        setError={setError} 
                        newInvite={newInvite}
                        getUserRole={getUserRole}
                        userRole={userRole}
                        userName={userName}
                        userOrganization={userOrganization}
                        changePassword={changePassword}
                        getUser={getUser}
                        user={user}
                        editUser={editUser}
                        access={access.Buttons}
                    />
                }
            </div>
        </>
    );
};

export default SettingsPage;