import React from 'react';
import SkeletonTable from '../ui/skeleton/SkeletonTable'
import RelationshipsTable from './relationships/RelationshipsTable';
import GoalTable from './goal/GoalTable';
import CapabilityTable from './capability/CapabilityTable';
import ActivityTable from './activity/ActivityTable';


const SettingsDictionaries = ({ loading, fetched, relationship, goal, activity, capability, capabilityType, access }) => {

	return (
		<div className="settings_dictionaries">
			<div className="flex flex-col min-w-[49%] mb-4">
				<h4>Goals types</h4>
				<div className="dict-list">
						{loading || !fetched ? 
							<SkeletonTable />:
							<GoalTable
								data={goal}
								deletedChosen={false}
								setSelected={null}
							/>
						}
				</div>
			</div>
			<div className="flex flex-col min-w-[49%]"> 
				<h4>Capabilities properties</h4>
				<div className="dict-list">
						{loading || !fetched ? 
							<SkeletonTable />:
							<CapabilityTable
								data={capabilityType}
								deletedChosen={false}
								setSelected={null}
							/>
						}
				</div>
			</div>
			<div className="flex flex-col min-w-[49%]">
				<h4>Activities types</h4>
				<div className="dict-list">
						{loading || !fetched ? 
							<SkeletonTable />:
							<ActivityTable
								data={activity}
								deletedChosen={false}
								setSelected={null}
							/>
						}
				</div>
			</div>
			<div className="flex flex-col min-w-[49%]">
				<h4>Relationships</h4>
				<div className="dict-list">
						{loading || !fetched ? 
							<SkeletonTable />:
							<RelationshipsTable
								data={relationship}
								deletedChosen={false}
								setSelected={null}
							/>
						}
				</div>
			</div>
		</div>
		)
}

export default SettingsDictionaries;