import React, { useState } from "react"

import InviteTableHeaderItem from "./InviteTableHeaderItem"
import Invite from './Invite'

import Modal from '../../Modal'


const UserTable = ({ data, setError, setSuccess, access }) => {
	const [showDelete, setShowDelete] = useState(false)
	const [deleteId, setDeleteId] = useState('')
	const [deleteItem, setDeleteItem] = useState({name: ''})

	return (
		<div className="flex flex-col min-w-[50%] w-[100%]">
			{showDelete && 
				<Modal
					setShowDelete={setShowDelete}
					deleteId={deleteId}
					deleteItem={deleteItem}
					deleteItemType={'organizationInvite'}
					setSuccess={setSuccess}
					setError={setError}
				/>
			}
			<table className="table-auto w-full text-left whitespace-no-wrap">
				<thead className="table-header">
				<tr>
					<InviteTableHeaderItem name={'EMAIL'}  value={'email'} />
					<InviteTableHeaderItem name={'STATUS'}  value={'status'} />
				</tr>
				</thead>
				<tbody className="table-body">
				{data.map((item) => {
					return (
					<Invite 
						key={Math.random()}
						data={data}
						item={item}
						showDelete={showDelete}
						setShowDelete={setShowDelete}
						setDeleteId={setDeleteId}
						setDeleteItem={setDeleteItem}
						access={access}
					/>
					)
				})
			}
			</tbody>
		</table>
	</div>
  );
};

export default UserTable;
