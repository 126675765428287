import React from 'react'

import Products from "../../assets/product.svg"
import CardItem from './CardItem';

const DirectoryProduct = ({ product }) => {
    return (
        <div className="directory">
            <CardItem linkRoute={'products'}
                    image={Products}
                    dataArray={product} >
                    Products
            </CardItem>

        </div>
    );
};

export default DirectoryProduct;