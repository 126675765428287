import React, { useState } from "react";
import { BsTrash3 } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { BiAddToQueue } from "react-icons/bi";
import { RiParentFill, RiParentLine } from "react-icons/ri";
import { useEffect } from "react";

import IconButton from "../ui/buttons/IconButton";

const CapabilityItem = ({
  item,
  showDelete,
  deleteId,
  setDeleteItem,
  setShowDelete,
  setDeleteId,
  setUpdateChosen,
  setAddChosen,
  setUpdateId,
  setUpdateName,
  setUpdateStartDate,
  setUpdateEndDate,
  setUpdateProperty,
  setUpdateLink,
  setUpdateFirstLevel,
  setUpdateSecondLevel,
  setUpdateThirdLevel,
  setUpdateLane,
  setUpdateLayout,
  setFirstLevel,
  setUpdateSetId,
  setMaturity,
  setUpdateDescription,
  setSelected,
  setSet,
  set,
  selectAll,
  selectedItems,
  access,
  capabilities,
}) => {
  const [selectedItem, setSelectedItem] = useState(false);
  const {
    Capabilities_Visual_editor_Add_capability,
    Capabilities_Visual_editor_Delete_capability,
    Capabilities_Visual_editor_Edit_capability,
  } = access;

  useEffect(() => {
    if (selectAll) {
      selectedItems.find((i) => i.id === item.id) && setSelectedItem(true);
    } else {
      setSelectedItem(false);
    }
  }, [selectAll, item]);

  const handleDoubleClick = (event) => {
    if (!Capabilities_Visual_editor_Edit_capability) return;
    if (event.detail === 2) {
      setUpdateChosen(true);
      setAddChosen(false);
      setUpdateId(item.id);
      setUpdateName(item.name);
      setUpdateStartDate(item.start_date);
      setUpdateEndDate(item.end_date);
      setUpdateProperty(item.property);
      setUpdateLink(item.link);
      setUpdateFirstLevel(item.first_level);
      setUpdateSecondLevel(item.second_level);
      setUpdateThirdLevel(item.third_level);
      setUpdateLane(item.lane);
      setUpdateLayout(!item.layout ? "vertical" : item.layout);
      setUpdateSetId(
        set.find((element) => element.id === item.universal_item_set_id).name
      );
      setMaturity(item.maturity === null ? [] : item.maturity);
      setUpdateDescription(item.description);

      return;
    }
    if (event.detail === 1) {
      if (selectedItem) {
        setSelectedItem(false);
        setSelected(item);
        return;
      }
      setSelectedItem(true);
      setSelected(item);
    }
  };

  const calcSecondLevel = () => {
    if (item.second_level === 0) {
      return item.second_level + 1;
    }
    if (item.second_level > 0 || item.third_level > 0) {
      return item.second_level;
    }
    return item.second_level + 1;
  };

  const calcThirdLevel = () => {
    if (item.second_level > 0 || item.third_level > 0) {
      return item.third_level + 1;
    }
    return item.third_level;
  };

  const hasParent = (currentItem) => {
    return capabilities.find((i) => i.id === currentItem.parent);
  };

  const getItemIcon = () => {
    const validParent = hasParent(item);
    const isBacklogFirstLevel =
      "" + item.first_level + item.second_level + item.third_level === "000";

    // backlog first level cap
    if (isBacklogFirstLevel) {
      return (
        <RiParentFill style={{ marginRight: 5, color: "gray" }} alt="parent" />
      );
    }

    // backlog child cap
    if (!item.first_level) {
      return (
        <RiParentLine style={{ marginRight: 5, color: "gray" }} alt="parent" />
      );
    }

    if (!validParent) {
      // first level cap, has no parent
      if (item.first_level && !item.second_level && !item.third_level) {
        return <RiParentFill style={{ marginRight: 5 }} alt="parent" />;
      }
      // child cap w/o a parent
      return (
        <RiParentLine style={{ marginRight: 5, color: "red" }} alt="parent" />
      );
    }
    // second or third level cap, valid parent
    return <RiParentLine style={{ marginRight: 5 }} alt="parent" />;
  };

  return (
    <tr
      key={item.id}
      className={
        selectedItem ? "capability-item selected-item" : "capability-item"
      }
      style={{
        backgroundColor: showDelete && deleteId === item.id && "#A3AAC4",
      }}
    >
      <td
        className="px-4 py-3 cursor-pointer"
        onClick={(e) => {
          handleDoubleClick(e);
        }}
      >
        {item.id}
      </td>
      <td
        className="px-4 py-3 flex items-center cursor-pointer"
        onClick={(e) => {
          handleDoubleClick(e);
        }}
      >
        {getItemIcon()} {item.name}
      </td>
      <td
        className="px-4 py-3 cursor-pointer"
        onClick={(e) => {
          handleDoubleClick(e);
        }}
      >
        {item.layout}
      </td>
      <td
        className="px-4 py-3 cursor-pointer"
        onClick={(e) => {
          handleDoubleClick(e);
        }}
      >
        {item.first_level + "." + item.second_level + "." + item.third_level}
      </td>
      <td
        className="px-4 py-3 cursor-pointer"
        onClick={(e) => {
          handleDoubleClick(e);
        }}
      >
        {item.lane}
      </td>
      <td
        className="px-4 py-3 cursor-pointer"
        onClick={(e) => {
          handleDoubleClick(e);
        }}
      >
        {item.start_date}
      </td>
      <td
        className="px-4 py-3 cursor-pointer"
        onClick={(e) => {
          handleDoubleClick(e);
        }}
      >
        {item.end_date}
      </td>
      <td
        className="px-4 py-3 cursor-pointer"
        onClick={(e) => {
          handleDoubleClick(e);
        }}
      >
        {item.property}
      </td>
      <td
        className="px-4 py-3 cursor-pointer"
        onClick={(e) => {
          handleDoubleClick(e);
        }}
      >
        {item.parent}
      </td>
      <td
        className="px-4 py-3 cursor-pointer"
        onClick={(e) => {
          handleDoubleClick(e);
        }}
      >
        {item.description}
      </td>
      <td className="px-4 py-3 text-blue-800 flex link">
        {item.link.length > 0 ? (
          <a href={item.link} rel="noreferrer">
            Link{" "}
          </a>
        ) : (
          <span>Link</span>
        )}
        <div className="capability-controls">
          <IconButton
            className="ml-5 capability-controls__button"
            onClick={() => {
              setShowDelete(!showDelete);
              setDeleteId(item.id);
              setDeleteItem({ name: item.name });
            }}
            notRestricted={Capabilities_Visual_editor_Delete_capability}
          >
            <BsTrash3 alt="delete" />
          </IconButton>
          <IconButton
            className="ml-5 capability-controls__button"
            onClick={() => {
              setUpdateChosen(true);
              setAddChosen(false);
              setUpdateId(item.id);
              setUpdateName(item.name);
              setUpdateStartDate(item.start_date);
              setUpdateEndDate(item.end_date);
              setUpdateProperty(item.property);
              setUpdateLink(item.link);
              setUpdateFirstLevel(item.first_level);
              setUpdateSecondLevel(item.second_level);
              setUpdateThirdLevel(item.third_level);
              setUpdateSetId(
                set.find((element) => element.id === item.universal_item_set_id)
                  .name
              );
              setMaturity(item.maturity === null ? [] : item.maturity);
            }}
            notRestricted={Capabilities_Visual_editor_Edit_capability}
          >
            <FiEdit alt="edit" />
          </IconButton>
          <IconButton
            className="ml-5 capability-controls__button"
            onClick={() => {
              setUpdateChosen(false);
              setAddChosen(true);
              setFirstLevel({
                first_level: item.first_level,
                second_level: calcSecondLevel(),
                third_level: calcThirdLevel(),
              });
              setSet(
                set.find((element) => element.id === item.universal_item_set_id)
                  .name
              );
            }}
            notRestricted={Capabilities_Visual_editor_Add_capability}
          >
            <BiAddToQueue alt="add child" />
          </IconButton>
        </div>
      </td>
    </tr>
  );
};

export default CapabilityItem;
