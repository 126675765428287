import { connect } from "react-redux"
import { fetched, token, loading, product, productPerPage, activity, business, successMessage, errorMessage, access } from '../../store/reducers/selectors'
import { getProduct, newProduct, editProduct, getActivity, getBusiness, clearSuccessMessage, clearErrorMessage } from "../../api/actions"
import { createStructuredSelector } from "reselect"
import ProductPage from './ProductPage'

const mapStateToProp = createStructuredSelector({
    token,
    fetched,
    loading,
    product,
    activity,
    business,
    productPerPage,
    successMessage,
    errorMessage,
    access
})

const mapDispatchToProps = {
    getProduct,
    newProduct,
    editProduct,
    getActivity,
    getBusiness,
    clearSuccessMessage,
    clearErrorMessage
}

export default connect(mapStateToProp, mapDispatchToProps)(ProductPage);