import React from 'react'

import Tab from '../Tab'

const GoalsManagement = ({ active, setActive, access }) => {
	const { Goals,
			Goals_Report,
			Goals_Import,
			Goals_Export 
	} = access.Tabs

	return (
		<div className="tabs-container">
			<div className="flex row mt-5 justify-start items-start">
				<Tab
					section={"Goals"}
					active={active}
					setActive={setActive}
					isDisabled={!Goals}
				/>
				<Tab
					section={"Visual-Editor"}
					active={active}
					setActive={setActive}
					isDisabled={!Goals_Report}
					// isDisabled={true}
				/>
				<Tab
					section={"Report"}
					active={active}
					setActive={setActive}
					isDisabled={!Goals_Report}
				/>
				<Tab
					section={"Import"}
					active={active}
					setActive={setActive}
					isDisabled={!Goals_Import}
				/>
				<Tab
					section={"Export"}
					active={active}
					setActive={setActive}
					isDisabled={!Goals_Export}
				/>
			</div>
		</div>
	)
}

export default GoalsManagement