const RegularButton = ({ children, notRestricted, className, onClick, stateActive = false}) => {
    const findSuitedClassName = () => {
        if (!notRestricted) return className + " disabled"
        if (stateActive) return className + "-active"

        return className
    }

    const cName = findSuitedClassName()
    return (
        <button className={cName} onClick={onClick} disabled={!notRestricted}>
            {children}
        </button>
    )
}

export default RegularButton