import React, { useState } from 'react';

import { VscGear } from "react-icons/vsc";
import { VscClearAll } from 'react-icons/vsc'


const BusinessTableHeaderItem = ({ 
    sortData, 
    name, 
    value, 
    activeSort, 
    setSortingStartDate, 
    setSortingEndDate, 
    setSortingApplication,
    setSortingBusiness,  
    setSortingRelation, 
}) => {
    const [filter, setFilter] = useState(false)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [sortBusiness, setSortBusiness] = useState('')
    const [sortApplication, setSortApplication] = useState('')
    const [relation, setRelation] = useState('')

    let headerItemType = ''
    let headerItemFilterActive = ''

    const setFieldClear = () => {
        setFilter(!filter)
    }

    const Checkbox = ({ type = "checkbox", name, checked = false, onChange }) => {
        return (
          <input type={type} name={name} checked={checked} onChange={onChange} />
        )
    }

    const checkboxesRelations = [
        {id: 1, name: 'Composition', class: 'Structural'},
        {id: 2, name: 'Aggregation', class: 'Structural'},
        {id: 3, name: 'Assignment', class: 'Structural'},
        {id: 4, name: 'Realization', class: 'Structural'},
        {id: 5, name: 'Serving', class: 'Dependency'},
        {id: 6, name: 'Access', class: 'Dependency'},
        {id: 7, name: 'Influence', class: 'Dependency'},
        {id: 8, name: 'Association', class: 'Dependency'},
        {id: 9, name: 'Triggering', class: 'Dynamic'},
        {id: 10, name: 'Flow', class: 'Dynamic'},
        {id: 11, name: 'Specialization', class: 'Other'}
      ]

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' || e.key === 'Escape'){
            setFilter(!filter)
        }
    }

    const closeFilter = (e) => {
        if (e.key === 'Escape'){
            setFilter(!filter)
        }
    }
    
    const businessChangeHandler = (e) => {
        setSortBusiness(e.target.value)
        setSortingBusiness(e.target.value)
    }

    const applicationChangeHandler = (e) => {
        setSortApplication(e.target.value)
        setSortingApplication(e.target.value)
    }

    const startDateChangeHandler = (e) => {
        setStartDate(e.target.value)
        setSortingStartDate(e.target.value)
        setFilter(!filter)
    }

    const endDateChangeHandler = (e) => {
        setEndDate(e.target.value)
        setSortingEndDate(e.target.value)
        setFilter(!filter)
    }

    const relationChangeHandler = (e) => {
        setRelation({...relation, [e.target.name] : e.target.checked })
        setSortingRelation({...relation, [e.target.name] : e.target.checked })
    }


    if (value === 'start-date') {
        headerItemFilterActive = startDate
        headerItemType = <input
                            value={startDate} 
                            className="h-11 w-72 shadow rounded py-2 px-3 filter-input" 
                            type="date" 
                            onChange={startDateChangeHandler}>
                        </input>
    }
    if (value === 'end-date') {
        headerItemFilterActive = endDate
        headerItemType = <input
                            value={endDate} 
                            className="h-11 w-72 shadow rounded py-2 px-3 filter-input" 
                            type="date" 
                            onChange={endDateChangeHandler}>
                        </input>
    }
    if (value === 'business') {
        headerItemFilterActive = sortBusiness
        headerItemType = <div className='h-11 w-72 shadow rounded py-2 px-3 filter-input'>
                            <input
                                value={sortBusiness} 
                                className="" 
                                type="text" 
                                placeholder="Start typing here"
                                onChange={businessChangeHandler}>
                            </input>
                            <button className='clear-field' onClick={() => {setFieldClear(); setSortBusiness(''); setSortingBusiness('') }}><VscClearAll /></button>
                        </div>
    }
    if (value === 'application') {
        headerItemFilterActive = sortApplication
        headerItemType = <div className='h-11 w-72 shadow rounded py-2 px-3 filter-input'>
                            <input
                                value={sortApplication} 
                                className="" 
                                type="text" 
                                placeholder="Start typing here"
                                onChange={applicationChangeHandler}>
                            </input>
                            <button className='clear-field' onClick={() => {setFieldClear(); setSortApplication(''); setSortingApplication('') }}><VscClearAll /></button>
                        </div>
    }
    if (value === 'relation') {
        headerItemFilterActive = relation
        headerItemType = 
                    <div className="filter-select">
                        <form className="filter-select__form" onChange={relationChangeHandler}>
                            {checkboxesRelations.map(item => (
                                <label key={item.id}>
                                    <br/>
                                    <Checkbox name={item.name} checked={relation[item.name]} onChange={relationChangeHandler} />
                                    {item.name}
                                </label>
                                ))}

                        </form>
                        <button className='clear-field' onClick={() => {setFieldClear(); setRelation(''); setSortingRelation('') }}><VscClearAll /></button>
                    </div>
    }

    let uniqueStyling

    if (value === 'id') {
        uniqueStyling = 'header-item_id'
    }
    if (value === 'name') {
        uniqueStyling = 'header-item_name'
    }
    if (value === 'link') {
        uniqueStyling = 'header-item_link'
    }

    return (
        <th className={"table-header-item "  + uniqueStyling}>
            <div className='table-header-item__container' tabIndex={-1} onKeyDown={closeFilter}>
                {filter && headerItemType}
                <button onClick={() => {sortData(value)}}>{name}</button>
                {(activeSort.key !== value) && <div style={{marginRight: 12}}></div>}
                {(activeSort.key === value && activeSort.inc) && <div style={{marginBottom: 2}}>▲</div>}
                {(activeSort.key === value && !activeSort.inc) && <div style={{marginBottom: 3}}>▼</div>}
                {(value !== 'id' && value !== 'link') && <VscGear className={headerItemFilterActive ? "text-2xl cursor-pointer capability-filter text-red-400" : (filter ? "text-2xl cursor-pointer capability-filter filter-active" : "text-2xl cursor-pointer capability-filter")} onClick={() => {setFilter(!filter)}} />}
            </div>
        </th>
    );
};

export default BusinessTableHeaderItem;