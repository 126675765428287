import React, {useState, useRef} from 'react'
import html2canvas from "html2canvas"
import Draggable from 'react-draggable'
import TypeRow from './Reports/ProcessesReport/TypeRow'

import useOnClickOutside from '../hooks/useOnClickOutside'

const ModalSave = ({closeModal, active, rightDatesData, application, bpRelations, date, organizationName, columnWidth, capabilityTypeType, }) => { 
    const [borderSize, setBorderSize] = useState(50)
    const [imagePosition, setImagePosition] = useState(-700)


    const ref = useRef();

    useOnClickOutside(ref, () => closeModal)

    const borderSizeChangeHandler = (e) => {
        setBorderSize(e.target.value)
    }
    
    const handleKeyPress = (e) => {
        if(e.key === 'Escape'){
            closeModal()
        }
    }

    const downloadImage = async () => {
        const canvas = await html2canvas(document.getElementById('saveChart'))
        const link = document.createElement("a")
        link.download = "business-processes-report.png"
        link.href = canvas.toDataURL('image/png', 1.0)
        
        link.click()
    }


    if (!active) {
        return (<></>)
    }

    return (
        <div id="openModal" className="modalbg">
            <div className="dialog">
            <Draggable
                handle=".save-controls"
                defaultPosition={{ x: 0, y: 0 }}
                position={null}
                grid={[1, 1]}
                scale={1}
            >
                <div id="dialog-window" className="save-controls">
                    <h5 className='save-controls__title'>Save report</h5>
                    <button className="close" onClick={closeModal}>X</button>
                    <div>
                        <p>Border</p>
                        <input
                            value={borderSize}
                            onChange={borderSizeChangeHandler}
                            placeholder="Size"
                            type="text"
                            id="name"
                            autoFocus
                            name="name"
                            onKeyDown={handleKeyPress}
                            className="h-11 w-96 shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        />
                    </div>
                        <button onClick={downloadImage}
                                className="text-white bg-second-color border-0 py-2 mt-6 px-12 focus:outline-none rounded text-lg"
                            >
                            Download
                        </button>                    
                    {/* <div className="move-controls">
                        <button className="cursor-pointer bg-gray-300 px-4 py-4 rounded mr-5 move-controls__left" onClick={() => {setImagePosition(imagePosition + 800)}}></button>
                        <label>Move</label>
                        <button className="cursor-pointer bg-gray-300 px-4 py-4 rounded mr-5 move-controls__right" onClick={() => {setImagePosition(imagePosition - 800)}}></button>
                    </div> */}
                </div>
            </Draggable>
            <Draggable
                handle=".modal-save"
                defaultPosition={{ x: 0, y: 0 }}
                position={null}
                grid={[1, 1]}
                scale={1}
            >
                <div id='saveChart' className='capabilities modal-save' style={{border: borderSize + "px solid #EEEEF2"}}>
                    <div className="capabilities-report-info">
                        <p>{organizationName}</p>
                        <p>{date}</p>
                    </div>
                    <div className="capabilities-report overflow-x-scroll" id="chart">
                        {capabilityTypeType && capabilityTypeType.map(item => {
							return <TypeRow 
										key={item} 
										item={item} 
										dataArray={rightDatesData} 
										application={application} 
										bpRelations={bpRelations} 
										itemWidth={columnWidth} 
									/>
						})}
                    </div>
                </div>
            </Draggable>
            </div>
        </div>
    );
};

export default ModalSave;
