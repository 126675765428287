import React, { useState } from "react";
import {Eye, EyeSlash} from 'react-bootstrap-icons';


const FormInput = (props) => {
  const [focused, setFocused] = useState(false)
  const [visible, setVisible] = useState(false)
  const { label, errorMessage, onChange, id, ...inputProps } = props  

  const eye = visible ? 'text' : 'password'

  const togglePasswordVisibility = (e) => {
    setVisible(!visible)
  }

  const handleFocus = (e) => {
    setFocused(true);
  }

  return (
    <div className="input-container">
      <label className="input-container__label">{label}</label>
      {inputProps.type === 'password' && <span className="input-container__eye" onClick={togglePasswordVisibility}>{visible ? <Eye /> : <EyeSlash />}</span>}
      <input
        className="input-container__input"
        {...inputProps}
        type={inputProps.type === "password" ? eye : inputProps.type}
        onChange={onChange}
        onBlur={handleFocus}
        onFocus={() =>
          inputProps.name === "confirmPassword" && setFocused(true)
        }
        focused={focused.toString()}
      />
      <span className="input-container__input-error">{errorMessage}</span>
    </div>
  );
};

export default FormInput;