import { wrapLabel, getCenterX } from './handlers/handlers'

const ThirdLevel = ({ item, xPos, yPos }) => {
	const firstLevelWidth = 120
	const firstLevelHeight = 100
	const secondLevelWidth = 100
	const secondLevelHeight = 100
	const thirdLevelWidth = 80
	const thirdLevelHeight = 80
	const levelDistance = 10
	const textHeightPosition = 30
	const fontSize = 12

	let thirdLevelHeightDynamic = thirdLevelHeight

	const labels = wrapLabel(item.name, 60)
	const wrappedText = labels.map((word, index) => {
		const centerX = getCenterX(word, xPos + 15, thirdLevelWidth)
		yPos += 10

		thirdLevelHeightDynamic += 5

		return (
			<tspan color='black' x={centerX} dy={index === 0 ? 0 : fontSize}>
				{word}
			</tspan>
		)
	})
	
	const addressText = item.first_level + '.' + item.second_level + '.' + item.third_level
	const addressXPos = getCenterX(addressText, xPos, secondLevelWidth + 20)

	return (
		// className={heatmapActive && isHeatmapNotDot ? "third-level-capability " + getMaturityColor(item, 3) : "third-level-capability"}
		<g>
			<rect 
				width={thirdLevelWidth}
				height={thirdLevelHeightDynamic}
				fill="#FFFFFF"
				rx="8"
				key={item.id}
				x={xPos + 20}
				y={yPos + 60}
			/>
			<text color='black' x={addressXPos} y={yPos + 80} fontWeight="normal" fontSize={fontSize}>
				<tspan>{addressText}</tspan>
			</text>
			<text color='black' x={xPos + 40} y={yPos + 92} fontWeight="normal" fontSize={fontSize}>
				{wrappedText}
			</text>
		</g>
	) 
}
 
export default ThirdLevel