export default function groupArrayIntoSixes(array, itemsAmount) {
    const result = [];
    const length = array ? array.length : 0;
    let i = 0;

    while (i < length) {
      result.push(array.slice(i, i + itemsAmount));
      i += itemsAmount;
    }
    result.push([])
    return result;
  }