import React from 'react'

const Tab = ({ section, active, setActive, isDisabled, skeleton }) => {
    let tabState = ''
    if (isDisabled) tabState = ' disabled'
    if (skeleton) tabState = ' skeleton skeleton_gray'
    return (
        <div className ={ 'section-tab truncate' + tabState}
            onClick={() => {
                setActive(section)
            }}
        >
            <h3 style={{
                fontWeight: active === section ? "600" : "300"
            }}>{section}</h3>
        </div>
    )
}

export default Tab