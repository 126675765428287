import axios from "axios";
import axiosMiddleware from "redux-axios-middleware";
import { createStore, applyMiddleware } from "redux";
import { reducers } from "../store/reducers/reducers";
import { token } from "../store/reducers/selectors";

const client = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  responseType: "json",
});

const middlewareConfig = {
  interceptors: {
    request: [
      function ({ getState }, req) {
        req.headers["Token"] = token(getState());
        return req;
      },
    ],
  },
};

export const store = createStore(
  reducers,
  applyMiddleware(axiosMiddleware(client, middlewareConfig))
);
