import React from 'react';
import { BsTrash3 } from 'react-icons/bs'
import IconButton from '../../ui/buttons/IconButton'


const Invite = ({ data, item, showDelete, setShowDelete, setDeleteId, setDeleteItem, access }) => {
	const { Settings_Organization_Edit_organization } = access

	return (
		<tr key={item.id} className="Scenario-item">
			<td className="px-4 py-3">{item.email}</td>
			<td className="px-4 py-3 flex items-center justify-between">{item.status}
				<IconButton
					className="ml-5 capability-controls__button"
					onClick={() => {
						setShowDelete(!showDelete)
						setDeleteId(item.email)
						setDeleteItem({name: item.email})
					}}
					notRestricted={Settings_Organization_Edit_organization}
				>
					<BsTrash3 alt="delete" />
				</IconButton>
			</td>
		</tr>
	);
};

export default Invite