import React from 'react';


const InviteTableHeaderItem = ({ name, value }) => {
    let uniqueStyling

    if (value === 'name') {
        uniqueStyling = 'header-item_name'
    }
    if (value === 'role') {
        uniqueStyling = 'header-item_id'
    }
    if (value === 'email') {
        uniqueStyling = 'header-item_link'
    }
    if (value === 'status') {
        uniqueStyling = 'header-item_link'
    }

    return (
        <th className={"table-header-item "  + uniqueStyling}>
            <div className='table-header-item__container' tabIndex={-1}>
                {/* <button>{name}</button> */}
                {name}
            </div>
        </th>
    );
};

export default InviteTableHeaderItem;