import React, { useState, useEffect, useRef } from 'react'
import { HiOutlineChevronDoubleRight, HiOutlineChevronRight, HiOutlineChevronLeft, HiOutlineChevronDoubleLeft  } from 'react-icons/hi'
import { RxDividerVertical } from 'react-icons/rx'


const Timeline = ({ dataArray, setRightDatesData, access, setReportDate}) => {
	const [showTimeline, setShowTimeline] = useState(true)
	const myDivRef = useRef(null)
	const [timeLine, setTimeLine] = useState(new Date().toJSON().slice(0, 10))
	const [timeLineLength, setTimeLineLength] = useState(364)
	const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), 0, 2).toJSON().slice(0, 10))
	const [endDate, setEndDate] = useState(new Date(new Date().getFullYear(), 11, 32).toJSON().slice(0, 10))

	const [pinsDates, setPinsDates] = useState([])

	const [timeoutId, setTimeoutId] = useState(null)

	const {Capabilities_Report_Change_start_date, Capabilities_Report_Change_current_date, Capabilities_Report_Change_end_date} = access
	
	const findClosestBiggerDate = (targetDate, dateArray) => {
		console.log(dateArray)
		return dateArray.reduce((closestDate, currentDate) => {
			const target = new Date(targetDate)
			const current = new Date(currentDate)
	
			if (current > target) {
				if (!closestDate || current < closestDate) {
					return currentDate
				}
			}
			
			return closestDate
		}, null)
	}
	

	const nextPin = () => {
		const closestBiggerDate = findClosestBiggerDate(timeLine, pinsDates)
		const pinInView = positionPinInView(closestBiggerDate)
		setTimeLine(pinInView)
	}

	const previousPin = () => {
		for (const date of pinsDates) {
			if (date && date < timeLine) {
				setTimeLine(date)
				// const pinInView = positionPinInView(date)
				// setTimeLine(pinInView)
			}
		}
	}


	const handleLongPressInc = () => {
		setTimeoutId(
			setInterval(() => {
				setTimeLine(prevValue => convertPositionToDate(convertDateToDays(prevValue) + 1))
			}, 100)
		)
	}
  
	const handleLongPressDec = () => {
		setTimeoutId(
		setInterval(() => {
			setTimeLine(prevValue => convertPositionToDate(convertDateToDays(prevValue) - 1))
		}, 100)
		)
	}
	
	const handleRelease = () => {
		if (timeoutId !== null) {
			clearInterval(timeoutId)
			setTimeoutId(null)
		}
	}

	function groupObjectsByDynamicDate(objects, getDateFromObject) {
		return objects.reduce((grouped, object) => {
		  const date = getDateFromObject(object); // Call the provided function to get the date from the object
		  if (!grouped[date]) {
			grouped[date] = [];
		  }
		  grouped[date].push(object);
		  return grouped;
		}, {});
	}
	  
	function getDateFromObject(object) {
		return object.start_date; // Modify this function based on your object structure
	}

	const activeDatesArray = (dataArray, date) => {
		const result = []
		for (const i of dataArray) {
			if (
				((new Date(date) > new Date(i.start_date)) && (new Date(date) < new Date(i.end_date))) ||       
				((!i.start_date && new Date(i.end_date) > new Date(date)) || (!i.end_date && new Date(i.start_date) < new Date(date))) ||
				(!i.start_date && !i.end_date)
			) {
				result.push(i)
			}
		}
		return result
	}

	const countDays = (sDate, eDate) => {
		const startDate = new Date(sDate)
		const endDate = new Date(eDate)
		// Convert both dates to milliseconds
		const startDateMs = startDate.getTime();
		const endDateMs = endDate.getTime();
	  
		// Calculate the difference in milliseconds
		const diffMs = endDateMs - startDateMs;
	  
		// Convert the difference in milliseconds to days
		const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
	  
		// Return the number of days
		return diffDays;
	}

	const startDateChangeHandler = (e) => {
		const date = e.target.value
		setStartDate(date)
		setTimeLineLength(countDays(date, endDate))
		new Date(date) < new Date(timeLine) && setTimeLine(date)
	}

	const endDateChangeHandler = (e) => {
		setEndDate(e.target.value)
		setTimeLineLength(countDays(startDate, e.target.value))
	}

	const convertDateToDays = (str) => {
		const date = new Date(str)
		const diff = date - new Date(startDate)  //slider position
		const position = Math.floor(diff / (1000 * 60 * 60 * 24)); // convert milliseconds to days and round down
		return position
	}

	const convertPositionToDate = (position) => {
		const newDateMs = new Date(startDate).getTime() + (position * 24 * 60 * 60 * 1000)
		const newDate = new Date(newDateMs)
		
		return newDate.toJSON().slice(0, 10)
	}

	const calculatePinPositions = (pinValue) => {
		const pinPositionRouph = convertDateToDays(pinValue)

		return ((pinPositionRouph + 1) / timeLineLength) * 97.5
	}

	useEffect(() => {
		const dates = groupObjectsByDynamicDate(dataArray, getDateFromObject)
		const pinsArray = []
		const keys = Object.keys(dates)

		for (const key of keys) {
			pinsArray.push(key)
		}

		pinsArray.sort((a, b) => new Date(a) - new Date(b))
		setPinsDates(pinsArray)

	}, [])

	useEffect(() => {
		const data = activeDatesArray(dataArray, timeLine)
		setRightDatesData(data)
		// data.sort((a, b) => parseFloat(Number('' + a.first_level + a.second_level + a.third_level)) - parseFloat(Number('' + b.first_level + b.second_level + b.third_level)))
		// const groupedArray = groupArrayIntoSixes(firstLevelCaps, amountElementsOnPage)
		
		// setLevels(groupedArray)
		if (myDivRef.current) {
			const width = myDivRef.current.clientWidth
			// setDivWidth(width)
		}
		setReportDate(timeLine)
	}, [timeLine, dataArray])

	const positionPinInView = (strDate) => {
		if (!strDate) return strDate

		const currentDate = new Date(strDate)
		currentDate.setDate(currentDate.getDate() + 1)
		const isoString = currentDate.toISOString().split('T')[0]

		return isoString
		
	}

	// const isPinHidden = (pinPosition, timelinePosition) => {
	// 	if ((pinPosition + 5) >= timelinePosition) return 1
	// 	if ((pinPosition - 5) <= timelinePosition) return 1

	// 	return 3
	// }

	return (
		<div className="timeline-container">
				<div className="timeline-switch mb-5">
					<input type="checkbox" className="switch" checked={showTimeline} onChange={() => setShowTimeline(prev => !prev)}/>
				</div>
				<div className={!Capabilities_Report_Change_start_date ? "timeline-content disabled" : "timeline-content"}>
					{showTimeline && <div className="start-end-date mb-5">
						<input
							value={startDate}
							onChange={(e) => startDateChangeHandler(e)}
							type="date"
							id="timeline-start-date"
							name="timeline-start-date"
							className="w-30 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
							disabled={!Capabilities_Report_Change_start_date}
						/>
						<div className="custom-date flex items-center">
							<button className="timeline-content__navigation-controls" onClick={previousPin}><HiOutlineChevronDoubleLeft /></button>
							<button className="timeline-content__navigation-controls"><HiOutlineChevronLeft onClick={() => setTimeLine((prevValue) => convertPositionToDate(convertDateToDays(prevValue) - 1))} onMouseDown={handleLongPressDec} onMouseUp={handleRelease} onMouseLeave={handleRelease} /></button>
							<input
								value={timeLine}
								onChange={(e) => setTimeLine(e.target.value)}
								type="date"
								id="timeline-date"
								name="timeline-date"
								className="w-72 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
								disabled={!Capabilities_Report_Change_current_date}
							/>
							<button className="timeline-content__navigation-controls"><HiOutlineChevronRight onClick={() => setTimeLine((prevValue) => convertPositionToDate(convertDateToDays(prevValue) + 1))} onMouseDown={handleLongPressInc} onMouseUp={handleRelease} onMouseLeave={handleRelease} /></button>
							<button className="timeline-content__navigation-controls" onClick={nextPin}><HiOutlineChevronDoubleRight /></button>
						</div>
						<input
							value={endDate}
							onChange={(e) => endDateChangeHandler(e)}
							type="date"
							id="timeline-end-date"
							name="timeline-end-date"
							className="w-30 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
							disabled={!Capabilities_Report_Change_end_date}
						/> 
					</div>}
					<div className="w-full relative" ref={myDivRef}>
						<input type="range" id="r1" min="0" step="1" max={timeLineLength} value={convertDateToDays(timeLine)} onChange={(e) => setTimeLine(convertPositionToDate(e.target.value))} />
						{pinsDates.map(pin => {
							const pinPosition = calculatePinPositions(pin)
							const pinInView = positionPinInView(pin)
							const setTimeLineToPin = () => setTimeLine(pinInView)
							
							return (
								<RxDividerVertical key={pinPosition} className="absolute cursor-pointer text-gray-500 second-color-hover z-0" style={{left: pinPosition + '%', top: '-5px', zIndex: 2 }} onClick={setTimeLineToPin}/>
							)
						})}
					</div>
				</div>   
			</div>
	)
}
 
export default Timeline