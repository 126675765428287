import React, { useState, useEffect } from 'react'

import groupArrayIntoSixes from '../../handlers/groupArrayHandler'
import ActivitiesTableHeaderItem from './ActivitiesTableHeaderItem'
import Modal from '../Modal'
import ActivityItem from './ActivityItem'


const ActivitiesTable = ({
    data,
    deletedChosen,
    setSelected,
    setUpdateName,
    setUpdateLink,
    setUpdateParent,
    setUpdateStartDate,
    setUpdateEndDate,
    setUpdateId,
    setSuccess,
    setError,
    getData,
    setUpdateChosen,
    setAddChosen,
    current,
    setPaginationLevels,
    setUpdateGoal,
    setUpdateOwner,
    setUpdateType,
    setUpdatePredecessor,
    setUpdateScore,
    setUpdateTag,
    setUpdateSort,
    setUpdateLCost,
    setUpdateRCost,
    setUpdateEffort,
    setUpdateDescription,
    activityType,
    access
  }) => {

    const [showDelete, setShowDelete] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [deleteItem, setDeleteItem] = useState({name: ''});
    const [sortedData, setSortedData] = useState([])
    const [activeSort, setActiveSort] = useState({key: '', inc: true})

    const [sortingStartDate, setSortingStartDate] = useState('')
    const [sortingEndDate, setSortingEndDate] = useState('')
    const [sortingName, setSortingName] = useState('')
    const [sortingParent, setSortingParent] = useState('')
    const [sortingActivityType, setSortingActivityType] = useState('')
    const [sortingGoal, setSortingGoal] = useState('')
    const [sortingSort, setSortingSort] = useState('')
    const [sortingPredecessor, setSortingPredecessor] = useState('')
    const [sortingOwner, setSortingOwner] = useState('')
    const [sortingScore, setSortingScore] = useState('')
    const [sortingTag, setSortingTag] = useState('')
    const [sortingLCost, setSortingLCost] = useState('')
    const [sortingRCost, setSortingRCost] = useState('')
    const [sortingEffort, setSortingEffort] = useState('')
    const [sortingDescription, setSortingDescription] = useState('')
  
    useEffect(() => {
      let result = []
  
      for (const array of data) {
        result.push(...array)
      }
  
      if (sortingStartDate) {
        result = result.filter(item => item.start_date.includes(sortingStartDate))
      }
      if (sortingEndDate) {
        result = result.filter(item => item.end_date.includes(sortingEndDate))
      }
      if (sortingName) {
        result = result.filter(item => item.name.includes(sortingName))
      }
      if (sortingParent) {
        result = result.filter(item => item.parent === Number(sortingParent))
      }
      if (sortingActivityType) {
          const getKeyByValue = (object) => {
            const result = []
            const keys = Object.keys(object)
            for (const key of keys) {
              if (sortingActivityType[key] === true) {result.push(key)}
            }
            return result
          }
  
        const activities = getKeyByValue(sortingActivityType, true)
        
        if (activities.length > 0) {
          const originalActivities = activityType
          const selectedActivities = originalActivities.filter(n => !activities.includes(n))

          for (const a of selectedActivities) {
            result = result.filter(item => item.activity_type_id !== originalActivities.id)
          }
        }
      }
      if (sortingGoal) {
        result = result.filter(item => item.goal_id === Number(sortingGoal))
      }
      if (sortingSort) {
        result = result.filter(item => item.sorting_number === Number(sortingSort))
      }
      if (sortingPredecessor) {
        result = result.filter(item => item.predecessor === Number(sortingPredecessor))
      }
      if (sortingOwner) {
        result = result.filter(item => item.owner.includes(sortingOwner))
      }
      if (sortingScore) {
        result = result.filter(item => item.score === Number(sortingScore))
      }
      if (sortingTag) {
        result = result.filter(item => item.tag.includes(sortingTag))
      }
      if (sortingLCost) {
        result = result.filter(item => item.labor_cost === Number(sortingLCost))
      }
      if (sortingRCost) {
        result = result.filter(item => item.resources_cost === Number(sortingRCost))
      }
      if (sortingEffort) {
        result = result.filter(item => item.effort === Number(sortingEffort))
      }
      if (sortingDescription) {
        result = result.filter(item => item.name.includes(sortingDescription))
      }
  
      setSortedData(groupArrayIntoSixes(result, 20))
      setPaginationLevels(sortedData.length)
    }, [data, sortingStartDate, sortingEndDate, sortingName, sortingParent, setPaginationLevels, sortedData.length, sortingActivityType, sortingGoal, sortingSort, sortingPredecessor, sortingOwner, sortingScore, sortingTag, sortingLCost, sortingRCost, sortingEffort, sortingDescription])
  
  
    const sortData = (sortKey) => {
      let result = []
  
      if (sortedData.length > 1) {
        for (const array of sortedData) {
          result.push(...array)
        }
  
      } else {
        for (const array of data) {
          result.push(...array)
        }
      }
  
      const reverseSort = (sortKey) => {
          setActiveSort({key: sortKey, inc: false })
          result.reverse()
      }
      
      if (sortKey === 'id') {
        result.sort((a, b) => parseFloat(a.id) - parseFloat(b.id))
  
        if (activeSort.key === sortKey && activeSort.inc) {
          reverseSort(sortKey)
        }
         else {
          setActiveSort({key: 'id', inc: true})
        }
      }
      if (sortKey === 'name') {
        result.sort((a, b) => a.name.localeCompare(b.name))
  
        if (activeSort.key === sortKey && activeSort.inc) {
          reverseSort(sortKey)
        } else {
          setActiveSort({key: 'name', inc: true})
        }
      }
      if (sortKey === 'address') {
        result.sort((a, b) => parseFloat(Number("" + a.first_level + a.second_level + a.third_level)) 
                            - parseFloat(Number("" + b.first_level + b.second_level + b.third_level)))
  
        if (activeSort.key === sortKey && activeSort.inc) {
          reverseSort(sortKey)
        } else {
          setActiveSort({key: 'address', inc: true})
        }
      }
      if (sortKey === 'start-date') {
        const emptyDates = result.filter(item => item.start_date === "")
        const actuallDates = result.filter(item => item.start_date !== "")
        actuallDates.sort((a, b) => new Date(a.start_date) - new Date(b.start_date))
        result = [...actuallDates, ...emptyDates]
  
        if (activeSort.key === sortKey && activeSort.inc) {
          reverseSort(sortKey)
        } else {
        setActiveSort({key: 'start-date', inc: true})
        }
      }
      if (sortKey === 'end-date') {
        const emptyDates = result.filter(item => item.end_date === "")
        const actuallDates = result.filter(item => item.end_date !== "")
        actuallDates.sort((a, b) => new Date(a.end_date) - new Date(b.end_date))
        result = [...actuallDates, ...emptyDates]
  
        if (activeSort.key === sortKey && activeSort.inc) {
          reverseSort(sortKey)
        } else {
        setActiveSort({key: 'end-date', inc: true})
        }
      }
      if (sortKey === 'goal-id') { 
        result.sort((a, b) => parseFloat(a.goal_id) - parseFloat(b.goal_id))
  
        if (activeSort.key === sortKey && activeSort.inc) {
          reverseSort(sortKey)
        } else {
        setActiveSort({key: 'goal-id', inc: true}) 
        }
      }
      if (sortKey === 'sorting-number') { 
        result.sort((a, b) => parseFloat(a.sorting_number) - parseFloat(b.sorting_number))
  
        if (activeSort.key === sortKey && activeSort.inc) {
          reverseSort(sortKey)
        } else {
        setActiveSort({key: 'sorting-number', inc: true}) 
        }
      }
      if (sortKey === 'predecessor') { 
        result.sort((a, b) => parseFloat(a.predecessor) - parseFloat(b.predecessor))
  
        if (activeSort.key === sortKey && activeSort.inc) {
          reverseSort(sortKey)
        } else {
        setActiveSort({key: 'predecessor', inc: true}) 
        }
      }
      if (sortKey === 'owner') {
        result.sort((a, b) => a.owner.localeCompare(b.owner))
  
        if (activeSort.key === sortKey && activeSort.inc) {
          reverseSort(sortKey)
        } else {
          setActiveSort({key: 'owner', inc: true})
        }
      }
      if (sortKey === 'property') {
        result.sort((a, b) => parseFloat(a.property) - parseFloat(b.property))
  
        if (activeSort.key === sortKey && activeSort.inc) {
          reverseSort(sortKey)
        } else {
        setActiveSort({key: 'property', inc: true})
        }
      }
      if (sortKey === 'parent') { 
        result.sort((a, b) => parseFloat(a.parent) - parseFloat(b.parent))
  
        if (activeSort.key === sortKey && activeSort.inc) {
          reverseSort(sortKey)
        } else {
        setActiveSort({key: 'parent', inc: true}) 
        }
      }
      if (sortKey === 'score') { 
        result.sort((a, b) => parseFloat(a.score) - parseFloat(b.score))
  
        if (activeSort.key === sortKey && activeSort.inc) {
          reverseSort(sortKey)
        } else {
        setActiveSort({key: 'score', inc: true}) 
        }
      }
      if (sortKey === 'labor-cost') { 
        result.sort((a, b) => parseFloat(a.labor_cost) - parseFloat(b.labor_cost))
  
        if (activeSort.key === sortKey && activeSort.inc) {
          reverseSort(sortKey)
        } else {
        setActiveSort({key: 'labor-cost', inc: true}) 
        }
      }
      if (sortKey === 'resources-cost') { 
        result.sort((a, b) => parseFloat(a.resources_cost) - parseFloat(b.resources_cost))
  
        if (activeSort.key === sortKey && activeSort.inc) {
          reverseSort(sortKey)
        } else {
        setActiveSort({key: 'resources-cost', inc: true}) 
        }
      }
      if (sortKey === 'activity-type-id') {
        result.sort((a, b) => parseFloat(a.activity_type_id) - parseFloat(b.activity_type_id))
  
        if (activeSort.key === sortKey && activeSort.inc) {
          reverseSort(sortKey)
        } else {
        setActiveSort({key: 'activity-type-id', inc: true})
        }
      }
      if (sortKey === 'link') {
        result.sort((a, b) => parseFloat(a.link) - parseFloat(b.link))
  
        if (activeSort.key === sortKey && activeSort.inc) {
          reverseSort(sortKey)
        } else {
        setActiveSort({key: 'link', inc: true})
        }
      }
      if (sortKey === 'description') {
        result.sort((a, b) => a.name.localeCompare(b.name))
  
        if (activeSort.key === sortKey && activeSort.inc) {
          reverseSort(sortKey)
        } else {
          setActiveSort({key: 'description', inc: true})
        }
      }
      setSortedData(groupArrayIntoSixes(result, 20))
      setPaginationLevels(sortedData.length)
    }
  
    return (
      <div className="flex flex-col w-[100%]">
        {showDelete && (
          <Modal
            setShowDelete={setShowDelete}
            deleteId={deleteId}
            deleteItem={deleteItem}
            deleteItemType={'activity'}
            setSuccess={setSuccess}
            setError={setError}
            getData={getData}
          />
        )}
        <table className="table-auto w-full text-left whitespace-no-wrap">
          <thead className="table-header">
            <tr>
              <ActivitiesTableHeaderItem name={'ID'} sortData={sortData} value={'id'} activeSort={activeSort}/>
              <ActivitiesTableHeaderItem name={'NAME'} sortData={sortData} value={'name'} activeSort={activeSort} setSortingName={setSortingName}/>
              <ActivitiesTableHeaderItem name={'TYPE'} sortData={sortData} value={'activity-type-id'} activeSort={activeSort} setSortingActivityType={setSortingActivityType}/>
              <ActivitiesTableHeaderItem name={'GOAL'} sortData={sortData} value={'goal-id'} activeSort={activeSort} setSortingGoal={setSortingGoal}/>
              <ActivitiesTableHeaderItem name={'SORT'} sortData={sortData} value={'sorting-number'} activeSort={activeSort} setSortingSort={setSortingSort}/>
              <ActivitiesTableHeaderItem name={'PREDECESSOR'} sortData={sortData} value={'predecessor'} activeSort={activeSort} setSortingPredecessor={setSortingPredecessor}/>
              <ActivitiesTableHeaderItem name={'PARENT'} sortData={sortData} value={'parent'} activeSort={activeSort} setSortingParent={setSortingParent}/>
              <ActivitiesTableHeaderItem name={'OWNER'} sortData={sortData} value={'owner'} activeSort={activeSort} setSortingOwner={setSortingOwner}/>
              <ActivitiesTableHeaderItem name={'SCORE'} sortData={sortData} value={'score'} activeSort={activeSort} setSortingScore={setSortingScore}/>
              <ActivitiesTableHeaderItem name={'START'} sortData={sortData} value={'start-date'} activeSort={activeSort} setSortingStartDate={setSortingStartDate}/>
              <ActivitiesTableHeaderItem name={'END'} sortData={sortData} value={'end-date'} activeSort={activeSort} setSortingEndDate={setSortingEndDate}/>
              <ActivitiesTableHeaderItem name={'TAG'} sortData={sortData} value={'tag'} activeSort={activeSort} setSortingTag={setSortingTag}/>
              <ActivitiesTableHeaderItem name={'LCOST'} sortData={sortData} value={'labor-cost'} activeSort={activeSort} setSortingLCost={setSortingLCost}/>
              <ActivitiesTableHeaderItem name={'RCOST'} sortData={sortData} value={'resources-cost'} activeSort={activeSort} setSortingRCost={setSortingRCost}/>
              <ActivitiesTableHeaderItem name={'EFFORT'} sortData={sortData} value={'effort'} activeSort={activeSort} setSortingEffort={setSortingEffort}/>
              <ActivitiesTableHeaderItem name={'NOTE'} sortData={sortData} value={'description'} activeSort={activeSort} setSortingEffort={setSortingEffort}/>
              <ActivitiesTableHeaderItem name={'LINK'} sortData={sortData} value={'link'} activeSort={activeSort}/>
            </tr>
          </thead>
          <tbody className="table-body">
          {sortedData.length > 0 &&
                sortedData[current].map((item) => {
                  return (
                    <ActivityItem 
                      key={item.id} 
                      item={item}
                      showDelete={showDelete} 
                      deleteId={deleteId}
                      setDeleteItem={setDeleteItem}  
                      setShowDelete={setShowDelete} 
                      setDeleteId={setDeleteId} 
                      setUpdateChosen={setUpdateChosen} 
                      setAddChosen={setAddChosen} 
                      setUpdateId={setUpdateId} 
                      setUpdateName={setUpdateName} 
                      setUpdateStartDate={setUpdateStartDate} 
                      setUpdateEndDate={setUpdateEndDate} 
                      setUpdateParent={setUpdateParent} 
                      setUpdateLink={setUpdateLink}
                      setUpdateType={setUpdateType}
                      setUpdateGoal={setUpdateGoal}
                      setUpdateOwner={setUpdateOwner}
                      setUpdatePredecessor={setUpdatePredecessor}
                      setUpdateScore={setUpdateScore}
                      setUpdateTag={setUpdateTag}
                      setUpdateSort={setUpdateSort}
                      setUpdateLCost={setUpdateLCost}
                      setUpdateRCost={setUpdateRCost}
                      setUpdateEffort={setUpdateEffort}
                      setUpdateDescription={setUpdateDescription}
                      activityType={activityType}

                      access={access}
                    />
                )
              }
            )
          }
          </tbody>
        </table>
      </div>
    );
};

export default ActivitiesTable;