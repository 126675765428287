import { connect } from "react-redux";
import { fetched, token, loading } from '../../store/reducers/selectors'
import { getSet, newSet, editSet } from "../../api/actions";
import { createStructuredSelector } from "reselect";
import NewSetModal from './NewSetModal'

const mapStateToProp = createStructuredSelector({
    token,
    fetched,
    loading
})

const mapDispatchToProps = {
    getSet,
    newSet,
    editSet
}

export default connect(mapStateToProp, mapDispatchToProps)(NewSetModal);