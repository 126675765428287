import { connect } from "react-redux"
import { fetched, token, loading, errorMessage, successMessage } from "../store/reducers/selectors"
import { signUp, clearErrorMessage, clearSuccessMessage } from "../api/actions"
import { createStructuredSelector } from "reselect"
import SignUp from "./SignUp";


const mapStateToProp = createStructuredSelector({
    token,
    fetched,
    loading,
    errorMessage,
    successMessage
})

const mapDispatchToProps = {
    signUp,
    clearErrorMessage,
    clearErrorMessage
}

export default connect(mapStateToProp, mapDispatchToProps)(SignUp);