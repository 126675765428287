import React, { useEffect, useState } from "react";

import groupArrayIntoSixes from "../../handlers/groupArrayHandler";

import Modal from "../Modal";
import ScenarioTableHeaderItem from "./ScenarioTableHeaderItem";
import ScenarioItem from './ScenarioItem';


const ScenarioTable = ({
	data,
	deletedChosen,
	setSelected,
	setUpdateName,
	setUpdateId,
	setSuccess,
	setError,
	getData,
	setUpdateChosen,
	setAddChosen,
	setUpdateActivities,
	setUpdateActiveFlag,
	current,
	setPaginationLevels,
	access
}) => {
	const [showDelete, setShowDelete] = useState(false);
	const [deleteId, setDeleteId] = useState("");
	const [deleteItem, setDeleteItem] = useState({name: ''});
	const [sortedData, setSortedData] = useState([])
	const [activeSort, setActiveSort] = useState({key: '', inc: true})
	const [sortingActivities, setSortingActivities] = useState("")
	const [sortingActive, setSortingActive] = useState("")
	const [sortingName, setSortingName] = useState("")


	useEffect(() => {
		let result = []

		for (const array of data) {
			result.push(...array)
		}

		if (sortingName) {
			result = result.filter(item => item.name.includes(sortingName))
		}
		if (sortingActivities) {
			result = result.filter(item => item.list_of_activities.some(activity => activity.includes(sortingActivities)))
		}
		if (sortingActive) {
			const getKeyByValue = (object) => {
				const result = []
				const keys = Object.keys(object)
				for (const key of keys) {
					if (sortingActive[key] === true) { result.push(key)} 
				}
				return result
			}
			
			const properties = getKeyByValue(sortingActive)
			if (properties.length > 0) {
				const originalProperties = ['Yes', 'No']
				const propertiesIncludes = originalProperties.filter(n => !properties.includes(n))
				for (const p of propertiesIncludes) {
					result = result.filter(item => (item.active_flag === true ? 'Yes' : 'No') !== p)
				}
			}
		}

		setSortedData(groupArrayIntoSixes(result, 20))
		setPaginationLevels(sortedData.length)
	}, [data, sortingName, setPaginationLevels, sortingActivities, sortingActive, sortedData.length])


	const sortData = (sortKey) => {
		let result = []

		if (sortedData.length > 1) {
			for (const array of sortedData) {
				result.push(...array)
			}

		} else {
			for (const array of data) {
				result.push(...array)
			}
		}

		const reverseSort = (sortKey) => {
				setActiveSort({key: sortKey, inc: false })
				result.reverse()
		}

		if (sortKey === 'id') {
			result.sort((a, b) => parseFloat(a.id) - parseFloat(b.id))

			if (activeSort.key === sortKey && activeSort.inc) {
				reverseSort(sortKey)
			}
			 else {
				setActiveSort({key: 'id', inc: true})
			}
		}
		if (sortKey === 'name') {
			result.sort((a, b) => a.name.localeCompare(b.name))

			if (activeSort.key === sortKey && activeSort.inc) {
				reverseSort(sortKey)
			} else {
				setActiveSort({key: 'name', inc: true})
			}
		}
		// if (sortKey === 'activities') {
		//   result.sort((a, b) => a.list_of_activities.localeCompare(b.list_of_activities))

		//   if (activeSort.key === sortKey && activeSort.inc) {
		//     reverseSort(sortKey)
		//   } else {
		//   setActiveSort({key: 'activities', inc: true})
		//   }
		// }
		if (sortKey === 'active') {
			result.sort((a, b) => parseFloat(a.active_flag) - parseFloat(b.active_flag))

			if (activeSort.key === sortKey && activeSort.inc) {
				reverseSort(sortKey)
			} else {
			setActiveSort({key: 'active', inc: true})
			}
		}
		setSortedData(groupArrayIntoSixes(result, 20))
		setPaginationLevels(sortedData.length)
	}

	return (
		<div className="flex flex-col w-[100%]">
			{showDelete && (
				<Modal
					setShowDelete={setShowDelete}
					deleteId={deleteId}
					deleteItem={deleteItem}
					deleteItemType={'scenario'}
					setSuccess={setSuccess}
					setError={setError}
					getData={getData}
				/>
			)}
			<table className="table-auto w-full text-left whitespace-no-wrap">
				<thead className="table-header">
					<tr>
						<ScenarioTableHeaderItem name={'ID'} sortData={sortData} value={'id'} activeSort={activeSort}/>
						<ScenarioTableHeaderItem name={'NAME'} sortData={sortData} value={'name'} activeSort={activeSort} setSortingName={setSortingName}/>
						<ScenarioTableHeaderItem name={'ACTIVITIES'} sortData={sortData} value={'activities'} activeSort={activeSort} setSortingActivities={setSortingActivities}/>
						<ScenarioTableHeaderItem name={'ACTIVE'} sortData={sortData} value={'active'} activeSort={activeSort} setSortingActive={setSortingActive}/>
					</tr>
				</thead>
				<tbody className="table-body">
				{sortedData.length > 0 &&
							sortedData[current].map((item) => {
								return (
									<ScenarioItem 
										key={item.id} 
										item={item}
										showDelete={showDelete} 
										deleteId={deleteId}
										setDeleteItem={setDeleteItem}  
										setShowDelete={setShowDelete} 
										setDeleteId={setDeleteId} 
										setUpdateChosen={setUpdateChosen} 
										setAddChosen={setAddChosen} 
										setUpdateId={setUpdateId} 
										setUpdateName={setUpdateName} 
										setUpdateActivities={setUpdateActivities}
										setUpdateAcviteFlag={setUpdateActiveFlag}
										access={access}
									/>
							)
						}
					)
				}
				</tbody>
			</table>
		</div>
	);
};

export default ScenarioTable;
