import React, { useState } from 'react';

import { VscGear } from "react-icons/vsc";
import { VscClearAll } from 'react-icons/vsc'


const BusinessTableHeaderItem = ({ 
    sortData, 
    name, 
    value, 
    activeSort, 
    setSortingStartDate, 
    setSortingEndDate, 
    setSortingName,  
    setSortingTag,
    setSortingProperty
}) => {
    const [filter, setFilter] = useState(false)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [sortName, setSortName] = useState('')
    const [property, setProperty] = useState('')
    const [tag, setTag] = useState('')

    let headerItemType = ''
    let headerItemFilterActive = ''

    const Checkbox = ({ type = "checkbox", name, checked = false, onChange }) => {
        return (
          <input type={type} name={name} checked={checked} onChange={onChange} />
        )
    }

    const setFieldClear = () => {
        setFilter(!filter)
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' || e.key === 'Escape'){
            setFilter(!filter)
        }
    }

    const closeFilter = (e) => {
        if (e.key === 'Escape'){
            setFilter(!filter)
        }
    }
    
    const nameChangeHandler = (e) => {
        setSortName(e.target.value)
        setSortingName(e.target.value)
    }

    const startDateChangeHandler = (e) => {
        setStartDate(e.target.value)
        setSortingStartDate(e.target.value)
        setFilter(!filter)
    }

    const endDateChangeHandler = (e) => {
        setEndDate(e.target.value)
        setSortingEndDate(e.target.value)
        setFilter(!filter)
    }

    const tagChangeHandler = (e) => {
        setTag(e.target.value)
        setSortingTag(e.target.value)
    }

    const propertyChangeHandler = (e) => {
        setProperty({...property, [e.target.name] : e.target.checked })
        setSortingProperty({...property, [e.target.name] : e.target.checked })
    }

    const checkboxesProperties = [
        {
            name: '',
            key: 1,
            label: '',
        },{
            name: 'Support',
            key: 2,
            label: 'Support',
        },
        {
            name: 'MDM',
            key: 3,
            label: 'MDM',
        },
        {
            name: 'ESG',
            key: 4,
            label: 'ESG',
        },
        {
            name: 'Strategic',
            key: 5,
            label: 'Strategic',
        },
        {
            name: 'Learning',
            key: 6,
            label: 'Learning',
        },
        {
            name: 'Planning',
            key: 7,
            label: 'Planning',
        },
        {
            name: 'Execution',
            key: 8,
            label: 'Execution',
        },
    ]

    if (value === 'start-date') {
        headerItemFilterActive = startDate
        headerItemType = <input
                            value={startDate} 
                            className="h-11 w-72 shadow rounded py-2 px-3 filter-input" 
                            type="date" 
                            onChange={startDateChangeHandler}>
                        </input>
    }
    if (value === 'end-date') {
        headerItemFilterActive = endDate
        headerItemType = <input
                            value={endDate} 
                            className="h-11 w-72 shadow rounded py-2 px-3 filter-input"
                            type="date"
                            onChange={endDateChangeHandler}>
                        </input>
    }
    if (value === 'name') {
        headerItemFilterActive = sortName
        headerItemType = <div className='h-11 w-72 shadow rounded py-2 px-3 filter-input'>
                            <input
                                value={sortName} 
                                className="" 
                                type="text" 
                                placeholder="Start typing here"
                                onChange={nameChangeHandler}>
                            </input>
                            <button className='clear-field' onClick={() => {setFieldClear(); setSortName(''); setSortingName('') }}><VscClearAll /></button>
                        </div>
    }
    if (value === 'tag') {
        headerItemFilterActive = tag
        headerItemType = <div className='h-11 w-72 shadow rounded py-2 px-3 filter-input'>
                            <input
                                value={tag} 
                                className="" 
                                type="text" 
                                placeholder="Start typing here"
                                onChange={tagChangeHandler}
                                onKeyDown={handleKeyPress}>
                            </input>
                            <button className='clear-field' onClick={() => {setFieldClear(); setTag(''); setSortingTag('') }}><VscClearAll /></button>
                        </div>
    }
    if (value === 'property') {
        headerItemFilterActive = property
        headerItemType = 
                    <div className="filter-select">
                        <form className="filter-select__form" onChange={propertyChangeHandler}>
                            {checkboxesProperties.map(item => (
                                <label key={item.key}>
                                    <br/>
                                    <Checkbox name={item.name} checked={property[item.name]} onChange={propertyChangeHandler} />
                                    {item.name}
                                </label>
                                ))}

                        </form>
                        <button className='clear-field' onClick={() => {setFieldClear(); setProperty(''); setSortingProperty('') }}><VscClearAll /></button>
                    </div>
    }

    let uniqueStyling

    if (value === 'id') {
        uniqueStyling = 'header-item_id'
    }
    if (value === 'name') {
        uniqueStyling = 'header-item_name'
    }
    if (value === 'link') {
        uniqueStyling = 'header-item_link'
    }

    return (
        <th className={"table-header-item "  + uniqueStyling}>
            <div className='table-header-item__container' tabIndex={-1} onKeyDown={closeFilter}>
                {filter && headerItemType}
                <button onClick={() => {sortData(value)}}>{name}</button>
                {(activeSort.key !== value) && <div style={{marginRight: 12}}></div>}
                {(activeSort.key === value && activeSort.inc) && <div style={{marginBottom: 2}}>▲</div>}
                {(activeSort.key === value && !activeSort.inc) && <div style={{marginBottom: 3}}>▼</div>}
                {(value !== 'id' && value !== 'link') && <VscGear className={headerItemFilterActive ? "text-2xl cursor-pointer capability-filter text-red-400" : (filter ? "text-2xl cursor-pointer capability-filter filter-active" : "text-2xl cursor-pointer capability-filter")} onClick={() => {setFilter(!filter)}} />}
            </div>
        </th>
    );
};

export default BusinessTableHeaderItem;