import SecondLevel from './SecondLevel'
import { wrapLabel, getCenterX } from './handlers/handlers'

const CapabilitiesReportDownload = ({ 
	item, 
	data, 
	heatmapActive, 
	timeline, 
	isHeatmapNotDot, 
	setUpdateName, 
	setActive, 
	setUpdateChosen,
	setUpdateLink,
	setUpdateProperty,
	setUpdateStartDate,
	setUpdateEndDate,
	setUpdateId,
	setUpdateFirstLevel,
	setUpdatesecondLevels,
	setUpdatethirdLevels,
	setUpdateSetId,
	set,
	setMaturity,
	xPos,
}) => {
	const firstLevelWidth = 120
	const firstLevelHeight = 100
	const secondLevelHeight = 100
	const thirdLevelHeight = 80
	const levelDistance = 10
	const textHeightPosition = 30
	const fontSize = 12
	// const getMaturityColor = (item, level) => {
	// 	const itemMaturities = item.maturity && item.maturity.filter(el => el.change_date === timeline || el.change_date < timeline)
	// 	itemMaturities && itemMaturities.sort((a, b) => b.id - a.id)
	// 	const itemMaturity = itemMaturities && itemMaturities.length > 0 ? itemMaturities[0] : null
	// 	// const itemMaturity = item.maturity && item.maturity.find(el => el.change_date === timeline || el.change_date < timeline)

	// 	if (itemMaturity) {
	// 		if (itemMaturity.maturity_level_dict.name === 'No capability') {
	// 			return 'maturity-color-1'
	// 		}
	// 		if (itemMaturity.maturity_level_dict.name === 'Initial') {
	// 			return 'maturity-color-2'
	// 		}
	// 		if (itemMaturity.maturity_level_dict.name === 'Managed') {
	// 			return 'maturity-color-3'
	// 		}
	// 		if (itemMaturity.maturity_level_dict.name === 'Defined') {
	// 			return 'maturity-color-4'
	// 		}
	// 		if (itemMaturity.maturity_level_dict.name === 'Quantatively Managed') {
	// 			return 'maturity-color-5'
	// 		}
	// 		return 'maturity-color-6'
	// 		// old way of changing class name
	// 		// return 'maturity-color-' + itemMaturity.maturity_level_dict.id
	// 	}
	// 	if (level === 1) {
	// 		return ''
	// 	}
	// 	if (level === 2) {
	// 		return 'no-maturity-color'
	// 	}
	// 	if (level === 3) {
	// 		return ''
	// 	}
	// }
	let yPos = -60
	const secondLevels = data.filter(i2 => i2.first_level === item.first_level && i2.second_level !== 0 && i2.third_level === 0)
	const thirdLevels = data.filter(i3 => i3.first_level === item.first_level && i3.first_level === item.first_level && i3.third_level !== 0)
	const firstLevelHeightDynamic = secondLevels.length * (secondLevelHeight + levelDistance) + thirdLevels.length * (thirdLevelHeight + levelDistance) + (firstLevelHeight + levelDistance)
	const labels = wrapLabel(item.name, 80)

	const wrappedText = labels.map((word, index) => {
		const centerX = getCenterX(word, xPos, firstLevelWidth)
		yPos += 10

		return (
		<tspan x={centerX} dy={index === 0 ? 0 : fontSize}>
			{word}
		</tspan>)
	})
	
	const addressText = item.first_level + '.' + item.second_level + '.' + item.third_level
	const addressXPos = getCenterX(addressText, xPos, firstLevelWidth)
	
	return (
// 		className={heatmapActive && isHeatmapNotDot ? "first-level-capability " + getMaturityColor(item, 1) : "first-level-capability"}
// {heatmapActive && <i className={"maturity-min " + getMaturityColor(item, 1)} />}
	<g key={item.key} stroke="#1E1E1E">
		<rect
		 	width={firstLevelWidth}
			height={firstLevelHeightDynamic}
			fill="#FFFFFF"
			rx="8"
			x={xPos}
		/>
		
		<text color='black' x={addressXPos} y={textHeightPosition} fontWeight="normal" fontSize={fontSize}>
			<tspan>{addressText}</tspan>
		</text>
		<text color='black' x={xPos} y={textHeightPosition + fontSize} fontWeight="normal" fontSize={fontSize}>
			{wrappedText}
		</text>
		
		{secondLevels.map((i2) => {	
			yPos += secondLevelHeight + levelDistance
			return (
				<SecondLevel item={i2} thirdLevels={thirdLevels} xPos={xPos} yPos={yPos} />
			)
		})}
	</g>
	)
}





export default CapabilitiesReportDownload