import React, {useEffect, useMemo, useState} from 'react'
import { Navigate } from 'react-router-dom'
import FormInput from '../components/ui/inputs/FormInput'
import FacebookSpinner from '../components/ui/spinners/FacebookSpinner'

import SuccessNotice from '../components/SuccessNotice'
import ErrorNotice from '../components/ErrorNotice'

const Login = ({ login, token, fetched, loading, errorMessage, successMessage, clearSuccessMessage, clearErrorMessage }) => {
  const [values, setValues] = useState({
      // username: "",
      email: "",
      // birthday: "",
      password: "",
      // confirmPassword: "",
  })

  if (token) return <Navigate to="/"/>  

  const inputs = [
    // {
    //   id: 1,
    //   name: "username",
    //   type: "text",
    //   placeholder: "Username",
    //   errorMessage:
    //     "Username should be 3-16 characters and shouldn't include any special character!",
    //   label: "Username",
    //   pattern: "^[A-Za-z0-9]{3,16}$",
    //   required: true,
    // },
    {
      id: 2,
      name: "email",
      // type: "email",
      placeholder: "Email",
      errorMessage: "It should be a valid email address",
      label: "Email",
      required: true,
    },
    {
      id: 4,
      name: "password",
      type: "password",
      placeholder: "Password",
      errorMessage:
        "Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special character",
      label: "Password",
      // pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
      required: true,
    }
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    login({ email: values.email, password: values.password });
  }

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  if (!fetched || loading) {
    return (
      <div className="spinner-container">
        <FacebookSpinner />
      </div>
      )
  }

  return (
    <div className="sign-up mt-10">
      {errorMessage && <ErrorNotice message={errorMessage} clearMessage={clearErrorMessage}/>}
      {successMessage && <SuccessNotice message={successMessage} clearMessage={clearSuccessMessage} />}
      <form className="form" onSubmit={handleSubmit}>
        <h4 className='form__title'>Login</h4>
        {inputs.map((input) => (
          <FormInput
            key={input.id}
            {...input}
            value={values[input.name]}
            onChange={onChange}
          />
        ))}
        <div className="action-buttons">
          <p><a href="/recover">I forgot my password</a></p>
        </div>
        <button className='form__button'>Submit</button>
        <p>Don't have an account?</p>
        <a href="/signup">Sign up here</a>
      </form>
    </div>
  );

}

export default Login