import React from 'react';
import { BsTrash3 } from 'react-icons/bs'
import { FiEdit } from 'react-icons/fi'
import IconButton from '../ui/buttons/IconButton';


const ScenarioItem = ({
        item, 
        showDelete, 
        deleteId,
        setDeleteItem, 
        setShowDelete, 
        setDeleteId, 
        setUpdateChosen, 
        setAddChosen, 
        setUpdateId, 
        setUpdateName, 
        setUpdateActivities,
        setUpdateAcviteFlag,
        access
}) => {
    const { Scenarios_Edit, Scenarios_Delete} = access
    const handleDoubleClick = event => {
        if (!Scenarios_Edit) return 
        if (event.detail === 2) {
            setUpdateChosen(true);
            setAddChosen(false);
            setUpdateId(item.id);
            setUpdateName(item.name);
            setUpdateActivities(prepareActivities)
            setUpdateAcviteFlag(item.active_flag ? 'Yes' : 'No')
        }
    }

    const prepareActivities = () => {
        const result = {}

        if (!item.list_of_activities) return result

        for (const activity of item.list_of_activities) {
            result[activity] = true
        }

        return result
    }

    return (
        <tr
            key={item.id}
            className="Scenario-item"
            style={{
                backgroundColor:
                showDelete && deleteId === item.id && "#A3AAC4",
            }}
            onClick={(e) => {handleDoubleClick(e)}}
            >
            <td className="px-4 py-3">{item.id}</td>
            <td className="px-4 py-3">{item.name}</td>
            <td className="px-4 py-3">{item.list_of_activities && item.list_of_activities.toString()}</td>

            <td className="px-4 py-3 flex">{item.active_flag === true ? 'Yes' : 'No'}
            
                <div className='capability-controls'>
                    <IconButton
                        className="ml-5 capability-controls__button"
                        onClick={() => {
                            setShowDelete(!showDelete)
                            setDeleteId(item.id)
                            setDeleteItem({name: item.name})
                        }}
                        notRestricted={Scenarios_Delete}
                    >
                        <BsTrash3 alt="delete" />
                    </IconButton>
                    <IconButton
                        className="ml-5 capability-controls__button"
                        onClick={() => {
                            setUpdateChosen(true);
                            setAddChosen(false);
                            setUpdateId(item.id);
                            setUpdateName(item.name);
                            setUpdateActivities(prepareActivities)
                            setUpdateAcviteFlag(item.active_flag ? 'Yes' : 'No')
                        }}
                        notRestricted={Scenarios_Edit}
                    >
                        <FiEdit alt="edit" />
                    </IconButton>
                </div>
            </td>
        </tr>
    );
};

export default ScenarioItem;