import { connect } from "react-redux";
import { fetched, token, loading, capability, activity, goal, organization, userOrganization, set, access } from '../../store/reducers/selectors'
import { getCapability, getActivity, getGoal, getOrganization } from "../../api/actions";
import { createStructuredSelector } from "reselect";
import ReportsPage from './ReportsPage'

const mapStateToProp = createStructuredSelector({
    token,
    fetched,
    loading,
    capability,
    activity,
    goal,
    organization,
    userOrganization,
    set, 
    access
})

const mapDispatchToProps = {
    getCapability,
    getActivity,
    getGoal,
    getOrganization
}

export default connect(mapStateToProp, mapDispatchToProps)(ReportsPage);