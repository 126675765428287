import React, { useState, useEffect } from 'react'
import html2canvas from "html2canvas"
import AnyChartModule2 from './AnyChartModule2'
import IconButton from '../ui/buttons/IconButton'
import CustomModule from './CustomModule'
import CustomModule2 from './CustomModule2'
// import CustomModule from './CustomModule'
// import CustomModule2 from './CustomModule2'

const PERT2 = ({ dataArray, access }) => {
    const {Activities_Report_Download_report} = access
    const data = JSON.parse(JSON.stringify(dataArray))

    if (dataArray.length === 0) {
        return (
            <div className="chart-notification">
                <p className="chart-notification__text">No data for that chart</p>
            </div>
        )
    }

    if (!dataArray.find(item => item.predecessor === 0)) {
        return (
            <div className="chart-notification">
                <p className="chart-notification__text">Data structured incorrectly, there should be an element with a predecessor field equal to 0</p>
            </div>
        )
    }

     
    const downloadImage = async () => {
        const canvas = await html2canvas(document.getElementById('ac-chart-container'))
        const link = document.createElement("a")
        link.download = "pert.png"
        link.href = canvas.toDataURL('image/png', 1.0)  
        link.click()
    }

    return (
        <div className="reports">
            <div className="top-report">
                <div className="plus-minus">
                    <IconButton
						className="download"
						onClick={downloadImage}
						notRestricted={Activities_Report_Download_report}
					>
					</IconButton>
                    {/* <button className="download" onClick={downloadImage}></button> */}
                </div>
            </div>
            <div className='pert-container'>
                <AnyChartModule2 dataArray={data}/>

                {/* <CustomModule dataArray={dataArray}/> */}
                {/* <CustomModule2 dataArray={dataArray}/> */}
              </div>
        </div>
    )
}

export default PERT2