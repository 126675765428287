import React, { useEffect, useState } from 'react';
import RegularButton from '../ui/buttons/RegularButton'
import FormInput from '../ui/inputs/FormInputBasic'

const AccountParameters = ({ 
	loading, 
	fetched, 
	setError, 
	setSuccess, 
	userName, 
	userOrganization, 
	getOrganization, 
	organization, 
	access, 
	getUserRole, 
	userRole, 
	changePassword,
	user,
	getUser,
	editUser
}) => {
	const { role } = userRole
	const organizationName = organization && organization.name
	const { phone, name } = user ? user : ''
	const [values, setValues] = useState({
		name,
		phone,
		password: '',
	})

	const inputs = [
		{
			id: 1,
			name: "name",
			type: "text",
			placeholder: "Name",
			errorMessage:
				"Name should be 1-16 characters and shouldn't include any special character",
			label: "Name",
			pattern: "^[A-Za-z0-9]{3,16}$",
			required: true,
		},
		{
			id: 2,
			name: "phone",
			type: "tel",
			placeholder: "888 888 8888",
			label: "Phone",
			required: false,
			maxLength:"12"
		},
	]

	const inputPassword = [
		{
			id: 1,
			name: "password",
			label: "Change password",
			type: "password",
			placeholder: "Password",
			errorMessage:
				"Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special character",
			pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
			required: true,
		},
	]

	useEffect(() => {
		getUserRole()
		getOrganization({id: userOrganization})
		getUser()
	}, [])

	// const isValidEmail = (email) => {
	// 	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
	// 	return emailRegex.test(email)
	// }

	const onChange = (e) => {
		setValues({ ...values, [e.target.name]: e.target.value })
	}

	const editUserPassword = () => {
		changePassword({
			password: values.password
		}).then(() => {
			setValues({ ...values, password: null})
		})
	}

	const editUserInfo = () => {
		editUser({
			name: values.name,
			phone: values.phone
		})
	}

	return (
		<div className="settings">
			<div className="flex flex-col min-w[60%] mr-20">
				<div className="flex flex-col mb-10">
					<div>
						<h4 className="mb-2 font-bold">Account information</h4>
						<div>
							<p>User name</p>
							<input
								value={userName}
								placeholder="Change user name"
								type="text"
								id="user-name"
								name="user-name"
								minLength="2"
								disabled
								className="disabled h-11 input-basic-container shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mb-4"
							/>
						</div>
						<form className="">
							{inputs.map((input) => (
							<FormInput
								key={input.id}
								{...input}
								value={values[input.name]}
								onChange={onChange}
							/>
							))}
						</form>
						<RegularButton
							onClick={editUserInfo}
							className={"text-white bg-second-color border-0 py-2 mt-2 px-12 focus:outline-none rounded text-lg"}
							notRestricted={true}
						>
							Save
						</RegularButton>  
					</div>
				</div>
				<div>
					<h4 className="mb-2 font-bold">Account password</h4>
					<form className="">
						{inputPassword.map((input) => (
						<FormInput
							key={input.id}
							{...input}
							value={values[input.name]}
							onChange={onChange}
						/>
						))}
					</form>
					<RegularButton
						onClick={editUserPassword}
						className={"text-white bg-second-color border-0 py-2 mt-2 px-12 focus:outline-none rounded text-lg"}
						notRestricted={true}
					>
						Save
					</RegularButton>
				</div>  
			</div>
			<div className="flex min-w[30%] mr-10">
				<div>
					<h4 className="mb-2 font-bold">Account organization</h4>
					<div>
						<p>Organization name</p>
						<input
							value={organizationName}
							placeholder="Organization name"
							type="text"
							id="organization-name"
							name="organization-name"
							minLength="2"
							disabled
							className="h-11 shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mb-4"
						/>
					</div>
					<div>
						<p>User role</p>
						<input
							value={role}
							placeholder="User name"
							type="text"
							id="user-role"
							name="user-role"
							minLength="2"
							disabled
							className="h-11 shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mb-4"
						/>
					</div>
				</div>  
			</div> 
		</div>
	)
}

export default AccountParameters;