import React, { useState } from "react"

import UserTableHeaderItem from "./UserTableHeaderItem"
import User from './User'

import Modal from '../../Modal'


const UserTable = ({ data, access, setError, setSuccess }) => {
	const [showDelete, setShowDelete] = useState(false)
    const [deleteId, setDeleteId] = useState('')
    const [deleteItem, setDeleteItem] = useState({name: ''})

	return (
		<div className="flex flex-col min-w-[50%] w-[100%]">
			{showDelete && 
				<Modal
					setShowDelete={setShowDelete}
					deleteId={deleteId}
					deleteItem={deleteItem}
					deleteItemType={'organizationUser'}
					setSuccess={setSuccess}
					setError={setError}
				/>
			}
			<table className="table-auto w-full text-left whitespace-no-wrap">
				<thead className="table-header">
					<tr>
						<UserTableHeaderItem name={'NAME'}  value={'name'} />
						<UserTableHeaderItem name={'ROLE'}  value={'role'} />
						<UserTableHeaderItem name={'EMAIL'}  value={'email'} />
					</tr>
				</thead>
				<tbody className="table-body">
					{data && data.map((item) => {
									return (
										<User
											key={item.id}
											data={data}
											item={item}
											showDelete={showDelete}
											setShowDelete={setShowDelete}
											setDeleteId={setDeleteId}
											setDeleteItem={setDeleteItem}
											access={access}
										/>
								)
							}
						)
					}
				</tbody>
			</table>
		</div>
	);
};

export default UserTable;
