import React, { useState, useEffect } from "react";

import CapabilitiesManagement from "../../components/Capabilities/CapabilitiesManagement";
import CapabilitiesTable from "../../components/Capabilities/CapabilitiesTable";

import SuccessNotice from "../../components/SuccessNotice";
import ErrorNotice from "../../components/ErrorNotice";

import IconButton from "../../components/ui/buttons/IconButton";
import SelectSet from "../../components/ui/inputs/SelectSet";

import Left from "../../assets/left.svg";
import Right from "../../assets/right.svg";
import { HiOutlineFolderAdd } from "react-icons/hi";
import {
  AiOutlineEdit,
  AiOutlineFileAdd,
  AiOutlineClose,
} from "react-icons/ai";
import { MdOutlineDeleteOutline, MdDeleteForever } from "react-icons/md";
import { TbArrowMoveRight } from "react-icons/tb";
import { BiSelectMultiple } from "react-icons/bi";

import NewSetModal from "../../components/NewSetModal";
import Modal from "../../components/Modal";
import BCM from "../../components/Reports/BCM";
import SkeletonTabs from "../../components/ui/skeleton/SkeletonTabs";
import SkeletonTable from "../../components/ui/skeleton/SkeletonTable";
import groupArrayIntoSixes from "../../handlers/groupArrayHandler";
import EditSetModal from "../../components/EditSetModal";
import CapabilityImport from "../../components/Capabilities/CapabilityImport";
import CapabilityExport from "../../components/Capabilities/CapabilityExport";
import WarningNotice from "../../components/WarningNotice";
import VisualEditor from "../../components/VisualEditor/VisualEditor";
import NewBCM from "../../components/Reports/NewBCM";
import NewVisualEditor from "../../components/NewVisualEditor/VisualEditor";

import { formatValueIntoNumOrNull } from "../../handlers/formatValueIntoNumOrNull";
const CapabilitiesPage = ({
  loading,
  fetched,
  getCapability,
  getCapabilityType,
  newCapability,
  newCapabilityType,
  editCapability,
  editCapabilityType,
  getSet,
  capability,
  capabilityPerPage,
  capabilityType,
  set,
  organization,
  userOrganization,
  getOrganization,
  importCapability,
  getMaturityDict,
  maturity,
  newSet,
  deleteCapabilities,
  successMessage,
  errorMessage,
  clearErrorMessage,
  clearSuccessMessage,
  access,
}) => {
  const [capabilityBySet, setCapabilityBySet] = useState(capabilityPerPage);
  const [capabilityBySetForExport, setCapabilityBySetForExport] =
    useState(capability);

  const [active, setActive] = useState("Capabilities");
  const [maturityActive, setMaturityActive] = useState(false);
  const [activeSet, setActiveSet] = useState("-");
  const [updateChosen, setUpdateChosen] = useState(false);

  const [deleteItems, setDeleteItems] = useState("");
  const [deleteItem, setDeleteItem] = useState("");

  const [deleteItemsType, setDeleteItemsType] = useState("");

  const [addChosen, setAddChosen] = useState(false);

  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [editSetActive, setEditSetActive] = useState(false);
  const [newSetActive, setNewSetActive] = useState(false);

  const [editSet, setEditSet] = useState("");

  const [success, setSuccess] = useState("");
  const [warning, setWarning] = useState("");
  const [error, setError] = useState("");
  const [errorField, setErrorField] = useState("");

  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [property, setProperty] = useState("");
  const [firstLevel, setFirstLevel] = useState("0");
  const [secondLevel, setSecondLevel] = useState("0");
  const [thirdLevel, setThirdLevel] = useState("0");
  const [lane, setLane] = useState(null);
  const [layout, setLayout] = useState("vertical");
  const [capabilitySet, setCapabilitySet] = useState(activeSet);
  const [capabilityMaturityName, setCapabilityMaturityName] = useState("");
  const [capabilityMaturityDate, setCapabilityMaturityDate] = useState("");
  const [description, setDescription] = useState("");

  const [capabilityMoveToSet, setCapabilityMoveToSet] = useState("-");
  const [capabilityMaturitiesArray, setCapabilityMaturitiesArray] = useState(
    []
  );

  const [updateId, setUpdateId] = useState("");
  const [updateName, setUpdateName] = useState("");
  const [updateLink, setUpdateLink] = useState("");
  const [updateStartDate, setUpdateStartDate] = useState("");
  const [updateEndDate, setUpdateEndDate] = useState("");
  const [updateProperty, setUpdateProperty] = useState("");
  const [updateFirstLevel, setUpdateFirstLevel] = useState();
  const [updateSecondLevel, setUpdateSecondLevel] = useState();
  const [updateThirdLevel, setUpdateThirdLevel] = useState();
  const [updateLane, setUpdateLane] = useState();
  const [updateLayout, setUpdateLayout] = useState();
  const [updateCapabilitySet, setUpdateCapabilitySet] = useState("");
  const [updateCapabilityMaturityName, setUpdateCapabilityMaturityName] =
    useState("");
  const [updateCapabilityMaturityDate, setUpdateCapabilityMaturityDate] =
    useState("");
  const [updateDescription, setUpdateDescription] = useState("");

  const [current, setCurrent] = useState(0);
  const [paginationLevels, setPaginationLevels] = useState(
    capabilityPerPage.length
  );

  const organizationName = organization && organization.name;

  let addressError = errorField === "address" ? " form-error" : "";

  const {
    Capabilities_Add,
    Capabilities_Select_all,
    Capabilities_Delete_all,
    Capabilities_Add_set,
    Capabilities_Edit_set,
    Capabilities_Delete_set,
    Capabilities_Select_set,
    Capabilities_Move_capability_to_another_set,
  } = access.Buttons;

  const getSetId = (setName) => {
    const result = set.find((item) => item.name === setName);

    return result === undefined ? null : result.id;
  };

  const clearFields = () => {
    setName("");
    setLink("");
    setStartDate("");
    setEndDate("");
    setProperty("");
    setFirstLevel(0);
    setSecondLevel(0);
    setThirdLevel(0);
    setCapabilityMaturitiesArray([]);
    setSelected([]);
    setDescription("");
    setLayout("vertical");
    setLane(null);
  };

  const parentChangeHandler = (l1, l2, l3, setId) => {
    // backlog capability
    if (Number(l1) === 0) {
      return -1;
    }

    // first level capability
    if (Number(l2) === 0 && Number(l3) === 0) {
      return 0;
    }

    let parentL1 = l1,
      parentL2 = l2,
      parentL3 = 0;

    // capability has has l1, l2 or l3
    if (Number(l2) !== 0) {
      if (Number(l3) !== 0) {
        parentL3 = 0;
      } else {
        parentL2 = 0;
      }
    } else {
      return -1;
    }

    const isParent = capability.find(
      (item) =>
        "" + item.first_level + item.second_level + item.third_level ===
          "" + parentL1 + parentL2 + parentL3 &&
        item.universal_item_set_id === setId
    );

    // capability has no parent
    if (!isParent) {
      return -2;
    }

    return isParent.id;
  };

  const firstLevelChangeHandler = (e) => {
    const set = capabilitySet ? capabilitySet : activeSet;
    setThirdLevel("0");

    if (e === "0") {
      setSecondLevel("0");
    }
    setFirstLevel(e);
    const newData = capability.filter(
      (item) =>
        item.first_level === Number(e) &&
        item.universal_item_set_id === getSetId(set)
    );

    if (newData.length > 0) {
      newData.sort(
        (a, b) => parseFloat(a.second_level) - parseFloat(b.second_level)
      );
      setSecondLevel(newData[newData.length - 1].second_level + 1);
      return;
    }
    setSecondLevel("0");
  };

  const secondLevelChangeHandler = (e) => {
    const set = capabilitySet ? capabilitySet : activeSet;

    if (e === "0") {
      setThirdLevel("0");
    }
    setSecondLevel(e);

    const firstLevels = capability.filter(
      (item) =>
        item.first_level === Number(firstLevel) &&
        item.universal_item_set_id === getSetId(set)
    );
    const newData = firstLevels.filter(
      (item) =>
        item.second_level === Number(e) &&
        item.universal_item_set_id === getSetId(set)
    );

    if (newData.length > 0 && e !== "0") {
      newData.sort(
        (a, b) => parseFloat(a.third_level) - parseFloat(b.third_level)
      );
      setThirdLevel(newData[newData.length - 1].third_level + 1);
      return;
    }
    setThirdLevel("0");
  };

  const levelChildHandler = (obj) => {
    const set = capabilitySet ? capabilitySet : activeSet;
    const firstLevels = capability.filter(
      (item) =>
        item.first_level === Number(obj.first_level) &&
        item.universal_item_set_id === getSetId(set)
    );
    const newData = firstLevels.filter(
      (item) =>
        item.second_level === Number(obj.second_level) &&
        item.universal_item_set_id === getSetId(set)
    );

    if (obj.third_level === 0) {
      if (firstLevels.length > 0) {
        setFirstLevel(obj.first_level);
        firstLevels.sort(
          (a, b) => parseFloat(a.second_level) - parseFloat(b.second_level)
        );

        setSecondLevel(firstLevels[firstLevels.length - 1].second_level + 1);
        setThirdLevel(obj.third_level);
        return;
      }
    }

    if (newData.length > 0) {
      setFirstLevel(obj.first_level);
      setSecondLevel(obj.second_level);
      newData.sort(
        (a, b) => parseFloat(a.third_level) - parseFloat(b.third_level)
      );
      setThirdLevel(newData[newData.length - 1].third_level + 1);
      return;
    }
    setFirstLevel(obj.first_level);
    setSecondLevel(obj.second_level);
    setThirdLevel(obj.third_level);
  };

  const updateFirstLevelChangeHandler = (e) => {
    setUpdateThirdLevel("0");

    if (e.target.value === "0" || e.target.value === 0) {
      setUpdateSecondLevel("0");
    }
    setUpdateFirstLevel(e.target.value);
    const newData = capability.filter(
      (item) =>
        item.first_level === Number(e.target.value) &&
        item.universal_item_set_id === getSetId(updateCapabilitySet)
    );

    if (newData.length > 0) {
      newData.sort(
        (a, b) => parseFloat(a.second_level) - parseFloat(b.second_level)
      );
      setUpdateSecondLevel(newData[newData.length - 1].second_level + 1);
      return;
    }
    setUpdateSecondLevel("0");
  };

  const updateSecondLevelChangeHandler = (e) => {
    if (e.target.value === "0") {
      setUpdateThirdLevel("0");
      setUpdateProperty("");
    }
    setUpdateSecondLevel(e.target.value);

    const firstLevels = capability.filter(
      (item) =>
        item.first_level === Number(firstLevel) &&
        item.universal_item_set_id === getSetId(updateCapabilitySet)
    );
    const newData = firstLevels.filter(
      (item) =>
        item.second_level === Number(e.target.value) &&
        item.universal_item_set_id === getSetId(updateCapabilitySet)
    );

    if (newData.length > 0 && e.target.value !== "0") {
      newData.sort(
        (a, b) => parseFloat(a.third_level) - parseFloat(b.third_level)
      );
      setUpdateThirdLevel(newData[newData.length - 1].third_level + 1);
      return;
    }
    setUpdateThirdLevel("0");
  };

  const isAddressUnique = (update) => {
    const address = "" + firstLevel + secondLevel + thirdLevel;
    const updateAdress =
      "" + updateFirstLevel + updateSecondLevel + updateThirdLevel;

    if (update) {
      const updatedArray = capability.filter(
        (item) =>
          updateAdress ===
            "" + item.first_level + item.second_level + item.third_level &&
          item.universal_item_set_id === getSetId(updateCapabilitySet)
      );

      if (updatedArray.length > 0) {
        if (updatedArray[0].id === updateId) {
          return true;
        }

        return false;
      }

      return true;
    }

    for (const item of capability) {
      if (
        address ===
          "" + item.first_level + item.second_level + item.third_level &&
        item.universal_item_set_id === getSetId(capabilitySet)
      ) {
        return false;
      }
    }
    return true;
  };

  const deleteSetHandler = () => {
    if (activeSet === "-") {
      setError("You can't delete a default set");
      setTimeout(() => {
        setError("");
      }, 2000);
      return;
    }
    const findSet = set.find((item) => item.name === activeSet);
    const consistsCaps =
      capability &&
      capability.filter((item) => item.universal_item_set_id === findSet.id);
    if (consistsCaps.length > 0) {
      setError("You can't delete a set with capabilities");
      setTimeout(() => {
        setError("");
      }, 2000);
      return;
    }
    setDeleteItemsType("set");
    setDeleteItem(findSet);
    setShowDelete(true);
  };

  const deleteAllCapabilities = () => {
    setDeleteItems(capability);
    setDeleteItemsType("capabilitiesArray");
    setShowDelete(true);
  };

  const deleteCapabilitiesArrayHandler = () => {
    setDeleteItems(selected);
    setDeleteItemsType("capabilitiesArray");
    setShowDelete(true);
  };

  const editSetHandler = () => {
    if (activeSet === "-") {
      setError("You can't edit a default set");
      setTimeout(() => {
        setError("");
      }, 2000);
      return;
    }
    const findSet = set.find((item) => item.name === activeSet);
    setEditSet(findSet);
    setEditSetActive(true);
  };

  const capabilityMaturityAddHandler = (name, date) => {
    const maturityItem = maturity.find((item) => item.name === name);
    const newItem = {
      alt_id: Math.floor(Math.random() * 1000000),
      maturity_level_dict: maturityItem,
      change_date: date,
      maturity_level_dict_id: maturityItem.id,
    };
    setCapabilityMaturitiesArray([...capabilityMaturitiesArray, newItem]);
  };

  const capabilityMaturityDeleteHandler = (value) => {
    setCapabilityMaturitiesArray(
      capabilityMaturitiesArray.filter((item) => item.alt_id !== value.alt_id)
    );
  };

  const capabilityMaturityDateChangeHandler = (oldItem, newDate) => {
    // check for unique date
    // if (capabilityMaturitiesArray.find(item => item.change_date === newDate)) {
    //   setError('This date already in use')
    //   setTimeout(() => {
    //     setError("");
    //   }, 2000);
    //   return
    // }
    capabilityMaturitiesArray[
      capabilityMaturitiesArray.indexOf(oldItem)
    ].change_date = newDate;
    setCapabilityMaturitiesArray([...capabilityMaturitiesArray]);
  };

  const setSelectedItems = (selectedItem) => {
    const itemSelected = selected.find((item) => item.id === selectedItem.id);

    // deselect
    if (itemSelected) {
      setSelected(selected.filter((item) => item.id !== selectedItem.id));
      return;
    }
    setSelected([...selected, selectedItem]);
  };

  useEffect(() => {
    getCapability();
    getCapabilityType();
    getSet();
    getMaturityDict();
    getOrganization({ id: userOrganization });
  }, []);

  useEffect(() => {
    const getActiveSet = set.find((item) => item.name === activeSet);
    let setId = getActiveSet ? getActiveSet.id : 0;
    if (!getActiveSet) {
      setId = 0;
      setActiveSet("-");
      return;
    }
    setId = getActiveSet.id;
    if (capability) {
      setCurrent(0);
      setCapabilityBySet(
        groupArrayIntoSixes(
          capability.filter((item) => item.universal_item_set_id === setId),
          500
        )
      );
      setCapabilityBySetForExport(
        capability.filter((item) => item.universal_item_set_id === setId)
      );
    }
  }, [set, capability, activeSet]);

  const addData = () => {
    if (!name) {
      setErrorField("name");
      setTimeout(() => {
        setErrorField("");
      }, 2000);
      return;
    }
    if (new Date(startDate) > new Date(endDate)) {
      setError("Start Date can't be lower than End Date");
      setTimeout(() => {
        setError("");
      }, 2000);
      return;
    }
    if (!isAddressUnique()) {
      setError("Address should be unique");
      setErrorField("address");
      setTimeout(() => {
        setError("");
        setErrorField("");
      }, 2000);
      return;
    }
    newCapability({
      name,
      start_date: startDate,
      end_date: endDate,
      property,
      parent: parentChangeHandler(
        firstLevel,
        secondLevel,
        thirdLevel,
        getSetId(capabilitySet)
      ),
      link,
      first_level: Number(firstLevel),
      second_level: Number(secondLevel),
      third_level: Number(thirdLevel),
      lane: formatValueIntoNumOrNull(lane),
      layout,
      universal_item_set_id: Number(getSetId(capabilitySet)),
      maturity: capabilityMaturitiesArray,
      description,
    }).then(() => {
      setAddChosen(false);
      clearFields();
    });
  };

  const updateData = () => {
    if (!updateName) {
      setErrorField("name");
      setTimeout(() => {
        setErrorField("");
      }, 2000);
      return;
    }
    if (new Date(startDate) > new Date(endDate)) {
      setError("Start Date can't be lower than End Date");
      setTimeout(() => {
        setError("");
      }, 2000);
      return;
    }
    if (!isAddressUnique(true)) {
      setError("Address should be unique");
      setErrorField("address");
      setTimeout(() => {
        setError("");
        setErrorField("");
      }, 2000);
      return;
    }
    editCapability({
      id: updateId,
      name: updateName,
      start_date: updateStartDate,
      end_date: updateEndDate,
      property: updateProperty,
      parent: parentChangeHandler(
        updateFirstLevel,
        updateSecondLevel,
        updateThirdLevel,
        getSetId(updateCapabilitySet)
      ),
      link: updateLink,
      first_level: Number(updateFirstLevel),
      second_level: Number(updateSecondLevel),
      third_level: Number(updateThirdLevel),
      lane: formatValueIntoNumOrNull(updateLane),
      layout: updateLayout,
      universal_item_set_id: Number(getSetId(updateCapabilitySet)),
      maturity: capabilityMaturitiesArray,
      description: updateDescription,
    }).then(() => {
      setUpdateChosen(false);
      setCapabilityMaturitiesArray([]);
      setSelected([]);
      setSelectAll(false);
    });
  };
  const moveCapabilitiesToSet = () => {
    const setId = getSetId(capabilityMoveToSet);
    const specifiedSet = capability.filter(
      (item) => item.universal_item_set_id === setId
    );
    const backLogArray = specifiedSet.filter((item) => item.first_level === 0);

    if (backLogArray.length > 0) {
      backLogArray.sort(
        (a, b) =>
          parseFloat(
            Number("" + a.first_level + a.second_level + a.third_level)
          ) -
          parseFloat(
            Number("" + b.first_level + b.second_level + b.third_level)
          )
      );
    }

    const backLogLastItem =
      backLogArray.length > 0
        ? backLogArray[backLogArray.length - 1].second_level
        : -1;
    let tmp = backLogLastItem;

    for (const s of selected) {
      tmp++;
      s.first_level = 0;
      s.second_level = tmp;
      s.third_level = 0;
      s.parent = -1;
      s.universal_item_set_id = setId;
    }
    importCapability({ capabilities: selected })
      .then(() => {
        setSuccess("Capabilities successfully updated");
        setUpdateChosen(false);
        setSelected([]);
        setSelectAll(false);
        setTimeout(() => {
          setSuccess("");
        }, 2000);
      })
      .catch((err) => {
        setError(err.message);
        setTimeout(() => {
          setError("");
        }, 2000);
      });
  };

  const selectAllHandler = () => {
    if (selectAll) {
      setSelectAll(false);
      setSelected([]);
      return;
    }
    setSelectAll(true);
    setSelected(capabilityBySet[current]);
  };

  return (
    <>
      {/* <div className="notices">
					{(success || successMessage) && <SuccessNotice message={success || successMessage} clearMessage={clearSuccessMessage}/>}
					{warning && <WarningNotice message={warning} />}
					{(error || errorMessage) && <ErrorNotice message={error || errorMessage} clearMessage={clearErrorMessage}/>}
			</div> */}
      {loading || !fetched ? (
        <SkeletonTabs />
      ) : (
        <CapabilitiesManagement
          active={active}
          setActive={setActive}
          access={access.Tabs}
        />
      )}
      {editSetActive && (
        <EditSetModal
          setEditSetActive={setEditSetActive}
          set={editSet}
          setSuccess={setSuccess}
          setError={setError}
        />
      )}
      {newSetActive && (
        <NewSetModal
          setNewSetActive={setNewSetActive}
          setSuccess={setSuccess}
          setError={setError}
          setActiveSet={setActiveSet}
          setCapabilitySet={setCapabilitySet}
        />
      )}
      {showDelete && (
        <Modal
          setShowDelete={setShowDelete}
          deleteItem={deleteItem}
          deleteItemType={deleteItemsType}
          deleteItems={deleteItems}
          deleteId={deleteItemsType === "set" && deleteItems.id}
          setSuccess={setSuccess}
          setError={setError}
          setDeleteItems={setDeleteItems}
          setSelected={setSelected}
          setSelectAll={setSelectAll}
        />
      )}
      {active === "Capabilities" && (
        <>
          {addChosen && (
            <div>
              <div className="top-inputs">
                <div>
                  <p>Name</p>
                  <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Name"
                    type="text"
                    id="name"
                    autoFocus
                    name="name"
                    className={
                      errorField === "name"
                        ? "bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4 form-error"
                        : "bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4"
                    }
                  />
                </div>
                <div>
                  <p>Property</p>
                  <select
                    value={property}
                    onChange={(e) => setProperty(e.target.value)}
                    className={
                      secondLevel === "0"
                        ? "bg-white h-11 w-72 shadow rounded py-2 px-3 disabled"
                        : "bg-white h-11 w-72 shadow rounded py-2 px-3"
                    }
                    name="properties"
                    id="properties"
                  >
                    {capabilityType.length > 0 &&
                      capabilityType.map((item) => {
                        return (
                          <option value={item} key={item}>
                            {item}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="1-1/4 ml-4">
                  <p>Set</p>
                  <select
                    value={capabilitySet}
                    onChange={(e) => setCapabilitySet(e.target.value)}
                    className="bg-white h-11 shadow rounded py-2 px-3"
                    name="new-set"
                    id="new-set"
                  >
                    {set.length > 0 &&
                      set.map((item) => {
                        return (
                          <option value={item.name} key={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="top-inputs">
                <div className="1-1/4">
                  <p>Start Date</p>
                  <input
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    type="date"
                    id="start-date"
                    name="start-date"
                    className="w-48 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
                <div className="1-1/4">
                  <p>End Date</p>
                  <input
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    type="date"
                    id="end-date"
                    name="end-date"
                    className="w-48 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
                <div className="level-inputs mr-4">
                  <div className="1-1/4">
                    <p>Layout</p>
                    <select
                      value={layout}
                      onChange={(e) => setLayout(e.target.value)}
                      className="bg-white h-11 shadow rounded py-2 px-3"
                      name="layout"
                      id="layout"
                    >
                      <option value="vertical">vertical</option>
                      <option value="horizontal">horizontal</option>
                      <option value="backlog">backlog</option>
                    </select>
                    <span className="mx-2">/</span>
                  </div>
                  <div className="1-1/4">
                    <p>L1</p>
                    <input
                      value={firstLevel}
                      onChange={(e) => firstLevelChangeHandler(e.target.value)}
                      placeholder="L1"
                      type="number"
                      id="L1"
                      name="L1"
                      min="0"
                      className={
                        "w-96 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4 level-input" +
                        addressError
                      }
                    />
                  </div>
                  <span className="period-symbol">.</span>
                  <div className="1-1/4">
                    <p>L2</p>
                    <input
                      value={secondLevel}
                      onChange={(e) => secondLevelChangeHandler(e.target.value)}
                      placeholder="L2"
                      type="number"
                      id="L2"
                      name="L2"
                      min="0"
                      className={
                        // Number(firstLevel) === 0 ?
                        //  "w-96 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4 level-input disabled" + addressError :
                        "w-96 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4 level-input" +
                        addressError
                      }
                    />
                  </div>
                  <span className="period-symbol">.</span>
                  <div className="1-1/4">
                    <p>L3</p>
                    <input
                      value={thirdLevel}
                      onChange={(e) => setThirdLevel(e.target.value)}
                      placeholder="L3"
                      type="number"
                      id="L3"
                      name="L3"
                      min="0"
                      className={
                        // Number(secondLevel) === 0 ?
                        //   "w-96 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4 level-input disabled" + addressError :
                        "w-96 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4 level-input" +
                        addressError
                      }
                    />
                    <span className="mx-2">/</span>
                  </div>
                  <div className="1-1/4">
                    <p>Lane</p>
                    <input
                      value={lane}
                      onChange={(e) => setLane(e.target.value)}
                      placeholder="Lane number"
                      type="number"
                      id="lane"
                      name="lane"
                      min="0"
                      className={
                        "w-96 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4 level-input"
                      }
                    />
                  </div>
                </div>
                <div>
                  <p>Link</p>
                  <input
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                    placeholder="Link"
                    type="text"
                    id="name"
                    name="name"
                    className="w-80 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
                  />
                </div>
              </div>
              <div className="top-inputs">
                <div className="mr-4">
                  <p>Maturity</p>
                  <div className="form-maturity-container">
                    {maturityActive ? (
                      <>
                        <div className="h-11 w-72 shadow rounded py-2 px-3 form-maturity">
                          <button
                            className="form-activity__button-close"
                            onClick={() => {
                              setMaturityActive(false);
                            }}
                          >
                            <span>Close</span>
                            <AiOutlineClose />
                          </button>
                          <select
                            value={capabilityMaturityName}
                            onChange={(e) =>
                              setCapabilityMaturityName(e.target.value)
                            }
                            className="bg-white h-11 shadow rounded py-2 px-3 mr-4"
                            name="maturityLevel"
                            id="maturityLevel"
                          >
                            {maturity.length > 0 &&
                              maturity.map((item) => {
                                return (
                                  <option value={item.name} key={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })}
                          </select>
                          <input
                            value={capabilityMaturityDate}
                            onChange={(e) =>
                              setCapabilityMaturityDate(e.target.value)
                            }
                            type="date"
                            id="end-date"
                            name="end-date"
                            className="w-40 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 mb-8 mr-4 leading-8 transition-colors duration-200 ease-in-out"
                          />
                          <button
                            className="text-white bg-second-color border-0 py-2 mt-2 px-12 focus:outline-none rounded text-lg"
                            onClick={() =>
                              capabilityMaturityAddHandler(
                                capabilityMaturityName,
                                capabilityMaturityDate
                              )
                            }
                          >
                            Add
                          </button>
                          {capabilityMaturitiesArray.length > 0 &&
                            capabilityMaturitiesArray.map((item) => {
                              return (
                                <div
                                  className="flex items-center justify-between"
                                  key={item.id}
                                >
                                  <p className="mr-4">
                                    {item.maturity_level_dict.name}
                                  </p>
                                  <div className="flex">
                                    <input
                                      value={item.change_date}
                                      onChange={(e) =>
                                        capabilityMaturityDateChangeHandler(
                                          item,
                                          e.target.value
                                        )
                                      }
                                      type="date"
                                      id="maturity-date"
                                      name="maturity-date"
                                      className="w-40 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 mr-4 leading-8 transition-colors duration-200 ease-in-out"
                                    />
                                    <button
                                      onClick={() =>
                                        capabilityMaturityDeleteHandler(item)
                                      }
                                    >
                                      X
                                    </button>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </>
                    ) : (
                      <button
                        className="form-activity__button-open"
                        onClick={() => {
                          setMaturityActive(true);
                          maturity.length > 0 &&
                            setCapabilityMaturityName(maturity[0].name);
                        }}
                      >
                        Open
                      </button>
                    )}
                  </div>
                </div>
                <div className="top-inputs__container ">
                  <p>Note</p>
                  <input
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Note"
                    type="text"
                    id="description"
                    name="description"
                    className="w-80 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
                  />
                </div>
              </div>
              <div className="w-3/4 flex justify-between align-middle">
                <button
                  onClick={() => {
                    setAddChosen(false);
                  }}
                  className="text-white bg-red-500 border-0 py-2 mt-6 px-12 focus:outline-none hover:bg-red-600 rounded text-lg"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    addData();
                  }}
                  className="text-white bg-second-color border-0 py-2 mt-6 px-12 focus:outline-none rounded text-lg"
                >
                  Add Capability
                </button>
              </div>
            </div>
          )}
          {updateChosen && (
            <div>
              <div className="top-inputs">
                <div>
                  <p>Name</p>
                  <input
                    value={updateName}
                    onChange={(e) => setUpdateName(e.target.value)}
                    placeholder="Name"
                    type="text"
                    id="name"
                    name="name"
                    autoFocus
                    className={
                      errorField === "name"
                        ? "w-96 mr-4 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out form-error"
                        : "w-96 mr-4 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    }
                  />
                </div>
                <div>
                  <p>Property</p>
                  <select
                    value={updateProperty}
                    onChange={(e) => setUpdateProperty(e.target.value)}
                    className={
                      updateFirstLevel === "0" || updateSecondLevel === "0"
                        ? "bg-white h-11 w-72 shadow rounded py-2 px-3 disabled"
                        : "bg-white h-11 w-72 shadow rounded py-2 px-3"
                    }
                    name="properties"
                    id="properties"
                  >
                    {capabilityType.length > 0 &&
                      capabilityType.map((item) => {
                        return (
                          <option value={item} key={item}>
                            {item}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="1-1/4 ml-4">
                  <p>Set</p>
                  <select
                    value={updateCapabilitySet}
                    onChange={(e) => setUpdateCapabilitySet(e.target.value)}
                    className="bg-white h-11 shadow rounded py-2 px-3"
                    name="update-set"
                    id="update-set"
                  >
                    {set.length > 0 &&
                      set.map((item) => {
                        return (
                          <option value={item.name} key={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="top-inputs">
                <div className="1-1/4">
                  <p>Start Date</p>
                  <input
                    value={updateStartDate}
                    onChange={(e) => setUpdateStartDate(e.target.value)}
                    type="date"
                    id="start-date"
                    name="start-date"
                    className="w-48 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
                <div className="1-1/4">
                  <p>End Date</p>
                  <input
                    value={updateEndDate}
                    onChange={(e) => setUpdateEndDate(e.target.value)}
                    type="date"
                    id="end-date"
                    name="end-date"
                    className="w-48 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
                <div className="level-inputs">
                  <div className="1-1/4">
                    <p>Layout</p>
                    <select
                      value={updateLayout}
                      onChange={(e) => setUpdateLayout(e.target.value)}
                      className="bg-white h-11 shadow rounded py-2 px-3"
                      name="layout"
                      id="layout"
                    >
                      <option value="vertical">vertical</option>
                      <option value="horizontal">horizontal</option>
                      <option value="backlog">backlog</option>
                    </select>
                    <span className="mx-2">/</span>
                  </div>
                  <div className="1-1/4">
                    <p>L1</p>
                    <input
                      value={updateFirstLevel}
                      onChange={(e) => updateFirstLevelChangeHandler(e)}
                      placeholder="L1"
                      type="number"
                      id="name"
                      name="name"
                      min="0"
                      className={
                        "w-96 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4 level-input" +
                        addressError
                      }
                    />
                  </div>
                  <span className="period-symbol">.</span>
                  <div className="1-1/4">
                    <p>L2</p>
                    <input
                      value={updateSecondLevel}
                      onChange={(e) => updateSecondLevelChangeHandler(e)}
                      placeholder="L2"
                      type="number"
                      id="name"
                      name="name"
                      min="0"
                      className={
                        "w-96 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4 level-input" +
                        addressError
                      }
                    />
                  </div>
                  <span className="period-symbol">.</span>
                  <div className="1-1/4">
                    <p>L3</p>
                    <input
                      value={updateThirdLevel}
                      onChange={(e) => setUpdateThirdLevel(e.target.value)}
                      placeholder="L3"
                      type="number"
                      id="name"
                      name="name"
                      min="0"
                      className={
                        "w-96 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4 level-input" +
                        addressError
                      }
                    />
                    <span className="mx-2">/</span>
                  </div>
                  <div className="1-1/4">
                    <p>Lane</p>
                    <input
                      value={updateLane}
                      onChange={(e) => setUpdateLane(e.target.value)}
                      placeholder="Lane number"
                      type="number"
                      id="lane"
                      name="lane"
                      min="0"
                      className={
                        "w-96 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4 level-input"
                      }
                    />
                  </div>
                </div>
                <div>
                  <p>Link</p>
                  <input
                    value={updateLink}
                    onChange={(e) => setUpdateLink(e.target.value)}
                    placeholder="Link"
                    type="text"
                    id="name"
                    name="name"
                    className="w-80 mr-4 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
              </div>
              <div className="top-inputs">
                <div className="mr-4">
                  <p>Maturity</p>
                  <div className="form-maturity-container">
                    {maturityActive ? (
                      <>
                        <div className="h-11 w-72 shadow rounded py-2 px-3 form-maturity">
                          <button
                            className="form-activity__button-close"
                            onClick={() => {
                              setMaturityActive(false);
                            }}
                          >
                            <span>Close</span>
                            <AiOutlineClose />
                          </button>
                          <select
                            value={updateCapabilityMaturityName}
                            onChange={(e) =>
                              setUpdateCapabilityMaturityName(e.target.value)
                            }
                            className="bg-white h-11 shadow rounded py-2 px-3 mr-4"
                            name="maturityLevel"
                            id="maturityLevel"
                          >
                            {maturity.length > 0 &&
                              maturity.map((item) => {
                                return (
                                  <option value={item.name} key={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })}
                          </select>
                          <input
                            value={updateCapabilityMaturityDate}
                            onChange={(e) =>
                              setUpdateCapabilityMaturityDate(e.target.value)
                            }
                            type="date"
                            id="maturity-date"
                            name="maturity-date"
                            className="w-40 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 mb-8 mr-4 leading-8 transition-colors duration-200 ease-in-out"
                          />
                          <button
                            className="text-white bg-second-color border-0 py-2 mt-2 px-12 focus:outline-none rounded text-lg"
                            onClick={() =>
                              capabilityMaturityAddHandler(
                                updateCapabilityMaturityName,
                                updateCapabilityMaturityDate
                              )
                            }
                          >
                            Add
                          </button>
                          {capabilityMaturitiesArray.length > 0 &&
                            capabilityMaturitiesArray.map((item) => {
                              return (
                                <div
                                  key={item.alt_id}
                                  className="flex items-center justify-between"
                                >
                                  <p className="mr-4">
                                    {item.maturity_level_dict.name}
                                  </p>
                                  <div className="flex">
                                    <input
                                      value={item.change_date}
                                      onChange={(e) =>
                                        capabilityMaturityDateChangeHandler(
                                          item,
                                          e.target.value
                                        )
                                      }
                                      type="date"
                                      id="end-date"
                                      name="end-date"
                                      className="w-40 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 mr-4 leading-8 transition-colors duration-200 ease-in-out"
                                    />
                                    <button
                                      onClick={() =>
                                        capabilityMaturityDeleteHandler(item)
                                      }
                                    >
                                      X
                                    </button>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </>
                    ) : (
                      <button
                        className="form-activity__button-open"
                        onClick={() => {
                          setMaturityActive(true);
                          maturity.length > 0 &&
                            setUpdateCapabilityMaturityName(maturity[0].name);
                        }}
                      >
                        Open
                      </button>
                    )}
                  </div>
                </div>
                <div>
                  <p>Note</p>
                  <input
                    value={updateDescription}
                    onChange={(e) => setUpdateDescription(e.target.value)}
                    placeholder="Note"
                    type="text"
                    id="description"
                    name="description"
                    className="w-80 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
                  />
                </div>
              </div>
              <div className="w-3/4 flex justify-between align-middle">
                <button
                  onClick={() => {
                    setUpdateChosen(false);
                    setCapabilityMaturitiesArray([]);
                  }}
                  className="text-white bg-red-500 border-0 py-2 mt-6 px-12 focus:outline-none hover:bg-red-600 rounded text-lg"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    updateData();
                  }}
                  className="text-white bg-second-color border-0 py-2 mt-6 px-12 focus:outline-none rounded text-lg"
                >
                  Update Capability
                </button>
              </div>
            </div>
          )}
          <div className="tables">
            <div className="top-table">
              <div className="plus-minus">
                <IconButton
                  className="icon-img"
                  onClick={() => {
                    setUpdateChosen(false);
                    setAddChosen(!addChosen);
                  }}
                  notRestricted={Capabilities_Add}
                  stateActive={addChosen}
                  tooltip="Add new"
                >
                  <AiOutlineFileAdd />
                </IconButton>
                <IconButton
                  className="icon-img"
                  onClick={selectAllHandler}
                  notRestricted={Capabilities_Select_all}
                  stateActive={selectAll}
                  tooltip="Select everything on the page"
                >
                  <BiSelectMultiple />
                </IconButton>
                <IconButton
                  className="icon-img"
                  onClick={deleteCapabilitiesArrayHandler}
                  notRestricted={Capabilities_Delete_all && selected.length}
                  stateActive={showDelete}
                >
                  <MdOutlineDeleteOutline />
                </IconButton>
              </div>
              <div className="plus-minus">
                <IconButton
                  className="icon-img"
                  onClick={() => setNewSetActive(true)}
                  notRestricted={Capabilities_Add_set}
                  stateActive={newSetActive}
                  tooltip="Add new set"
                >
                  <HiOutlineFolderAdd />
                </IconButton>
                <IconButton
                  className="icon-img"
                  onClick={editSetHandler}
                  notRestricted={Capabilities_Add_set}
                  stateActive={editSetActive}
                  tooltip="Edit current set"
                >
                  <AiOutlineEdit />
                </IconButton>
                <IconButton
                  className="icon-img"
                  onClick={deleteSetHandler}
                  notRestricted={Capabilities_Delete_set}
                  stateActive={showDelete}
                  tooltip="Delete current set"
                >
                  <MdOutlineDeleteOutline />
                </IconButton>
                <SelectSet
                  activeSet={activeSet}
                  onChange={(e) => {
                    setActiveSet(e.target.value);
                    setCapabilitySet(e.target.value);
                  }}
                  notRestricted={Capabilities_Select_set}
                  set={set}
                  tooltip={"Select active set"}
                />
              </div>
              <div
                className={
                  selected.length && Capabilities_Select_set
                    ? "plus-minus"
                    : "plus-minus disabled"
                }
              >
                <SelectSet
                  activeSet={capabilityMoveToSet}
                  onChange={(e) => setCapabilityMoveToSet(e.target.value)}
                  notRestricted={Capabilities_Select_set}
                  set={set}
                  tooltip={"Select a set to move capabilities"}
                />
                <IconButton
                  className="icon-img"
                  onClick={() => moveCapabilitiesToSet()}
                  notRestricted={Capabilities_Move_capability_to_another_set}
                  tooltip={"Move capabilities to another set"}
                >
                  <TbArrowMoveRight />
                </IconButton>
              </div>
              <div className="plus-minus">
                <IconButton
                  className="icon-img"
                  onClick={deleteAllCapabilities}
                  notRestricted={
                    Capabilities_Delete_set && capability && capability.length
                  }
                  stateActive={showDelete}
                  tooltip={"Delete all table data"}
                >
                  <MdDeleteForever />
                </IconButton>
              </div>

              <div className="flex flex-row mb-5 mt-6 justify-end items-center px-10">
                <div
                  className={
                    current !== 0
                      ? "cursor-pointer bg-gray-100 px-2 py-1.5 rounded mr-2"
                      : "cursor-pointer bg-gray-100 px-2 py-1.5 rounded mr-2 disabled"
                  }
                  onClick={() => {
                    if (current > 0) {
                      setCurrent(current - 1);
                    }
                  }}
                >
                  <img src={Left} alt="left" />
                </div>

                <div
                  className={
                    current < paginationLevels - 2
                      ? "cursor-pointer bg-gray-100 px-2 py-1.5 rounded"
                      : "cursor-pointer bg-gray-100 px-2 py-1.5 rounded disabled"
                  }
                  onClick={() => {
                    if (current < paginationLevels - 2) {
                      setCurrent(current + 1);
                    }
                  }}
                >
                  <img src={Right} alt="right" />
                </div>
              </div>
            </div>
            <div className="table-div">
              {loading || !fetched ? (
                <SkeletonTable />
              ) : (
                <CapabilitiesTable
                  data={capabilityBySet}
                  deletedChosen={false}
                  setUpdateName={setUpdateName}
                  setUpdateLink={setUpdateLink}
                  setUpdateProperty={setUpdateProperty}
                  setUpdateStartDate={setUpdateStartDate}
                  setUpdateEndDate={setUpdateEndDate}
                  setUpdateId={setUpdateId}
                  setSuccess={setSuccess}
                  setError={setError}
                  getData={getCapability}
                  setUpdateChosen={setUpdateChosen}
                  setAddChosen={setAddChosen}
                  setUpdateFirstLevel={setUpdateFirstLevel}
                  setUpdateSecondLevel={setUpdateSecondLevel}
                  setUpdateThirdLevel={setUpdateThirdLevel}
                  setUpdateLane={setUpdateLane}
                  setUpdateLayout={setUpdateLayout}
                  setUpdateSetId={setUpdateCapabilitySet}
                  setUpdateDescription={setUpdateDescription}
                  current={current}
                  set={set}
                  setPaginationLevels={setPaginationLevels}
                  setFirstLevel={levelChildHandler}
                  setSecondLevel={setSecondLevel}
                  setThirdLevel={setThirdLevel}
                  setLane={setLane}
                  setLayout={setLayout}
                  setMaturity={setCapabilityMaturitiesArray}
                  setSet={setCapabilitySet}
                  setSelected={setSelectedItems}
                  selectAll={selectAll}
                  selectedItems={selected}
                  access={access.Buttons}
                  capabilities={capability}
                />
              )}
            </div>
          </div>
        </>
      )}

      {active === "Visual Editor" && (
        <NewVisualEditor
          loading={loading}
          fetched={fetched}
          dataArray={capability}
          organizationName={organizationName}
          set={set}
          setError={setError}
          activeSet={activeSet}
          setActiveSet={setActiveSet}
          importCapability={importCapability}
          deleteCapabilities={deleteCapabilities}
          access={access.Buttons}
        />
      )}
      {active === "Report" && (
        <NewBCM
          dataArray={capability}
          organizationName={organizationName}
          set={set}
          setError={setError}
          activeSet={activeSet}
          setActiveSet={setActiveSet}
          setUpdateName={setUpdateName}
          setActive={setActive}
          setUpdateChosen={setUpdateChosen}
          setUpdateLink={setUpdateLink}
          setUpdateProperty={setUpdateProperty}
          setUpdateStartDate={setUpdateStartDate}
          setUpdateEndDate={setUpdateEndDate}
          setUpdateId={setUpdateId}
          setUpdateFirstLevel={setUpdateFirstLevel}
          setUpdateSecondLevel={setUpdateSecondLevel}
          setUpdateThirdLevel={setUpdateThirdLevel}
          setUpdateSetId={setUpdateCapabilitySet}
          setMaturity={setCapabilityMaturitiesArray}
          access={access.Buttons}
        />
      )}
      {active === "Import" && (
        <CapabilityImport
          activeSet={activeSet}
          setActiveSet={setActiveSet}
          access={access.Buttons}
        />
      )}
      {active === "Export" && (
        <CapabilityExport
          capability={capability}
          capabilityBySetForExport={capabilityBySetForExport}
          setSuccess={setSuccess}
          setError={setError}
          activeSet={activeSet}
          setActiveSet={setActiveSet}
          set={set}
          access={access.Buttons}
        />
      )}
    </>
  );
};

export default CapabilitiesPage;
