import React from 'react';
import { BsTrash3 } from 'react-icons/bs'
import { FiEdit } from 'react-icons/fi'
import IconButton from '../ui/buttons/IconButton';


const BusinessRelationsItem = ({
        item,
        showDelete, 
        deleteId,
        setDeleteItem, 
        setShowDelete, 
        setDeleteId, 
        setUpdateChosen, 
        setAddChosen, 
        setUpdateId,
        setUpdateStartDate, 
        setUpdateEndDate, 
        setUpdateRelationships,
        setUpdateBusinesses,
        setUpdateApplications,
        relationship,
        application,
        business,
        access
}) => {
    const { 
        Processes_App_processes_Edit,
        Processes_App_processes_Delete, 
    } = access

    const handleDoubleClick = event => {
        if (!Processes_App_processes_Edit) return
        if (event.detail === 2) {
            setUpdateChosen(true)
            setAddChosen(false)
            setUpdateId(item.id)
            setUpdateStartDate(item.start_date)
            setUpdateEndDate(item.end_date)
            setUpdateApplications(item.application.name)
            setUpdateBusinesses(item.bp_list.name)
            setUpdateRelationships(item.relationship_dict.name)
        }
    }

    return (
        <tr
            key={item.id}
            className="capability-item"
            style={{
                backgroundColor:
                showDelete && deleteId === item.id && "#A3AAC4",
            }}
            onClick={(e) => {handleDoubleClick(e)}}
            >
            <td className="px-4 py-3">{item.id}</td>
            <td className="px-4 py-3">{item.bp_list.name}</td>
            <td className="px-4 py-3">{item.application.name}</td>
            <td className="px-4 py-3">{item.start_date}</td>
            <td className="px-4 py-3">{item.end_date}</td>
            <td className="px-4 py-3">{item.relationship_dict.name}</td>
            <td className="px-4 py-3 flex link"> 
                <div className='capability-controls'>
                    <IconButton
						className="ml-5 capability-controls__button"
						onClick={() => {
                            setShowDelete(!showDelete)
                            setDeleteId(item.id)
                            setDeleteItem(item)
                        }}
						notRestricted={Processes_App_processes_Delete}
					>
						<BsTrash3 alt="delete" />
					</IconButton>
					<IconButton
						className="ml-5 capability-controls__button"
						onClick={() => {
                            setUpdateChosen(true)
                            setAddChosen(false)
                            setUpdateId(item.id)
                            setUpdateStartDate(item.start_date)
                            setUpdateEndDate(item.end_date)
                            setUpdateApplications(item.application.name)
                            setUpdateBusinesses(item.bp_list.name)
                            setUpdateRelationships(item.relationship_dict.name)
                        }}
						notRestricted={Processes_App_processes_Edit}
					>
						<FiEdit alt="edit" />
					</IconButton>
                </div>
            </td>
        </tr>
    );
};

export default BusinessRelationsItem;