import React, { useEffect, useRef, useState } from 'react'
import * as d3 from 'd3'


const TreeChart = ({ dataObj, rawData, setRawData, updatePresentWithoutPast, addPast, longestVerticalType, setIsSaved, setRequireSave, goalsToDelete, setGoalsToDelete, setAddingNewNode, setEditingNode, newWarningMessage }) => {
	const [screenWidth, setScreenWidth] = useState(window.innerWidth)
	const width = screenWidth
	// const height = 400;
	const titleFontSize = 14 // screenWidth * .01
	const nodeSize = { y: screenWidth * 0.11, x: 90}
	const svgRef = useRef()
	const dragStartPosRef = useRef({ x: 0, y: 0, buttonHovered: false })
	const scrollPosition = useRef({ positionY: 0, positionX: 0 })
	const sensitivityGap = 20
	const MAX_LEVEL = 7

	useEffect(() => {
		const handleScroll = () => {
			scrollPosition.current = { positionY: window.scrollY, positionX: 0 }
		}
	
		window.addEventListener('scroll', handleScroll)
		window.addEventListener('resize', setScreenWidth(window.innerWidth))
		
		return () => {
			window.removeEventListener('resize', setScreenWidth(window.innerWidth))
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])


	const getNodeColor = (node) => {
		const defaultColor = '#a8a8a8'
		const { level } = node
		const colors = {
			1: '#fee0e0',
            2: '#fee0e0',
            3: '#ffffcb',
            4: '#ffffcb',
            5: '#c2f0ff',
            6: '#c2f0ff',
			7: '#c2f0ff'
		}

		return colors[level] ? colors[level] : defaultColor
	}
	
	const transferNodes = (newParentId, node, rawDataCopy) => {
		function traverse(node, updatedNodes, level = 0) {
			node.data.level = level
			updatedNodes.push(node)

			if (node.children) {
				node.children.forEach(child => traverse(child, updatedNodes, level + 1))
			}

			return updatedNodes
		}
		node.data.parent_id = newParentId
		
		const newParent = rawData.find((item) => item.id === newParentId)
		const { level } = newParent

		const updatedNodes = []
		traverse(node, updatedNodes, level + 1)
		const newData = rawData.map(item1 => {
			let item2 = updatedNodes.find(item2 => item2.id === item1.id)
		  
			if (item2) {
			  return { ...item1, level: item2.level }
			}
		  
			return item1
		})
		addPast(rawDataCopy)
		updatePresentWithoutPast(newData)
	}

	const checkHover = (draggedNode, mouseX, mouseY) => {
		const SPY = scrollPosition.current.positionY
		const rects = document.querySelectorAll("rect")		
		for (const node of rects) {
			const bbox = node.getBoundingClientRect()
			if (
				mouseX >= bbox.left - (sensitivityGap * 2) &&
				mouseX <= bbox.right + (sensitivityGap * 2) &&
				(mouseY - SPY) >= bbox.top - sensitivityGap &&
				(mouseY - SPY) <= bbox.bottom + sensitivityGap 
			) {
				if (parseInt(node?.classList[0].split('-')[1]) === draggedNode?.data?.id) return null
				d3.select(node).raise().attr("stroke", "lightblue")

				return parseInt(node?.classList[0].split('-')[1])
			}

			for (const node of rects) {
				d3.select(node).attr("stroke", "#ccc")
			}
		}

		return null
	}
		
	const addChild = (event, d) => {	
		const { id } = d?.data
		const { level } = d?.data
		const generatedId = Math.floor(Math.random() * (1000000000 - 100000000 + 1)) + 100000000
		if (event.shiftKey) {
			const newNode = {
				id: generatedId,
				name: 'new goal',
				description: '',
				tag: '',
				parent_id: id,
				level: level + 1,
				link: String(generatedId)
			}
			const updatedRawData = [...rawData, newNode]
			setRawData(updatedRawData)
			return
			
		}

		setAddingNewNode({id: generatedId, name: 'New goal', parent_id: id, level: level + 1, link: String(generatedId)})
	}

	const removeNode = (event, d) => {
		function gatherAllIds(obj) {
			let ids = []
		  
			if (obj.children && obj.children.length > 0) {
			  obj.children.forEach((child) => {
				ids = ids.concat(gatherAllIds(child))
			  })
			}
		  	ids.push(obj.data.id)
		  
			return ids
		}
		  
		const { id } = d?.data
		const nestingNodes = gatherAllIds(d)
		setGoalsToDelete([...goalsToDelete, ...nestingNodes])
		const filteredRawData = rawData.filter(i => i.id !== id)
		
		setRawData(filteredRawData)
		setIsSaved(false)
	}

	const getClassWithId = (node) => {
		return `node-${node.data.id}`
	}

	function dragstarted(event, d) {	
		d3.selectAll(".tooltip").style("opacity", 0) // hide all tooltips
		const rects = document.querySelectorAll("rect")		
		for (const node of rects) {
			const controls = d3.select(node.parentNode).selectAll('circle')
			for (const control of controls) {
				d3.select(control)
					.transition()
					.attr('r', 0)
			}
			d3.select(node).attr("stroke", "#ccc")
		}
		const rect = d3.select(this).select('rect')
		const controls = d3.select(this).selectAll('circle')
		for (const control of controls) {
			d3.select(control)
				.transition()
				.attr('r', 5)
		}
		rect.attr('stroke', 'orange')
		
		if (!d.data.level) return

		const { x, y } = d   
		dragStartPosRef.current = { x, y }

		d3.select(this.parentNode)
			.insert('rect')
			.attr('x', x)
			.attr('y', y)
			.attr('width', 80)
			.attr('height', 30)
			.attr('opacity', 1)
			.attr('fill', '#ccc')
			.attr('id', 'dragRect')
			.attr('class', 'shadow-rect')
	}
		
	function dragged(event, d) {
		if (!d.data.level) return
		
		d3.selectAll(".tooltip").style("opacity", 0); // hide all tooltips
		d3.select(this).attr("transform", `translate(${d.x = event.x},${d.y = event.y})`)
		const [mouseX, mouseY] = d3.pointer(event)
		checkHover(d, mouseX, mouseY)
	}
	
	function dragended(event, d) {
		const findLowestLevel= (node) => {
			if (!node?.children || node.children.length === 0) {
				return node.level
			}

			let lowestLevel = 0
 
			for (const child of node.children) {
				const childLevel = findLowestLevel(child)
				lowestLevel = Math.max(lowestLevel, childLevel)
			}

			return lowestLevel
		}

		const returnNodeAtStartPosition = () => {
			const { x, y } = dragStartPosRef.current
			d3.select(this).attr("transform", `translate(${d.x = x},${d.y = y})`)
		}

		const levelOfDraggedNode = d.data.level
		if (!levelOfDraggedNode) return

		d3.select('#dragRect').remove() // remove the shadow rect
		d3.select(this).raise().attr("fill", "black")

		const [mouseX, mouseY] = d3.pointer(event)
		const hoveredNodeId = checkHover(d, mouseX, mouseY)
		const hoveredNodeLevel = rawData.find(node => node.id === hoveredNodeId)?.level
		const lowestLevel = findLowestLevel(d.data)
		if (!hoveredNodeId) {
			returnNodeAtStartPosition()
			return 
		}
		if (hoveredNodeLevel >= MAX_LEVEL) {
			returnNodeAtStartPosition()
			newWarningMessage({message: 'Not a valid level'})
            return
        }
		if (lowestLevel > MAX_LEVEL) { // ?
			returnNodeAtStartPosition()
			newWarningMessage({message: 'Not a valid level'})
            return
        }

		if (((lowestLevel - levelOfDraggedNode) + hoveredNodeLevel) >= MAX_LEVEL) {
			returnNodeAtStartPosition()
			newWarningMessage({message: 'Not a valid level'})
			return 
		}
		const rawDataCopy = JSON.parse(JSON.stringify(rawData))
		transferNodes(hoveredNodeId, d, rawDataCopy)
		setIsSaved(false)
	}

	function handleClick(event, d) {
		if (event.detail === 2) {
			d3.selectAll(".tooltip").style("opacity", 0)
			setEditingNode(d?.data)
		}
	}

	const renderTree = (dataObj) => {
		if (!dataObj) return

		// Compute the tree height; this approach will allow the height of the
		// SVG to scale according to the breadth (width) of the tree layout.
		const root = d3.hierarchy(dataObj)
		const dx = nodeSize.x
		const dy = nodeSize.y  // Increase the distance between nodes along the y-axis
	
		// Create a tree layout.
		const tree = d3.tree().nodeSize([dx, dy])
		tree(root)

		// Compute the extent of the tree. Note that x and y are swapped here
		// because in the tree layout, x is the breadth, but when displayed, the tree extends right rather than down.
		let x0 = Infinity
		let x1 = -x0
		root.each(d => {
			if (d.x > x1) x1 = d.x
			if (d.x < x0) x0 = d.x
		})
	
		// Compute the adjusted height of the tree.
		const height = x1 - x0 + dx * 2
		const switchCoors = (node) => {
			const temp = node.x
			node.x = node.y
			node.y = temp
		}

		const svg = d3.select(svgRef.current)
			.attr("width", width)
			.attr("height", height)
			.attr("viewBox", [0, x0 - dx, width, height])
			.attr("style", "max-width: 100%; height: auto; font: 10px sans-serif;")

		svg.selectAll('*').remove()

		const link = svg.append("g")
			.attr("fill", "none")
			.attr("stroke", "#555")
			.attr("stroke-opacity", 0.4)
			.attr("stroke-width", 1.5)
			.attr("transform", `translate(${(10)},${-5})`)
		.selectAll()
			.data(root.links())
			.join("path")
			.attr("d", d3.linkHorizontal()
				.x(d => d.y)
				.y(d => d.x))
			.transition()	
			.duration(1000) 
			
		const tooltip = d3.select("body").append("div")
			.attr('class', 'tooltip')
			.style("opacity", 0)
			.style('position', 'absolute')
			.style('padding', '10px')
			.style('background', 'rgba(0, 0, 0, 0.8)')
			.style('border', '1px solid black')
			.style('border-radius', '5px')
			.style('color','white')
			.style('font-size', '12px')

		const node = svg.append("g")
			// .attr("stroke-linejoin", "round")
			// .attr("stroke-width", 2)
			.attr("transform", `translate(${(10)},${0})`)
			.selectAll()
			.data(root.descendants())
			.join("g")
			.attr("transform", d => `translate(${(d.y)},${d.x = d.x - 20})`)
			.attr("y", d => switchCoors(d))
			.call(d3.drag()
				.on("start", dragstarted)
				.on("drag", dragged)
				.on("end", dragended)
			)
			
		node.append("rect")
			.attr("stroke-width", d => d.children ? "3px" : "2px")
			.attr("rx", 2)
			.attr("ry", 2)
			.style("z-index", 10)
			.attr("stroke", "#bbb")
			.style("cursor", "pointer")
			.attr("fill", d => getNodeColor(d.data))
			.attr("width", 80)
			.attr("height", 30)
			.attr("class", getClassWithId)
			.on("mouseover", handleMouseOverNode)
			.on("mouseout", handleMouseOutNode)

		node.append("text")
			.attr("dy", "-.3em")
			.style("fill", "#333")
			.style("font-family", "Montserrat")
			.style("font-weight", "600")
			.style("font-size", titleFontSize + "px")
			.attr("x", 0)
			.attr("text-anchor", "start")
			.text(wrapNameInTheNode)
			.on("mouseover", handleHoverOn)
			.on("mouseout", handleHoverOff)
			.on("click", handleClick)
			.attr("stroke", "none")
		// delete/add node
		
		node.append("circle")
			.attr("cx", 90)
			.attr("cy", 25)
			.attr("r", 0)
			.attr("fill", "red")
			.attr("cursor", "pointer")
			.on("mouseover", handleMouseOverCircle)
			.on("mouseout", handleMouseOutCircle)
			.on("click", removeNode)

		node.append("text")
			.attr("x", 90)
			.attr("y", 24)
			.attr("text-anchor", "middle")
			.attr("dy", "0.35em")
			.attr("fill", "white")
			.attr("pointer-events", "none")
			.text("-")
			.attr('class', 'minus-sign-node')
		
		node.append("circle")
			.attr("cx", 90)
			.attr("cy", 5.5)
			.attr("r", 0)
			.attr("fill", "lightgreen")
			.attr("cursor", "pointer")
			.on("mouseover", handleMouseOverCircle)
			.on("mouseout", handleMouseOutCircle)
			.on("click", addChild)

		node.append("text")
			.attr("x", 90)
			.attr("y", 5.5)
			.attr("text-anchor", "middle")
			.style("pointer-events", "none")
			.attr("dy", "0.35em")
			.attr("fill", "white")
			.text("+")
			.attr('class', 'plus-sign-node')

		function handleMouseOverCircle() {
			// d3.select(".plus-sign-node")
			d3.select(this)
			.transition()
			.attr("r", 10)
		}

		function handleMouseOutCircle() {
			d3.select(this)
			.transition()
			.attr("r", 5)
		}
		
		function handleMouseOverNode (event, d) {
			if (d.data.description || d.data.tag) {
				tooltip.transition()
					.duration(100)

				const rect = this.getBoundingClientRect();
				const x = rect.left + window.scrollX + rect.width / 2
				const y = rect.top + window.scrollY + rect.height / 2

				const name = d.data.name ? d.data.name.match(/.{1,50}/g).join("<br>") : "" // insert line break after every 50 characters
				const tag = d.data.tag ? d.data.tag.match(/.{1,50}/g).join("<br>") : "" // insert line break after every 50 characters
				const description = d.data.description ? d.data.description.match(/.{1,50}/g).join("<br>") : "" // insert line break after every 50 characters
			
				tooltip.style("left", x + "px")
				.style("top", y + 20 + "px")
				.style("opacity", .9)
				.html(`<strong>Name:</strong> ${name}<br><strong>Tag:</strong> ${tag}<br><strong>Note:</strong> ${description}`)
			}		
		}

		function handleMouseOutNode () {
			tooltip.transition()
				.duration(200)
				.style("opacity", 0)
		}

		function handleHoverOn(event, d) {
			if (!d3.select(this).text().includes('...')) return

			const title = d3.select(this)
			.transition()
			.duration(100)
			.style("opacity", .1)
			title
			.transition()
			.duration(100)
			.style("opacity", 1)
			.text(d.data.name)
		}

		function handleHoverOff(event, d) {
			if (d3.select(this).text().length < 10) return

			const title = d3.select(this)
			.transition()
			.duration(100)
			.style("opacity", .1)
			title
			.transition()
			.duration(100)
			.style("opacity", 1)
			.text(wrapNameInTheNode(d))
		}

		function wrapNameInTheNode(d) {
			function shortenWord(word) {
				const firstPart = word.substring(0, 5)
				const lastPart = word.substring(word.length - 4)
				const shortenedWord = firstPart + '...' + lastPart

				return shortenedWord
			}
			const { name } = d.data
			if (name.length > 10) {
				return shortenWord(name)
			}

			return name
		}
	}

	useEffect(() => {
		renderTree(dataObj)
	}, [dataObj, rawData, nodeSize.x, nodeSize.y, screenWidth])
	
	return <svg ref={svgRef} />
}

export default TreeChart