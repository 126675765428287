import React from 'react'
import Tab from '../Tab'

const ActivitiesManagement = ({ active, setActive, access}) => {
    const { Activities, Activities_Report, Activities_Import, Activities_Export } = access
    return (
        <div className="tabs-container">
            <div className="flex row mt-5 justify-start items-start">
                <Tab
                    section={"Activities"}
                    active={active}
                    setActive={setActive}
                    isDisabled={!Activities}
                />
                <Tab
                    section={"Report"}
                    active={active}
                    setActive={setActive}
                    isDisabled={!Activities_Report}
                />
                <Tab
                    section={"Import"}
                    active={active}
                    setActive={setActive}
                    // isDisabled={true}
                    isDisabled={!Activities_Import}
                />
                <Tab
                    section={"Export"}
                    active={active}
                    setActive={setActive}
                    // isDisabled={true}
                    isDisabled={!Activities_Export}
                />
            </div>
        </div>
    )
}

export default ActivitiesManagement