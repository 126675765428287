import React from 'react'
import Tab from '../Tab'

const SettingsManagement = ({ active, setActive, access }) => {
    const { Settings_Dictionaries, Settings_Organization, Settings_Account, Settings_Security } = access
    return (
        <div className="tabs-container">
            <div className="flex row mt-5 justify-start items-start">
                <Tab
                    section={"Dictionaries"}
                    active={active}
                    setActive={setActive}
                    isDisabled={!Settings_Dictionaries}
                />
                <Tab
                    section={"Organization"}
                    active={active}
                    setActive={setActive}
                    isDisabled={!Settings_Organization}
                />
                <Tab
                    section={"Organization Structure"}
                    active={active}
                    setActive={setActive}
                    isDisabled={!Settings_Organization}
                />
                <Tab
                    section={"People"}
                    active={active}
                    setActive={setActive}
                    isDisabled={!Settings_Organization}
                />
                <Tab
                    section={"Account"}
                    active={active}
                    setActive={setActive}
                    isDisabled={!Settings_Account}
                />
            </div>
        </div>
    )
}

export default SettingsManagement;