import React, { useState, useEffect, useRef } from 'react'
import TreeChart from './TreeChart'
import IconButton from '../ui/buttons/IconButton'
import useUndoRedo from '../../hooks/useUndoRedo'
import { GoalsLegend } from './GoalsLegend'
import { FaUndoAlt, FaRedoAlt } from "react-icons/fa"
import AddNewNodeModal from '../AddNewNodeModal'


const VisualEditor = ({ dataArray, importGoal, deleteGoals, newWarningMessage }) => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
	const [isSaved, setIsSaved] = useState(true)
	const [requireSave, setRequireSave] = useState(false)
	const [goalsToDelete, setGoalsToDelete] = useState([])
	const reportsRef = useRef(null)
	const { state, firstState, lastState, undo, redo, updatePresent, updatePresentWithoutPast, addPast, resetState } = useUndoRedo(JSON.parse(JSON.stringify(dataArray)))

	const [reportsWidth, setReportsWidth] = useState(0)
	const [addingNewNode, setAddingNewNode] = useState(false)
	const [editingNode, setEditingNode] = useState(false)

	const resetLocals = () => {
		localStorage.removeItem('editing-goals')
		localStorage.removeItem('deleting-goals')
		resetState()
	}
	const formatDataToResult = (arrayData) => {
		let resultData = {}
		
		// Create a mapping of id to object for efficient lookups
		let idToObjectMap = {}
		
		// Loop through the arrayData to populate idToObjectMap
		for (let i = 0; i < arrayData.length; i++) {
			let obj = arrayData[i]
			obj.children = []
			idToObjectMap[obj.id] = obj
		}
		
		// Loop through the arrayData again to populate resultData
		for (let i = 0; i < arrayData.length; i++) {
			let obj = arrayData[i];
			
			// If the object has a parent_id, add it as a child to the corresponding parent object in resultData
			if (obj.parent_id !== 0 && idToObjectMap.hasOwnProperty(obj.parent_id)) {
				let parentObj = idToObjectMap[obj.parent_id];
				
				// Add the current object as a child to the parent object
				parentObj.children.push(obj);
				// If the object has a parent_id of 0, add it as a top-level object in resultData
			} else if (obj.parent_id === 0) {
				resultData = obj;
			}
		}
		return resultData;
	}
	
	const [preparedDataArray, setPreparedDataArray] = useState(formatDataToResult(state))
	
	useEffect(() => {
		const handleKeyDown = (event) => {
			if ((event.ctrlKey || event.metaKey) && event.code === 'KeyS') {
				event.preventDefault()
				document.getElementById('saveButton').click()
				return
			}

			if ((event.ctrlKey || event.metaKey) && event.shiftKey && event.code === 'KeyZ') {
				event.preventDefault()
				document.getElementById('redoButton').click()
				return
			}

			if ((event.ctrlKey || event.metaKey) && event.code === 'KeyZ') {
				event.preventDefault()
				document.getElementById('undoButton').click()
				return
			}
		}

		const handleResize = () => {
			setScreenWidth(window.innerWidth)
		}
		
		setReportsWidth(reportsRef.current.offsetWidth)
		resetLocals()
		localStorage.setItem('backup-goals', JSON.stringify(dataArray))
		
		window.addEventListener('resize', handleResize)

		document.addEventListener('keydown', handleKeyDown)
		
		return () => {
			document.removeEventListener('keydown', handleKeyDown);
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	useEffect(() => {
		if (firstState.length) {
			setIsSaved(false)
			return
		}

		if (lastState.length) {
			setIsSaved(true)
			return
		}
	}, [firstState, lastState])
	
	useEffect(() => {
		localStorage.setItem("editing-goals", JSON.stringify(state)) // work on this
		setPreparedDataArray(formatDataToResult(state))
	}, [state])

	useEffect(() => {
		if(requireSave) uploadData()
	}, [requireSave])

	if (dataArray.length === 0) {
		return (
			<div className="chart-notification" ref={reportsRef}>
				<p className="chart-notification__text">No data for that chart</p>
			</div>
		)
	}

	if (!dataArray.find(item => item.parent_id === 0)) {
		return (
			<div className="chart-notification" ref={reportsRef}>
				<p className="chart-notification__text">Data structured incorrectly, there should be an element with a type "Strategy"</p>
			</div>
		)
	}

	const countLevels = (node, levelCounts = {}) => {
		if (!levelCounts[node.level]) {
			levelCounts[node.level] = 0;
		}
		
		levelCounts[node.level]++
		
		if (node.children && node.children.length > 0) {
			for (const child of node.children) {
				countLevels(child, levelCounts)
			}
		}
		
		return levelCounts;
	}
	
	const levelCountsResult = countLevels(preparedDataArray, {})

	const calculateLevels = (dataObj) => {
		const dataKeys = Object.keys(dataObj)

		let level0 = 0,
			level1 = 0,
			level2 = 0,
			level3 = 0,
			level4 = 0,
			level5 = 0,
			level6 = 0,
			level7 = 0
			
		let strategy = 0,
			business = 0,
			project = 0

		for (const i of dataKeys) {
			if (i === '0') {
				strategy++
				level0 += dataObj[i]
			} else if (i === '1') {
				strategy++
				level1 += dataObj[i]
			} else if (i === '2') {
				strategy++
				level2 += dataObj[i]
			} else if (i === '3') {
				business++
				level3 += dataObj[i]
			} else if (i === '4') {
				business++
				level4 += dataObj[i]
			} else if (i === '5') {
				project++
				level5 += dataObj[i]
			} else if (i === '6') {
				project++
				level6 += dataObj[i]
			} else if (i === '7') {
				project++
				level7 += dataObj[i]
			} else {
				project++
			}
		}

		const processObject = (obj, results) => {
			if (obj.children && obj.children.length > 1) {
				// Check the first child element
				results.push({
					level: obj.children[0].level,
					count: obj.children[0].children.length,
					name: obj.children[0].name
				})
				
			
				// Check the last child element
				const lastChildIndex = obj.children.length - 1
				results.push({
					level: obj.children[lastChildIndex].level,
					count: obj.children[lastChildIndex].children.length,
					name: obj.children[lastChildIndex].name
				})
				
			
				// Recursively process each child
				for (const child of obj.children) {
					processObject(child, results)
				}
			}
	
			if (obj.children && obj.children.length === 1) {
				results.push({
					level: obj.children[0].level,
					count: obj.children[0].children.length,
					name: obj.children[0].name
				})
	
				for (const child of obj.children) {
					processObject(child, results)
				}
			}
		}
			
		const results = []
		processObject(preparedDataArray, results)

		const levelsArray = [level0, level1, level2, level3, level4, level5, level6, level7]
		const biggest = levelsArray.sort((a, b) => { return b - a })[0]
		let longestVerticalType = biggest

		const totalLength = strategy + business + project

		// combining longest levels 
		for (const i of results) {
			if (i.count > 1) longestVerticalType += i.count / 2
		}

		return {strategy, business, project, longestVerticalType, totalLength}
	
}

	const {strategy, business, project, longestVerticalType, totalLength} = calculateLevels(levelCountsResult)
	const nodeLength = 130 / totalLength

	const restoreDeleted = () => {
		const restoredData = localStorage.getItem('backup-goals')
		setIsSaved(true)
		updatePresent(JSON.parse(restoredData))
		resetLocals()
	}

	const arrangeGoals = (nodes = []) => {
		return nodes.map(node => {
			const index = nodes.findIndex(i => Number(i.link) === node.parent_id)
			if (!node.level) return node // don't replace strategy
			if (index !== -1) {
				return {
					...node,
					link: '',
					parent_id: nodes[index].id,
				}
			} else {
				return node
			}
		})
	}
	
	const uploadData = async () => {
		deleteGoals({deleteArray: goalsToDelete})
		const { payload } = await importGoal({goals: state})
		setGoalsToDelete([])

		if (!payload) {
			return
		}

		const arrangedGoals = arrangeGoals(payload?.data?.data)
		const secondPayload = await importGoal({goals: arrangedGoals})
		if (secondPayload) {
			localStorage.setItem('backup-goals', JSON.stringify(secondPayload.payload?.data?.data))
			updatePresent(secondPayload.payload?.data?.data)
		}

		if (secondPayload) {
			setIsSaved(true)
			resetLocals()
		}
	}

	const onSaveModal = (nodeData) => {
		if (nodeData.children) {
			// updating node
			const index = state.findIndex(item => item.id === nodeData.id)

			if (index !== -1) {
				const updatedData = [...state]
				updatedData[index] = { ...updatedData[index], ...nodeData }
				updatePresent(updatedData)
			}
		} else {
			const updatedRawData = [...state, nodeData]
			updatePresent(updatedRawData)
		}
		
		// setRequireSave(true)
		setAddingNewNode(null)
		setEditingNode(null)
	}
	return (
		<div className="reports" ref={reportsRef}>
			{addingNewNode && <AddNewNodeModal item={addingNewNode} title={'New goal'} onSave={onSaveModal} onCancel={() => setAddingNewNode(null)} />}
			{editingNode && <AddNewNodeModal item={editingNode} title={'Edit goal'} onSave={onSaveModal} onCancel={() => setEditingNode(null)} />}
		  	<div className="top-report">
				<div className="plus-minus w-full justify-end">
					<div className="flex flex-row mb-5 mt-6 justify-end items-center px-10">
						<IconButton
							className="text-white bg-red-500 border-0 mr-2 py-1.5 px-9 focus:outline-none hover:bg-red-600 rounded"
							onClick={restoreDeleted}
							notRestricted={firstState.length}
						>
							Restore
						</IconButton>
						<IconButton
							id={'undoButton'}
							className="cursor-pointer mr-2 text-xl"
							onClick={undo}
							notRestricted={firstState.length}
						>
							<FaUndoAlt />
						</IconButton>
						<IconButton
							id={'redoButton'}
							className="cursor-pointer mr-2 text-xl"
							onClick={redo}
							notRestricted={lastState.length}
						>
							<FaRedoAlt />
						</IconButton>
						<IconButton
							id={'saveButton'}
							className="text-white bg-second-color border-0 py-1.5 px-12 focus:outline-none rounded"
							onClick={uploadData}
							notRestricted={!isSaved}
						>
							Save
						</IconButton>

					</div>
				</div>
			</div>
			<div className="ml-5 tree-wrapper" id="treeWrapper" style={{ width: '100vw', minHeight: 500}}>
				<TreeChart 
					lastState={lastState} 
					dataObj={preparedDataArray} 
					rawData={state} 
					setRawData={updatePresent} 
					updatePresentWithoutPast={updatePresentWithoutPast}
					addPast={addPast}
					longestVerticalType={longestVerticalType}
					setIsSaved={setIsSaved}
					setRequireSave={setRequireSave}
					goalsToDelete={goalsToDelete}
					setGoalsToDelete={setGoalsToDelete}
					setAddingNewNode={setAddingNewNode}
					setEditingNode={setEditingNode}
					newWarningMessage={newWarningMessage}
				/>
			</div>
			<GoalsLegend strategy={strategy} business={business} project={project} nodeLength={nodeLength} parentWidth={reportsWidth} />
		</div>
        );
}
 
export default VisualEditor