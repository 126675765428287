import SetOfCheckboxes from "./SetOfCheckboxes"


export default function CRUDChecklist({item = {create: false, read: false, update: false, delete: false}, goalType}) {
    return (
        <div className="w-1/2 ">
            <div className="flex w-[16%] justify-between">
                <span className="text-center w-[21px]">C</span>
                <span className="text-center w-[21px]">R</span>
                <span className="text-center w-[21px]">U</span>
                <span className="text-center w-[21px]">D</span>
            </div>
            {goalType.length && goalType.map(goal => {
                return <SetOfCheckboxes key={goal.id} values={item} item={goal} />
            })}
        </div>
    )
}