import React, { useState } from 'react'
import { Navigate } from 'react-router-dom'
import FormInput from '../components/ui/inputs/FormInput'
import FacebookSpinner from '../components/ui/spinners/FacebookSpinner'
import { ImNotification } from 'react-icons/im'
import ErrorNotice from "../components/ErrorNotice"
import SuccessNotice from '../components/SuccessNotice'
import TermsModal from '../components/TermsModal'
 
const SignUp = ({ token, fetched, loading, signUp, orgToken, orgName, regEmail, errorMessage, successMessage, clearErrorMessage, clearSuccessMessage }) => {
	const [showDelete, setShowDelete] = useState(false)
	const [checkboxTerms, setCheckboxTerms] = useState(false)
	const [values, setValues] = useState({
		department_id: 1,
		organizationName: orgName,
		organizationId: orgToken,
		email: regEmail,
		password: '',
		confirmPassword: '',
		name: '',
		phone: ''
	})

	if(token) return <Navigate to="/"/>

	if (!fetched || loading) {
		return (
			<div className="spinner-container">
				<FacebookSpinner />
			</div>
		)
	}
	
	const inputs = [
		{
			id: 1,
			name: "name",
			type: "text",
			placeholder: "Name",
			errorMessage:
				"Name should be 1-16 characters and shouldn't include any special character",
			label: "Name",
			pattern: "^[A-Za-z0-9]{3,16}$",
			required: true,
		},
		{
			id: 2,
			name: "email",
			type: "email",
			placeholder: "Email",
			errorMessage: "It should be a valid email address",
			label: "Email",
			disabled: regEmail && true,
			required: true,
		},
		// {
		// 	id: 3,
		// 	name: "department_id",
		// 	type: "number",
		// 	placeholder: "Department",
		// 	errorMessage: "It should be a number",
		// 	label: "Department",
		// 	required: true,
		// 	min: 0
		// },
		{
			id: 3,
			name: "organizationName",
			type: "text",
			placeholder: "Organization name",
			errorMessage: "Organization name should be 2-16 characters and shouldn't include any special character",
			label: "Organization name",
			required: true,
			disabled: values.organizationId && true,
			minLength: "2"
		},
		{
			id: 5,
			name: "password",
			type: "password",
			placeholder: "Password",
			errorMessage:
			"Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special character",
			label: "Password",
			// pattern: `/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/`,
			required: true,
		},
		{
			id: 4,
			name: "phone",
			type: "tel",
			placeholder: "888 888 8888",
			label: "Phone",
			required: false,
			maxLength:"12"
		},
		{
			id: 6,
			name: "confirmPassword",
			type: "password",
			placeholder: "Confirm Password",
			errorMessage: "Passwords don't match",
			label: "Confirm Password",
			pattern: values.password.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
			required: true,
		},
	]

	const handleSubmit = (e) => {
		e.preventDefault();
		signUp({name: values.name, email: values.email, organization_name: values.organizationName, organization_id: values.organizationId, department_id: Number(values.department_id), phone: values.phone, password: values.password})
	}

	const onChange = (e) => {
		setValues({ ...values, [e.target.name]: e.target.value })
	}

	const toggleTermsModal = () => setShowDelete(!showDelete)

  return (
	<div className="sign-up">
		{showDelete && (
			<TermsModal
				setShowDelete={setShowDelete}
				setTerms={setCheckboxTerms}
			/>
		)}
		<div className="rectangle">
			<div className="notification-text">
				<div>
					<p className="flex items-center mb-2"><ImNotification className="mr-2"/> Attention!</p>
					<p className="mb-2">This product is currently in the testing phase. We are taking all possible measures to ensure the safety of your data. However, due to the ongoing development process, we cannot guarantee:</p>
					<p className="mb-2">- The security and confidentiality of your data.</p>
					<p className="mb-2">By using this product, you acknowledge that you have read this warning, understand, and accept the potential risks.</p>					
					<p className="mb-2">Sincerely,</p>
					<p className="mb-2">The DSM Team</p>
				</div>
			</div>
		</div>

		{errorMessage && <ErrorNotice message={errorMessage} clearMessage={clearErrorMessage} />}
		{successMessage && <SuccessNotice message={successMessage} clearMessage={clearSuccessMessage} />}
		<form className="form" onSubmit={handleSubmit}>
			<h4 className='form__title'>Register</h4>
			<div className="input-fields">
				{inputs.map((input) => (
				<FormInput
					key={input.id}
					{...input}
					value={values[input.name]}
					onChange={onChange}
				/>
				))}
			</div>
			<div className="action-buttons">
				<div className="signup-terms">
					<input type="checkbox" id="terms" name="terms" onChange={() => setCheckboxTerms(!checkboxTerms)} checked={checkboxTerms ? true : false}/>
					<label className="second-color" onClick={toggleTermsModal}>I accept the terms of service and privacy policy.</label>
				</div>
				<div className="form-buttons-container">
					<div className="form-buttons-container__login">
						<p>Already have an account?</p>
						<a href="/login">Log in here</a>
					</div>
					<button className={!checkboxTerms ? 'form__button disabled' : 'form__button'}>Sign Up</button>
				</div>

			</div>
		</form>
	</div>
  );

}

export default SignUp;