import React, { useContext } from 'react'
import { BsTrash3 } from 'react-icons/bs'
import { FiEdit } from 'react-icons/fi'
import { BiAddToQueue } from 'react-icons/bi'
import { RiParentFill, RiParentLine } from 'react-icons/ri'

import IconButton from '../ui/buttons/IconButton'
import { GoalsPageContext } from '../../contexts/GoalsPageContext'

const GoalItem = ({
        item,
        goals,
        showDelete, 
        deleteId,
        setDeleteItem, 
        setShowDelete, 
        setDeleteId, 
        setParent,
        setType,
        setLevel,
        setIsAddingChild,
        goalType,
        accessButtons
}) => {       
	const [
		setUpdateChosen,
		setAddChosen,
		setUpdateId,
		setUpdateName,
		setUpdateLevel,
		setUpdateTag,
		setUpdateType,
		setUpdateParent,
		setUpdateLink,
		setUpdateDescription,
		setActive,
	] = useContext(GoalsPageContext)
    const hasParent = goals.find(goal => goal.id === item.parent_id)
    const nextGoalType = goalType.find(gt => gt.level === item.level + 1)

    const { Goals_Delete, Goals_Edit, Goals_Add_child_goal } = accessButtons

    const handleDoubleClick = event => {
        if (!Goals_Edit) return
        if (event.detail === 2) {
            setUpdateChosen(true);
            setAddChosen(false);
            setUpdateId(item.id);
            setUpdateName(item.name);
            setUpdateLevel(item.level);
            setUpdateTag(item.tag);
            setUpdateType(item.type);
            setUpdateParent(item.parent_id);
            setUpdateLink(item.link);
            setUpdateDescription(item.description);
        }
    }

    return (
        <tr
            key={item.id}
            className="capability-item"
            style={{
                backgroundColor:
                showDelete && deleteId === item.id && "#A3AAC4",
            }}
            onClick={(e) => {handleDoubleClick(e)}}
            >
            <td className="px-4 py-3">{item.id}</td>
            <td className="px-4 py-3 flex items-center">{item.parent_id === 0 ? <RiParentFill style={{marginRight: 5}} alt="parent" /> : <RiParentLine style={!hasParent ? {marginRight: 5, color: 'red'} : {marginRight: 5}} alt="parent" />}{item.name}</td>
            <td className="px-4 py-3">{item.level}</td>
            <td className="px-4 py-3">{item.tag}</td>
            <td className="px-4 py-3">{item.type}</td>
            <td className="px-4 py-3">{item.parent_id}</td>
            <td className="px-4 py-3">{item.description}</td>
            <td className="px-4 py-3 text-blue-800 flex link">
            {item.link.length > 0 ? <a href={item.link} rel="noreferrer">Link </a> : <div>Link</div>}
                <div className='capability-controls'>
                    <IconButton
                        className="ml-5"
                        onClick={() => {
                            setShowDelete(!showDelete);
                            setDeleteId(item.id);
                            setDeleteItem({name: item.name})
                        }}
                        notRestricted={Goals_Delete}
                    >
                        <BsTrash3 alt="delete"/>
                    </IconButton>
                    <IconButton
                        className="ml-5"
                        onClick={() => {
                            setUpdateChosen(true);
                            setAddChosen(false);
                            setUpdateId(item.id);
                            setUpdateName(item.name);
                            setUpdateLevel(item.level);
                            setUpdateTag(item.tag);
                            setUpdateType(item.type);
                            setUpdateParent(item.parent_id);
                            setUpdateLink(item.link);
                        }}
                        notRestricted={Goals_Edit}
                    >
                        <FiEdit alt="edit" />
                    </IconButton>
                    <IconButton
                        className="ml-5"
                        onClick={() => {
                            setUpdateChosen(false)
                            setAddChosen(true)
                            setParent(item.id)
                            setType(nextGoalType && nextGoalType.name)
                            setLevel(item.level + 1)
                            setIsAddingChild(true)
                        }}
                        notRestricted={Goals_Add_child_goal && (goalType.length -1 !== item.level)}
                    >
                        <BiAddToQueue alt="add child" />
                    </IconButton>
                </div>
            </td>
        </tr>
    );
};

export default GoalItem;