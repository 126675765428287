import React, { useState, useEffect } from "react"

import ProductManagement from "../../components/Product/ProductManagement"
import ProductTable from "../../components/Product/ProductTable.js"

import SuccessNotice from "../../components/SuccessNotice"
import ErrorNotice from "../../components/ErrorNotice"
import WarningNotice from "../../components/WarningNotice"

import Checkbox from '../../components/ui/inputs/Checkbox.js'

import Left from "../../assets/left.svg"
import Right from "../../assets/right.svg"
import { AiOutlineClose, AiOutlineFileAdd } from "react-icons/ai"
import { MdDeleteForever } from "react-icons/md"

import SkeletonTabs from "../../components/ui/skeleton/SkeletonTabs"
import SkeletonTable from "../../components/ui/skeleton/SkeletonTable"
import IconButton from "../../components/ui/buttons/IconButton"
import ProductExport from "../../components/Product/ProductExport"
import ProductImport from "../../components/Product/ProductImport"
import Modal from "../../components/Modal"

const ProductPage = ({
	loading,
	fetched,
	getProduct,
	getActivity,
	getBusiness,
	newProduct,
	editProduct,
	product,
	activity,
	business,
	productPerPage,
	successMessage,
	errorMessage,
	clearErrorMessage,
	clearSuccessMessage,
	access
}) => {
	const [active, setActive] = useState('Product')
	const [updateChosen, setUpdateChosen] = useState(false);

	const [showDelete, setShowDelete] = useState(false)
	const [deleteItemsType, setDeleteItemsType] = useState('productsArray')

	const [addChosen, setAddChosen] = useState(false)
	const [formActive, setFormActive] = useState(false)

	const [success, setSuccess] = useState("")
	const [error, setError] = useState("")
	const [warning, setWarning] = useState("")
	const [errorField, setErrorField] = useState('')

	const [name, setName] = useState('');
	const [portfolio, setPortfolio] = useState('No')
	const [products, setProducts] = useState([]);
	const [type, setType] = useState('0');
	const [activityField, setActivityField] = useState('')
	const [process, setProcess] = useState('')

	const [updateId, setUpdateId] = useState('');
	const [updateName, setUpdateName] = useState('');
	const [updatePortfolio, setUpdatePortfolio] = useState('');
	const [updateProducts, setUpdateProducts] = useState('');
	const [updateType, setUpdateType] = useState('');
	const [updateActivityField, setUpdateActivityField] = useState('')
	const [updateProcess, setUpdateProcess] = useState('')
		
	const [current, setCurrent] = useState(0);
	const [paginationLevels, setPaginationLevels] = useState(productPerPage.length)

	const inputFieldLong = 'bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4 '

	const {
		Products_Add,
		Products_Edit,
		Products_Delete,
		Products_Previous_page,
		Products_Next_page
	} = access.Buttons
 
	useEffect(() => {
		getProduct()
		getActivity()
		getBusiness()
	}, [])

	const clearFields = () => {
		setName('')
		setPortfolio('No')
		setType('')
		setProducts([])
		setActivityField('')
		setProcess('')
		
		setFormActive(false)
	}

	const deleteAllProducts = () => {
		setShowDelete(true)
		setDeleteItemsType('productsArray')
	}

	const portfolioChangeHandler = (value, mainState, additionalState ) => {
		if (value === 'false') {
			additionalState([])
		}

		mainState(value)
	}

	const typeChangeHandler = (event) => {
		const { value } = event.target

		setType(value)

		if (Number(value)) {
			setActivityField('')
			return
		}
		setProcess('')
	}

	const updateTypeChangeHandler = (event) => {
		const { value } = event.target
		
		setUpdateType(value)

		if (Number(value)) {
			setUpdateActivityField('')
			return
		}
		setUpdateProcess('')
	}
	
	const addData = () => {
		if (!name) {
			setErrorField('name')
			setTimeout(() => {
				setErrorField('')
			}, 2000);
			return
		}
		newProduct({
			name: name,
			activity_id: Number(activityField),
			list_of_products: checkedProducts(products),
			parent_process: Number(process),
			parent_type: Number(type),
			portfolio_flag: portfolio === 'Yes' ? 1 : 0
		})
		.then(() => {
			setAddChosen(false)
			clearFields()
		})
	}

	const updateData = () => {
		if (!updateName) {
			setErrorField('name')
			setTimeout(() => {
				setErrorField('')
			}, 2000);
			return
		}
		editProduct({
			id: updateId,
			name: updateName,
			activity_id: Number(updateActivityField),
			list_of_products: checkedProducts(updateProducts),
			parent_process: Number(updateProcess),
			parent_type: Number(updateType),
			portfolio_flag: updatePortfolio === 'Yes' ? 1 : 0
		})
		.then(() => {
			setUpdateChosen(false)
			clearFields()
		})
	}

	const multiSelectChangeHandler = (e, data, mainState) => {
		mainState({...data, [e.target.name] : e.target.checked }) 
	}

	const checkedProducts = (itemsObject) => {
		const keys = Object.keys(itemsObject)
		const checkedItems = []
		for (const key of keys) {
			if (itemsObject[key] === true) {
				checkedItems.push(key)
			}
		}
		return checkedItems
	}

	return (
		<>
			<div className="notices">
					{(success || successMessage) && <SuccessNotice message={success || successMessage} clearMessage={clearSuccessMessage} />}
					{warning && <WarningNotice  message={warning} />}
					{(error || errorMessage) && <ErrorNotice message={error || errorMessage} clearMessage={clearErrorMessage} />}
			</div>
			{loading || !fetched ? 
				<SkeletonTabs /> :
				<ProductManagement active={active} setActive={setActive} access={access.Tabs} />
			}
			{active === 'Product' && 
				<>
					{showDelete && (
						<Modal
							setShowDelete={setShowDelete}
							deleteItemType={deleteItemsType}
							deleteItems={product}
						/>
					)}
					{addChosen && (
						<div>
							<div className="top-inputs">
								<div>
									<p>Name</p>
									<input
										value={name}
										placeholder="Name"
										onChange={(e) => setName(e.target.value)}
										type="text"
										id="name"
										autoFocus
										name="name"
										className={errorField === 'name' ? inputFieldLong + 'form-error' : inputFieldLong}
									/>
								</div>
								<div>
									<p>Portfolio</p>
									<select
										value={portfolio}
										onChange={(e) => portfolioChangeHandler(e.target.value, setPortfolio, setProducts)}
										className="bg-white h-10 w-96 shadow rounded py-2 px-3 mr-4"
										name="portfolio"
										id="portfolio"
									>
										<option value="No" key="No">No</option>
										<option value="Yes" key="Yes">Yes</option>
									</select>
								</div>
								<div>
									<p>Products</p>
									<div className="form-activity-container">
										{formActive ?
											<>
												<div className="h-11 w-72 shadow rounded py-2 px-3 form-activity">
													<button className="form-activity__button-close" onClick={() => { setFormActive(false) }}><span>Close</span><AiOutlineClose /></button>
													{product && product.map(item => (
														<label key={item.id}><br/>
															<Checkbox key={item.id} name={item.id} checked={products[item.id]} onChange={(e) => { multiSelectChangeHandler(e, products, setProducts) }} />
															{item.id + ' ' + item.name}
														</label>
													))}
												</div>
											</>
											:
											<button className={portfolio === 'Yes' ? "form-activity__button-open" : "form-activity__button-open disabled"} onClick={() => { setFormActive(true) }}>Open</button>
										}
									</div>
								</div>
							</div>
							<div className="top-inputs">
								<div className="mr-4">
									<p>This product is created by Type:</p>
									<select
										value={type}
										onChange={(e) => typeChangeHandler(e)}
										className="bg-white h-10 w-96 shadow rounded py-2 px-3"
										name="type"
										id="type"
										disabled={portfolio === 'Yes'}
									>
										<option value="0" key="0">Activity</option>
										<option value="1" key="1">Process</option>
									</select>
								</div>
								{ (Number(type)) ?
									<div>
										<p>Process</p>
										<select
											value={process}
											onChange={(e) => setProcess(e.target.value)}
											className="h-11 w-96 shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
											name="type"
											id="type"
											disabled={!business.length || !Number(type)}
										>
											<option></option>
											{business.length > 0 &&
												business.map((item) => {
													return (
														<option value={item.id} key={item.id}>
															{item.name}
														</option>
													)
												})
											}
										</select>
									</div>
								:
									<div>
										<p>Activity</p>
										<select
											value={activityField}
											onChange={(e) => setActivityField(e.target.value)}
											className="h-11 w-96 shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
											name="type"
											id="type"
											disabled={!activity.length || Number(type) || (portfolio === 'Yes')}
										>
											<option></option>
											{activity.length > 0 &&
												activity.map((item) => {
													return (
														<option value={item.id} key={item.id}>
															{item.name}
														</option>
													)
												})
											}
										</select>
									</div>
								
								}
							</div>
							<div className="w-3/4 flex justify-between align-middle">
								<button
									onClick={() => {
										setAddChosen(false);
									}}
									className="text-white bg-red-500 border-0a py-2 mt-6 px-12 focus:outline-none hover:bg-red-600 rounded text-lg"
								>
									Cancel	
								</button>
								<button
									onClick={() => {
										addData();
									}}
									className="text-white bg-second-color border-0 py-2 mt-6 px-12 focus:outline-none rounded text-lg"
								>
									Add Product
								</button>
							</div>
						</div>
					)}
					{updateChosen && (
						<div>
							<div className="top-inputs">
								<div>
									<p>Name</p>
									<input
										value={updateName}
										onChange={(e) => setUpdateName(e.target.value)}
										placeholder="Name"
										type="text"
										id="name"
										autoFocus
										name="name"
										className={errorField === 'name' ? inputFieldLong + 'form-error' : inputFieldLong}
									/>
								</div>
								<div>
									<p>Portfolio</p>
									<select
										value={updatePortfolio}
										onChange={(e) => portfolioChangeHandler(e.target.value, setUpdatePortfolio, setUpdateProducts)}
										className="bg-white h-10 w-96 shadow rounded py-2 px-3 mr-4"
										name="portfolio"
										id="portfolio"
									>
										<option value="No" key="No">No</option>
										<option value="Yes" key="Yes">Yes</option>
									</select>
								</div>
								<div>
									<p>Products</p>
									<div className="form-activity-container">
										{formActive ?
											<>
												<div className="h-11 w-72 shadow rounded py-2 px-3 form-activity">
													<button className="form-activity__button-close" onClick={() => { setFormActive(false) }}><span>Close</span><AiOutlineClose /></button>
													{product.map(item => (
														<label key={item.id}><br/>
																<Checkbox name={item.id} key={item.id} checked={updateProducts[item.id]} onChange={(e) => { multiSelectChangeHandler(e, updateProducts, setUpdateProducts) }} />
																{item.id + ' ' + item.name}
														</label>
													))}
												</div>
											</>
											:
											<button className={updatePortfolio === 'Yes' ? "form-activity__button-open" : "form-activity__button-open disabled"} onClick={() => { setFormActive(true) }}>Open</button>
										}
									</div>
								</div>
								
							</div>
							<div className="top-inputs"> 
								<div className="mr-4">
									<p>Type</p>
									<select
										value={updateType}
										onChange={(e) => updateTypeChangeHandler(e)}
										className="bg-white h-10 w-96 shadow rounded py-2 px-3"
										name="type"
										id="type"
									>
										<option value="0" key="0">Activity</option>
										<option value="1" key="1">Process</option>
									</select>
								</div>
								{Number(updateType) ?
									<div>
										<p>Process</p>
										<select
											value={updateProcess}
											onChange={(e) => setUpdateProcess(e.target.value)}
											className="h-11 w-96 shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
											name="type"
											id="type"
											disabled={!business.length || !Number(updateType)}
										>
											<option></option>
											{business.length > 0 &&
												business.map((item) => {
													return (
														<option value={item.id} key={item.id}>
															{item.name}
														</option>
													)
												})
											}
										</select>
									</div>
									:
									<div>
										<p>Activity</p>
										<select
											value={updateActivityField}
											onChange={(e) => setUpdateActivityField(e.target.value)}
											className="h-11 w-96 shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
											name="type"
											id="type"
											disabled={!activity.length || Number(updateType) || (updatePortfolio === 'Yes')}
										>	
											<option></option>
											{activity.length > 0 &&
												activity.map((item) => {
													return (
														<option value={item.id} key={item.id}>
															{item.name}
														</option>
													)
												})
											}
										</select>
									</div>
								}
							</div>
							<div className="w-3/4 flex justify-between align-middle">
								<button
									onClick={() => {
										setUpdateChosen(false);
									}}
									className="text-white bg-red-500 border-0 py-2 mt-6 px-12 focus:outline-none hover:bg-red-600 rounded text-lg"
								>
									Cancel
								</button>
								<button
									onClick={() => {
										updateData();
									}}
									className="text-white bg-second-color border-0 py-2 mt-6 px-12 focus:outline-none rounded text-lg"
								>
									Update Product
								</button>
							</div>
						</div>
					)}
					<div className="tables">
						<div className="top-table">
							<div className="plus-minus">
								<IconButton
									className="icon-img"
									onClick={() => {
										setUpdateChosen(false);
										setAddChosen(!addChosen);
									}}
									notRestricted={Products_Add}
									stateActive={addChosen}
									tooltip={'Add new'}
								>
									<AiOutlineFileAdd alt="add" />
								</IconButton>
							</div>
							<div className="plus-minus ml-5">
								<IconButton
										className="icon-img"
										onClick={deleteAllProducts}
										notRestricted={Products_Delete && (product && product.length)}
										stateActive={showDelete}
										tooltip={'Delete all table data'}
									>
										<MdDeleteForever />
								</IconButton>
							</div>
							<div className="w-full flex flex-row mb-5 mt-6 justify-end items-center px-10">
							<div
								className={current !== 0 ? "cursor-pointer bg-gray-100 px-2 py-1.5 rounded mr-2" : "cursor-pointer bg-gray-100 px-2 py-1.5 rounded mr-2 disabled"}
								onClick={() => {
									if (current > 0) {
										setCurrent(current - 1);
									}
								}}
							>
								<img src={Left} alt="left" />
							</div>

							<div
								className={current < paginationLevels - 2 ? "cursor-pointer bg-gray-100 px-2 py-1.5 rounded" : "cursor-pointer bg-gray-100 px-2 py-1.5 rounded disabled"}
								onClick={() => {
									if (current < paginationLevels - 2) {
										setCurrent(current + 1);
									}
								}}
							>
								<img src={Right} alt="right" />
							</div>
						</div>
						</div>
						<div className="table-div">
						{loading || !fetched ? 
								<SkeletonTable />:
								<ProductTable
									data={productPerPage}
									deletedChosen={false}
									setSelected={null}
									setUpdateChosen={setUpdateChosen}
									setAddChosen={setAddChosen}
									setSuccess={setSuccess}
									setError={setError}
									getData={getProduct}

									setUpdateId={setUpdateId}
									setUpdateName={setUpdateName}
									setUpdatePortfolio={setUpdatePortfolio}
									setUpdateProducts={setUpdateProducts}
									setUpdateType={setUpdateType}
									

									setUpdateActivityField={setUpdateActivityField}
									setUpdateProcess={setUpdateProcess}

									current={current}
									setPaginationLevels={setPaginationLevels}

									setShowDelete={setShowDelete}
									
									access={access.Buttons}
								/>
						}
						</div>
						
					</div>
				</>
			}
			{active === 'Export' && <ProductExport dataArray={product} access={access.Buttons} />}
			{active === 'Import' && <ProductImport dataArray={product} access={access.Buttons} setWarning={setWarning} setSuccess={setSuccess} setError={setError}/>}
		</>
	)
}

export default ProductPage
