import React, { useState } from 'react';

import { VscGear } from "react-icons/vsc";
import { VscClearAll } from 'react-icons/vsc'


const ActivitiesTableHeaderItem = ({ 
    sortData, 
    name, 
    value, 
    activeSort, 
    setSortingStartDate, 
    setSortingEndDate, 
    setSortingName, 
    setSortingParent, 
    setSortingLevel, 
    setSortingTag, 
    setSortingGoal, 
    setSortingSort, 
    setSortingScore, 
    setSortingPredecessor, 
    setSortingOwner, 
    setSortingLCost, 
    setSortingRCost, 
    setSortingEffort, 
    setSortingActivityType 
}) => {
    const [filter, setFilter] = useState(false)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [sortName, setSortName] = useState('')
    const [parent, setParent] = useState('')

    const [level, setLevel] = useState('')
    const [tag, setTag] = useState('')

    const [goal, setGoal] = useState('')
    const [sort, setSort] = useState('')
    const [score, setScore] = useState('')
    const [predecessor, setPredecessor] = useState('')
    const [owner, setOwner] = useState('')
    const [lCost, setLCost] = useState('')
    const [rCost, setRCost] = useState('')
    const [effort, setEffort] = useState('')
    const [activityType, setActivityType] = useState('')

    let headerItemType = ''
    let headerItemFilterActive = ''

    const Checkbox = ({ type = "checkbox", name, checked = false, onChange }) => {
        return (
          <input type={type} name={name} checked={checked} onChange={onChange} />
        )
    }

    const checkboxesActivityTypes = [
        {
            name: 'Initiative',
            key: 'Initiative',
            label: 'Initiative',
        },
        {
            name: 'Project',
            key: 'Project',
            label: 'Project',
        },
        {
            name: 'Task',
            key: 'Task',
            label: 'Task',
        }
    ]

    const setFieldClear = () => {
        setFilter(!filter)
    }

    const handleKeyPress = (e) => {
        if(e.key === 'Enter' || e.key === 'Escape'){
            setFilter(!filter)
        }
    }

    const closeFilter = (e) => {
        if(e.key === 'Escape'){
            setFilter(!filter)
        }
    }
    
    const nameChangeHandler = (e) => {
        setSortName(e.target.value)
        setSortingName(e.target.value)
    }

    const startDateChangeHandler = (e) => {
        setStartDate(e.target.value)
        setSortingStartDate(e.target.value)
        setFilter(!filter)
    }

    const endDateChangeHandler = (e) => {
        setEndDate(e.target.value)
        setSortingEndDate(e.target.value)
        setFilter(!filter)
    }

    const parentChangeHandler = (e) => {
        setParent(e.target.value)
        setSortingParent(e.target.value)
    }

    const tagChangeHandler = (e) => {
        setTag(e.target.value)
        setSortingTag(e.target.value)
    }
    
    const levelChangeHandler = (e) => {
        setLevel(e.target.value)
        setSortingLevel(e.target.value)
    }

    const goalChangeHandler = (e) => {
        setGoal(e.target.value)
        setSortingGoal(e.target.value)
    }

    const sortChangeHandler = (e) => {
        setSort(e.target.value)
        setSortingSort(e.target.value)
    }

    const scoreChangeHandler = (e) => {
        setScore(e.target.value)
        setSortingScore(e.target.value)
    }

    const predecessorChangeHandler = (e) => {
        setPredecessor(e.target.value)
        setSortingPredecessor(e.target.value)
    }

    const ownerChangeHandler = (e) => {
        setOwner(e.target.value)
        setSortingOwner(e.target.value)
    }

    const lCostChangeHandler = (e) => {
        setLCost(e.target.value)
        setSortingLCost(e.target.value)
    }

    const rCostChangeHandler = (e) => {
        setRCost(e.target.value)
        setSortingRCost(e.target.value)
    }

    const effortChangeHandler = (e) => {
        setEffort(e.target.value)
        setSortingEffort(e.target.value)
    }

    const activityTypeChangeHandler = (e) => {
        setActivityType({...activityType, [e.target.name] : e.target.checked })
        setSortingActivityType({...activityType, [e.target.name] : e.target.checked })
    }


    if (value === 'start-date') {
        headerItemFilterActive = startDate
        headerItemType = <input
                            value={startDate} 
                            className="h-11 w-72 shadow rounded py-2 px-3 filter-input" 
                            type="date" 
                            onChange={startDateChangeHandler}>
                        </input>
    }
    if (value === 'end-date') {
        headerItemFilterActive = endDate
        headerItemType = <input
                            value={endDate} 
                            className="h-11 w-72 shadow rounded py-2 px-3 filter-input" 
                            type="date" 
                            onChange={endDateChangeHandler}>
                        </input>
    }
    if (value === 'name') {
        headerItemFilterActive = sortName
        headerItemType = <div className='h-11 w-72 shadow rounded py-2 px-3 filter-input'>
                            <input
                                value={sortName} 
                                className="" 
                                type="text" 
                                placeholder="Start typing here"
                                onChange={nameChangeHandler}>
                            </input>
                            <button className='clear-field' onClick={() => {setFieldClear(); setSortName(''); setSortingName('') }}><VscClearAll /></button>
                        </div>
    }
    
    if (value === 'parent') {
        headerItemFilterActive = parent
        headerItemType = <div className='h-11 w-72 shadow rounded py-2 px-3 filter-input'>
                            <input 
                                value={parent}
                                type="number"
                                min="1"
                                placeholder="Parent number" 
                                onChange={parentChangeHandler}
                                onKeyDown={handleKeyPress}>
                            </input>
                            <button className='clear-field' onClick={() => {setFieldClear(); setParent(''); setSortingParent('') }}><VscClearAll /></button>
                        </div>
    }
    if (value === 'level') {
        headerItemFilterActive = level
        headerItemType = <div className='h-11 w-72 shadow rounded py-2 px-3 filter-input'>
                            <input 
                                value={level}
                                type="number"
                                min="1"
                                placeholder="Level number" 
                                onChange={levelChangeHandler}
                                onKeyDown={handleKeyPress}>
                            </input>
                            <button className='clear-field' onClick={() => {setFieldClear(); setLevel(''); setSortingLevel('') }}><VscClearAll /></button>
                        </div>
    }
    // if (value === 'type') {
    //     headerItemFilterActive = type
    //     headerItemType = 
    //                 <div className="filter-select">
    //                     <form className="h-11 w-72 shadow rounded py-2 px-3" onChange={typeChangeHandler}>
    //                         {checkboxesTypes.map(item => (
    //                             <label key={item.key}><br/>
    //                                 <Checkbox name={item.name} checked={type[item.name]} onChange={typeChangeHandler} />
    //                                 {item.name}
    //                             </label>
    //                             ))}
    //                     </form>
    //                     <button className='clear-field' onClick={() => {setFieldClear(); setType(''); setSortingType('') }}><VscClearAll /></button>
    //                 </div>
    // }
    if (value === 'tag') {
        headerItemFilterActive = tag
        headerItemType = <div className='h-11 w-72 shadow rounded py-2 px-3 filter-input'>
                            <input
                                value={tag} 
                                className="" 
                                type="text" 
                                placeholder="Start typing here"
                                onChange={tagChangeHandler}
                                onKeyDown={handleKeyPress}>
                            </input>
                            <button className='clear-field' onClick={() => {setFieldClear(); setTag(''); setSortingTag('') }}><VscClearAll /></button>
                        </div>
    }


    if (value === 'goal-id') {
        headerItemFilterActive = goal
        headerItemType = <div className='h-11 w-72 shadow rounded py-2 px-3 filter-input'>
                            <input
                                value={goal} 
                                className="" 
                                type="number" 
                                placeholder="Enter a number" 
                                onChange={goalChangeHandler}
                                onKeyDown={handleKeyPress}>
                            </input>
                            <button className='clear-field' onClick={() => {setFieldClear(); setGoal(''); setSortingGoal('') }}><VscClearAll /></button>
                        </div>
    }

    if (value === 'sorting-number') {
        headerItemFilterActive = sort
        headerItemType = <div className='h-11 w-72 shadow rounded py-2 px-3 filter-input'>
                            <input 
                                value={sort}
                                type="number"
                                min="1"
                                placeholder="Enter a number" 
                                onChange={sortChangeHandler}
                                onKeyDown={handleKeyPress}>
                            </input>
                            <button className='clear-field' onClick={() => {setFieldClear(); setSort(''); setSortingSort('') }}><VscClearAll /></button>
                        </div>
    }

    if (value === 'score') {
        headerItemFilterActive = score
        headerItemType = <div className='h-11 w-72 shadow rounded py-2 px-3 filter-input'>
                            <input 
                                value={score}
                                type="number"
                                min="1"
                                placeholder="Enter a number" 
                                onChange={scoreChangeHandler}
                                onKeyDown={handleKeyPress}>
                            </input>
                            <button className='clear-field' onClick={() => {setFieldClear(); setScore(''); setSortingScore('') }}><VscClearAll /></button>
                        </div>
    }

    if (value === 'predecessor') {
        headerItemFilterActive = predecessor
        headerItemType = <div className='h-11 w-72 shadow rounded py-2 px-3 filter-input'>
                            <input 
                                value={predecessor}
                                type="number"
                                min="1"
                                placeholder="Enter a number" 
                                onChange={predecessorChangeHandler}
                                onKeyDown={handleKeyPress}>
                            </input>
                            <button className='clear-field' onClick={() => {setFieldClear(); setPredecessor(''); setSortingPredecessor('') }}><VscClearAll /></button>
                        </div>
    }

    if (value === 'owner') {
        headerItemFilterActive = owner
        headerItemType = <div className='h-11 w-72 shadow rounded py-2 px-3 filter-input'>
                            <input
                                value={owner} 
                                className="" 
                                type="text" 
                                placeholder="Start typing here"
                                onChange={ownerChangeHandler}
                                onKeyDown={handleKeyPress}>
                            </input>
                            <button className='clear-field' onClick={() => {setFieldClear(); setOwner(''); setSortingOwner('') }}><VscClearAll /></button>
                        </div>
    }

    if (value === 'labor-cost') {
        headerItemFilterActive = lCost
        headerItemType = <div className='h-11 w-72 shadow rounded py-2 px-3 filter-input'>
                            <input 
                                value={lCost}
                                type="number"
                                min="1"
                                placeholder="Enter a number"  
                                onChange={lCostChangeHandler}
                                onKeyDown={handleKeyPress}>
                            </input>
                            <button className='clear-field' onClick={() => {setFieldClear(); setLCost(''); setSortingLCost('') }}><VscClearAll /></button>
                        </div>
    }

    if (value === 'resources-cost') {
        headerItemFilterActive = rCost
        headerItemType = <div className='h-11 w-72 shadow rounded py-2 px-3 filter-input'>
                            <input 
                                value={rCost}
                                type="number"
                                min="1"
                                placeholder="Enter a number" 
                                onChange={rCostChangeHandler}
                                onKeyDown={handleKeyPress}>
                            </input>
                            <button className='clear-field' onClick={() => {setFieldClear(); setRCost(''); setSortingRCost('') }}><VscClearAll /></button>
                        </div>
    }

    if (value === 'effort') {
        headerItemFilterActive = effort
        headerItemType = <div className='h-11 w-72 shadow rounded py-2 px-3 filter-input'>
                            <input 
                                value={effort}
                                type="number"
                                min="1"
                                placeholder="Enter a number" 
                                onChange={effortChangeHandler}
                                onKeyDown={handleKeyPress}>
                            </input>
                            <button className='clear-field' onClick={() => {setFieldClear(); setEffort(''); setSortingEffort('') }}><VscClearAll /></button>
                        </div>
    }



    if (value === 'activity-type-id') {
        headerItemFilterActive = activityType
        headerItemType = 
                    <div className="filter-select">
                        <form className="filter-select__form" onChange={activityTypeChangeHandler}>
                            {checkboxesActivityTypes.map(item => (
                                <label key={item.key}><br/>
                                    <Checkbox name={item.name} checked={activityType[item.name]} onChange={activityTypeChangeHandler} />
                                    {item.name}
                                </label>
                                ))}
                        </form>
                        <button className='clear-field' onClick={() => {setFieldClear(); setActivityType(''); setSortingActivityType('') }}><VscClearAll /></button>
                    </div>
    }

    let uniqueStyling

    if (value === 'id') {
        uniqueStyling = 'header-item_id'
    }
    if (value === 'name') {
        uniqueStyling = 'header-item_name'
    }
    if (value === 'link') {
        uniqueStyling = 'header-item_link'
    }

    return (
        <th className={"table-header-item "  + uniqueStyling}>
            <div className='table-header-item__container' tabIndex={-1} onKeyDown={closeFilter}>
                {filter && headerItemType}
                <button onClick={() => {sortData(value)}}>{name}</button>
                {(activeSort.key !== value) && <div style={{marginRight: 12}}></div>}
                {(activeSort.key === value && activeSort.inc) && <div style={{marginBottom: 2}}>▲</div>}
                {(activeSort.key === value && !activeSort.inc) && <div style={{marginBottom: 3}}>▼</div>}
                {(value !== 'id' && value !== 'link') && <VscGear className={headerItemFilterActive ? "text-2xl cursor-pointer capability-filter text-red-400" : (filter ? "text-2xl cursor-pointer capability-filter filter-active" : "text-2xl cursor-pointer capability-filter")} onClick={() => {setFilter(!filter)}} />}
            </div>
        </th>
    );
};

export default ActivitiesTableHeaderItem;