import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const CapabilitiesReportVerticalDownload = ({
  item,
  data,
  heatmapActive,
  timeline,
  isHeatmapNotDot,
  itemWidth,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const editCapabilityHandler = (item) => {
    if (location.pathname !== "/capabilities") {
      navigate("/capabilities");

      return;
    }
    // setUpdateChosen(true);
    // setActive("Capabilities");
    // setUpdateName(item.name);
    // setUpdateLink(item.link);
    // setUpdateProperty(item.property);
    // setUpdateStartDate(item.start_date);
    // setUpdateEndDate(item.end_date);
    // setUpdateId(item.id);
    // setUpdateFirstLevel(item.first_level);
    // setUpdateSecondLevel(item.second_level);
    // setUpdateThirdLevel(item.third_level);
    // setUpdateSetId(
    //   set.find((element) => element.id === item.universal_item_set_id).name
    // );
    // setMaturity(item.maturity === null ? [] : item.maturity);
  };

  const getMaturityColor = (item, level) => {
    const itemMaturities =
      item.maturity &&
      item.maturity.filter(
        (el) => el.change_date === timeline || el.change_date < timeline
      );
    itemMaturities && itemMaturities.sort((a, b) => b.id - a.id);
    const itemMaturity =
      itemMaturities && itemMaturities.length > 0 ? itemMaturities[0] : null;
    // const itemMaturity = item.maturity && item.maturity.find(el => el.change_date === timeline || el.change_date < timeline)

    if (itemMaturity) {
      if (itemMaturity.maturity_level_dict.name === "No capability") {
        return "maturity-color-1";
      }
      if (itemMaturity.maturity_level_dict.name === "Initial") {
        return "maturity-color-2";
      }
      if (itemMaturity.maturity_level_dict.name === "Managed") {
        return "maturity-color-3";
      }
      if (itemMaturity.maturity_level_dict.name === "Defined") {
        return "maturity-color-4";
      }
      if (itemMaturity.maturity_level_dict.name === "Quantitatively Managed") {
        return "maturity-color-5";
      }
      return "maturity-color-6";
      // old way of changing class name
      // return 'maturity-color-' + itemMaturity.maturity_level_dict.id
    }
    if (level === 1) {
      return "";
    }
    if (level === 2) {
      return "no-maturity-color";
    }
    if (level === 3) {
      return "";
    }
  };

  return (
    <div
      style={{ width: itemWidth + "%" }}
      className={
        heatmapActive && isHeatmapNotDot
          ? "flex flex-col items-center gap-[5px] text-xs w-[10%] min-w-[100px] h-full min-h-[50vh] z-[1] transition-all duration-[0.2s] pt-3 rounded-t-xl bg-white " +
            getMaturityColor(item, 1)
          : "flex flex-col items-center gap-[5px] text-xs w-[10%] min-w-[100px] h-full min-h-[50vh] z-[1] transition-all duration-[0.2s] pt-3 rounded-t-xl bg-white"
      }
    >
      {heatmapActive && (
        <i className={"maturity-min " + getMaturityColor(item, 1)} />
      )}
      <p
        onClick={() => editCapabilityHandler(item)}
        className="text-center cursor-pointer"
      >
        {"v" +
          "." +
          item.first_level +
          "." +
          item.second_level +
          "." +
          item.third_level +
          "/" +
          item.lane}{" "}
        {item.name}
      </p>
      {data.map((i2) => {
        if (
          i2.first_level === item.first_level &&
          i2.second_level !== 0 &&
          i2.third_level === 0 &&
          i2.layout === "vertical"
        ) {
          return (
            <div
              className={
                heatmapActive && isHeatmapNotDot
                  ? "second-level-capability " + getMaturityColor(i2, 2)
                  : "second-level-capability no-maturity-color"
              }
              key={i2.id}
            >
              {heatmapActive && (
                <i className={"maturity-min " + getMaturityColor(i2, 2)} />
              )}
              <p
                className="cursor-pointer"
                onClick={() => editCapabilityHandler(i2)}
              >
                {"v" +
                  "." +
                  i2.first_level +
                  "." +
                  i2.second_level +
                  "." +
                  i2.third_level +
                  "/" +
                  item.lane}{" "}
                {i2.name}
              </p>
              {data.map((i3) => {
                if (
                  item.first_level === i3.first_level &&
                  i2.second_level === i3.second_level &&
                  i3.third_level !== 0 &&
                  i2.layout === "vertical"
                ) {
                  return (
                    <div
                      className={
                        heatmapActive && isHeatmapNotDot
                          ? "third-level-capability " + getMaturityColor(i3, 3)
                          : "third-level-capability"
                      }
                      key={i3.id}
                    >
                      {heatmapActive && (
                        <i
                          className={"maturity-min " + getMaturityColor(i3, 3)}
                        />
                      )}
                      <p
                        className="cursor-pointer"
                        onClick={() => editCapabilityHandler(i3)}
                      >
                        {"v" +
                          "." +
                          i3.first_level +
                          "." +
                          i3.second_level +
                          "." +
                          i3.third_level +
                          "/" +
                          i3.lane}{" "}
                        {i3.name}
                      </p>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default CapabilitiesReportVerticalDownload;
