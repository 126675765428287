import { connect} from "react-redux";
import { fetched, token, loading, application, applicationPerPage, successMessage, errorMessage, access, bpRelations } from '../../store/reducers/selectors'
import { getApplication, newApplication, editApplication, getBPRelations, clearSuccessMessage, clearErrorMessage } from "../../api/actions";
import { createStructuredSelector}  from "reselect";
import ApplicationsPage from './ApplicationsPage'

const mapStateToProp = createStructuredSelector({
    token,
    fetched,
    loading,
    application,
    applicationPerPage,
    successMessage,
    errorMessage,
    access,
    bpRelations
})

const mapDispatchToProps = {
    getApplication,
    getBPRelations,
    newApplication,
    editApplication,
    clearSuccessMessage,
    clearErrorMessage
}

export default connect(mapStateToProp, mapDispatchToProps)(ApplicationsPage);