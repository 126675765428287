import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

const ProcessesReportApplicationItem = ({ 
	name
}) => {
	
	return (
		<div className="inline border border-solid rounded text-center bg-green-300 truncate min-w-[150px] m-2 p-2 transition ease-in-out">
			{name}
		</div>
	)
}

export default ProcessesReportApplicationItem