import React from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import './styles/main.scss'

import Layout from "./layout"

import DirectoriesPage from './pages/DirectoriesPage'
import NotFound from "./pages/NotFound"
import CapabilitiesPage from "./pages/CapabilitiesPage"
import GoalsPage from "./pages/GoalsPage"
import ActivitiesPage from "./pages/ActivitiesPage"
import ReportsPage from "./pages/ReportsPage"
import ScenarioPage from "./pages/ScenarioPage"
import ProductPage from "./pages/ProductPage"
import BusinessPage from "./pages/BusinessPage"
import ApplicationsPage from "./pages/ApplicationsPage"
import SettingsPage from "./pages/SettingsPage"
import AboutPage from "./pages/AboutPage"
import SignUp from "./SignUp"
import Login from './Login'
import BCMNewPage from "./components/BCMNewPage"
import BCMNewPageSVG11 from "./components/BCMNewPageSVG11"


function App() {

	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route index element={<DirectoriesPage />} />
					<Route path="*" element={<NotFound />} />
					<Route path="goals" element={<GoalsPage />}/>
					<Route path="capabilities" element={<CapabilitiesPage />}/>
					<Route path="activities" element={<ActivitiesPage />}/>
					<Route path="scenarios" element={<ScenarioPage />} />
					<Route path="products" element={<ProductPage />} />
					<Route path="processes" element={<BusinessPage />} />
					<Route path="applications" element={<ApplicationsPage />} />
					<Route path="reports" element={<ReportsPage />} />
					<Route path="settings" element={<SettingsPage />} />
					<Route path="about" element={<AboutPage />} />
					<Route path="login" element={<Login />}/>
					<Route path="signup" element={<SignUp />}/>
				</Route>
				<Route path="bcm-new-page" element={<BCMNewPage />}/>
				<Route path="bcm-new-page-svg" element={<BCMNewPageSVG11 />}/>
			</Routes>
		</BrowserRouter>
	)
}

export default App
