import { connect } from "react-redux";
import { fetched, token, loading } from '../../store/reducers/selectors'
import { getSet, editSet } from "../../api/actions";
import { createStructuredSelector } from "reselect";
import EditSetModal from './EditSetModal'

const mapStateToProp = createStructuredSelector({
    token,
    fetched,
    loading
})

const mapDispatchToProps = {
    getSet,
    editSet
}

export default connect(mapStateToProp, mapDispatchToProps)(EditSetModal);