import React, { useState, useRef } from 'react'
import * as xlsx from 'xlsx'

import RegularButton from '../../ui/buttons/RegularButton'                                                                                                                                                                                                                                                                                                                                                                                                         

import { TbUpload } from 'react-icons/tb'

const  ScenarioImport = ({ loading, scenario, activity, importScenario, setSuccess, setError, setWarning, access }) => {
    const [file, setFile] = useState()
    const [dragActive, setDragActive] = useState(false)
    const inputRef = useRef(null)

    const onlyImportantFields = (data) => {
        const findActivityIds = namesArray => {
            const ids = []
            for (const name of namesArray) {
                const activityItem = activity.find(i => i.name === name)
                if (activityItem) {
                    ids.push(activityItem.id.toString())
                }
            }

            return ids
        }

        const result = []

        for (const item of data) {
            const activityIds = findActivityIds(item.activities)
            result.push({
                id: item.id,
                name: item.name,
                list_of_activities: activityIds,
                active_flag: item.active_flag
            })
        }
        return result
    }

    const createTree = (data) => {
        const newData = onlyImportantFields(data)
    
        return newData
    }
    
    // handle drag events
    const handleDrag = function(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    }
    
    // triggers when file is dropped
    const handleDrop = function(e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFileChange(e.dataTransfer.files[0]);
        }
    }
    
    // triggers when file is selected with click
    const handleChange = function(e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            handleFileChange(e.target.files[0]);
        }
    }
    
    // triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click();
    }

    const handleFileChange = (file) => {
        setFile(file)
    }

    const uploadNewCaps = (json) => {
        setWarning('Work in progress')
        if (json.length) {
            const scenarios = createTree(json)
            importScenario({scenarios})
            .then(() => {
                setWarning('')
            })
            return
        }

        setWarning('')
        setError('Nothing to import')
        setTimeout(() => {
            setError('')
        }, 2000)
    }

    const handleUploadClick = () => {
        if (!file) {
            setError('Nothing to import')
            setTimeout(() => {
                setError('')
            }, 2000)
            return
        }

        const readerCaps = new FileReader()
        readerCaps.onload = async (e) => {
            const data = e.target.result
            const workbook = xlsx.read(data, { type: "array" })
            const sheetName = workbook.SheetNames[0]
            const worksheet = workbook.Sheets[sheetName]
            const json = xlsx.utils.sheet_to_json(worksheet)

            setFile()


           uploadNewCaps(json)

        }

        readerCaps.readAsArrayBuffer(file)
    }

    const excelImport = () => {
        const wb = xlsx.utils.book_new()
        const ws = xlsx.utils.json_to_sheet([{ 
            id: "1", 
            name: "Example name", 
            activities: "",
            active: ""   
        }])

        xlsx.utils.book_append_sheet(wb, ws, 'scenarios-export')
    
        xlsx.writeFile(wb, 'Export-scenarios-template.xlsx')
    }

    return (
        <div className="reports">
            <div className="top-table mt-4">
                <div className="plus-minus">
                </div>
                <div className="empty-template">
                    <RegularButton                    
                        className="text-white bg-second-color border-0 py-2 px-2 focus:outline-none rounded w-72"
                        onClick={excelImport}
                        notRestricted={true}
                    >
                        Download empty template
                    </RegularButton>
                </div>
            </div>
            <div className={loading ? "form-container disabled flex justify-center items-center flex-col flex-wrap" : "form-container flex justify-center items-center flex-col flex-wrap"}>
                <form id="form-file-upload" className="upload-files-container" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                        <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
                        <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" }>
                        <div className="drag-file-area">
                            {
                                !file ? <>
                                        <TbUpload className="upload-icon" />
                                        <h3>Drag and drop your file here</h3>
                                        <p> or <span className="upload-button">browse file</span> from the device</p>
                                    </> 
                                :
                                    <>
                                        <p>{file.name} - {file.type}</p>
                                        <button className="upload-button" onClick={onButtonClick}>Change file</button>
                                    </>
                            }
                        </div> 
                        </label>
                        { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
                        <RegularButton                    
                            className="text-white bg-second-color border-0 py-2 mt-6 px-12 focus:outline-none rounded text-lg w-64"
                            onClick={handleUploadClick}
                            notRestricted={true}
                        >
                            Upload
                        </RegularButton>
                    </form>
                </div>
        </div>
    );
}

export default ScenarioImport