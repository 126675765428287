import React from 'react';

export const GoalsLegend = ({ strategy, business, project, nodeLength, parentWidth }) => {
	return (
		<div className="goals-colors" style={{width: parentWidth}}>
			{strategy > 0 && <div style={{
				width: strategy * nodeLength + '%'
			}} className="goals-colors__level strategy-level">
				<div className="goals-colors__level-item strategy-level-color">Strategy level</div>
				<div className="goals-colors__level-item">Enterprise level</div>
			</div>}
			{business > 0 && <div style={{
				width: business * nodeLength + '%'
			}} className="goals-colors__level business-level">
				<div className="goals-colors__level-item business-level-color">Business level</div>
				<div className="goals-colors__level-item">Department level</div>
			</div>}
			{project > 0 && <div style={{
				width: project * nodeLength + '%'
			}} className="goals-colors__level project-level">
				<div className="goals-colors__level-item project-level-color">Project level</div>
				<div className="goals-colors__level-item">Teams level</div>
			</div>}
		</div>
	)
}
