import React, { useEffect, useState } from "react";

import groupArrayIntoSixes from "../../handlers/groupArrayHandler";

import Modal from "../Modal";
import GoalsTableHeaderItem from "./GoalsTableHeaderItem";
import GoalItem from './GoalItem';


const GoalsTable = ({
	data,
	goals,
	setSuccess,
	setError,
	getData,
	// setUpdateId,
	// setUpdateName,
	// setUpdateChosen,
	// setAddChosen,
	// setUpdateLevel,
	// setUpdateTag,
	// setUpdateType,
	// setUpdateParent,
	// setUpdateLink,
	// setUpdateDescription,
	current,
	setPaginationLevels,
	setParent,
	setType,
	setIsAddingChild,
	setLevel,
	goalType,
	accessButtons
}) => {
	const [showDelete, setShowDelete] = useState(false);
	const [deleteId, setDeleteId] = useState("");
	const [deleteItem, setDeleteItem] = useState({name: ''});
	const [sortedData, setSortedData] = useState([])
	const [activeSort, setActiveSort] = useState({key: '', inc: true})
	const [sortingName, setSortingName] = useState("")
	const [sortingParent, setSortingParent] = useState("")
	const [sortingType, setSortingType] = useState("")
	const [sortingLevel, setSortingLevel] = useState()
	const [sortingTag, setSortingTag] = useState()

	useEffect(() => {
		let result = []

		for (const array of data) {
		result.push(...array)
		}

		if (sortingName) {
		result = result.filter(item => item.name.includes(sortingName))
		}
		
		if (sortingTag) {
		result = result.filter(item => item.tag.includes(sortingTag))
		}
		if (sortingParent) {
		result = result.filter(item => item.parent_id === Number(sortingParent))
		}
		if (sortingType) {
		const getKeyByValue = (object) => {
			const result = []
			const keys = Object.keys(object)
			for (const key of keys) {
			if (sortingType[key] === true) {result.push(key)}
			}
			return result
		}

		const types = getKeyByValue(sortingType, true)
		
		if (types.length > 0) {
			const originalTypes = ['Strategy', 'Strategic intention', 'Strategic goal', 'Business goal', 'Business objective', 'Project goal', 'Sprint goal', 'Task goal']
			const potato = originalTypes.filter(n => !types.includes(n))
			for (const p of potato) {
			result = result.filter(item => item.type !== p)
			}
		}
		
		}
		if (sortingLevel) {
		result = result.filter(item => item.level === Number(sortingLevel))
		}

		setSortedData(groupArrayIntoSixes(result, 20))
		setPaginationLevels(sortedData.length)
	}, [data, sortingName, sortingTag, sortingParent, sortingType, sortingLevel, setPaginationLevels, sortedData.length])


	const sortData = (sortKey) => {
		let result = []

		if (sortedData.length > 1) {
		for (const array of sortedData) {
			result.push(...array)
		}

		} else {
		for (const array of data) {
			result.push(...array)
		}
		}

		const reverseSort = (sortKey) => {
			setActiveSort({key: sortKey, inc: false })
			result.reverse()
		}

		if (sortKey === 'id') {
		result.sort((a, b) => parseFloat(a.id) - parseFloat(b.id))

		if (activeSort.key === sortKey && activeSort.inc) {
			reverseSort(sortKey)
		}
		else {
			setActiveSort({key: 'id', inc: true})
		}
		}
		if (sortKey === 'name') {
		result.sort((a, b) => a.name.localeCompare(b.name))

		if (activeSort.key === sortKey && activeSort.inc) {
			reverseSort(sortKey)
		} else {
			setActiveSort({key: 'name', inc: true})
		}
		}
		if (sortKey === 'level') {
		result.sort((a, b) => parseFloat(a.level) - parseFloat(b.level))

		if (activeSort.key === sortKey && activeSort.inc) {
			reverseSort(sortKey)
		} else {
			setActiveSort({key: 'level', inc: true})
		}
		}
		if (sortKey === 'type') {
		result.sort((a, b) => a.type.localeCompare(b.type))

		if (activeSort.key === sortKey && activeSort.inc) {
			reverseSort(sortKey)
		} else {
		setActiveSort({key: 'type', inc: true})
		}
		}
		if (sortKey === 'tag') {
		result.sort((a, b) => a.type.localeCompare(b.type))

		if (activeSort.key === sortKey && activeSort.inc) {
			reverseSort(sortKey)
		} else {
		setActiveSort({key: 'tag', inc: true})
		}
		}
		if (sortKey === 'parent') { 
		result.sort((a, b) => parseFloat(a.parent_id) - parseFloat(b.parent_id))

		if (activeSort.key === sortKey && activeSort.inc) {
			reverseSort(sortKey)
		} else {
		setActiveSort({key: 'parent', inc: true}) 
		}
		}
		if (sortKey === 'link') {
		result.sort((a, b) => parseFloat(a.link) - parseFloat(b.link))

		if (activeSort.key === sortKey && activeSort.inc) {
			reverseSort(sortKey)
		} else {
		setActiveSort({key: 'link', inc: true})
		}
		}
		setSortedData(groupArrayIntoSixes(result, 20))
		setPaginationLevels(sortedData.length)
	}

	return (
		<div className="flex flex-col w-[100%]">
		{showDelete && (
			<Modal
			setShowDelete={setShowDelete}
			deleteId={deleteId}
			deleteItem={deleteItem}
			deleteItemType={'goal'}
			/>
		)}
		<table className="table-auto w-full text-left whitespace-no-wrap">
			<thead className="table-header">
			<tr>
				<GoalsTableHeaderItem name={'ID'} sortData={sortData} value={'id'} activeSort={activeSort}/>
				<GoalsTableHeaderItem name={'NAME'} sortData={sortData} value={'name'} activeSort={activeSort} setSortingName={setSortingName}/>
				<GoalsTableHeaderItem name={'LEVEL'} sortData={sortData} value={'level'} activeSort={activeSort} setSortingLevel={setSortingLevel}/>
				<GoalsTableHeaderItem name={'TAG'} sortData={sortData} value={'tag'} activeSort={activeSort} setSortingTag={setSortingTag}/>
				<GoalsTableHeaderItem name={'TYPE'} sortData={sortData} value={'type'} activeSort={activeSort} setSortingType={setSortingType}/>
				<GoalsTableHeaderItem name={'PARENT'} sortData={sortData} value={'parent'} activeSort={activeSort} setSortingParent={setSortingParent}/>
				<GoalsTableHeaderItem name={'NOTE'} sortData={sortData} value={'description'} activeSort={activeSort}/>
				<GoalsTableHeaderItem name={'LINK'} sortData={sortData} value={'link'} activeSort={activeSort}/>
			</tr>
			</thead>
			<tbody className="table-body">
			{sortedData.length > 0 &&
				sortedData[current].map((item) => {
					return (
					<GoalItem
						goals={goals}
						key={item.id} 
						item={item}
						goalType={goalType}
						showDelete={showDelete} 
						deleteId={deleteId}
						setDeleteItem={setDeleteItem}  
						setShowDelete={setShowDelete} 
						setDeleteId={setDeleteId} 
						// setUpdateChosen={setUpdateChosen} 
						// setUpdateId={setUpdateId} 
						// setUpdateName={setUpdateName} 
						// setUpdateLevel={setUpdateLevel} 
						// setUpdateParent={setUpdateParent} 
						// setUpdateLink={setUpdateLink}
						// setUpdateTag={setUpdateTag}
						// setUpdateType={setUpdateType}
						// setUpdateDescription={setUpdateDescription}
						// setAddChosen={setAddChosen} 

						setParent={setParent}
						setType={setType}
						setLevel={setLevel}
						setIsAddingChild={setIsAddingChild}
						accessButtons={accessButtons}
					/>
				)
				}
			)
			}
			</tbody>
		</table>
		</div>
	);
};

export default GoalsTable;
