import React, {useState, useRef} from 'react'
import html2canvas from "html2canvas"
import Draggable from 'react-draggable'

import useOnClickOutside from '../hooks/useOnClickOutside'

const ModalFeedback = ({ closeModal, newFeedback }) => { 
    const ref = useRef()
    useOnClickOutside(ref, () => closeModal())
    const url = window.location.href
    
    const [feedback, setFeedback] = useState({title: '', text: '', url, screenshot: '', userName: localStorage.getItem('userName')})
    const [isAttachScreenShot, setIsAttachScreenShot] = useState(true)
    
    const handleKeyPress = (e) => {
        if(e.key === 'Escape'){
            closeModal()
        }
    }

    const downloadImage = async () => {
        closeModal()
        setTimeout(async () => {
            const canvas = await html2canvas(document.getElementById('root'))
            const screenshotUrl = canvas.toDataURL()
            const base64Image = screenshotUrl.split(',')[1]

            if (isAttachScreenShot) {
                newFeedback({...feedback, screenshot: base64Image})
            } else {
                newFeedback(feedback)
            }
            setFeedback({title: '', text: '', url, screenshot: '', userName: localStorage.getItem('userName')})

        }, 100)
    }

    const feedbackTitleChangeHandler = (e) => {
        setFeedback({...feedback, title: e.target.value})
    }

    const feedbackTextChangeHandler = (e) => {
        setFeedback({...feedback, text: e.target.value})
    }

    const attachScreenShotChangeHandler = (e) => {
        setIsAttachScreenShot(!isAttachScreenShot)
    }

    

    return (
        <div id="openModal" className="modalbg">
            <div className="dialog">
            <Draggable
                handle=".save-controls-feedback"
                defaultPosition={{ x: 0, y: 0 }}
                position={null}
                grid={[1, 1]}
                scale={1}
            >
                <div id="dialog-window" className="save-controls-feedback">
                    <h5 className='save-controls__title'>Send a feedback</h5>
                    <button className="close" onClick={closeModal}>X</button>
                    <div>
                        <p>Title</p>
                        <input
                            value={feedback.title}
                            onChange={feedbackTitleChangeHandler}
                            placeholder="Title"
                            type="text"
                            id="title"
                            autoFocus
                            name="title"
                            onKeyDown={handleKeyPress}
                            className="h-11 w-96 shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        />
                    </div>
                    <div>
                        <p>Text</p>
                        <textarea
                            value={feedback.text}
                            onChange={feedbackTextChangeHandler}
                            placeholder="Text"
                            type="text"
                            maxLength="500"
                            id="text-field"
                            autoFocus
                            name="text-field"
                            onKeyDown={handleKeyPress}
                            className="text-area h-20 w-96 shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        />
                    </div>
                    <div className="w-9/12">
                        <input type="checkbox" checked={isAttachScreenShot} onChange={attachScreenShotChangeHandler}/>
                        <label>Include screenshot of the page</label>
                    </div>
                    <button 
                        onClick={downloadImage}
                        className="text-white bg-second-color border-0 py-2 mt-6 px-12 focus:outline-none rounded text-lg"
                    >
                        Send
                    </button>                    
                </div>
            </Draggable>
            </div>
        </div>
    );
};

export default ModalFeedback;
