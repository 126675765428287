import { connect } from "react-redux"
import { fetched, token, loading, errorMessage, successMessage } from "../store/reducers/selectors"
import { newPassword, clearErrorMessage, clearSuccessMessage } from "../api/actions"
import { createStructuredSelector } from "reselect"
import NewPassword from "./NewPassword"


const mapStateToProp = createStructuredSelector({
    token,
    fetched,
    loading,
    errorMessage,
    successMessage
})

const mapDispatchToProps = {
    newPassword,
    clearErrorMessage,
    clearSuccessMessage
}

export default connect(mapStateToProp, mapDispatchToProps)(NewPassword);