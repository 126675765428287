import React, { useState, useRef } from 'react'
import * as xlsx from 'xlsx'

import RegularButton from '../../ui/buttons/RegularButton'                                                                                                                                                                                                                                                                                                                                                                                                         

import { TbUpload } from 'react-icons/tb'

const  BusinessImport = ({ loading, business, application, bpRelations, importBusiness, importApplication, importBPRelations, capabilityType, setSuccess, setError, setWarning, access, relationship }) => {
    const [file, setFile] = useState()
    const [dragActive, setDragActive] = useState(false)
    const inputRef = useRef(null)

    const getPropertyId = (property) => {
		const propertyIndex = capabilityType && capabilityType.indexOf(property)

		return propertyIndex ? propertyIndex : 1
	}

    const getRelationshipId = (relationshipName) => {
		const relationshipItem = relationship && relationship.find(r => r.name === relationshipName)

		return relationshipItem ? relationshipItem.id : 1
	}

    const onlyImportantFields = (data, existingBusinesses, existingApplications, existingRelations) => {
        const businesses = []
        const applications = []
        const relations = []

        for (const item of data) {
            const existingBusiness =  existingBusinesses && existingBusinesses.find(b => b.name === item.name)
            const existingApplication =  existingApplications && existingApplications.find(a => a.name === item.application_name)
            const existingRelation =  existingRelations && existingRelations.find(r => r.bp_list_id === existingBusiness.id)

            businesses.push({
                id: existingBusiness ? existingBusiness.id : (item.id || null),
                name: item.name,
                start_date: item.start_date,
                end_date: item.end_date,
                property_id: getPropertyId(item.process_level),
                tag: item.tag,
                link: item.link,
            })

            if (item.application_id || item.application_name) {
                applications.push({
                    id: existingApplication ? existingApplication.id : (item.application_id || null),
                    name: existingApplication ? existingApplication.name : item.application_name,
                    start_date: item.start_date_ap,
                    end_date: item.end_date_ap,
                    tag: item.tag,
                    link: item.link,
                })
            }
            if (existingApplication) {
                relations.push({
                    id: existingRelation ? existingRelation.id : null,
                    bp_list_id: existingBusiness && existingBusiness.id,
                    application_id: existingApplication && existingApplication.id,
                    start_date_ap: item.start_date_ap,
                    end_date_ap: item.end_date_ap,
                    relationship_dict_id: getRelationshipId(item.relation),
                })
            }
           
        }
        return { businesses, applications, relations }
    }

    const createTree = (data, existingBusinesses, existingApplications, existingRelations) => {
        const newData = onlyImportantFields(data, existingBusinesses, existingApplications, existingRelations)
        
        return newData
    }
    
    // handle drag events
    const handleDrag = function(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    }
    
    // triggers when file is dropped
    const handleDrop = function(e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFileChange(e.dataTransfer.files[0]);
        }
    }
    
    // triggers when file is selected with click
    const handleChange = function(e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            handleFileChange(e.target.files[0]);
        }
    }
    
    // triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click();
    }

    const handleFileChange = (file) => {
        setFile(file)
    }

    const uploadNewCaps = async (json) => {
        setWarning('Work in progress')
        if (json.length) {
            const { businesses, applications } = createTree(json, business, application, bpRelations)
            const importedBusinesses = await importBusiness({businesses})
            const importedApplications = await importApplication({applications})

                if (importedApplications && importedBusinesses) {
                    const businessData = importedBusinesses.payload && importedBusinesses.payload.data.data
                    const applicationData = importedApplications.payload && importedApplications.payload.data.data
                    const { relations } = createTree(json, businessData, applicationData, bpRelations)

                    importBPRelations({bpRelations: relations})
                    .then(() => {
                        setWarning('')
                    })
                    return
                }
                setWarning('')
        }
        setWarning('')
        setError('Nothing to import')
        setTimeout(() => {
            setError('')
        }, 2000)
    }

    const handleUploadClick = () => {
        if (!file) {
            setError('Nothing to import')
            setTimeout(() => {
                setError('')
            }, 2000)
            return
        }

        const readerCaps = new FileReader()
        readerCaps.onload = async (e) => {
            const data = e.target.result
            const workbook = xlsx.read(data, { type: "array" })
            const sheetName = workbook.SheetNames[0]
            const worksheet = workbook.Sheets[sheetName]
            const json = xlsx.utils.sheet_to_json(worksheet)

            setFile()


            uploadNewCaps(json)

        }

        readerCaps.readAsArrayBuffer(file)
    }

    const excelImport = () => {
        const wb = xlsx.utils.book_new()
        const ws = xlsx.utils.json_to_sheet([{ 
            id: "1", 
            name: "Example name", 
            start_date: "",
            end_date: "",
            tag: "Support", 
            process_level: "2029-01-01", 
            link: "",
            application_id: "",
			application_name: "",
			relation: "",
			start_date_ap: "",
			end_date_ap: "",

   
        }])

        xlsx.utils.book_append_sheet(wb, ws, 'processes-export')
    
        xlsx.writeFile(wb, 'Export-processes-template.xlsx')
    }

    return (
        <div className="reports">
            <div className="top-table mt-4">
                <div className="plus-minus">
                </div>
                <div className="empty-template">
                    <RegularButton                    
                        className="text-white bg-second-color border-0 py-2 px-2 focus:outline-none rounded w-72"
                        onClick={excelImport}
                        notRestricted={true}
                    >
                        Download empty template
                    </RegularButton>
                </div>
            </div>
            <div className={loading ? "form-container disabled flex justify-center items-center flex-col flex-wrap" : "form-container flex justify-center items-center flex-col flex-wrap"}>
                <form id="form-file-upload" className="upload-files-container" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                        <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
                        <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" }>
                        <div className="drag-file-area">
                            {
                                !file ? <>
                                        <TbUpload className="upload-icon" />
                                        <h3>Drag and drop your file here</h3>
                                        <p> or <span className="upload-button">browse file</span> from the device</p>
                                    </> 
                                :
                                    <>
                                        <p>{file.name} - {file.type}</p>
                                        <button className="upload-button" onClick={onButtonClick}>Change file</button>
                                    </>
                            }
                        </div> 
                        </label>
                        { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
                        <RegularButton                    
                            className="text-white bg-second-color border-0 py-2 mt-6 px-12 focus:outline-none rounded text-lg w-64"
                            onClick={handleUploadClick}
                            notRestricted={true}
                        >
                            Upload
                        </RegularButton>
                    </form>
                </div>
        </div>
    )
}

export default BusinessImport