import React from 'react';
import { BsTrash3 } from 'react-icons/bs'
import { FiEdit } from 'react-icons/fi'
import IconButton from '../ui/buttons/IconButton';


const ActivityItem = ({
        item,
        showDelete, 
        deleteId,
        setDeleteItem, 
        setShowDelete, 
        setDeleteId, 
        setUpdateChosen, 
        setAddChosen, 
        setUpdateId, 
        setUpdateName, 
        setUpdateStartDate, 
        setUpdateEndDate, 
        setUpdateParent, 
        setUpdateLink,
        setUpdateGoal,
        setUpdateType,
        setUpdateOwner,
        setUpdatePredecessor,
        setUpdateScore,
        setUpdateTag,
        setUpdateSort,
        setUpdateLCost,
        setUpdateRCost,
        setUpdateEffort,
        setUpdateDescription,
        activityType,
        access
}) => {
    const {
        Activities_Add,
        Activities_Edit,
        Activities_Delete,
    } = access

    const currentType = activityType.find(type => type.id === item.activity_type_id)

    const handleDoubleClick = event => {
        if (!Activities_Edit) return
        if (event.detail === 2) {
            setUpdateChosen(true);
            setAddChosen(false);
            setUpdateId(item.id);
            setUpdateName(item.name);
            setUpdateStartDate(item.start_date);
            setUpdateEndDate(item.end_date);
            setUpdateType(currentType && currentType.name);
            setUpdateParent(item.parent);
            setUpdateLink(item.link);
            setUpdateGoal(item.goal_id)
            setUpdateOwner(item.owner)
            setUpdatePredecessor(item.predecessor)
            setUpdateScore(item.score)
            setUpdateTag(item.tag)
            setUpdateSort(item.sorting_number)
            setUpdateLCost(item.labor_cost)
            setUpdateRCost(item.resources_cost)
            setUpdateEffort(item.effort)
            setUpdateDescription(item.description)
        }
    }
    return (
        <tr
            key={item.id}
            className="capability-item"
            style={{
                backgroundColor:
                showDelete && deleteId === item.id && "#A3AAC4",
            }}
            onClick={(e) => {handleDoubleClick(e)}}
            >
            <td className="px-4 py-3">{item.id}</td>
            <td className="px-4 py-3">{item.name}</td>
            <td className="px-4 py-3">{currentType && currentType.name}</td>
            <td className="px-4 py-3">{item.goal_id}</td>
            <td className="px-4 py-3">{item.sorting_number}</td>
            <td className="px-4 py-3">{item.predecessor}</td>
            <td className="px-4 py-3">{item.parent}</td>
            <td className="px-4 py-3">{item.owner}</td>
            <td className="px-4 py-3">{item.score}</td>
            <td className="px-4 py-3">{item.start_date}</td>
            <td className="px-4 py-3">{item.end_date}</td>
            <td className="px-4 py-3">{item.tag}</td>
            <td className="px-4 py-3">{item.labor_cost}</td>
            <td className="px-4 py-3">{item.resources_cost}</td>
            <td className="px-4 py-3">{item.effort}</td>
            <td className="px-4 py-3">{item.description}</td>
            <td className="px-4 py-3 text-blue-800 flex link">
                {item.link.length > 0 ? <a href={item.link} rel="noreferrer">Link </a> : <div>Link</div>}
                <div className='capability-controls'>
                    <IconButton
						className="ml-5"
						onClick={() => {
							setShowDelete(!showDelete)
							setDeleteId(item.id)
							setDeleteItem({name: item.name})
						}}
						notRestricted={Activities_Delete}
					>
						<BsTrash3 alt="delete" />
					</IconButton>
                    <IconButton
						className="ml-5"
						onClick={() => {
                            setUpdateChosen(true);
                            setAddChosen(false);
                            setUpdateId(item.id);
                            setUpdateName(item.name);
                            setUpdateStartDate(item.start_date);
                            setUpdateEndDate(item.end_date);
                            setUpdateType(currentType && currentType.name);
                            setUpdateParent(item.parent);
                            setUpdateLink(item.link);
                            setUpdateGoal(item.goal_id)
                            setUpdateOwner(item.owner)
                            setUpdatePredecessor(item.predecessor)
                            setUpdateScore(item.score)
                            setUpdateTag(item.tag)
                            setUpdateSort(item.sorting_number)
                            setUpdateLCost(item.labor_cost)
                            setUpdateRCost(item.resources_cost)
                            setUpdateEffort(item.effort)
                            setUpdateDescription(item.description)
                        }}
						notRestricted={Activities_Edit}
					>
						<FiEdit alt="edit" />
					</IconButton>
                </div>
            </td>
        </tr>
    );
};

export default ActivityItem;