import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const NewCapabilitiesReportHorizontalDownload = ({
  item,
  data,
  heatmapActive,
  timeline,
  isHeatmapNotDot,
  itemWidth,
  verticalData,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const editCapabilityHandler = (item) => {
    if (location.pathname !== "/capabilities") {
      navigate("/capabilities");

      return;
    }
    // setUpdateChosen(true);
    // setActive("Capabilities");
    // setUpdateName(item.name);
    // setUpdateLink(item.link);
    // setUpdateProperty(item.property);
    // setUpdateStartDate(item.start_date);
    // setUpdateEndDate(item.end_date);
    // setUpdateId(item.id);
    // setUpdateFirstLevel(item.first_level);
    // setUpdateSecondLevel(item.second_level);
    // setUpdateThirdLevel(item.third_level);
    // setUpdateSetId(
    //   set.find((element) => element.id === item.universal_item_set_id).name
    // );
    // setMaturity(item.maturity === null ? [] : item.maturity);
  };

  const getMaturityColor = (item, level) => {
    const itemMaturities =
      item.maturity &&
      item.maturity.filter(
        (el) => el.change_date === timeline || el.change_date < timeline
      );
    itemMaturities && itemMaturities.sort((a, b) => b.id - a.id);
    const itemMaturity =
      itemMaturities && itemMaturities.length > 0 ? itemMaturities[0] : null;
    // const itemMaturity = item.maturity && item.maturity.find(el => el.change_date === timeline || el.change_date < timeline)

    if (itemMaturity) {
      if (itemMaturity.maturity_level_dict.name === "No capability") {
        return "maturity-color-1";
      }
      if (itemMaturity.maturity_level_dict.name === "Initial") {
        return "maturity-color-2";
      }
      if (itemMaturity.maturity_level_dict.name === "Managed") {
        return "maturity-color-3";
      }
      if (itemMaturity.maturity_level_dict.name === "Defined") {
        return "maturity-color-4";
      }
      if (itemMaturity.maturity_level_dict.name === "Quantitatively Managed") {
        return "maturity-color-5";
      }
      return "maturity-color-6";
      // old way of changing class name
      // return 'maturity-color-' + itemMaturity.maturity_level_dict.id
    }
    if (level === 1) {
      return "";
    }
    if (level === 2) {
      return "no-maturity-color";
    }
    if (level === 3) {
      return "";
    }
  };

  return (
    <div
      className={
        heatmapActive && isHeatmapNotDot
          ? "flex bg-white/50 text-xs relative w-full min-h-[100px] h-full transition-all duration-[0.2s] rounded-xl" +
            getMaturityColor(item, 1)
          : "flex bg-white/50 text-xs relative w-full min-h-[100px] h-full transition-all duration-[0.2s] rounded-xl border"
      }
    >
      {heatmapActive && (
        <i className={"maturity-min " + getMaturityColor(item, 1)} />
      )}
      <div
        onClick={() => editCapabilityHandler(item)}
        className="flex flex-col cursor-pointer whitespace-nowrap ml-[-1.5%] bg-white/50 h-auto [writing-mode:vertical-rl] rotate-180 rounded-md"
      >
        <p className="flex justify-center py-2 w-full mr-1">
          {"h" +
            "." +
            item.first_level +
            "." +
            item.second_level +
            "." +
            item.third_level +
            "/" +
            item.lane +
            " " +
            item.name}
        </p>
      </div>
      <div className="flex w-full h-full gap-[1%] min-h-[100px]">
        {verticalData.map((vi) => {
          return (
            <div
              key={vi.id}
              style={{ width: itemWidth + "%" }}
              className="flex flex-col justify-start items-center transition-all h-auto gap-[5px] bg-white py-2" //bg-white
            >
              {data.map((i2) => {
                if (
                  i2.first_level === item.first_level &&
                  i2.second_level !== 0 &&
                  i2.third_level === 0 &&
                  i2.layout === "horizontal" &&
                  i2.lane === vi.first_level
                ) {
                  return (
                    <div
                      key={i2.id}
                      className="flex justify-center items-center w-full min-w-[100px] transition-all"
                    >
                      <div
                        className={
                          heatmapActive && isHeatmapNotDot
                            ? "flex flex-col items-center w-[80%] gap-5 relative h-full transition-all duration-[0.2s] p-[13px] rounded-lg " +
                              getMaturityColor(i2, 2)
                            : "flex items-center gap-5 w-[80%] flex-col relative h-full transition-all duration-[0.2s] p-[13px] rounded-lg no-maturity-color"
                        }
                      >
                        {heatmapActive && (
                          <i
                            className={
                              "maturity-min " + getMaturityColor(i2, 2)
                            }
                          />
                        )}
                        <p
                          className="cursor-pointer text-center"
                          onClick={() => editCapabilityHandler(i2)}
                        >
                          {"h" +
                            "." +
                            i2.first_level +
                            "." +
                            i2.second_level +
                            "." +
                            i2.third_level +
                            "/" +
                            i2.lane}{" "}
                          {i2.name}
                        </p>
                        {data.map((i3) => {
                          if (
                            item.first_level === i3.first_level &&
                            i2.second_level === i3.second_level &&
                            i3.third_level !== 0 &&
                            i3.layout === "horizontal"
                          ) {
                            return (
                              <div
                                className={
                                  heatmapActive && isHeatmapNotDot
                                    ? "third-level-capability " +
                                      getMaturityColor(i3, 3)
                                    : "third-level-capability"
                                }
                                key={i3.id}
                              >
                                {heatmapActive && (
                                  <i
                                    className={
                                      "maturity-min " + getMaturityColor(i3, 3)
                                    }
                                  />
                                )}
                                <p
                                  className="cursor-pointer"
                                  onClick={() => editCapabilityHandler(i3)}
                                >
                                  {"h" +
                                    "." +
                                    i3.first_level +
                                    "." +
                                    i3.second_level +
                                    "." +
                                    i3.third_level +
                                    "/" +
                                    i3.lane}{" "}
                                  {i3.name}
                                </p>
                              </div>
                            );
                          }
                          return null;
                        })}
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          );
        })}
      </div>
    </div>

    // </div>
  );
};

export default NewCapabilitiesReportHorizontalDownload;
