import styled from "styled-components";

export const NewCard = styled.div`
  font-size: 0.8em;
  color: grey;
  min-width: 100px;
  text-align: center;
  cursor: pointer;
`;

export const CardsContainer = styled.div`
  display: flex;
  margin-left: 10px;
`;

export const CardsContainerHorizontal = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.width}px;
  gap: 5px;
  margin-top: 10px;
  margin-left: ${props => props.backlog ? props.columnWidth + 51 : 0}px;
  transition: all 0.2s ease-in-out;
`;

export const Input = styled.input`
  font-size: ${(props) => props.fontSize || "inherit"};
  font-family: inherit;
  margin: ${(props) => props.margin || "inherit"};
  padding: 8px;
  width: 100%;
`;
export const TitleBarHorizontal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-left: -41px;
  background-color: #fff;
  text-align: center;
  border-radius: 12px;
`;
export const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled.p`
  font-size: 12px;
  text-overflow: ellipsis;
  width: 100%;
`;

export const TitleHorizontal = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Cross = styled.div`
  position: absolute;
  right: 10%;
  top: 0;
  cursor: pointer;
  color: grey;
`;

export const CrossHorizontal = styled.div`
  color: grey;
  cursor: pointer;
`;

export const Up = styled.div`
  position: absolute;
  left: 10%;
  top: 0;
  cursor: pointer;
  color: grey;
`;

export const Down = styled.div`
  position: absolute;
  left: 10%;
  bottom: 0%;
  cursor: pointer;
  color: grey;
  font-size: 1em;
`;

export const DownHorizontal = styled.div`
  cursor: pointer;
  color: grey;
  font-size: 1em;
`;

export const CardContainer = styled.div`
  display: ${props => props.backlog ? 'none' : 'flex'};
  position: relative;
  flex-direction: column;
  align-items: center;
  padding: 13px 13px;
  min-width: ${(props) => props.columnWidth}px;
  width: ${(props) => props.columnWidth}px;
  background: #ffffff;
  border-radius: 12px;
  transition: all 0.2s;
  margin-right: 5px;
`;

export const CardContainerHorizontal = styled.div`
  display: flex;
  `;

export const CardContainerHorizontalContent = styled.div`
  display: flex;
  width: 100%;
  transition: all 0.2s;
  margin-right: 5px;
  min-height: 100px;
  gap: 5px;
`;

export const TaskList = styled.div`
  padding: 1px;
  background-color: ${(props) =>
    props.isDraggingOver ? "skyblue" : "inherit"};
  min-height: 20px;
  width: 100%;
  `;

export const TaskListHorizontal = styled.div`
  padding: 1px;
  background-color: ${(props) =>
    props.isDraggingOver ? "skyblue" : "inherit"};
  min-height: 20px;
  width: 100%;
`;

export const LaneContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  min-width: ${(props) => props.columnWidth}px;
  width: ${(props) => props.columnWidth}px;
  padding: 13px;
  border-radius: 12px;
  background-color: #fff;
`;

export const NewTaskBar = styled.div`
  display: flex;
`;

export const NewTaskButton = styled.div`
  cursor: pointer;
  text-align: center;
  color: grey;
  font-size: 0.8em;
`;

export const TaskContainer = styled.div`
  display: flex;
  width: 100%;
`;
export const TaskHorizontalContainer = styled.div`
  display: flex;
  width: 100%;
  border-radius: 12px;
`;

export const TaskContent = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 13px 13px;
  margin-bottom: 5px;
  border-radius: 8px;
  transition: all 0.2s;
  font-style: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #1e1e1e;
  background-color: ${(props) => (props.isdragging ? "lightgreen" : "#C9D5F2")};
`;
