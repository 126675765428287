import React, { useEffect, useState } from "react";

import groupArrayIntoSixes from "../../handlers/groupArrayHandler";

import Modal from "../Modal";
import ProductTableHeaderItem from './ProductTableHeaderItem'
import ProductItem from './ProductItem';


const ProductTable = ({
	data,
	deletedChosen,
	setSelected,
	setUpdateName,
	setUpdateId,
	setSuccess,
	setError,
	getData,
	setUpdateChosen,
	setAddChosen,
	current,
	setPaginationLevels,
	setUpdatePortfolio,
	setUpdateProducts,
	setUpdateType,
	setUpdateActivityField,
	setUpdateProcess,
	access
}) => {
	const [showDelete, setShowDelete] = useState(false);
	const [deleteId, setDeleteId] = useState("");
	const [deleteItem, setDeleteItem] = useState({name: ''});
	const [sortedData, setSortedData] = useState([])
	const [activeSort, setActiveSort] = useState({key: '', inc: true})

	const [sortingName, setSortingName] = useState("")
	const [sortingProducts, setSortingProducts] = useState("")
	const [sortingPortfolio, setSortingPortfolio] = useState("")
	const [sortingType, setSortingType] = useState("")
	const [sortingActivity, setSortingActivity] = useState("")
	const [sortingProcess, setSortingProcess] = useState('')

	useEffect(() => {
		let result = []

		for (const array of data) {
			result.push(...array)
		}

		if (sortingName) {
			result = result.filter(item => item.name.includes(sortingName))
		}

		if (sortingType) {
			result = result.filter(item => item.parent_type === Number(sortingType))
		}

		if (sortingActivity) {
			result = result.filter(item => item.parent_activity === Number(sortingActivity))
		}

		if (sortingPortfolio) {
			const getKeyByValue = (object) => {
				const result = []
				const keys = Object.keys(object)
					for (const key of keys) {
						if (sortingPortfolio[key] === true) {result.push(key)}
					}
					return result
			}
					
			const properties = getKeyByValue(sortingPortfolio, true)

			if (properties.length > 0) {
				const originalProperties = ['Yes', 'No']
				const filteredProperties = originalProperties.filter(n => !properties.includes(n))
				for (const p of filteredProperties) {
					result = result.filter(item => (item.portfolio_flag > 0 ? 'Yes' : 'No') !== p)
				}
			}
		}

		if (sortingProducts) {
			result = result.filter(item => item.list_of_products.some(product => product.includes(sortingProducts)))
		}

		if (sortingProcess) {
			result = result.filter(item => item.products === Number(sortingProcess))
		}


		setSortedData(groupArrayIntoSixes(result, 20))
		setPaginationLevels(sortedData.length)
	}, [data, sortingName, sortingType, setPaginationLevels, sortedData.length, sortingActivity, sortingPortfolio, sortingProcess, sortingProducts])


	const sortData = (sortKey) => {
		let result = []

		if (sortedData.length > 1) {
			for (const array of sortedData) {
				result.push(...array)
			}

		} else {
			for (const array of data) {
				result.push(...array)
			}
		} 
		const reverseSort = (sortKey) => {
				setActiveSort({key: sortKey, inc: false })
				result.reverse()
		}

		if (sortKey === 'id') {
			result.sort((a, b) => parseFloat(a.id) - parseFloat(b.id))

			if (activeSort.key === sortKey && activeSort.inc) {
				reverseSort(sortKey)
			}
			 else {
				setActiveSort({key: 'id', inc: true})
			}
		}
		
		if (sortKey === 'name') {
			result.sort((a, b) => a.name.localeCompare(b.name))

			if (activeSort.key === sortKey && activeSort.inc) {
				reverseSort(sortKey)
			} else {
				setActiveSort({key: 'name', inc: true})
			}
		}
		
		if (sortKey === 'portfolio-flag') {
			result.sort((a, b) => parseFloat(a.portfolio_flag) - parseFloat(b.portfolio_flag))

			if (activeSort.key === sortKey && activeSort.inc) {
				reverseSort(sortKey)
			} else {
			setActiveSort({key: 'portfolio-flag', inc: true})
			}
		}

		// if (sortKey === 'list-of-products') { 
		//   result.sort((a, b) => a.list_of_products.localeCompare(b.list_of_products))

		//   if (activeSort.key === sortKey && activeSort.inc) {
		//     reverseSort(sortKey)
		//   } else {
		//   setActiveSort({key: 'list-of-products', inc: true}) 
		//   }
		// }

		if (sortKey === 'parent-type') { 
			result.sort((a, b) => parseFloat(a.parent_type) - parseFloat(b.parent_type))

			if (activeSort.key === sortKey && activeSort.inc) {
				reverseSort(sortKey)
			} else {
			setActiveSort({key: 'parent-type', inc: true}) 
			}
		}

		if (sortKey === 'activity-id') { 
			result.sort((a, b) => parseFloat(a.activity_id) - parseFloat(b.activity_id))

			if (activeSort.key === sortKey && activeSort.inc) {
				reverseSort(sortKey)
			} else {
			setActiveSort({key: 'activity-id', inc: true}) 
			}
		}

		if (sortKey === 'parent-process') { 
			result.sort((a, b) => parseFloat(a.parent_process) - parseFloat(b.parent_process))

			if (activeSort.key === sortKey && activeSort.inc) {
				reverseSort(sortKey)
			} else {
			setActiveSort({key: 'parent-process', inc: true}) 
			}
		}



		setSortedData(groupArrayIntoSixes(result, 20))
		setPaginationLevels(sortedData.length)
	}

	return (
		<div className="flex flex-col w-[100%]">
			{showDelete && (
				<Modal
					setShowDelete={setShowDelete}
					deleteId={deleteId}
					deleteItem={deleteItem}
					deleteItemType={'product'}
					setSuccess={setSuccess}
					setError={setError}
					getData={getData}
				/>
			)}
			<table className="table-auto w-full text-left whitespace-no-wrap">
				<thead className="table-header">
					<tr>
						<ProductTableHeaderItem name={'ID'} sortData={sortData} value={'id'} activeSort={activeSort}/>
						<ProductTableHeaderItem name={'NAME'} sortData={sortData} value={'name'} activeSort={activeSort} setSortingName={setSortingName}/>
						<ProductTableHeaderItem name={'PORTFOLIO'} sortData={sortData} value={'portfolio-flag'} activeSort={activeSort} setSortingPortfolio={setSortingPortfolio}/>
						<ProductTableHeaderItem name={'PRODUCTS'} sortData={sortData} value={'list-of-products'} activeSort={activeSort} setSortingProducts={setSortingProducts}/>
						<ProductTableHeaderItem name={'TYPE'} sortData={sortData} value={'parent-type'} activeSort={activeSort} setSortingType={setSortingType}/>
						<ProductTableHeaderItem name={'ACTIVITY'} sortData={sortData} value={'activity-id'} activeSort={activeSort} setSortingActivity={setSortingActivity}/>
						<ProductTableHeaderItem name={'PROCESS'} sortData={sortData} value={'parent-process'} activeSort={activeSort} setSortingProcess={setSortingProcess}/>
					</tr>
				</thead>
				<tbody className="table-body">
				{sortedData.length > 0 &&
							sortedData[current].map((item) => {
								return (
									<ProductItem 
										key={item.id} 
										item={item}
										showDelete={showDelete} 
										deleteId={deleteId}
										setDeleteItem={setDeleteItem}  
										setShowDelete={setShowDelete} 
										setDeleteId={setDeleteId} 
										setUpdateChosen={setUpdateChosen} 
										setAddChosen={setAddChosen} 
										setUpdateId={setUpdateId} 
										setUpdateName={setUpdateName} 
										setUpdateType={setUpdateType}
										setUpdatePortfolio={setUpdatePortfolio}
										setUpdateProducts={setUpdateProducts}
										setUpdateActivityField={setUpdateActivityField}
										setUpdateProcess={setUpdateProcess}

										access={access}
									/>
							)
						}
					)
				}
				</tbody>
			</table>
		</div>
	);
};

export default ProductTable;
