import { connect } from "react-redux"
import { fetched, loading, application, business, bpRelations, capabilityType, relationship } from '../../../store/reducers/selectors'
import { importApplication, importBusiness, importBPRelations } from "../../../api/actions"
import { createStructuredSelector } from "reselect"
import ApplicationsImport from "./ApplicationsImport"


const mapStateToProp = createStructuredSelector({
    fetched,
    loading,
    application,
    business,
    bpRelations,
    capabilityType,
    relationship
})

const mapDispatchToProps = {
    importApplication,
    importBusiness,
    importBPRelations
}

export default connect(mapStateToProp, mapDispatchToProps)(ApplicationsImport)