import React, { useEffect, useState } from "react";

import CapabilitiesReport from "./NewCapabilitiesReport";
import ModalSave from "../ModalSave";

import Left from "../../assets/left.svg";
import Right from "../../assets/right.svg";
import { GoPlus } from "react-icons/go";
import { TbTemperature, TbZoomInArea, TbZoomOutArea } from "react-icons/tb";
import { BiDownload } from "react-icons/bi";
import { RxDotFilled } from "react-icons/rx";
import { BsFillSquareFill } from "react-icons/bs";

import groupArrayIntoSixes from "../../handlers/groupArrayHandler";
import IconButton from "../ui/buttons/IconButton";
import SelectSet from "../ui/inputs/SelectSet";
// import CapabilitiesReportHorizontal from "./CapabilitiesReportHorizontal";
import CapabilitiesReportVertical from "./CapabilitiesReportVertical";
import NewCapabilitiesReportHorizontal from "./NewCapabilitiesReportHorizontal";
// import { dataArray } from "../../capabilities.js";

const NewBCM = ({
  dataArray,
  organizationName,
  set,
  setError,
  activeSet,
  setActiveSet,
  setUpdateName,
  setActive,
  setUpdateChosen,
  setUpdateLink,
  setUpdateProperty,
  setUpdateStartDate,
  setUpdateEndDate,
  setUpdateId,
  setUpdateFirstLevel,
  setUpdateSecondLevel,
  setUpdateThirdLevel,
  setUpdateSetId,
  setMaturity,
  access,
}) => {
  const [modalSaveActive, setModalSaveActive] = useState(false);
  const [toggleBacklog, setToggleBacklog] = useState(true);
  const [toggleHeatmap, setToggleHeatmap] = useState(false);
  const [isHeatmapNotDot, setIsHeatMapDot] = useState(false);

  const [levels, setLevels] = useState([]);
  const [horizontalLevels, setHorizontalLevels] = useState([]);
  const [backlogData, setBacklogData] = useState([]);
  const [backlogLevels, setBacklogLevels] = useState([]);
  const [firstLevels, setFirstLevels] = useState([]);
  const [firstHorizontalLevels, setFirstHorizontalLevels] = useState([]);
  const [current, setCurrent] = useState(0);
  const [timeLine, setTimeLine] = useState(new Date().toJSON().slice(0, 10));
  const [timeLineLength, setTimeLineLength] = useState(364);
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), 0, 2).toJSON().slice(0, 10)
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), 11, 32).toJSON().slice(0, 10)
  );
  const [rightDatesData, setRightDatesData] = useState([]);

  const [dataBySet, setDataBySet] = useState([]);

  const widthValue = JSON.parse(
    localStorage.getItem("settings-column-width-bcm")
  );
  const [columnWidth, setColumnWidth] = useState(
    widthValue ? widthValue.width : 10
  );

  const [amountElementsOnPage, setAmountElementsOnPage] = useState(10);

  const {
    Capabilities_Report_Save_report,
    Capabilities_Report_Toggle_backlog,
    Capabilities_Report_Show_heatmap,
    Capabilities_Report_Show_backlog,
    Capabilities_Report_Select_set,
    Capabilities_Report_Previous_page,
    Capabilities_Report_Next_page,
    Capabilities_Report_Change_start_date,
    Capabilities_Report_Change_current_date,
    Capabilities_Report_Change_end_date,
  } = access;

  const columnWidthIncrease = () => {
    if (columnWidth < 50) {
      setColumnWidth((columnWidth) => columnWidth + 5);
      localStorage.setItem(
        "settings-column-width-bcm",
        JSON.stringify({ width: columnWidth })
      );
    }
  };

  const columnWidthDecrease = () => {
    if (columnWidth > 10) {
      setColumnWidth((columnWidth) => columnWidth - 5);
      localStorage.setItem(
        "settings-column-width-bcm",
        JSON.stringify({ width: columnWidth })
      );
    }
  };

  const activeDatesArray = (dataArray, date) => {
    const result = [];
    for (const i of dataArray) {
      if (
        (new Date(date) > new Date(i.start_date) &&
          new Date(date) < new Date(i.end_date)) ||
        (!i.start_date && new Date(i.end_date) > new Date(date)) ||
        (!i.end_date && new Date(i.start_date) < new Date(date)) ||
        (!i.start_date && !i.end_date)
      ) {
        result.push(i);
      }
    }
    return result;
  };

  const countDays = (sDate, eDate) => {
    const startDate = new Date(sDate);
    const endDate = new Date(eDate);
    // Convert both dates to milliseconds
    const startDateMs = startDate.getTime();
    const endDateMs = endDate.getTime();

    // Calculate the difference in milliseconds
    const diffMs = endDateMs - startDateMs;

    // Convert the difference in milliseconds to days
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));

    // Return the number of days
    return diffDays;
  };

  const startDateChangeHandler = (e) => {
    setStartDate(e.target.value);
    setTimeLineLength(countDays(e.target.value, endDate));
    new Date(e.target.value) < new Date(timeLine) &&
      setTimeLine(e.target.value);
  };

  const endDateChangeHandler = (e) => {
    setEndDate(e.target.value);
    setTimeLineLength(countDays(startDate, e.target.value));
  };

  const convertDateToDays = (str) => {
    const date = new Date(str);
    const diff = date - new Date(startDate); //slider position
    const position = Math.floor(diff / (1000 * 60 * 60 * 24)); // convert milliseconds to days and round down
    return position;
  };

  const convertPositionToDate = (position) => {
    const newDateMs =
      new Date(startDate).getTime() + position * 24 * 60 * 60 * 1000;
    const newDate = new Date(newDateMs);
    setTimeLine(newDate.toJSON().slice(0, 10));
  };

  useEffect(() => {
    const getActiveSet = set.find((item) => item.name === activeSet);
    const setId = getActiveSet ? getActiveSet.id : 0;
    // if (dataArray.length > 0)
    setDataBySet(
      dataArray.filter((item) => item.universal_item_set_id === setId)
    );
    // }, [set, dataArray, activeSet]);
  }, []);

  useEffect(() => {
    const data = activeDatesArray(dataBySet, timeLine);
    setRightDatesData(data);
    data.sort(
      (a, b) =>
        parseFloat(
          Number("" + a.first_level + a.second_level + a.third_level)
        ) -
        parseFloat(Number("" + b.first_level + b.second_level + b.third_level))
    );
    const firstLevelCaps = data.filter(
      (item) =>
        item.second_level === 0 &&
        item.third_level === 0 &&
        item.first_level !== 0 &&
        item.layout === "vertical"
    );
    firstLevelCaps.sort(
      (a, b) => parseFloat(a.first_level) - parseFloat(b.first_level)
    );
    const firstLevelHorizontalCaps = data.filter(
      (item) =>
        item.second_level === 0 &&
        item.third_level === 0 &&
        item.first_level !== 0 &&
        item.layout === "horizontal"
    );
    const groupedArray = groupArrayIntoSixes(
      firstLevelCaps,
      amountElementsOnPage
    );
    const groupedHorizontalArray = groupArrayIntoSixes(
      firstLevelHorizontalCaps,
      amountElementsOnPage
    );

    setLevels(groupedArray);
    setFirstLevels(firstLevelCaps);
    setHorizontalLevels(groupedHorizontalArray);
    setFirstHorizontalLevels(firstLevelHorizontalCaps);

    setBacklogLevels(
      data.filter(
        (item) =>
          item.first_level === 0 &&
          item.second_level === 0 &&
          item.third_level === 0
      )
    );
    setBacklogData(data.filter((item) => item.first_level === 0));
  }, [timeLine, dataBySet, amountElementsOnPage]);

  useEffect(() => {
    if (columnWidth <= 30) setAmountElementsOnPage(3);
    if (columnWidth <= 25) setAmountElementsOnPage(4);
    if (columnWidth <= 20) setAmountElementsOnPage(5);
    if (columnWidth <= 15) setAmountElementsOnPage(6);
    if (columnWidth <= 10) setAmountElementsOnPage(10);
  }, [columnWidth]);

  const saveActive = () => {
    if (dataBySet.length === 0) {
      setError("Nothing to export");
      setTimeout(() => {
        setError();
      }, 2000);
      return;
    }
    setModalSaveActive(!modalSaveActive);
  };

  if (dataArray.length === 0) {
    return (
      <div className="chart-notification">
        <p className="chart-notification__text">No data for that chart</p>
      </div>
    );
  }

  return (
    <>
      <div className="reports">
        <div className="top-report">
          <div className="plus-minus">
            <IconButton
              className="icon-img"
              onClick={saveActive}
              notRestricted={Capabilities_Report_Save_report}
              stateActive={modalSaveActive}
            >
              <BiDownload />
            </IconButton>
            <IconButton
              className="icon-img"
              onClick={() => {
                setToggleBacklog(!toggleBacklog);
              }}
              notRestricted={Capabilities_Report_Toggle_backlog}
              stateActive={toggleBacklog}
            >
              <GoPlus />
            </IconButton>
            <IconButton
              className="icon-img"
              onClick={() => {
                setToggleHeatmap(!toggleHeatmap);
              }}
              notRestricted={Capabilities_Report_Show_heatmap}
              stateActive={toggleHeatmap}
            >
              <TbTemperature />
            </IconButton>
            <SelectSet
              activeSet={activeSet}
              onChange={(e) => setActiveSet(e.target.value)}
              notRestricted={Capabilities_Report_Select_set}
              set={set}
            />
          </div>
          <div className="plus-minus ml-10">
            <IconButton
              className="icon-img"
              onClick={columnWidthDecrease}
              notRestricted={columnWidth > 10}
            >
              <TbZoomOutArea />
            </IconButton>
            <IconButton
              className="icon-img"
              onClick={columnWidthIncrease}
              notRestricted={columnWidth < 30}
            >
              <TbZoomInArea />
            </IconButton>
          </div>
          <div className="flex flex-col justify-center items-center p-5 w-full h-full">
            <p>{organizationName}</p>
            <p>{timeLine}</p>
          </div>
          <div className="w-full flex flex-row mb-5 mt-6 justify-end items-center px-5">
            <div
              className={
                current !== 0
                  ? "cursor-pointer bg-gray-100 px-2 py-1.5 rounded mr-2"
                  : "cursor-pointer bg-gray-100 px-2 py-1.5 rounded mr-2 disabled"
              }
              onClick={() => {
                if (current > 0) {
                  setCurrent(current - 1);
                }
              }}
            >
              <img src={Left} alt="left" />
            </div>
            <div
              className={
                current < levels.length - 2
                  ? "cursor-pointer bg-gray-100 px-2 py-1.5 rounded"
                  : "cursor-pointer bg-gray-100 px-2 py-1.5 rounded disabled"
              }
              onClick={() => {
                if (current < levels.length - 2) {
                  setCurrent(current + 1);
                }
              }}
            >
              <img src={Right} alt="right" />
            </div>
          </div>
        </div>

        <div className="capabilities-report">
          {toggleHeatmap && (
            <div className="heatmap-switch">
              <label htmlFor="s1">
                <RxDotFilled />
              </label>
              <input
                id="s1"
                type="checkbox"
                className="switch"
                checked={isHeatmapNotDot}
                onChange={() => setIsHeatMapDot(!isHeatmapNotDot)}
              />
              <label htmlFor="s1">
                <BsFillSquareFill />
              </label>
            </div>
          )}
          <div className="capabilities-report__chart" id="chart">
            <ModalSave
              active={modalSaveActive}
              firstLevels={firstLevels}
              firstHorizontalLevels={firstHorizontalLevels}
              data={rightDatesData}
              date={timeLine}
              heatmapActive={toggleHeatmap}
              isHeatmapNotDot={isHeatmapNotDot}
              organizationName={organizationName}
              nameOfTheSet={activeSet}
              itemWidth={100}
              verticalData={firstLevels}
              timeline={timeLine}
              closeModal={() => {
                setModalSaveActive(false);
              }}
            />
            <div className="flex w-full h-full flex-col ml-5">
              <div className="flex w-full h-full gap-[1%]">
                {toggleBacklog && (
                  <div
                    style={{
                      width: columnWidth + "%",
                    }}
                    className="flex h-full"
                  >
                    {backlogLevels &&
                      backlogLevels.map((item) => {
                        return (
                          <CapabilitiesReportVertical
                            key={item.id}
                            item={item}
                            data={backlogData}
                            heatmapActive={toggleHeatmap}
                            timeline={timeLine}
                            setUpdateName={setUpdateName}
                            setActive={setActive}
                            setUpdateChosen={setUpdateChosen}
                            setUpdateLink={setUpdateLink}
                            setUpdateProperty={setUpdateProperty}
                            setUpdateStartDate={setUpdateStartDate}
                            setUpdateEndDate={setUpdateEndDate}
                            setUpdateId={setUpdateId}
                            setUpdateFirstLevel={setUpdateFirstLevel}
                            setUpdateSecondLevel={setUpdateSecondLevel}
                            setUpdateThirdLevel={setUpdateThirdLevel}
                            setUpdateSetId={setUpdateSetId}
                            setMaturity={setMaturity}
                            set={set}
                            itemWidth={100}
                          />
                        );
                      })}
                  </div>
                )}
                {levels &&
                  levels[current] &&
                  levels[current].map((item) => {
                    return (
                      <CapabilitiesReportVertical
                        key={item.id}
                        item={item}
                        data={rightDatesData}
                        heatmapActive={toggleHeatmap}
                        timeline={timeLine}
                        isHeatmapNotDot={isHeatmapNotDot}
                        setUpdateName={setUpdateName}
                        setActive={setActive}
                        setUpdateChosen={setUpdateChosen}
                        setUpdateLink={setUpdateLink}
                        setUpdateProperty={setUpdateProperty}
                        setUpdateStartDate={setUpdateStartDate}
                        setUpdateEndDate={setUpdateEndDate}
                        setUpdateId={setUpdateId}
                        setUpdateFirstLevel={setUpdateFirstLevel}
                        setUpdateSecondLevel={setUpdateSecondLevel}
                        setUpdateThirdLevel={setUpdateThirdLevel}
                        setUpdateSetId={setUpdateSetId}
                        setMaturity={setMaturity}
                        set={set}
                        itemWidth={columnWidth}
                      />
                    );
                  })}
              </div>
              <div className="flex flex-col gap-3 h-full min-w-[500px] z-[5] bottom-5">
                {firstHorizontalLevels &&
                  firstHorizontalLevels.map((item) => {
                    return (
                      <NewCapabilitiesReportHorizontal
                        key={item.id}
                        item={item}
                        data={rightDatesData}
                        verticalData={levels[current]}
                        heatmapActive={toggleHeatmap}
                        timeline={timeLine}
                        isHeatmapNotDot={isHeatmapNotDot}
                        setUpdateName={setUpdateName}
                        setActive={setActive}
                        setUpdateChosen={setUpdateChosen}
                        setUpdateLink={setUpdateLink}
                        setUpdateProperty={setUpdateProperty}
                        setUpdateStartDate={setUpdateStartDate}
                        setUpdateEndDate={setUpdateEndDate}
                        setUpdateId={setUpdateId}
                        setUpdateFirstLevel={setUpdateFirstLevel}
                        setUpdateSecondLevel={setUpdateSecondLevel}
                        setUpdateThirdLevel={setUpdateThirdLevel}
                        setUpdateSetId={setUpdateSetId}
                        setMaturity={setMaturity}
                        set={set}
                        itemWidth={columnWidth}
                        toggleBacklog={toggleBacklog}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="timeline-container">
        <div
          className={
            !Capabilities_Report_Change_start_date
              ? "timeline-content disabled"
              : "timeline-content"
          }
        >
          <div className="start-end-date">
            <input
              value={startDate}
              onChange={(e) => startDateChangeHandler(e)}
              type="date"
              id="timeline-start-date"
              name="timeline-start-date"
              className="w-30 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              disabled={!Capabilities_Report_Change_start_date}
            />
            <div className="custom-date">
              <input
                value={timeLine}
                onChange={(e) => setTimeLine(e.target.value)}
                type="date"
                id="timeline-date"
                name="timeline-date"
                className="w-72 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                disabled={!Capabilities_Report_Change_current_date}
              />
            </div>
            <input
              value={endDate}
              onChange={(e) => endDateChangeHandler(e)}
              type="date"
              id="timeline-end-date"
              name="timeline-end-date"
              className="w-30 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              disabled={!Capabilities_Report_Change_end_date}
            />
          </div>
          <input
            type="range"
            id="r1"
            min="0"
            max={timeLineLength}
            value={convertDateToDays(timeLine)}
            onChange={(e) => convertPositionToDate(e.target.value)}
          />
        </div>
      </div>
    </>
  );
};

export default NewBCM;
