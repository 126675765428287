import { connect } from "react-redux";
import { fetched, token, loading, capability, goal, activity, product, scenario, business, application, access } from '../../store/reducers/selectors'
import { getCapability, getGoal, getActivity, getScenario, getProduct, editGoal, editActivity } from "../../api/actions";
import { createStructuredSelector } from "reselect";
import DirectoriesPage from './DirectoriesPage'

const mapStateToProp = createStructuredSelector({
    token,
    fetched,
    loading,
    capability,
    goal,
    activity,
    product,
    scenario,
    business,
    application,
    access
})

const mapDispatchToProps = {
    getCapability,
    getGoal,
    getActivity,
    getProduct,
    getScenario,
    editGoal,
    editActivity
}

export default connect(mapStateToProp, mapDispatchToProps)(DirectoriesPage);