const SelectSet = ({ tooltip, activeSet, set, notRestricted, onChange = false}) => {
	const findSuitedClassName = () => {
		if (!notRestricted) return "bg-white h-11 shadow rounded py-2 px-3 max-w-xs" + " disabled"
		
		return "bg-white h-11 shadow rounded py-2 px-3 max-w-xs"
	}

	const cName = findSuitedClassName()

	return (
		<select
			value={activeSet}
			onChange={onChange}
			className={cName}
			disabled={!notRestricted}
			data-tooltip={tooltip}
		>
			{set.length > 0 &&
				set.map((item) => {
				return (
					<option value={item.name} key={item.id}>
						{item.name}
					</option>
				);
			})}
		</select>
	)
}

export default SelectSet