import React from 'react'

import Scenarios from "../../assets/scenario.svg"
import CardItem from './CardItem';

const DirectoryScenario = ({ scenario }) => {
    return (
        <div className="directory">
            <CardItem linkRoute={'scenario'}
                    image={Scenarios}
                    dataArray={scenario} >
                    Scenarios
            </CardItem>
        </div>
    );
};

export default DirectoryScenario