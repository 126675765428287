import React from 'react';
import Tab from '../../../components/Tab'


const SkeletonTabs = () => {
   
    return (
        <div className="tabs-container">
            <div style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "row",
                marginTop: 20
            }}>
                <Tab skeleton={true}/>
                <Tab skeleton={true}/>
                <Tab skeleton={true}/>
                <Tab skeleton={true}/>
            </div>
        </div>
    )
}

export default SkeletonTabs;