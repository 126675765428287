import React from "react";

import GoalTableHeaderItem from "./GoalTableHeaderItem";
import GoalItem from './GoalItem';


const GoalTable = ({
  data,
}) => {
  return (
    <div className="flex flex-col min-w-[100%]"
    >
      <table className="table-auto w-full text-left whitespace-no-wrap">
        <thead className="table-header">
          <tr>
            <GoalTableHeaderItem name={'NAME'}  value={'name'} />
            <GoalTableHeaderItem name={'LEVEL'}  value={'level'} />
          </tr>
        </thead>
        <tbody className="table-body">
        {data.map((item) => {
                return (
                  <GoalItem 
                    key={Math.random()} 
                    item={item}
                  />
              )
            }
          )
        }
        </tbody>
      </table>
    </div>
  );
};

export default GoalTable;
