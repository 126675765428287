import { connect } from "react-redux"
import { fetched, token, loading, goal, capability, activity, product, scenario, business, application, bpRelations } from '../../store/reducers/selectors'
import { 
    deleteActivity, 
    deleteActivityType, 
    deleteCapability, 
    deleteCapabilityType, 
    deleteGoal, 
    deleteGoalType, 
    deleteProduct, 
    deleteScenario, 
    deleteBusiness, 
    deleteApplication, 
    deleteBPRelation, 
    deleteBPRelations, 
    deleteSet, 
    deleteCapabilities, 
    deleteActivities, 
    deleteApplications, 
    deleteProcesses, 
    deleteProducts, 
    deleteScenarios, 
    deleteGoals, 
    deleteUser, 
    deleteInvite, 
    getActiveInvites, 
    deleteDepartment, 
    deletePerson, 
    deleteTeam, 
    deleteTeams 
} from "../../api/actions"
import { createStructuredSelector } from "reselect"
import Modal from './Modal'

const mapStateToProp = createStructuredSelector({
    token,
    fetched,
    loading,
    goal,
    capability,
    activity,
    product,
    scenario,
    business,
    application,
    bpRelations
})

const mapDispatchToProps = {
    deleteActivity,
    deleteActivityType,
    deleteCapabilityType,
    deleteCapability,
    deleteGoal,
    deleteGoalType,
    deleteProduct,
    deleteScenario,
    deleteBusiness,
    deleteApplication,
    deleteBPRelation,
    deleteSet,
    deleteCapabilities,
    deleteActivities, 
    deleteApplications, 
    deleteProcesses,
    deleteBPRelations,
    deleteProducts, 
    deleteScenarios, 
    deleteGoals,
    deleteUser,
    deleteInvite,
    getActiveInvites,
    deleteDepartment,
    deleteTeam,
    deleteTeams,
    deletePerson
}

export default connect(mapStateToProp, mapDispatchToProps)(Modal)