import { connect } from "react-redux"
import { fetched, loading, product } from '../../../store/reducers/selectors'
import { importProduct } from "../../../api/actions"
import { createStructuredSelector } from "reselect"
import ProductImport from "./ProductImport"


const mapStateToProp = createStructuredSelector({
    fetched,
    loading,
    product,
})

const mapDispatchToProps = {
    importProduct
}

export default connect(mapStateToProp, mapDispatchToProps)(ProductImport);