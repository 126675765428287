import { connect } from "react-redux"
import { capability } from '../../store/reducers/selectors'
import { getCapability } from "../../api/actions"
import { createStructuredSelector } from "reselect"
import BCMNewPage from './BCMNewPage'

const mapStateToProp = createStructuredSelector({
    capability
})

const mapDispatchToProps = {
    getCapability
}

export default connect(mapStateToProp, mapDispatchToProps)(BCMNewPage)