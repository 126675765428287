import React from "react";
import Tab from "../Tab";
import { MdDisabledByDefault } from "react-icons/md";

const DirectoriesManagement = ({ active, setActive, access }) => {
  const {
    Dashboard_All,
    Dashboard_Capabilities,
    Dashboard_Goals,
    Dashboard_Activities,
    Dashboard_Products,
    Dashboard_Scenarios,
    Dashboard_Processes,
    Dashboard_Applications,
  } = access.Tabs;

  return (
    <div className="tabs-container">
      <div className="flex row mt-5 justify-start items-start">
        <Tab
          section={"All"}
          active={active}
          setActive={setActive}
          // isDisabled={!Dashboard_All}
          isDisabled={true}
        />
        <Tab
          section={"Capabilities"}
          active={active}
          setActive={setActive}
          isDisabled={!Dashboard_Capabilities}
        />
        <Tab
          section={"Goals"}
          active={active}
          setActive={setActive}
          isDisabled={!Dashboard_Goals}
        />
        <Tab
          section={"Activities"}
          active={active}
          setActive={setActive}
          isDisabled={!Dashboard_Activities}
        />
        <Tab
          section={"Products"}
          active={active}
          setActive={setActive}
          isDisabled={!Dashboard_Products}
        />
        <Tab
          section={"Scenarios"}
          active={active}
          setActive={setActive}
          isDisabled={!Dashboard_Scenarios}
        />
        <Tab
          section={"Processes"}
          active={active}
          setActive={setActive}
          isDisabled={true}
        />
        <Tab
          section={"Applications"}
          active={active}
          setActive={setActive}
          isDisabled={true}
        />
      </div>
    </div>
  );
};

export default DirectoriesManagement;
