import { useState, useEffect } from "react"

import GoalsManagement from "../../components/Goals/GoalsManagement"
import GoalsTable from "../../components/Goals/GoalsTable"

import SuccessNotice from "../../components/SuccessNotice"
import WarningNotice from "../../components/WarningNotice"
import ErrorNotice from "../../components/ErrorNotice"

import Left from "../../assets/left.svg"
import Right from "../../assets/right.svg"

import { AiOutlineFileAdd } from "react-icons/ai"
import { MdDeleteForever } from "react-icons/md"

import IconButton from "../../components/ui/buttons/IconButton"

import GoalsTreeChart from "../../components/Goals/GoalsTreeChart"
import GoalImport from "../../components/Goals/GoalImport"
import GoalExport from "../../components/Goals/GoalExport"

import SkeletonTabs from "../../components/ui/skeleton/SkeletonTabs"
import SkeletonTable from "../../components/ui/skeleton/SkeletonTable"
import Modal from "../../components/Modal"
import VisualEditor from "../../components/Goals/VisualEditor"

import { GoalsPageContext } from "../../contexts/GoalsPageContext"


const GoalsPage = ({
	fetched,
	loading,
	getGoal,
	getGoalType,
	newGoal,
	newGoalType,
	editGoal,
	editGoalType,
	goal,
	goalPerPage,
	goalType,
	successMessage,
	warningMessage,
	errorMessage,
	clearSuccessMessage,
	clearErrorMessage,
	clearWarningMessage,
	newWarningMessage,
	importGoal,
	deleteGoals,
	access,
	
}) => {
	const [active, setActive] = useState("Goals")
	const [updateChosen, setUpdateChosen] = useState(false)
	const [addChosen, setAddChosen] = useState(false)
	const [showDelete, setShowDelete] = useState(false)
	const [deleteItemsType, setDeleteItemsType] = useState('goalsArray')

	const [name, setName] = useState('')
	const [link, setLink] = useState()
	const [parent, setParent] = useState('')
	const [type, setType] = useState(!goal.length ? 'Strategy' : 'Strategic intention')
	const [tag, setTag] = useState('')
	const [level, setLevel] = useState(1)
	const [description, setDescription] = useState('')

	const [isAddingChild, setIsAddingChild] = useState(false)

	const [success, setSuccess] = useState("")
	const [warning, setWarning] = useState("")
	const [error, setError] = useState("")
	const [errorField, setErrorField] = useState('')

	const [updateId, setUpdateId] = useState("")
	const [updateName, setUpdateName] = useState("")
	const [updateLink, setUpdateLink] = useState("")
	const [updateParent, setUpdateParent] = useState("")
	const [updateType, setUpdateType] = useState("")
	const [updateTag, setUpdateTag] = useState("")
	const [updateLevel, setUpdateLevel] = useState("")
	const [updateDescription, setUpdateDescription] = useState('')

	const [current, setCurrent] = useState(0)
	const [paginationLevels, setPaginationLevels] = useState(goalPerPage.length)

	const { 
		Goals_Add,
		Goals_Delete,
		Goals_Report_Download_report
	} = access.Buttons

	const randomNotExistingParentNumber = () => {
		return -1
	}

	const parentValidatorHandler = (parent, currentType) => {
		if (!goal.length) return 0
		if (parent === 0 && (currentType && currentType.name !== 'Strategy')) return randomNotExistingParentNumber()
		if (parent === 0) return 0
		if (currentType && currentType.level === 0) return 0
		if (!parent) return randomNotExistingParentNumber()

		return Number(parent)
	}

	useEffect(() => {
		getGoal()
		getGoalType()
	}, [])

	useEffect(() => {
		const typeName = !goal.length ? 'Strategy' : 'Strategic intention'
		setType(typeName)
	}, [goal])

	useEffect(() => {
		setTimeout(() => {
			clearWarningMessage()
		}, 1000)
	}, [warningMessage])

	const clearFieldsSuccess = () => {
		setName('')
		setParent('')
		setType('Strategic intention')
		setTag('')
		setLevel(1)
		setLink('')
		setDescription('')
		setIsAddingChild(false)
	}

	const clearFieldsCancel = () => {
		const typeName = !goal.length ? 'Strategy' : 'Strategic intention'
		setName('')
		setParent('')
		setType(typeName)
		setTag('')
		setLevel(1)
		setLink('')
		setDescription('')
		setIsAddingChild(false)
	}

	const deleteAllGoals = () => {
		setShowDelete(true)
		setDeleteItemsType('goalsArray')
	}

	const addData = () => {
		if (!name) {
			setError("Name is required")
			setErrorField('name')
			setTimeout(() => {
				setError("")
				setErrorField('')
			}, 2000);

			return
		}

		const currentGoalType = goalType.find(item => item.name === type)
		const parent_id = parentValidatorHandler(parent, currentGoalType)

		newGoal({
			name,
			type,
			parent_id,
			level: currentGoalType.level,
			tag,
			description,
			link
		})
		.then(() => {
			setAddChosen(false)
			clearFieldsSuccess()
		})
	}

	const updateData = () => {
		if (!updateName) {
			setErrorField('name')
			setTimeout(() => {
				setErrorField('')
			}, 2000);
			return
		}

		const currentGoalType = goalType.find(item => item.name === updateType)
		const parent_id = parentValidatorHandler(updateParent, currentGoalType)
		editGoal({
			id: updateId,
			name: updateName,
			type: updateType,
			parent_id,
			level: currentGoalType?.level,
			tag: updateTag,
			description: updateDescription,
			link: updateLink
		})
		.then(() => {
			setUpdateChosen(false)
		})
	}
	
	const typeChangeHandler = (e) => {
		const selectedGoal = goalType.find(item => item.name === e.target.value)
		setType(e.target.value)
		setParent('')
		!isAddingChild && setLevel(selectedGoal.level)
	}
	
	const updateTypeChangeHandler = (e) => {
		const selectedGoal = goalType.find(item => item.name === e.target.value)
		setUpdateType(e.target.value)
		setUpdateLevel(selectedGoal.level)
	}

	const parentChangeHandler = (e) => {
		setParent(e.target.value)
	}

	const updateParentChangeHandler = (e) => {
		setUpdateParent(e.target.value)
	}

	const parentOptionAvailabilityCheck = (goal, level, updateId = undefined) => {
		if (updateId === goal.id) {
			return true
		}
		if (goal.level >= level || goal.level <= level -2) {
			return true
		}
	}

	const typeOptionAvailabilityCheck = (item, parentId) => {
		const strategicGoal = goal && goal.find(g => g.parent_id === 0)
		if (parentId === 0) return false
		if (strategicGoal && item.level === 0) return true
		if (level > item.level && isAddingChild) return true
	}

	return (
		<>
			<div className="notices">
				{(success || successMessage) && <SuccessNotice message={success || successMessage} clearMessage={clearSuccessMessage} />}
				{warning && <WarningNotice message={warning} />}
				{(error || errorMessage) && <ErrorNotice message={error || errorMessage} clearMessage={clearErrorMessage} />}
			</div>
			{loading || !fetched ? <SkeletonTabs /> : <GoalsManagement active={active} setActive={setActive} access={access} />}
			{active === 'Goals' && 
				<>
				{showDelete && (
					<Modal
						setShowDelete={setShowDelete}
						deleteItemType={deleteItemsType}
						deleteItems={goal}
					/>
				)}
				{addChosen && (
					<div>
						<div className="top-inputs">
							<div>
								<p>Name</p>
								<input
									value={name}
									onChange={(e) => setName(e.target.value)}
									placeholder="Name"
									type="text"
									id="name"
									autoFocus
									name="name"
									maxLength="50"
									className={errorField === 'name' ? "bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4 form-error" : "bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4"}
								/>
							</div>
							<div>
								<p>Type</p>
								<select
									value={type}
									onChange={(e) => typeChangeHandler(e)}
									className="h-11 w-96 shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
									name="type"
									id="type"
									disabled={isAddingChild || !goal.length}
								>
									{goalType.length > 0 &&
										goalType.map((item) => {
											const disabledValue = typeOptionAvailabilityCheck(item, parent)
											const style = disabledValue ? "text-slate-300" : null
											return (
												<option className={style} disabled={disabledValue} value={item.name} key={item.name}>
													{item.level} - {item.name}
												</option>
											);
										})
									}
								</select>
							</div>
							<div>
								<p>Parent</p>
								<select
									value={parent}
									onChange={(e) => parentChangeHandler(e)}

									className="h-11 w-96 shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
									name="parent"
									id="parent"
									disabled={isAddingChild || !goal.length}
								>
									<option></option>
									{goal.length > 0 &&
										goal.map((item) => {
											const disabledValue = parentOptionAvailabilityCheck(item, level)
											const style = disabledValue ? "text-slate-300" : null
											return (
												<option className={style} disabled={disabledValue} value={item.id} key={item.id}>
													{item.level} - {item.name}
												</option>
											)
										})
									}
								</select>
							</div>
						</div>
						<div className="top-inputs">
							<div>
								<p>Tag</p>
								<input
									value={tag}
									onChange={(e) => setTag(e.target.value)}
									placeholder="Tag"
									type="string"
									id="tag"
									name="tag"
									min="0"
									className="h-11 w-96 shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
								/>
							</div>
							<div>
								<p>Link</p>
								<input
									value={link}
									onChange={(e) => setLink(e.target.value)}
									placeholder="Link"
									type="text"
									id="link"
									name="link"
									className="h-11 w-96 shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
								/>
							</div>
							<div>
								<p>Note</p>
								<input
									value={description}
									onChange={(e) => setDescription(e.target.value)}
									placeholder="Note"
									type="text"
									id="note"
									name="note"
									className="h-11 w-96 shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
								/>
							</div>
						</div>
						<div className="w-3/4 flex justify-between align-middle">
							<button
								onClick={() => {
									setAddChosen(false);
									clearFieldsCancel();
								}}
								className="text-white bg-red-500 border-0 py-2 mt-6 px-12 focus:outline-none hover:bg-red-600 rounded text-lg"
							>
								Cancel
							</button>
							<button
								onClick={() => {
									addData();
								}}
								className="text-white bg-second-color border-0 py-2 mt-6 px-12 focus:outline-none rounded text-lg"
							>
								Add Goal
							</button>
						</div>
					</div>
				)}
				{updateChosen && (
					<div>
						<div className="top-inputs">
							<div>
								<p>Name</p>
								<input
									value={updateName}
									onChange={(e) => setUpdateName(e.target.value)}
									placeholder="Name"
									type="text"
									id="name"
									name="name"
									maxLength="50"
									autoFocus
									className={errorField === 'name' ? "bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4 form-error" : "bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4"}
								/>
							</div>
							<div>
								<p>Type</p>
								<select
									value={updateType}
									onChange={(e) => updateTypeChangeHandler(e)}
									className="h-11 w-96 shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
									name="types"
									id="types"
								>
									{goalType.length > 0 &&
										goalType.map((item) => {
											const disabledValue = typeOptionAvailabilityCheck(item, updateParent)
											const style = disabledValue ? "text-slate-300" : null
											return (
												<option className={style} disabled={disabledValue} value={item.name} key={item.name}>
													{item.level} - {item.name}
												</option>
											);
										})}
								</select>
							</div>
							<div>
								<p>Parent</p>
								<select
									value={updateParent}
									onChange={(e) => updateParentChangeHandler(e)}
									className="h-11 w-96 shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
									name="parent"
									id="parent"
									disabled={updateType === "Strategy"}
								>
									<option></option>
									{goal.length > 0 &&
										goal.map((item) => {
											const disabledValue = parentOptionAvailabilityCheck(item, updateLevel, updateId)
											const style = disabledValue ? "text-slate-300" : null
											return (
												<option className={style} disabled={disabledValue} value={item.id} key={item.id}>
													{item.level} - {item.name}
												</option>   
											) 
										})
									}
								</select>
							</div>      
						</div>
						<div className="top-inputs">
							<div>
								<p>Tag</p>
								<input
									value={updateTag}
									onChange={(e) => setUpdateTag(e.target.value)}
									placeholder="Tag"
									type="text"
									id="tag"
									name="tag"
									className="h-11 w-96 shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
								/>
							</div>
							<div>
								<p>Link</p>
								<input
									value={updateLink}
									onChange={(e) => setUpdateLink(e.target.value)}
									placeholder="Link"
									type="text"
									id="name"
									name="name"
									className="h-11 w-96 shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
								/>
							</div>
							<div>
								<p>Note</p>
								<input
									value={updateDescription}
									onChange={(e) => setUpdateDescription(e.target.value)}
									placeholder="Note"
									type="text"
									id="note"
									name="note"
									className="h-11 w-96 shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
								/>
							</div>
						</div>
						<div className="w-3/4 flex justify-between align-middle">
							<button
								onClick={() => {
									setUpdateChosen(false);
								}}
								className="text-white bg-red-500 border-0 py-2 mt-6 px-12 focus:outline-none hover:bg-red-600 rounded text-lg"
							>
								Cancel
							</button>
							<button
								onClick={() => {
									updateData();
								}}
								className="text-white bg-second-color border-0 py-2 mt-6 px-12 focus:outline-none rounded text-lg"
							>
								Update Goal
							</button>
						</div>
					</div>
				)}
				<div className="tables">
					<div className="top-table">
						<div className="plus-minus">
							<IconButton
								className="icon-img"
								onClick={() => {
									setUpdateChosen(false);
									setAddChosen(!addChosen);
								}}
								notRestricted={Goals_Add}
								stateActive={addChosen}
								tooltip={'Add new'}
							>
								<AiOutlineFileAdd />
							</IconButton>
						</div>
						<div className="plus-minus ml-5">
							<IconButton
									className="icon-img"
									onClick={deleteAllGoals}
									notRestricted={Goals_Delete && (goal && goal.length)}
									stateActive={showDelete}
									tooltip={'Delete all table data'}
								>
									<MdDeleteForever />
							</IconButton>
						</div>
						<div className="w-full flex flex-row mb-5 mt-6 justify-end items-center px-10">
							<button
								className={current !== 0? "cursor-pointer bg-gray-100 px-2 py-1.5 rounded mr-2" : "cursor-pointer bg-gray-100 px-2 py-1.5 rounded mr-2 disabled"}
								onClick={() => {
									if (current > 0) {
										setCurrent(current - 1);
									}
								}}
							>
								<img src={Left} alt="left" />
							</button>

							<button
								className={current < paginationLevels - 2? "cursor-pointer bg-gray-100 px-2 py-1.5 rounded" : "cursor-pointer bg-gray-100 px-2 py-1.5 rounded disabled"}
								onClick={() => {
									if (current < paginationLevels - 2) {
										setCurrent(current + 1);
									}
								}}
							>
								<img src={Right} alt="right" />
							</button>
						</div>
					</div>
					<div className="table-div">
					{loading || !fetched ? 
						<SkeletonTable />:
						<GoalsPageContext.Provider value={[ 
							setUpdateChosen,
							setAddChosen,
							setUpdateId,
							setUpdateName,
							setUpdateLevel,
							setUpdateTag,
							setUpdateType,
							setUpdateParent,
							setUpdateLink,
							setUpdateDescription,
							setActive,
						]}>
							<GoalsTable
								goals={goal}
								data={goalPerPage}
								goalType={goalType}
								deletedChosen={false}
								setSelected={null}
								setUpdateId={setUpdateId}
								setUpdateName={setUpdateName}
								setUpdateLevel={setUpdateLevel}
								setUpdateParent={setUpdateParent}
								setUpdateTag={setUpdateTag}
								setUpdateType={setUpdateType}
								setUpdateLink={setUpdateLink}
								setUpdateDescription={setUpdateDescription}
								setSuccess={setSuccess}
								setError={setError}
								getData={getGoal}
								setUpdateChosen={setUpdateChosen}
								setAddChosen={setAddChosen}
								current={current}
								setPaginationLevels={setPaginationLevels}

								setParent={setParent}
								setType={setType}
								setLevel={setLevel}
								setIsAddingChild={setIsAddingChild}

								setShowDelete={setShowDelete}

								accessButtons={access.Buttons}
							/>
						</GoalsPageContext.Provider>
					}
					</div>
				</div>
			</>}
			{active === 'Report' && 
				<GoalsPageContext.Provider value={[ 
					setUpdateChosen,
					setAddChosen,
					setUpdateId,
					setUpdateName,
					setUpdateLevel,
					setUpdateTag,
					setUpdateType,
					setUpdateParent,
					setUpdateLink,
					setUpdateDescription,
					setActive,
				]}>
					<GoalsTreeChart 
						dataArray={goal}
						downloadAccess={Goals_Report_Download_report} 
					/>
				</GoalsPageContext.Provider>
			}
			{active === 'Visual-Editor' && 
					<VisualEditor 
						dataArray={goal}
						downloadAccess={Goals_Report_Download_report}
						importGoal={importGoal}
						deleteGoals={deleteGoals}
						newWarningMessage={newWarningMessage}
					/>
			}
			{active === 'Import' && 
				<GoalImport 
					dataArray={goal} 
					access={access.Buttons}
					setSuccess={setSuccess} 
					setError={setError} 
					setWarning={setWarning}
				/>
			}
			{active === 'Export' && 
				<GoalExport 
					dataArray={goal} 
					setSuccess={setSuccess} 
					setError={setError} 
					access={access.Buttons}
				/>
			}
		</>

			
	);
};

export default GoalsPage
