import React, { useState, useEffect } from 'react'
import { Outlet, useLocation, Navigate }  from 'react-router-dom'
import { useParams } from 'react-router-dom'

import SuccessNotice from '../components/SuccessNotice'
import WarningNotice from '../components/WarningNotice'
import ErrorNotice from '../components/ErrorNotice'

import MaxNavigation from "../components/MaxNavigation"
import MiniNavigation from "../components/MiniNavigation"
import Header from '../components/Header'
import Login from '../Login'
import SignUp from '../SignUp'
import Recover from '../Recover'
import NewPassword from '../NewPassword'


const Layout = ({ token, userName, newFeedback, getUserRole, newLogout, successMessage, warningMessage, errorMessage, clearSuccessMessage, clearWarningMessage, clearErrorMessage, access}) => {
	const [nav, setNav] = useState("mini")
	const { pathname } = useLocation()
	const params = useParams()
	const signup = params && params['*']
	const url = signup && signup.split('/')
	const path = url && url[0]
	const orgToken = url && url[2]

	const urlWithToken = window.location.href
	const urlSearchParams = urlWithToken && new URLSearchParams(urlWithToken.split('?')[1])
	const resetPasswordToken = urlSearchParams.get('token')

	useEffect(() => {
		if (!token) return
	    getUserRole()
	}, [])

	const formatOrgName = (name) => {
		if (name) {
			const regex = new RegExp(`[${'+'}]`, 'g')
			return name.replace(regex, ' ')
		}
	
		return undefined
	}

	if (token && path === 'signup') {
		return <Navigate to="/"/>  
	}
	
	if (!token && path === 'auth') {
		return <NewPassword newPasswordToken={resetPasswordToken}/>
	}

	if (!token && path === 'signup') {
		const orgName = url && formatOrgName(url[3])
		const regEmail = url && url[1]
		return <SignUp orgToken={orgToken} orgName={orgName} regEmail={regEmail}/>
	}

	if (!token && pathname === '/recover') {
		return <Recover />
	}

	if (!token && pathname !== '/signup') {
		return <Login />
	}

	if (!token && pathname !== '/login') {
		return <SignUp />
	}

	return (
		<>
			<div className={nav === "mini" ? "grid grid-cols-12 transition-all" : "grid grid-cols-12 transition-all"}>
				<div className={nav === "mini" ? "col-span-1" : "col-span-2"}>
					{nav === "mini" ? (
					<MiniNavigation
						setNav={setNav}
						newFeedback={newFeedback}
						access={access}
					/>
					) : (
					<MaxNavigation
						setNav={setNav}
						newFeedback={newFeedback}
						access={access}
					/>
					)}
				</div>
				<div
					className={nav === "mini" ? "content content-mini" : "content content-max"}
				>
				<Header userName={userName} newLogout={newLogout} />

				<main className='main'>
					<div className="notices">
						{successMessage && <SuccessNotice message={successMessage} clearMessage={clearSuccessMessage} />}
						{warningMessage && <WarningNotice message={warningMessage} clearMessage={clearWarningMessage} />}
						{errorMessage && <ErrorNotice message={errorMessage} clearMessage={clearErrorMessage} />}
					</div>
					<Outlet />
				</main>
				</div>
			</div>
		</>
	);
};

export default Layout;