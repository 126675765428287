import React, { useEffect, useState } from 'react'
import RegularButton from '../ui/buttons/RegularButton'
import SkeletonTable from '../ui/skeleton/SkeletonTable'
import UserTable from './users/UserTable'
import InviteTable from './invites/InviteTable'
import Modal from '../Modal'

const OrganizationParameters = ({ loading, fetched, setError, setSuccess, organization, getOrganization, editOrganization, newInvite, access, getAllUsers, allUsers, deleteUser, activeInvites }) => {
	const [organizationName, setOrganizationName] = useState('')
	const [organizationStartDate, setOrganizationStartDate] = useState('')
	const [organizationEndDate, setOrganizationEndDate] = useState('')

	const [showDelete, setShowDelete] = useState(false)
	const [deleteId, setDeleteId] = useState('')
	const [deleteItem, setDeleteItem] = useState({name: ''})

	const [inviteEmail, setInviteEmail] = useState('')
	const id = localStorage.getItem('userOrganization')
	const {
		Settings_Organization_Edit_organization,
		Settings_Organization_Invite
	} = access

	useEffect(() => {
		getOrganization({id})
	}, [])

	const isValidEmail = (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		return emailRegex.test(email)
	}

	const isValidLength = (name) => {
		if (name.length > 1) {
			return true
		}
		return false
	}

	const clearDatabase = () => {
		setDeleteId('')
		setDeleteItem({name: 'Organization database'})
		setShowDelete(true)
	}

	const editOrganizationHandler = () => {
		if (!isValidLength(organizationName)) {
			setError('Organization name should be minimum 2 letters')
			setTimeout(() => {
				setError('')
			}, 2000)
			return
		}
		editOrganization({
			id,
			name: organizationName,
			start_date: organizationStartDate,
			end_date: organizationEndDate
		}).then(() => {
			setInviteEmail('')
			setTimeout(() => {
				setSuccess('')
			}, 2000)
		})
	}

	const organizationInviteHandler = () => {
		if (isValidEmail(inviteEmail)) {
			newInvite({email: inviteEmail})
			setInviteEmail('')
			return
		}

		setError('Not a valid email')
		setTimeout(() => {
			setError('')
		}, 2000)
	}

	useEffect(() => {
		const startDate = new Date(organization && organization.start_date.Time)
		const endDate = new Date(organization && organization.end_date.Time)
		const formattedStartDate = startDate.toISOString().slice(0, 10)
		const formattedEndDate = endDate.toISOString().slice(0, 10)

		setOrganizationName(organization && organization.name)
		setOrganizationStartDate(organization && formattedStartDate)
		setOrganizationEndDate(organization && formattedEndDate)
	}, [organization])
	
	return (
		<div className="settings">
			{showDelete && 
				<Modal
					setShowDelete={setShowDelete}
					deleteId={deleteId}
					deleteItem={deleteItem}
					deleteItemType={'organizationDatabase'}
					setSuccess={setSuccess}
					setError={setError}
				/>
			}
			<div className="flex flex-col min-w-[30%] w-[50%] mr-20 mb-4">
				<div className="mb-10">
					<h5 className="mb-2 font-bold">Invite to the organization</h5>
					<div>
						<p>Email</p>
						<div className="flex mb-6">
							<input
								value={inviteEmail}
								onChange={(e) => setInviteEmail(e.target.value)}
								placeholder="Email"
								type="email"
								id="Email"
								name="Email"
								pattern="/^[^\s@]+@[^\s@]+\.[^\s@]+$/"
								size="30" 
								required
								className="h-11 w-[100%] shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
							/>
							<RegularButton
								onClick={organizationInviteHandler}
								className={"text-white bg-second-color border-0 ml-5 px-6 py-2 focus:outline-none rounded text-lg"}
								notRestricted={Settings_Organization_Invite}
							>
								Send
							</RegularButton>
						</div>
						<p>Pending invites</p>
						<div className="dict-list">
							{loading || !fetched ? 
								<SkeletonTable />:
								<InviteTable
									data={activeInvites ? activeInvites : []}
									deletedChosen={false}
									setSelected={null}
									deleteUser={deleteUser}
									setError={setError} 
									setSuccess={setSuccess}
									access={access}
								/>
							}
						</div>
					</div>
				</div>
				<div className="flex flex-col mb-4">
					<h5 className="mb-2 font-bold">Organization users</h5>
					<p>Active users</p>
					<div className="dict-list">
							{loading || !fetched ? 
								<SkeletonTable />:
								<UserTable
									data={allUsers}
									deletedChosen={false}
									setSelected={null}
									deleteUser={deleteUser}
									setError={setError} 
									setSuccess={setSuccess}
									access={access}
								/>
							}
					</div>
				</div>
			</div>
			<div className="flex flex-col min-w-[20%] w-[30%] mr-20 mb-4">
				<div>
					<div>
						<h4 className="mb-2 font-bold">Edit the organization information</h4>
						<div>
							<p>Name</p>
							<input
								value={organizationName}
								onChange={(e) => setOrganizationName(e.target.value)}
								placeholder="Name"
								type="text"
								id="name"
								name="name"
								minLength="2"
								className="h-11 w-96 shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mb-4"
							/>
						</div>
						<div>
							<p>Start date</p>
							<input
								value={organizationStartDate}
								onChange={(e) => setOrganizationStartDate(e.target.value)}
								placeholder="Start date"
								type="date"
								id="start-date"
								name="start-date"
								className="h-11 w-96 shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mb-4"
							/>
						</div>
						<div>
							<p>End date</p>
							<input
								value={organizationEndDate}
								onChange={(e) => setOrganizationEndDate(e.target.value)}
								placeholder="End date"
								type="date"
								id="end-date"
								name="end-date"
								className="h-11 w-96 shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mb-4"
							/>
						</div>
						<div>
							<p>Active flag</p>
							<input
								value="Active"
								// onChange={feedbackTitleChanger}
								placeholder="Active flag"
								type="text"
								id="title"
								name="title"
								disabled
								className="disabled h-11 w-96 shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
							/>
						</div>
						<RegularButton
							onClick={editOrganizationHandler}
							className={"text-white bg-second-color border-0 py-2 mt-6 px-12 focus:outline-none rounded text-lg"}
							notRestricted={Settings_Organization_Edit_organization}
						>
							Save
						</RegularButton>                   
					</div>  
				</div>
				<div className="mt-8">
					<div>
						<h4 className="mb-2 font-bold">Clear Database</h4>
						<RegularButton
							onClick={clearDatabase}
							className={"text-white bg-second-color border-0 px-6 py-2 focus:outline-none rounded text-lg"}
							notRestricted={Settings_Organization_Invite}
						>
							Clear
						</RegularButton>
					</div>
				</div>
			</div>
		</div>
	)
}

export default OrganizationParameters;