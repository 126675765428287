import React, {useState} from 'react'

const EditSetModal = ({ 
    setSuccess, 
    setError, 
    setEditSetActive,
    editSet,
    set
}) => {
    const [listName, setListName] = useState(set.list_name)
    const [name, setName] = useState(set.name)

    const clearFields = () => {
        setListName('')
        setName('')
    }

    const successMessage = () => {
        setSuccess("Successfully Added")
        clearFields()
        setTimeout(() => {
            setSuccess("")
        }, 3000);        
    }

    const errorMessage = (error) => {
        setError(error)
        setTimeout(() => {
            setError("")
        }, 3000);
    }

    const addedSetConfirmed = async () => {
        const result = await editSet({
            id: set.id,
            name: name,
            list_name: listName,
        })
        setTimeout(() => {
            (result.payload.status === 200 || result.payload.status === 201 || result.payload.status === 204) && setEditSetActive(false)
            result.payload.status === 200 || result.payload.status === 201 ||  result.payload.status === 204 ? successMessage() : errorMessage(result.payload.status)
        }, [1])
    }

    return (
        <div className="fixed inset-0 z-10">
            <div className="overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div className="flex items-end justify-center min- px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                    <div className="transition-opacity bg-gray-500 bg-opacity-75" aria-hidden="true"></div>
                    <span className="hidden sm:inline-block sm:align-middle sm:" aria-hidden="true">​</span>
                    <div className="inline-block p-5 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-2xl lg:p-16 sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
                        <div className="flex flex-col justify-center items-center">
                            <div className="py-6 text-center">
                                <p className="mb-8 text-2xl font-semibold leading-none tracking-tighter text-neutral-600">Edit Set</p>
                            </div>
                            <div className="top-inputs">
                                <div>
                                    <p>Name</p>
                                    <input
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                    placeholder="Name"
                                    type="text"
                                    id="name"
                                    autoFocus
                                    name="name"
                                    className="bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4"
                                />
                                </div>
                            </div>
                            <div className="top-inputs mb">
                                <div>
                                    <p>Description</p>
                                    <input
                                    value={listName}
                                    onChange={e => setListName(e.target.value)}
                                    placeholder="Description"
                                    type="text"
                                    id="list-name"
                                    name="list-name"
                                    className="bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4"
                                />
                                </div>
                            </div>
                            <div className="top-inputs mb-4">
                                <div>
                                    <p>Set ID</p>
                                    <input
                                    value={set.id}
                                    type="text"
                                    id="set-id"
                                    name="set-id"
                                    className="disabled bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4"
                                />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row justify-between align-middle">
                            <button className="bg-red px-10 py-2 rounded bg-red-600 text-white font-bold cursor-pointer" onClick={() => setEditSetActive(false)}>
                                Cancel
                            </button>
                            <button className="bg-red px-10 py-2 rounded bg-green-600 text-white font-bold cursor-pointer" onClick={addedSetConfirmed}>
                                Update
                            </button>
                        </div>
                            
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditSetModal