import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
	return (
		<div style={{
			backgroundColor: "#EEEEF2"
		}} className="flex items-center justify-center w-full min-h-screen">
			<div className="flex flex-col text-gray-700 lg:flex-row lg:space-x-16 lg:space-x-reverse">
				<div className="order-1 max-w-md px-2 text-sm md:text-base lg:px-0">
					<header className="mb-6">
						<h2 className="text-4xl font-bold leading-none text-gray-400 select-none lg:text-6xl">404.</h2>
						<h3 className="text-xl font-light leading-normal lg:text-3xl md:text-3xl">Sorry, we couldn't find this page.</h3>
					</header>
					<p className="max-w-sm mb-5 leading-5 md:leading-7">
						Want to return to the homepage?
					</p>
					<Link to="/">
						<button
							style={{
								backgroundColor: "#5F7BE9"
							}}
							className="inline px-4 py-2 text-sm font-medium leading-5 text-white uppercase transition-colors duration-150 border border-transparent rounded-lg shadow focus:outline-none focus:shadow-outline-blue">
							Back to Homepage
						</button>
					</Link>
				</div>
			</div>
		</div>


	)
}

export default NotFound