import { connect} from "react-redux";
import { fetched, token, loading, scenario, scenarioPerPage, activity, successMessage, errorMessage, access } from '../../store/reducers/selectors'
import { getScenario, newScenario, editScenario, getActivity, clearSuccessMessage, clearErrorMessage } from "../../api/actions";
import { createStructuredSelector}  from "reselect";
import ScenarioPage from './ScenarioPage'

const mapStateToProp = createStructuredSelector({
    token,
    fetched,
    loading,
    scenario,
    scenarioPerPage,
    activity,
    successMessage,
    errorMessage,
    access
})
 
const mapDispatchToProps = {
    getScenario,
    newScenario,
    editScenario,
    getActivity,
    clearSuccessMessage,
    clearErrorMessage
}

export default connect(mapStateToProp, mapDispatchToProps)(ScenarioPage);