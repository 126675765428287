import React, { useState } from 'react';

import { VscGear } from "react-icons/vsc";
import { VscClearAll } from 'react-icons/vsc'


const ApplicationsTableHeaderItem = ({ 
    sortData, 
    name, 
    value, 
    activeSort, 
    setSortingStartDate, 
    setSortingEndDate, 
    setSortingName, 
    setSortingTag,
    setSortingDescription
}) => {
    const [filter, setFilter] = useState(false)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [sortName, setSortName] = useState('')
    const [tag, setTag] = useState('')
    const [description, setDescription] = useState('')

    let headerItemType = ''
    let headerItemFilterActive = ''

    const setFieldClear = () => {
        setFilter(!filter)
    }

    const handleKeyPress = (e) => {
        if(e.key === 'Enter' || e.key === 'Escape'){
            setFilter(!filter)
        }
    }

    const closeFilter = (e) => {
        if(e.key === 'Escape'){
            setFilter(!filter)
        }
    }
    
    const nameChangeHandler = (e) => {
        setSortName(e.target.value)
        setSortingName(e.target.value)
    }

    const startDateChangeHandler = (e) => {
        setStartDate(e.target.value)
        setSortingStartDate(e.target.value)
        setFilter(!filter)
    }

    const endDateChangeHandler = (e) => {
        setEndDate(e.target.value)
        setSortingEndDate(e.target.value)
        setFilter(!filter)
    }

    const tagChangeHandler = (e) => {
        setTag(e.target.value)
        setSortingTag(e.target.value)
    }

    const descriptionChangeHandler = (e) => {
        // setDescription(e.target.value)
        // setSortingDescription(e.target.value)
    }

    if (value === 'start-date') {
        headerItemFilterActive = startDate
        headerItemType = <input
                            value={startDate} 
                            className="h-11 w-72 shadow rounded py-2 px-3 filter-input" 
                            type="date" 
                            onChange={startDateChangeHandler}>
                        </input>
    }
    if (value === 'end-date') {
        headerItemFilterActive = endDate
        headerItemType = <input
                            value={endDate} 
                            className="h-11 w-72 shadow rounded py-2 px-3 filter-input" 
                            type="date" 
                            onChange={endDateChangeHandler}>
                        </input>
    }
    if (value === 'name') {
        headerItemFilterActive = sortName
        headerItemType = <div className='h-11 w-72 shadow rounded py-2 px-3 filter-input'>
                            <input
                                value={sortName} 
                                className="" 
                                type="text" 
                                placeholder="Start typing here"
                                onChange={nameChangeHandler}>
                            </input>
                            <button className='clear-field' onClick={() => {setFieldClear(); setSortName(''); setSortingName('') }}><VscClearAll /></button>
                        </div>
    }
    
    if (value === 'tag') {
        headerItemFilterActive = tag
        headerItemType = <div className='h-11 w-72 shadow rounded py-2 px-3 filter-input'>
                            <input
                                value={tag} 
                                className="" 
                                type="text" 
                                placeholder="Start typing here"
                                onChange={tagChangeHandler}
                                onKeyDown={handleKeyPress}>
                            </input>
                            <button className='clear-field' onClick={() => {setFieldClear(); setTag(''); setSortingTag('') }}><VscClearAll /></button>
                        </div>
    }
    if (value === 'description') {
        headerItemFilterActive = description
        headerItemType = <div className='h-11 w-72 shadow rounded py-2 px-3 filter-input'>
                            <input
                                value={description} 
                                className="" 
                                type="text" 
                                placeholder="Start typing here"
                                onChange={descriptionChangeHandler}>
                            </input>
                            <button className='clear-field' onClick={() => {setFieldClear(); setDescription(''); setSortingDescription('') }}><VscClearAll /></button>
                        </div>
    }

    let uniqueStyling

    if (value === 'id') {
        uniqueStyling = 'header-item_id'
    }
    if (value === 'name') {
        uniqueStyling = 'header-item_name'
    }
    if (value === 'link') {
        uniqueStyling = 'header-item_link'
    }

    return (
        <th className={"table-header-item "  + uniqueStyling}>
            <div className='table-header-item__container' tabIndex={-1} onKeyDown={closeFilter}>
                {filter && headerItemType}
                <button onClick={() => {sortData(value)}}>{name}</button>
                {(activeSort.key !== value) && <div style={{marginRight: 12}}></div>}
                {(activeSort.key === value && activeSort.inc) && <div style={{marginBottom: 2}}>▲</div>}
                {(activeSort.key === value && !activeSort.inc) && <div style={{marginBottom: 3}}>▼</div>}
                {(value !== 'id' && value !== 'link') && <VscGear className={headerItemFilterActive ? "text-2xl cursor-pointer capability-filter text-red-400" : (filter ? "text-2xl cursor-pointer capability-filter filter-active" : "text-2xl cursor-pointer capability-filter")} onClick={() => {setFilter(!filter)}} />}
            </div>
        </th>
    );
};

export default ApplicationsTableHeaderItem;