import { connect } from "react-redux"
import { fetched, token, loading, userName, successMessage, errorMessage, warningMessage, access } from '../store/reducers/selectors'
import { login, newFeedback, getUserRole, newLogout, newSuccessMessage, newErrorMessage, newWarningMessage, clearSuccessMessage, clearErrorMessage, clearWarningMessage } from "../api/actions"
import { createStructuredSelector } from "reselect"
import Layout from './Layout'

const mapStateToProp = createStructuredSelector({
    token,
    fetched,
    loading,
    userName,
    successMessage,
    warningMessage,
    errorMessage,
    access
})

const mapDispatchToProps = {
    login,
    newFeedback,
    getUserRole,
    newLogout,
    newSuccessMessage,
    newWarningMessage,
    newErrorMessage,
    clearSuccessMessage,
    clearWarningMessage,
    clearErrorMessage
}

export default connect(mapStateToProp, mapDispatchToProps)(Layout)