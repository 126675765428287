import React from 'react';
import { NavLink } from 'react-router-dom';


const CardItem = ({ children, image, dataArray, linkRoute, cardColor }) => {
    return (
        <div className={"card " + (cardColor ? cardColor : 'blue')}>
                <div className="card__content">
                    <div className="card__logo">
                        <img src={image} alt="image_item" />
                    </div>
                   <div className="card__text">
                        <p className="card__amount">{dataArray.length}</p>
                        <p className="card__description">{children}</p>
                   </div>
                </div>
                <div className="card__controls">
                    <NavLink to={linkRoute}>View details</NavLink>
                </div>
            </div>
    );
};

export default CardItem;