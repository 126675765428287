import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import ProcessesReportApplicationItem from './ProcessesReportApplicationItem'
import { TransitionGroup, CSSTransition } from 'react-transition-group'


const ProcessesReportItem = ({ 
	item,
	data,
	heatmapActive,
	timeline,
	isHeatmapNotDot,
	setUpdateName,
	setActive,
	setUpdateChosen,
	setUpdateLink,
	setUpdateProperty,
	setUpdateStartDate,
	setUpdateEndDate,
	setUpdateId,
	setUpdateFirstLevel,
	setUpdateSecondLevel,
	setUpdateThirdLevel,
	setUpdateSetId,
	set,
	setMaturity,
	itemWidth
}) => {
	const navigate = useNavigate()
	const location = useLocation()
	const editCapabilityHandler = (item) => {
	    // if (location.pathname !== '/processes') {
	    //     navigate("/processes")
						
	    //     return
	    // }
	    // setUpdateChosen(true)
	    // setActive('Processes')
	    // setUpdateName(item.name)
	    // setUpdateLink(item.link)
	    // setUpdateProperty(item.property)
	    // setUpdateStartDate(item.start_date)
	    // setUpdateEndDate(item.end_date)
	    // setUpdateId(item.id)
	    // setUpdateFirstLevel(item.first_level)
	    // setUpdateSecondLevel(item.second_level)
	    // setUpdateThirdLevel(item.third_level)
	    // setUpdateSetId(set.find(element => element.id === item.universal_item_set_id).name)
	    // setMaturity(item.maturity === null ? [] : item.maturity)
	}

	// const getMaturityColor = (item, level) => {
	//     const itemMaturities = item.maturity && item.maturity.filter(el => el.change_date === timeline || el.change_date < timeline)
	//     itemMaturities && itemMaturities.sort((a, b) => b.id - a.id)
	//     const itemMaturity = itemMaturities && itemMaturities.length > 0 ? itemMaturities[0] : null
	//     // const itemMaturity = item.maturity && item.maturity.find(el => el.change_date === timeline || el.change_date < timeline)

	//     if (itemMaturity) {
	//         if (itemMaturity.maturity_level_dict.name === 'No capability') {
	//             return 'maturity-color-1'
	//         }
	//         if (itemMaturity.maturity_level_dict.name === 'Initial') {
	//             return 'maturity-color-2'
	//         }
	//         if (itemMaturity.maturity_level_dict.name === 'Managed') {
	//             return 'maturity-color-3'
	//         }
	//         if (itemMaturity.maturity_level_dict.name === 'Defined') {
	//             return 'maturity-color-4'
	//         }
	//         if (itemMaturity.maturity_level_dict.name === 'Quantitatively Managed') {
	//             return 'maturity-color-5'
	//         }
	//         return 'maturity-color-6'
	//         // old way of changing class name
	//         // return 'maturity-color-' + itemMaturity.maturity_level_dict.id
	//     }
	//     if (level === 1) {
	//         return ''
	//     }
	//     if (level === 2) {
	//         return 'no-maturity-color'
	//     }
	//     if (level === 3) {
	//         return ''
	//     }
	// }

	const [isHovered, setIsHovered] = useState(false)

	const handleMouseEnter = () => {
	  setIsHovered(true)
	}
  
	const handleMouseLeave = () => {
	  setIsHovered(false)
	}

	function getRandomBigNumber() {
		const randomDecimal = Math.random()
		// Scale the decimal to a big number range
		const min = 1e15; // Adjust this as needed
		const max = 1e20; // Adjust this as needed
		const randomNumber = Math.floor(randomDecimal * (max - min + 1) + min);
	  
		return randomNumber;
	}
	  
	return (
		<div className="flex flex-col justify-between items-center border rounded-md bg-[#ffe2a3] w-auto mx-2" onClick={editCapabilityHandler}>
			<p className="mt-5 px-5 w-[200px] text-center">{item.name}</p>
			<div className="flex ml-9 w-full">
				{item.link && <div className="mx-3"><a href={item.link}>Link</a></div>}
				<div className="mx-2">{item.tag}</div>
			</div>
			<div className="applications-row flex flex-row justify-center mx-5 w-full transition ease-in-out" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
				{item.applications && item.applications.map(i => {
					const key = getRandomBigNumber()
					return (
							<ProcessesReportApplicationItem key={key} name={i.name} />
						)
					})
				}
			</div>
			
		</div>
	)
}

export default ProcessesReportItem