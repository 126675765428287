import React, { useEffect, useState } from 'react'
import RegularButton from '../ui/buttons/RegularButton'
import SkeletonTable from '../ui/skeleton/SkeletonTable'
import UserTable from './users/UserTable'
import InviteTable from './invites/InviteTable'
import Modal from '../Modal'
import IconButton from '../ui/buttons/IconButton'
import { AiOutlineClose, AiOutlineFileAdd } from 'react-icons/ai'
import { AiFillEdit } from 'react-icons/ai'
import Checkbox from '../ui/inputs/Checkbox'
import Department from './Department'
import CRUDChecklist from './CRUDChecklist'
import { OrganizationTabContext } from '../../contexts/OrganizationTabContext'


const Organization = ({ loading, fetched, setError, setSuccess, getOrganization, access, goalType }) => {
	const [formActive, setFormActive] = useState(false)
	const [addChosen, setAddChosen] = useState(false)
	const [updateChosen, setUpdateChosen] = useState(false)
	const [errorField, setErrorField] = useState('')
	const [showDelete, setShowDelete] = useState(false)
	const [deleteId, setDeleteId] = useState('')
	const [deleteItemsType, setDeleteItemsType] = useState('')
	const [crudActive, setCrudActive] = useState(false)
	const [deleteItems, setDeleteItems] = useState([])
	
	const [name, setName] = useState('')
	const [team, setTeam] = useState([])
	const [type, setType] = useState('department')
	const [head, setHead] = useState('')
	const [parent, setParent] = useState('')
	const [crud, setCrud] = useState()
	const [collaborators, setCollaborators] = useState([])
	
	const [updateId, setUpdateId] = useState('')
	const [updateName, setUpdateName] = useState('')
	const [updateTeam, setUpdateTeam] = useState('')
	const [updateType, setUpdateType] = useState('department')
	const [updateHead, setUpdateHead] = useState('')
	const [updateParent, setUpdateParent] = useState('')
	
	const [updateCrud, setUpdateCrud] = useState({create: false, read: false, update: false, delete: false})
	const [updateCollaborators, setUpdateCollaborators] = useState([])

	const [deleteItem, setDeleteItem] = useState({name: ''})

	const people = [{id: 1, name: 'John1', position: 'worker'}, {id: 2, name: 'John2', position: 'worker'}, {id: 3, name: 'John3', position: 'worker'}, {id: 4, name: 'John4', position: 'worker'}]

	const id = localStorage.getItem('userOrganization')
	
	const {
		Settings_Organization_Edit_organization,
		Settings_Organization_Invite
	} = access

	useEffect(() => {
		getOrganization({id})
	}, [])

	const clearFieldsSuccess = () => {
		setName('')
		setTeam([])
		setType('department')
		setHead('')
		setParent('')
		setCrudActive(false)
		setCollaborators([])
	}
	const clearFieldsCancel = () => {
		setName('')
		setTeam([])
		setType('department')
		setHead('')
		setParent('')
		setCrudActive(false)
		setCollaborators([])
	}

	const nameChangeHandler = (e) => setName(e.target.value)
	const updateNameChangeHandler = (e) => updateName(e.target.value)

	const typeChangeHandler = (e) => {
		setType(e.target.value)
		if (type === 'team') setParent('')
	}
	const updateTypeChangeHandler = (e) => {
		setUpdateType(e.target.value)
		if (updateType === 'team') setUpdateParent('')
	}
	const parentChangeHandler = (e) => 	setParent(e.target.value)
	const updateParentChangeHandler = (e) => setUpdateParent(e.target.value)

	const headChangeHandler = (e) => setHead(e.target.value)
	const updateHeadChangeHandler = (e) => setUpdateHead(e.target.value)

	const updateChosenHandler = () => setUpdateChosen(true)

	const departments = [
		{id: 1, name: 'department 1', create: true, read: true, update: true, delete: true, head: '', teams: ['team 1', 'team 2']}, 
		{id: 2, name: 'department 2', create: true, read: true, update: true, delete: true, head: '', teams: ['team 3']}, 
		{id: 3, name: 'department 3', create: true, read: true, update: true, delete: true, head: '', teams: ['team 4']}, 
		{id: 4, name: 'department 4', create: false, read: true, update: false, delete: false, head: '', teams: ['team 5']}, 
		{id: 5, name: 'department 5', create: false, read: true, update: false, delete: false, head: '', teams: []}
	]

	const teams = [
		{id: 1, name: 'team 1', create: false, read: true, update: false, delete: false, head: '', collaborators: [] }, 
		{id: 2, name: 'team 2', create: false, read: true, update: false, delete: false, head: '', collaborators: [] }, 
		{id: 3, name: 'team 3', create: false, read: true, update: false, delete: false, head: '', collaborators: [] }, 
		{id: 4, name: 'team 4', create: false, read: true, update: false, delete: false, head: '', collaborators: [] }, 
		{id: 5, name: 'team 5', create: false, read: true, update: false, delete: false, head: '', collaborators: [] }
	]

	const multiSelectChangeHandler = (e, data, setState) => {
		setState({...data, [e.target.name] : e.target.checked }) 
	}
	
	return (
		<>
			{showDelete && (
				<Modal
					setShowDelete={setShowDelete}
					deleteItem={deleteItem}
					deleteItemType={deleteItemsType}
					deleteId={deleteId}
					setSuccess={setSuccess}
					setError={setError}
					setDeleteItems={setDeleteItems}
				/>
			)}
			{addChosen && (
				<div>
					<div className="top-inputs">
						<div>
							<p>Name</p>
							<input
								value={name}
								onChange={nameChangeHandler}
								placeholder="Name"
								type="text"
								id="name"
								autoFocus
								name="name"
								maxLength="50"
								className={errorField === 'name' ? "shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4 form-error" : "bg-white shadow rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4"}
							/>
						</div>
						<div>
							<p>Type</p>
							<select
								className="h-11 w-96 shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
								onChange={typeChangeHandler}
								value={type}
							>
								<option value="department">Depeartment</option>
								<option value="team">Team</option>
							</select>
						</div>
						<div>
							<p>Parent</p>
							<select
								disabled={type === 'department'}
								className="h-11 w-96 shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
								onChange={parentChangeHandler}
								value={parent}
							>
								<option value=''></option>
								{departments.map((department) => {
									return <option value={department.name}>{department.name}</option>
								})}
							</select>
						</div>
						<div className="mr-4">
							<p>CRUD</p>
							<div className="form-maturity-container">
								{crudActive ?
								<>
									<div className="h-11 w-72 shadow rounded py-2 px-3 form-maturity">
										<button className="form-activity__button-close" onClick={() => {setCrudActive(false)}}><span>Close</span><AiOutlineClose /></button>
										<div className="w-full">
											<CRUDChecklist goalType={goalType} />
										</div>
									</div>
								</>
								:
								<button className="form-activity__button-open" onClick={() => {setCrudActive(true)}}>Open</button>
								}
							</div>
						</div>
						<div>
							<p>Head</p>
							<select
								className="h-11 w-96 shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
								onChange={headChangeHandler}
								value={head}
							>
								<option value=''></option>
								{people.map((person) => {
									return <option value={person.name}>{person.name}</option>
								})}
							</select>
						</div>
						{ type === 'team' ?
							<div>
								<p>Collaborators</p>
								<div className="form-activity-container">
									{formActive ?
										<>
											<div className="h-11 w-72 shadow rounded py-2 px-3 form-activity">
												<button className="form-activity__button-close" onClick={() => { setFormActive(false) }}><span>Close</span><AiOutlineClose /></button>
												{people && people.map(item => (
													<label key={item.id}><br/>
														<Checkbox key={item.id} name={item.id} checked={collaborators[item.id]} onChange={(e) => { multiSelectChangeHandler(e, collaborators, setCollaborators) }} />
														{item.id + ' ' + item.name}
													</label>
												))}
											</div>
										</>
										:
										<button className="form-activity__button-open" onClick={() => { setFormActive(true) }}>Open</button>
									}
								</div>
							</div>
							:
							<div>
								<p>Teams</p>
								<div className="form-activity-container">
									{formActive ?
										<>
											<div className="h-11 w-72 shadow rounded py-2 px-3 form-activity">
												<button className="form-activity__button-close" onClick={() => { setFormActive(false) }}><span>Close</span><AiOutlineClose /></button>
												{teams && teams.map(item => (
													<label key={item.id}><br/>
														<Checkbox key={item.id} name={item.id} checked={team[item.id]} onChange={(e) => { multiSelectChangeHandler(e, team, setTeam) }} />
														{item.id + ' ' + item.name}
													</label>
												))}
											</div>
										</>
										:
										<button className="form-activity__button-open" onClick={() => { setFormActive(true) }}>Open</button>
									}
								</div>
							</div>
						}
					<div className="w-3/4 flex justify-between align-middle mb-10">
						<button
							onClick={() => {
								setAddChosen(false);
								clearFieldsCancel();
							}}
							className="text-white bg-red-500 border-0 py-2 mt-6 px-12 focus:outline-none hover:bg-red-600 rounded text-lg"
						>
							Cancel
						</button>
						<button
							onClick={() => {
								// addData();
								setAddChosen(false);
							}}
							className="text-white bg-second-color border-0 py-2 mt-6 px-12 focus:outline-none rounded text-lg"
						>
							Add
						</button>
					</div>
				</div>
			</div>
			)}
			{updateChosen && (
				<div>
					<div className="top-inputs">
						<div>
							<p>Name</p>
							<input
								value={updateName}
								onChange={updateNameChangeHandler}
								placeholder="Name"
								type="text"
								id="name"
								autoFocus
								name="name"
								maxLength="50"
								className={errorField === 'name' ? "shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4 form-error" : "bg-white shadow rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4"}
							/>
						</div>
						<div>
							<p>Type</p>
							<select
								value={updateType}
								className="h-11 w-96 shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
								onChange={updateTypeChangeHandler}
							>
								<option value="department">Depeartment</option>
								<option value="team">Team</option>
							</select>
						</div>
						<div>
							<p>Parent</p>
							<select
								disabled={updateType === 'department'}
								className="h-11 w-96 shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
								onChange={updateParentChangeHandler}
								value={parent}
							>
								<option value=''></option>
								{departments.map((department) => {
									return <option value={department.name}>{department.name}</option>
								})}
							</select>
						</div>
						<div className="mr-4">
							<p>CRUD</p>
							<div className="form-maturity-container">
								{crudActive ?
								<>
									<div className="h-11 w-72 shadow rounded py-2 px-3 form-maturity">
										<button className="form-activity__button-close" onClick={() => {setUpdateCrud(false)}}><span>Close</span><AiOutlineClose /></button>
										<div className="w-full">
											<CRUDChecklist goalType={goalType} />
										</div>
									</div>
								</>
								:
								<button className="form-activity__button-open" onClick={() => {setCrudActive(true)}}>Open</button>
								}
							</div>
						</div>
						<div>
							<p>Head</p>
							<select
								className="h-11 w-96 shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out mr-4"
								onChange={updateHeadChangeHandler}
								value={head}
							>
								<option value=''></option>
								{people.map((person) => {
									return <option value={person.name}>{person.name}</option>
								})}
							</select>
						</div>
						{ type === 'team' ?
							<div>
								<p>Collaborators</p>
								<div className="form-activity-container">
									{formActive ?
										<>
											<div className="h-11 w-72 shadow rounded py-2 px-3 form-activity">
												<button className="form-activity__button-close" onClick={() => { setFormActive(false) }}><span>Close</span><AiOutlineClose /></button>
												{people && people.map(item => (
													<label key={item.id}><br/>
														<Checkbox key={item.id} name={item.id} checked={collaborators[item.id]} onChange={(e) => { multiSelectChangeHandler(e, collaborators, setCollaborators) }} />
														{item.id + ' ' + item.name}
													</label>
												))}
											</div>
										</>
										:
										<button className="form-activity__button-open" onClick={() => { setFormActive(true) }}>Open</button>
									}
								</div>
							</div>
							:
							<div>
								<p>Teams</p>
								<div className="form-activity-container">
									{formActive ?
										<>
											<div className="h-11 w-72 shadow rounded py-2 px-3 form-activity">
												<button className="form-activity__button-close" onClick={() => { setFormActive(false) }}><span>Close</span><AiOutlineClose /></button>
												{teams && teams.map(item => (
													<label key={item.id}><br/>
														<Checkbox key={item.id} name={item.id} checked={team[item.id]} onChange={(e) => { multiSelectChangeHandler(e, team, setTeam) }} />
														{item.id + + item.name}
													</label>
												))}
											</div>
										</>
										:
										<button className="form-activity__bu'tton-open" onClick={() => { setFormActive(true) }}>Open</button>
									}
								</div>
							</div>
						}
					<div className="w-3/4 flex justify-between align-middle mb-10">
						<button
							onClick={() => {
								setUpdateChosen(false);
								clearFieldsCancel();
							}}
							className="text-white bg-red-500 border-0 py-2 mt-6 px-12 focus:outline-none hover:bg-red-600 rounded text-lg"
						>
							Cancel
						</button>
						<button
							onClick={() => {
								// addData();
								console.log(123)
							}}
							className="text-white bg-second-color border-0 py-2 mt-6 px-12 focus:outline-none rounded text-lg"
						>
							Update
						</button>
					</div>
				</div>
			</div>
			)}
			<div className="flex flex-col items-center bg-white w-full rounded-xl min-h-screen">
				<div className="flex flex-col mt-5 w-full">
					<div className="plus-minus">
						<IconButton
							className="icon-img"
							onClick={() => {
								setUpdateChosen(false);
								setAddChosen(!addChosen);
							}}
							notRestricted={true}
							stateActive={addChosen}
							tooltip={'Add new'}
						>
							<AiOutlineFileAdd />
						</IconButton>
					</div>
					<div className="flex flex-col bg-white w-full divide-y divide-gray-200">
						<p className="py-5 px-10">Organization</p>
						<OrganizationTabContext.Provider value={[
							setShowDelete,
							setUpdateChosen,
							setAddChosen,
							setUpdateId,
							setUpdateName,
							setUpdateType,
							setUpdateParent,
							setDeleteId,
							setUpdateTeam,
							setDeleteItemsType,
							updateChosenHandler,
							setUpdateTeam,
							setUpdateCollaborators,
							people
						]}>
						{departments.map((department) => {
							return <Department key={department.id} goalType={goalType} department={department} teams={teams} />
						})}
						</OrganizationTabContext.Provider>
					</div>
				</div>
			</div>
		</>
	)
}

export default Organization