import React from 'react';
import { BsTrash3 } from 'react-icons/bs'
import { FiEdit } from 'react-icons/fi'
import IconButton from '../ui/buttons/IconButton';


const BusinessItem = ({
		item,
		showDelete, 
		deleteId,
		setDeleteItem, 
		setShowDelete, 
		setDeleteId, 
		setUpdateChosen, 
		setAddChosen, 
		setUpdateId, 
		setUpdateName, 
		setUpdateStartDate, 
		setUpdateEndDate, 
		setUpdateTag,
		setUpdateProperty,
		setUpdateLink,
		propertyType,
		bpRelations,
		setUpdateApplications,
		access
}) => {
	const { Processes_Edit, Processes_Delete } = access

	const handleDoubleClick = event => {
		if (!Processes_Edit) return
		if (event.detail === 2) {
			setUpdateChosen(true)
			setAddChosen(false)
			setUpdateId(item.id)
			setUpdateName(item.name)
			setUpdateStartDate(item.start_date)
			setUpdateEndDate(item.end_date)
			setUpdateProperty(propertyName)
			setUpdateTag(item.tag)
			setUpdateLink(item.link)
			setUpdateApplications(connectedRelations)
			// setUpdateApplications(getConnectedApplicationsFromRelations(connectedRelations))
		}
	}

	const connectedRelations = bpRelations.length ? bpRelations.filter(bpr => bpr.bp_list.id === item.id) : []

	const getConnectedApplicationsFromRelations = (relations) => {
		const result = []
		for (const r of relations) {
			result.push(r.application)
		}

		return result
	}

	const getConnectedApplicationNamesFromRelations = (relations) => {
		const result = []
		for (const r of relations) {
			result.push(r.application.name)
		}

		return result
	}

	const connectedApplicationNames = getConnectedApplicationNamesFromRelations(connectedRelations)

	const propertyItem = propertyType.find(p => p.order === item.property_id)
	const propertyName = propertyItem ? propertyItem.name : ''
	return (
		<tr
			key={item.id}
			className="capability-item"
			style={{
				backgroundColor:
				showDelete && deleteId === item.id && "#A3AAC4",
			}}
			onClick={(e) => {handleDoubleClick(e)}}
			>
			<td className="px-4 py-3">{item.id}</td>
			<td className="px-4 py-3">{item.name}</td>
			<td className="px-4 py-3">{connectedApplicationNames.join(', ')}</td>
			<td className="px-4 py-3">{item.start_date}</td>
			<td className="px-4 py-3">{item.end_date}</td>
			<td className="px-4 py-3">{item.tag}</td>
			<td className="px-4 py-3">{propertyName}</td>
			<td className="px-4 py-3 text-blue-800 flex link">
				{item.link.length > 0 ? <a href={item.link} rel="noreferrer">Link </a> : <div>Link</div>}
				<div className='capability-controls'>
					<IconButton
						className="ml-5 capability-controls__button"
						onClick={() => {
							setShowDelete(!showDelete)
							setDeleteId(item.id)
							setDeleteItem({name: item.name})
						}}
						notRestricted={Processes_Delete}
					>
						<BsTrash3 alt="delete" />
					</IconButton>
					<IconButton
						className="ml-5 capability-controls__button"
						onClick={() => {
							setUpdateChosen(true)
							setAddChosen(false)
							setUpdateId(item.id)
							setUpdateName(item.name)
							setUpdateStartDate(item.start_date)
							setUpdateEndDate(item.end_date)
							setUpdateProperty(propertyName)
							setUpdateTag(item.tag)
							setUpdateLink(item.link)
						}}
						notRestricted={Processes_Edit}
					>
						<FiEdit alt="edit" />
					</IconButton>
				</div>
			</td>
		</tr>
	);
};

export default BusinessItem;