import React from 'react'

const TermsModal = ({ setShowDelete, setTerms }) => {
    const termsAccepted = () => {
		setTerms(true)
		setShowDelete(false)
	}
    const termsNotAccepted = () => {
		setTerms(false)
		setShowDelete(false)
	}

    return (
		<div className="fixed inset-0 z-10">
			<div className="overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
				<div className="flex items-end justify-center min- px-4 pt-4 pb-20 text-center sm:block sm:p-0">
					<div className="transition-opacity bg-gray-500 bg-opacity-75" aria-hidden="true"></div>
					<span className="hidden sm:inline-block sm:align-middle sm:" aria-hidden="true">​</span>
					<div className="inline-block p-5 text-left align-bottom transition-all transform bg-white rounded-lg shadow-2xl lg:p-16 sm:my-8 sm:align-middle sm:max-w-[60%] sm:w-full">
						<div>
							<div className="mt-3 text-left sm:mt-5">
								<div className="py-6 flex flex-col justify-start align-start text-start max-h-96 overflow-y-auto">
                                    <h2 className="flex justify-center mb-8 text-2xl font-semibold leading-none tracking-tighter text-neutral-600">Terms and Conditions</h2>
									<p className="flex justify-start align-start">Dear users.</p>
									<p>
										This product is in beta testing. The product's creators take only reasonable basic measures to maintain the integrity of the product. No special measures are taken to protect and secure your information. Please do not enter personal or sensitive information when using this product. Do not make your business dependent on decisions made using this product. This is a beta version, which means the product creators do not provide any guarantees regarding the safety and integrity of your information. The beta version is not intended for business use and is not intended for use with personal or sensitive information.
									</p>
									<p className="mt-2">Good luck in testing the product!</p>
									
								</div>
								<div className="py-6 text-center">
								</div>
							</div>
							<div className="flex flex-row justify-center align-middle">
								<div className="bg-red px-10 py-2 mr-5 rounded bg-red-600 text-white font-bold cursor-pointer" onClick={termsNotAccepted}>
									Reject
								</div>
								<div className="bg-red px-10 py-2 ml-5 rounded bg-green-600 text-white font-bold cursor-pointer" onClick={termsAccepted}>
									Accept
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)    
}

export default TermsModal