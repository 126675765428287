import React, { useState, useEffect } from 'react'

import groupArrayIntoSixes from '../../handlers/groupArrayHandler'
import BusinessRelationsTableHeaderItem from './BusinessRelationsTableHeaderItem'
import Modal from '../Modal'
import BusinessRelationsItem from './BusinessRelationsItem'


const BusinessRelationsTable = ({
    data,
    setUpdateRelationships,
    setUpdateBusinesses,
    setUpdateApplications,
    setUpdateStartDate,
    setUpdateEndDate,
    setUpdateId,
    setSuccess,
    setError,
    getData,
    setUpdateChosen,
    setAddChosen,
    current,
    setPaginationLevels,
    relationship,
    application,
    business,
    access
  }) => {
    const [showDelete, setShowDelete] = useState(false);
    const [deleteId, setDeleteId] = useState("");
    const [deleteItem, setDeleteItem] = useState({name: ''});
    const [sortedData, setSortedData] = useState([])
    const [activeSort, setActiveSort] = useState({key: '', inc: true})
    const [sortingStartDate, setSortingStartDate] = useState("")
    const [sortingEndDate, setSortingEndDate] = useState("")
    const [sortingBusiness, setSortingBusiness] = useState("")
    const [sortingApplication, setSortingApplication] = useState("")
    const [sortingRelation, setSortingRelation] = useState("")
    const [sortingTag, setSortingTag] = useState('')
  
    useEffect(() => {
      let result = []
  
      for (const array of data) {
        result.push(...array)
      }
  
      if (sortingStartDate) {
        result = result.filter(item => item.start_date.includes(sortingStartDate))
      }
      if (sortingEndDate) {
        result = result.filter(item => item.end_date.includes(sortingEndDate))
      }
      if (sortingBusiness) {
        result = result.filter(item => item.bp_list.name.includes(sortingBusiness))
      }
      if (sortingApplication) {
        result = result.filter(item => item.application.name.includes(sortingApplication))
      }
      if (sortingTag) {
        result = result.filter(item => item.tag.includes(sortingTag))
      }
      if (sortingRelation) {
        const getKeyByValue = (object) => {
          const result = []
          const keys = Object.keys(object)
          for (const key of keys) {
            if (sortingRelation[key] === true) {result.push(key)}
          }
          return result
        }
        
        const properties = getKeyByValue(sortingRelation)
  
        if (properties.length > 0) {
          const originalRelations = [
            {id: 1, name: 'Composition', class: 'Structural'},
            {id: 2, name: 'Aggregation', class: 'Structural'},
            {id: 3, name: 'Assignment', class: 'Structural'},
            {id: 4, name: 'Realization', class: 'Structural'},
            {id: 5, name: 'Serving', class: 'Dependency'},
            {id: 6, name: 'Access', class: 'Dependency'},
            {id: 7, name: 'Influence', class: 'Dependency'},
            {id: 8, name: 'Association', class: 'Dependency'},
            {id: 9, name: 'Triggering', class: 'Dynamic'},
            {id: 10, name: 'Flow', class: 'Dynamic'},
            {id: 11, name: 'Specialization', class: 'Other'}
          ]
          const filtederRelations = originalRelations.filter(n => !properties.includes(n.name))

          for (const p of filtederRelations) {
            result = result.filter(item => item.relationship_dict.id !== p.id)
          }
        }
      }
      
  
      setSortedData(groupArrayIntoSixes(result, 20))
      setPaginationLevels(sortedData.length)
    }, [data, sortingStartDate, sortingEndDate, sortingRelation, setPaginationLevels, sortedData.length, sortingTag, sortingBusiness, sortingApplication, business])
  
  
    const sortData = (sortKey) => {
      let result = []
  
      if (sortedData.length > 1) {
        for (const array of sortedData) {
          result.push(...array)
        }
  
      } else {
        for (const array of data) {
          result.push(...array)
        }
      }
  
      const reverseSort = (sortKey) => {
          setActiveSort({key: sortKey, inc: false })
          result.reverse()
      }
      
      if (sortKey === 'id') {
        result.sort((a, b) => parseFloat(a.id) - parseFloat(b.id))
  
        if (activeSort.key === sortKey && activeSort.inc) {
          reverseSort(sortKey)
        }
         else {
          setActiveSort({key: 'id', inc: true})
        }
      }
      if (sortKey === 'business') {
        result.sort((a, b) => a.bp_list.name.localeCompare(b.bp_list.name))
  
        if (activeSort.key === sortKey && activeSort.inc) {
          reverseSort(sortKey)
        } else {
          setActiveSort({key: 'business', inc: true})
        }
      }
      if (sortKey === 'application') {
        result.sort((a, b) => a.application.name.localeCompare(b.application.name))
  
        if (activeSort.key === sortKey && activeSort.inc) {
          reverseSort(sortKey)
        } else {
          setActiveSort({key: 'application', inc: true})
        }
      }
      if (sortKey === 'start-date') {
        const emptyDates = result.filter(item => item.start_date === "")
        const actuallDates = result.filter(item => item.start_date !== "")
        actuallDates.sort((a, b) => new Date(a.start_date) - new Date(b.start_date))
        result = [...actuallDates, ...emptyDates]
  
        if (activeSort.key === sortKey && activeSort.inc) {
          reverseSort(sortKey)
        } else {
        setActiveSort({key: 'start-date', inc: true})
        }
      }
      if (sortKey === 'end-date') {
        const emptyDates = result.filter(item => item.end_date === "")
        const actuallDates = result.filter(item => item.end_date !== "")
        actuallDates.sort((a, b) => new Date(a.end_date) - new Date(b.end_date))
        result = [...actuallDates, ...emptyDates]
  
        if (activeSort.key === sortKey && activeSort.inc) {
          reverseSort(sortKey)
        } else {
        setActiveSort({key: 'end-date', inc: true})
        }
      }
      if (sortKey === 'tag') {
        result.sort((a, b) => a.tag.localeCompare(b.tag))
  
        if (activeSort.key === sortKey && activeSort.inc) {
          reverseSort(sortKey)
        } else {
          setActiveSort({key: 'tag', inc: true})
        }
      }
      if (sortKey === 'relation') {
        result.sort((a, b) => parseFloat(a.relation) - parseFloat(b.relation))
  
        if (activeSort.key === sortKey && activeSort.inc) {
          reverseSort(sortKey)
        } else {
        setActiveSort({key: 'relation', inc: true})
        }
      }
      if (sortKey === 'link') {
        result.sort((a, b) => parseFloat(a.link) - parseFloat(b.link))
  
        if (activeSort.key === sortKey && activeSort.inc) {
          reverseSort(sortKey)
        } else {
        setActiveSort({key: 'link', inc: true})
        }
      }
      setSortedData(groupArrayIntoSixes(result, 20))
      setPaginationLevels(sortedData.length)
    }
  
    return (
      <div
        className="flex flex-col"
        style={{
          width: "100%",
        }}
      >
        {showDelete && (
          <Modal
            setShowDelete={setShowDelete}
            deleteId={deleteId}
            deleteItem={deleteItem}
            deleteItemType={'bprelation'}
            getData={getData}
          />
        )}
        <table className="table-auto w-full text-left whitespace-no-wrap">
          <thead className="table-header">
            <tr>
              <BusinessRelationsTableHeaderItem name={'ID'} sortData={sortData} value={'id'} activeSort={activeSort}/>
              <BusinessRelationsTableHeaderItem name={'PROCESS'} sortData={sortData} value={'business'} activeSort={activeSort} setSortingBusiness={setSortingBusiness} />
              <BusinessRelationsTableHeaderItem name={'APPLICATION'} sortData={sortData} value={'application'} activeSort={activeSort} setSortingApplication={setSortingApplication} />
              <BusinessRelationsTableHeaderItem name={'START DATE'} sortData={sortData} value={'start-date'} activeSort={activeSort} setSortingStartDate={setSortingStartDate} />
              <BusinessRelationsTableHeaderItem name={'END DATE'} sortData={sortData} value={'end-date'} activeSort={activeSort} setSortingEndDate={setSortingEndDate} />
              <BusinessRelationsTableHeaderItem name={'RELATION'} sortData={sortData} value={'relation'} activeSort={activeSort} setSortingRelation={setSortingRelation} />
              <BusinessRelationsTableHeaderItem name={''} sortData={sortData} value={'link'} activeSort={activeSort}/>
            </tr>
          </thead>
          <tbody className="table-body">
          {sortedData.length > 0 &&
                sortedData[current].map((item) => {
                  return (
                    <BusinessRelationsItem 
                      key={item.id} 
                      item={item}
                      showDelete={showDelete} 
                      deleteId={deleteId}
                      setDeleteItem={setDeleteItem}  
                      setShowDelete={setShowDelete} 
                      setDeleteId={setDeleteId} 
                      setUpdateChosen={setUpdateChosen} 
                      setAddChosen={setAddChosen} 
                      setUpdateId={setUpdateId}
                      setUpdateStartDate={setUpdateStartDate}
                      setUpdateEndDate={setUpdateEndDate}
                      setUpdateApplications={setUpdateApplications}
                      setUpdateBusinesses={setUpdateBusinesses}
                      setUpdateRelationships={setUpdateRelationships}

                      relationship={relationship}
                      application={application}
                      business={business}
                      access={access}
                    />
                )
              }
            )
          }
          </tbody>
        </table>
      </div>
    );
};

export default BusinessRelationsTable;