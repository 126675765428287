import React, { useState, useEffect } from 'react'

import groupArrayIntoSixes from '../../handlers/groupArrayHandler'
import ApplicationsTableHeaderItem from './ApplicationsTableHeaderItem'
import Modal from '../Modal'
import ApplicationsItem from './ApplicationsItem'


const ApplicationsTable = ({
    data,
    setUpdateName,
    setUpdateLink,
    setUpdateStartDate,
    setUpdateEndDate,
    setUpdateTag,
    setUpdateId,
    setSuccess,
    setError,
    getData,
    setUpdateChosen,
    setAddChosen,
    current,
    setPaginationLevels,
    setUpdateDescription,
    access
  }) => {

    const [showDelete, setShowDelete] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [deleteItem, setDeleteItem] = useState({name: ''});
    const [sortedData, setSortedData] = useState([])
    const [activeSort, setActiveSort] = useState({key: '', inc: true})
    const [sortingStartDate, setSortingStartDate] = useState('')
    const [sortingEndDate, setSortingEndDate] = useState('')
    const [sortingName, setSortingName] = useState('')
    const [sortingTag, setSortingTag] = useState('')
    const [sortingDescription, setSortingDescription] = useState('')
  
    useEffect(() => {
      let result = []
  
      for (const array of data) {
        result.push(...array)
      }
  
      if (sortingStartDate) {
        result = result.filter(item => item.start_date.includes(sortingStartDate))
      }
      if (sortingEndDate) {
        result = result.filter(item => item.end_date.includes(sortingEndDate))
      }
      if (sortingName) {
        result = result.filter(item => item.name.includes(sortingName))
      }
      if (sortingDescription) {
        result = result.filter(item => item.description.includes(sortingDescription))
      }
      if (sortingTag) {
        result = result.filter(item => item.tag.includes(sortingTag))
      }
  
      setSortedData(groupArrayIntoSixes(result, 20))
      setPaginationLevels(sortedData.length)
    }, [data, sortingStartDate, sortingEndDate, sortingName, setPaginationLevels, sortedData.length, sortingTag, sortingDescription])
  
  
    const sortData = (sortKey) => {
      let result = []
  
      if (sortedData.length > 1) {
        for (const array of sortedData) {
          result.push(...array)
        }
  
      } else {
        for (const array of data) {
          result.push(...array)
        }
      }
  
      const reverseSort = (sortKey) => {
          setActiveSort({key: sortKey, inc: false })
          result.reverse()
      }
      
      if (sortKey === 'id') {
        result.sort((a, b) => parseFloat(a.id) - parseFloat(b.id))
  
        if (activeSort.key === sortKey && activeSort.inc) {
          reverseSort(sortKey)
        }
         else {
          setActiveSort({key: 'id', inc: true})
        }
      }
      if (sortKey === 'name') {
        result.sort((a, b) => a.name.localeCompare(b.name))
  
        if (activeSort.key === sortKey && activeSort.inc) {
          reverseSort(sortKey)
        } else {
          setActiveSort({key: 'name', inc: true})
        }
      }
      if (sortKey === 'tag') {
        result.sort((a, b) => a.tag.localeCompare(b.tag))
  
        if (activeSort.key === sortKey && activeSort.inc) {
          reverseSort(sortKey)
        } else {
          setActiveSort({key: 'tag', inc: true})
        }
      }
      if (sortKey === 'start-date') {
        const emptyDates = result.filter(item => item.start_date === "")
        const actuallDates = result.filter(item => item.start_date !== "")
        actuallDates.sort((a, b) => new Date(a.start_date) - new Date(b.start_date))
        result = [...actuallDates, ...emptyDates]
  
        if (activeSort.key === sortKey && activeSort.inc) {
          reverseSort(sortKey)
        } else {
        setActiveSort({key: 'start-date', inc: true})
        }
      }
      if (sortKey === 'end-date') {
        const emptyDates = result.filter(item => item.end_date === "")
        const actuallDates = result.filter(item => item.end_date !== "")
        actuallDates.sort((a, b) => new Date(a.end_date) - new Date(b.end_date))
        result = [...actuallDates, ...emptyDates]
  
        if (activeSort.key === sortKey && activeSort.inc) {
          reverseSort(sortKey)
        } else {
        setActiveSort({key: 'end-date', inc: true})
        }
      }
      if (sortKey === 'description') {
        result.sort((a, b) => a.name.localeCompare(b.name))
  
        if (activeSort.key === sortKey && activeSort.inc) {
          reverseSort(sortKey)
        } else {
          setActiveSort({key: 'description', inc: true})
        }
      }
      if (sortKey === 'link') {
        result.sort((a, b) => parseFloat(a.link) - parseFloat(b.link))
  
        if (activeSort.key === sortKey && activeSort.inc) {
          reverseSort(sortKey)
        } else {
        setActiveSort({key: 'link', inc: true})
        }
      }
      setSortedData(groupArrayIntoSixes(result, 20))
      setPaginationLevels(sortedData.length)
    }
  
    return (
      <div className="flex flex-col w-[100%]">
        {showDelete && (
          <Modal
            setShowDelete={setShowDelete}
            deleteId={deleteId}
            deleteItem={deleteItem}
            deleteItemType={'application'}
            setSuccess={setSuccess}
            setError={setError}
            getData={getData}
          />
        )}
        <table className="table-auto w-full text-left whitespace-no-wrap">
          <thead className="table-header">
            <tr>
              <ApplicationsTableHeaderItem name={'ID'} sortData={sortData} value={'id'} activeSort={activeSort}/>
              <ApplicationsTableHeaderItem name={'NAME'} sortData={sortData} value={'name'} activeSort={activeSort} setSortingName={setSortingName}/>
              <ApplicationsTableHeaderItem name={'START DATE'} sortData={sortData} value={'start-date'} activeSort={activeSort} setSortingStartDate={setSortingStartDate}/>
              <ApplicationsTableHeaderItem name={'END DATE'} sortData={sortData} value={'end-date'} activeSort={activeSort} setSortingEndDate={setSortingEndDate}/>
              <ApplicationsTableHeaderItem name={'TAG'} sortData={sortData} value={'tag'} activeSort={activeSort} setSortingTag={setSortingTag}/>
              <ApplicationsTableHeaderItem name={'NOTE'} sortData={sortData} value={'description'} activeSort={activeSort} setSortingDescription={setSortingDescription}/>
              <ApplicationsTableHeaderItem name={'LINK'} sortData={sortData} value={'link'} activeSort={activeSort}/>
            </tr>
          </thead>
          <tbody className="table-body">
          {sortedData.length > 0 &&
                sortedData[current].map((item) => {
                  return (
                    <ApplicationsItem 
                      key={item.id} 
                      item={item}
                      showDelete={showDelete} 
                      deleteId={deleteId}
                      setDeleteItem={setDeleteItem}  
                      setShowDelete={setShowDelete} 
                      setDeleteId={setDeleteId} 
                      setUpdateChosen={setUpdateChosen} 
                      setAddChosen={setAddChosen} 
                      setUpdateId={setUpdateId} 
                      setUpdateName={setUpdateName} 
                      setUpdateStartDate={setUpdateStartDate} 
                      setUpdateEndDate={setUpdateEndDate} 
                      setUpdateTag={setUpdateTag}
                      setUpdateLink={setUpdateLink}
                      setUpdateDescription={setUpdateDescription}

                      access={access}
                    />
                )
              }
            )
          }
          </tbody>
        </table>
      </div>
    );
};

export default ApplicationsTable;