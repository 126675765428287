import React from "react";

import CapabilityTableHeaderItem from "./CapabilityTableHeaderItem";
import CapabilityItem from './CapabilityItem';


const CapabilityTable = ({
  data,
}) => {
  return (
    <div className="flex flex-col min-w-[100%]"
    >
      <table className="table-auto w-full text-left whitespace-no-wrap">
        <thead className="table-header">
          <tr>
            <CapabilityTableHeaderItem name={'NAME'}  value={'name'} />
            <CapabilityTableHeaderItem name={'ORDER'}  value={'order'} />
          </tr>
        </thead>
        <tbody className="table-body">
        {data.map((item) => {
                return (
                  <CapabilityItem 
                    key={item.order} 
                    item={item}
                  />
              )
            }
          )
        }
        </tbody>
      </table>
    </div>
  );
};

export default CapabilityTable;
