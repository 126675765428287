import React, { useEffect, useState, useMemo } from 'react'
import AnyChart from 'anychart-react'
import anychart from 'anychart'


const AnyChartModule = ({ dataArray }) => {
    const [chartData, setChartData] = useState([]);

    const formatData = (dataArray) => {
        for (const item of dataArray) {
            item.id = item.id + ''
            if (item.predecessor > 0) {      
                item['dependsOn'] = [item.predecessor + '']
                item['itemType'] = 'cell'
            } 
            // else {
            //     dataArray.push()
            //     item['dependsOn'] =  []

            // }
            
        }

        const isLastItem = (item) => {
            const lastItem = dataArray.filter(i => (i.predecessor + '') === item.id )
            if (lastItem.length > 0) return false 
            return true
        }

        for (const item of dataArray) {
            if (isLastItem(item) && item.id !== 'lastItem') {
                dataArray.push({
                    id: 'lastItem',
                    predecessor: item.id,
                    lastItem: true
                })
            }
        }

        return dataArray
    }


    useEffect(() => {
        // Sort and format data array
        const sortedData = [...dataArray].sort((a, b) => parseFloat(a.id) - parseFloat(b.id))
        const formattedData = formatData(sortedData); // do I need to use useCallback here? 
        setChartData(formattedData);
    }, [dataArray])

    formatData(dataArray)

    const properStartDates = dataArray.filter(item => item.start_date !== '')
    const properEndDates = dataArray.filter(item => item.end_date && item.end_date.length > 0)
    const lowestDate = properStartDates.length > 0 && properStartDates[0].start_date
    const highestDate = properEndDates.length > 0 && properEndDates[properEndDates.length - 1].end_date

    const chart = anychart.pert()

    function defaultMilesoneTooltipformat () {
        var result = ''
        var i = 0;
        if (this['successors'] && this['successors'].length) {
            result += 'Successors:';
            for (i = 0; i < this['successors'].length; i++) {
                result += '\n - ' + this['successors'][i].get('name')
            }
            if (this['predecessors'] && this['predecessors'].length)
                result += '\n\n';
        }
        if (this['predecessors']) {
            result += 'Predecessors:';
            for (i = 0; i < this['predecessors'].length; i++) {
                result += '\n - ' + this['predecessors'][i].get('name')
            }
        }
        return result;
    }

    function decorateLabels(tasks) {
        decorateUpperLabels(tasks.upperLabels());
        decorateUpperLabels(tasks.hovered().upperLabels(), true);
        decorateUpperLabels(tasks.selected().upperLabels(), true);
        decorateLowerLabels(tasks.lowerLabels());
        decorateLowerLabels(tasks.hovered().lowerLabels(), true);
        decorateLowerLabels(tasks.selected().lowerLabels(), true);
    }

    function decorateUpperLabels(labels, opt_isBold) {
        labels.padding({'top': 0, 'right': 15, 'bottom': 0, 'left': 15})
            .fontSize(10).fontWeight(opt_isBold ? 'bold' : 'normal')
    }
    
    function decorateLowerLabels(labels, opt_isBold) {
        labels.padding({'top': 0, 'right': 5, 'bottom': 0, 'left': 5})
            .fontSize(9).fontOpacity(0.5).fontWeight(opt_isBold ? 'bold' : 'normal')
    }

    const tasks = chart.tasks();
    tasks.upperLabels().format(function () {
        return ''
    })

    tasks.lowerLabels().format(function () {
        return ''
    })

    chart.milestones().labels().format(function() {
        if (this.creator) {
            var result ="";
            var comma, i;
            
            
            for (i = 0; i < this.predecessors.length; i++){
                comma = i === this.predecessors.length - 1 ? '': ','
                result += this.predecessors[i].get('name') + comma
            }

            // result += '\n _______________________________________\n';
            // result += '\n'
        
            // for (i = 0; i < this.successors.length; i++){
            //     comma = i === this.successors.length - 1 ? '' : ','
            //     result += this.successors[i].get("start_date") + comma
            // }

            // result += '\n';
        
            // for (i = 0; i < this.successors.length; i++){
            //     console.log(this.successors);
            //     comma = i === this.successors.length - 1 ? '' : ','
            //     result += this.successors[i].get("effort") + 'h' + comma
            // }

            // result += '\n';
        
            // for (i = 0; i < this.successors.length; i++){
            //     comma = i === this.successors.length - 1 ? "" : ','
            //     result += this.successors[i].get("end_date") + comma
            // }
            return result;
        
        } else {
            return this.isStart ? "Start \n" + lowestDate : "End \n" + highestDate
        }

    });
    
    decorateLabels(tasks)

    chart.milestones().size('100px')
    chart.milestones().color('#7bc0f7')

    chart.criticalPath().milestones().fill(function () {
        return this['creator'] ? this.sourceColor : this['isStart'] ? '#3f53d9' : '#3f53d9';
    })

    chart.horizontalSpacing('10%');
    // set padding for chart
    // chart.padding([25, 50, 0, 50]);
    chart.milestones().shape("rectangle")


    chart.milestones().tooltip().format(defaultMilesoneTooltipformat)

    return (
        <>
            <AnyChart
                instance={chart}
                data={chartData}
                // title='Pert Chart'
                height={1000}
                width={'100%'}
            />
        </>
    )
}

export default AnyChartModule;