import React from 'react'
import Activities from "../../assets/activity.svg"
import CardItem from './CardItem'

const DirectoryActivities = ({ activity }) => {
    const inactiveActivities = activity && activity.filter(item => item.activity_type_id === 1)
    const projectActivities = activity && activity.filter(item => item.activity_type_id === 2)
    const taskActivities = activity && activity.filter(item => item.activity_type_id === 3)

    return (
        <div className="directory">
            <CardItem linkRoute={'activities'}
                cardColor={'red'}
                image={Activities}
                dataArray={activity} >
                Activities
            </CardItem>
            <CardItem linkRoute={'activities'}
                cardColor={'red'}
                image={Activities}
                dataArray={inactiveActivities} >
                Activities of inactive level
            </CardItem>
            <CardItem linkRoute={'activities'}
                cardColor={'red'}
                image={Activities}
                dataArray={projectActivities} >
                Activities of project level
            </CardItem>
            <CardItem linkRoute={'activities'}
                cardColor={'red'}
                image={Activities}
                dataArray={taskActivities} >
                Activities of task level
            </CardItem>
        </div>
    );
};

export default DirectoryActivities;