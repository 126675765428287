import React, { useEffect, useState } from 'react'
import RegularButton from '../ui/buttons/RegularButton'
import SkeletonTable from '../ui/skeleton/SkeletonTable'
import Modal from '../Modal'
import IconButton from '../ui/buttons/IconButton'
import { AiOutlineFileAdd } from 'react-icons/ai'
import PeopleTable from './users/PeopleTable'
import { PeopleTabContext } from '../../contexts/PeopleTabContext'

const People = ({ loading, fetched, setError, setSuccess, organization, getOrganization, editOrganization, newInvite, access, getAllUsers, allUsers, deleteUser, activeInvites }) => {
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [addChosen, setAddChosen] = useState(false)
	const [position, setPosition] = useState('')
	const [role, setRole] = useState('')
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState('')
	const [systemUser, setSystemUser] = useState('')

	const [updatePosition, setUpdatePosition] = useState('')
	const [updateRole, setUpdateRole] = useState('')
	const [updateStartDate, setUpdateStartDate] = useState('')
	const [updateEndDate, setUpdateEndDate] = useState('')
	const [updateSystemUser, setUpdateSystemUser] = useState('')
	const [updateId, setUpdateId] = useState('')
	const [updateName, setUpdateName] = useState('')
	const [updateEmail, setUpdateEmail] = useState('')
	const [updateChosen, setUpdateChosen] = useState(false)
	const [showDelete, setShowDelete] = useState(false)
	const [deleteId, setDeleteId] = useState('')
	const [deleteItem, setDeleteItem] = useState({name: ''})
	const [errorField, setErrorField] = useState('')

	const testUsers = [
		{id: 1, name: 'user1', email: 'email1@mail.com', position: 'worker', role: 'some role', startDate: '', endDate: '', systemUser: false},
		{id: 2, name: 'user2', email: 'email2@mail.com', position: 'worker', role: 'some role', startDate: '', endDate: '', systemUser: false},
		{id: 3, name: 'user3', email: 'email3@mail.com', position: 'worker', role: 'some role', startDate: '', endDate: '', systemUser: false},
		{id: 4, name: 'user4', email: 'email4@mail.com', position: 'worker', role: 'some role', startDate: '', endDate: '', systemUser: true},
		{id: 5, name: 'user5', email: 'email5@mail.com', position: 'worker', role: 'some role', startDate: '', endDate: '', systemUser: false},
		{id: 6, name: 'user6', email: 'email6@mail.com', position: 'teamlead', role: 'some role', startDate: '', endDate: '', systemUser: false},
		{id: 7, name: 'user7', email: 'email7@mail.com', position: 'worker', role: 'some role', startDate: '', endDate: '', systemUser: false},
		{id: 8, name: 'user8', email: 'email8@mail.com', position: 'worker', role: 'some role', startDate: '', endDate: '', systemUser: true},
		{id: 9, name: 'user9', email: 'email9@mail.com', position: 'worker', role: 'some role', startDate: '', endDate: '', systemUser: false},
		{id: 10, name: 'user10', email: 'email10@mail.com', position: 'manager', role: 'some role', startDate: '', endDate: '', systemUser: false},
		{id: 11, name: 'user11', email: 'email11@mail.com', position: 'manager', role: 'some role', startDate: '', endDate: '', systemUser: false}
	]

	const [inviteEmail, setInviteEmail] = useState('')
	const id = localStorage.getItem('userOrganization')
	const {
		Settings_Organization_Edit_organization,
		Settings_Organization_Invite
	} = access

	useEffect(() => {
		getOrganization({id})
	}, [])

	const clearFieldsCancel = () => {}
	const clearFieldsSuccess = () => {}
	const nameChangeHandler = (e) => setName(e.target.value)
	const updateNameChangeHandler = (e) => setUpdateName(e.target.value)
	const positionChangeHandler = (e) => setPosition(e.target.value)
	const updatePositionChangeHandler = (e) => setUpdatePosition(e.target.value)
	const roleChangeHandler = (e) => setRole(e.target.value)
	const updateRoleChangeHandler = (e) => setUpdateRole(e.target.value)
	const startDateChangeHandler = (e) => setStartDate(e.target.value)
	const updateStartDateChangeHandler = (e) => setUpdateStartDate(e.target.value)
	const endDateChangeHandler = (e) => setEndDate(e.target.value)
	const updateEndDateChangeHandler = (e) => setUpdateEndDate(e.target.value)
	const systemUserChangeHandler = (e) => setSystemUser(e.target.value)
	const updateSystemUserChangeHandler = (e) => setUpdateSystemUser(e.target.value)
	const emailChangeHandler = (e) => setEmail(e.target.value)
	const updateEmailChangeHandler = (e) => setUpdateEmail(e.target.value)

	const isValidEmail = (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		return emailRegex.test(email)
	} 

	const isValidLength = (name) => {
		if (name.length > 1) {
			return true
		}
		return false
	}

	return (
		<>
			{addChosen && (
				<div>
					<div className="top-inputs">
						<div>
							<p>Name</p>
							<input
								value={name}
								onChange={nameChangeHandler}
								placeholder="Name"
								type="text"
								id="name"
								autoFocus
								name="name"
								maxLength="50"
								className={errorField === 'name' ? "shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4 form-error" : "bg-white shadow rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4"}
							/>
						</div>
						<div>
							<p>Positon</p>
							<input
								value={position}
								onChange={positionChangeHandler}
								placeholder="Position"
								type="text"
								id="position"
								name="position"
								maxLength="50"
								className={errorField === 'position' ? "shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4 form-error" : "bg-white shadow rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4"}
							/>
						</div>
						<div>
							<p>Role</p>
							<input
								value={role}
								onChange={roleChangeHandler}
								placeholder="Role"
								type="text"
								id="role"
								name="role"
								maxLength="50"
								className={errorField === 'role' ? "shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4 form-error" : "bg-white shadow rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4"}
							/>
						</div>
						<div>
							<p>Start Date</p>
							<input
								value={startDate}
								onChange={startDateChangeHandler}
								placeholder="Start Date"
								type="date"
								id="start-date"
								name="start-date"
								maxLength="50"
								className={errorField === 'start-date' ? "shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4 form-error" : "bg-white shadow rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4"}
							/>
						</div>
						<div>
							<p>End Date</p>
							<input
								value={endDate}
								onChange={endDateChangeHandler}
								placeholder="End Date"
								type="date"
								id="end-date"
								name="end-date"
								maxLength="50"
								className={errorField === 'name' ? "shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4 form-error" : "bg-white shadow rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4"}
							/>
						</div>
						<div>
							<p>Email</p>
							<input
								value={email}
								onChange={emailChangeHandler}
								placeholder="Email"
								type="email"
								id="email"
								autoFocus
								name="email"
								maxLength="50"
								className={errorField === 'email' ? "shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4 form-error" : "bg-white shadow rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4"}
							/>
						</div>
						<div className="w-96">
							<p className="mb-3">System User</p>
							<select
								value={systemUser}
								onChange={systemUserChangeHandler}
								placeholder="System user"
								type="checkbox"
								id="system-user"
								name="system-user"
								maxLength="50"
								className={errorField === 'system-user' ? "shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 h-12 mr-4 form-error" : "h-12 bg-white shadow rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4"}
							>
								<option value="yes">Yes</option>
								<option value="no">No</option>
							</select>
						</div>
				</div>
					<div className="w-3/4 flex justify-between align-middle mb-10">
						<button
							onClick={() => {
								setAddChosen(false);
								clearFieldsCancel();
							}}
							className="text-white bg-red-500 border-0 py-2 mt-6 px-12 focus:outline-none hover:bg-red-600 rounded text-lg"
						>
							Cancel
						</button>
						<button
							onClick={() => {
								// addData();
								setAddChosen(false)
							}}
							className="text-white bg-second-color border-0 py-2 mt-6 px-12 focus:outline-none rounded text-lg"
						>
							Add
						</button>
					</div>
			</div>
			)}
			{updateChosen && (
				<div>
					<div className="top-inputs">
						<div>
							<p>Name</p>
							<input
								value={updateName}
								onChange={updateNameChangeHandler}
								placeholder="Name"
								type="text"
								id="name"
								autoFocus
								name="name"
								maxLength="50"
								className={errorField === 'name' ? "shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4 form-error" : "bg-white shadow rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4"}
							/>
						</div>
						<div>
							<p>Positon</p>
							<input
								value={updatePosition}
								onChange={updatePositionChangeHandler}
								placeholder="Position"
								type="text"
								id="position"
								name="position"
								maxLength="50"
								className={errorField === 'position' ? "shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4 form-error" : "bg-white shadow rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4"}
							/>
						</div>
						<div>
							<p>Role</p>
							<input
								value={updateRole}
								onChange={updateRoleChangeHandler}
								placeholder="Role"
								type="text"
								id="role"
								name="role"
								maxLength="50"
								className={errorField === 'role' ? "shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4 form-error" : "bg-white shadow rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4"}
							/>
						</div>
						<div>
							<p>Start Date</p>
							<input
								value={updateStartDate}
								onChange={updateStartDateChangeHandler}
								placeholder="Start Date"
								type="date"
								id="start-date"
								name="start-date"
								maxLength="50"
								className={errorField === 'start-date' ? "shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4 form-error" : "bg-white shadow rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4"}
							/>
						</div>
						<div>
							<p>End Date</p>
							<input
								value={updateEndDate}
								onChange={updateEndDateChangeHandler}
								placeholder="End Date"
								type="date"
								id="end-date"
								name="end-date"
								maxLength="50"
								className={errorField === 'name' ? "shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4 form-error" : "bg-white shadow rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4"}
							/>
						</div>
						<div>
							<p>Email</p>
							<input
								value={updateEmail}
								onChange={updateEmailChangeHandler}
								placeholder="Email"
								type="email"
								id="email"
								name="email"
								maxLength="50"
								className={errorField === 'email' ? "shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4 form-error" : "bg-white shadow rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4"}
							/>
						</div>
						<div className="w-96">
							<p className="mb-3">System User</p>
							<select
								value={updateSystemUser}
								onChange={updateSystemUserChangeHandler}
								placeholder="System user"
								type="checkbox"
								id="system-user"
								name="system-user"
								maxLength="50"
								className={errorField === 'system-user' ? "shadow bg-white rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 h-12 mr-4 form-error" : "h-12 bg-white shadow rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out w-96 mr-4"}
							>
								<option value="yes">Yes</option>
								<option value="no">No</option>
							</select>
						</div>
					</div>
					<div className="w-3/4 flex justify-between align-middle mb-10">
						<button
							onClick={() => {
								setUpdateChosen(false);
								clearFieldsCancel();
							}}
							className="text-white bg-red-500 border-0 py-2 mt-6 px-12 focus:outline-none hover:bg-red-600 rounded text-lg"
						>
							Cancel
						</button>
						<button
							onClick={() => {
								// addData();
								setUpdateChosen(false)
							}}
							className="text-white bg-second-color border-0 py-2 mt-6 px-12 focus:outline-none rounded text-lg"
						>
							Update
						</button>
					</div>
			</div>
			)}
			<div className="flex bg-white flex-col w-full rounded-xl">
				<div className="plus-minus mt-5">
					<IconButton
						className="icon-img"
						onClick={() => {
							setUpdateChosen(false);
							setAddChosen(!addChosen);
						}}
						notRestricted={true}
						stateActive={addChosen}
						tooltip={'Add new'}
					>
						<AiOutlineFileAdd />
					</IconButton>
				</div>
				{showDelete && 
					<Modal
						setShowDelete={setShowDelete}
						deleteId={deleteId}
						deleteItem={deleteItem}
						deleteItemType={'organizationDatabase'}
						setSuccess={setSuccess}
						setError={setError}
					/>
				}
				<div className="flex w-full mx-5">
					<div className="flex flex-col w-full mr-10 mb-4">
						<div className="flex flex-col mb-4">
								{loading || !fetched ? 
									<SkeletonTable />:
									<PeopleTabContext.Provider value={[ 
										setUpdateChosen,
										setAddChosen,
										setUpdateId,
										setUpdateName,
										setUpdateEmail,
										setUpdateEndDate,
										setUpdatePosition,
										setUpdateRole,
										setUpdateStartDate,
										setUpdateSystemUser
									]}>
										<PeopleTable
											data={testUsers}
											deletedChosen={false}
											setSelected={null}
											deleteUser={deleteUser}
											setError={setError} 
											setSuccess={setSuccess}
											access={access}
										/>
									</PeopleTabContext.Provider>
								}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default People