import React, { useState, useContext } from 'react'
import { LiaEditSolid } from "react-icons/lia"
import CRUDChecklist from './CRUDChecklist'
import { OrganizationTabContext } from '../../contexts/OrganizationTabContext'
import { MdDeleteOutline } from "react-icons/md"


export default function Team({team, goalType}) {
    const [
        setShowDelete,
        setUpdateChosen,
        setAddChosen,
        setUpdateId,
        setUpdateName,
        setUpdateType,
        setUpdateParent,
        setDeleteId,
        setDeleteItemsType,
        updateChosenHandler,
        setUpdateTeam,
        setUpdateCollaborators,
        people
    ] = useContext(OrganizationTabContext)
    const [isOpen, setIsOpen] = useState(false)
    const detailsToggle = () => setIsOpen(prev => !prev)
    const summaryStyle = isOpen ? "items-center transition-all question py-3 pl-4 cursor-pointer select-none w-full outline-none" : " items-center transiton-all question py-3 pl-4 cursor-pointer select-none w-full outline-none"
    // const teamPeople = people.filter(person => team.collaborators.includes(person.name))

    const editTeamHandler = () => {
        setAddChosen(false)
        setShowDelete(false)
        updateChosenHandler(true)
        setUpdateId(team.id)
        setUpdateName(team.name)
        setUpdateType('team')
        setUpdateParent(team.parent)
        setUpdateCollaborators([])
    }

    const deleteTeamHandler = () => {
        setShowDelete(true)
        setAddChosen(false)
        setUpdateChosen(false)
        setDeleteItemsType('team')
        setDeleteId(team.id)
    }

    return (
        <details className={isOpen ? "bg-gray-200 ml-[100px] rounded-xl mb-1" : "ml-[100px] rounded-xl mb-1"}>
            <summary className={summaryStyle} onClick={detailsToggle}>
                {team.name}
                <div className="flex align-items">
                    <LiaEditSolid className="m-1" onClick={editTeamHandler}/>
                    <MdDeleteOutline className="m-1" onClick={deleteTeamHandler} />
                </div>
            </summary>
            <div className="flex mt-[-3em] w-full justify-center pt-1 pb-3 px-4"><CRUDChecklist item={team} goalType={goalType} /></div>
        </details>
    )
}