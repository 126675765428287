import React from 'react'
import Tab from '../Tab'

const BusinessManagement = ({ active, setActive, access }) => {
    const { Processes, Processes_App_processes, Processes_Import, Processes_Export } = access
    return (
        <div className="tabs-container">
            <div className="flex row mt-5 justify-start items-start">
                <Tab
                    section={"Process"}
                    active={active}
                    setActive={setActive}
                    isDisabled={!Processes}
                />
                <Tab
                    section={"Apps 2 Processes"}
                    active={active}
                    setActive={setActive}
                    isDisabled={!Processes_App_processes}
                />
                <Tab
                    section={"Report"}
                    active={active}
                    setActive={setActive}
                    isDisabled={!Processes_Import}
                />
                <Tab
                    section={"Import"}
                    active={active}
                    setActive={setActive}
                    isDisabled={!Processes_Import}
                />
                <Tab
                    section={"Export"}
                    active={active}
                    setActive={setActive}
                    isDisabled={!Processes_Export}
                />
            </div>
        </div>
    )
}

export default BusinessManagement