import { connect } from "react-redux";
import { fetched, loading, capability, set } from '../../../store/reducers/selectors'
import { getSet, importCapability, newSet, newSuccessMessage, newErrorMessage, newWarningMessage, clearSuccessMessage, clearErrorMessage, clearWarningMessage } from "../../../api/actions";
import { createStructuredSelector } from "reselect";
import CapabilityImport from "./CapabilityImport";


const mapStateToProp = createStructuredSelector({
    fetched,
    loading,
    capability,
    set,
})

const mapDispatchToProps = {
    getSet,
    importCapability,
    newSet,
    newSuccessMessage,
    newErrorMessage,
    newWarningMessage,
    clearSuccessMessage,
    clearErrorMessage,
    clearWarningMessage
}

export default connect(mapStateToProp, mapDispatchToProps)(CapabilityImport);