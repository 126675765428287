import { connect } from "react-redux"
import { fetched, loading, activity } from '../../../store/reducers/selectors'
import { importActivity } from "../../../api/actions"
import { createStructuredSelector } from "reselect"
import ActivitiesImport from "./ActivitiesImport"


const mapStateToProp = createStructuredSelector({
    fetched,
    loading,
    activity,
})

const mapDispatchToProps = {
    importActivity
}

export default connect(mapStateToProp, mapDispatchToProps)(ActivitiesImport);