import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import ModalFeedback from "../components/ModalFeedback"
// import { RxBackpack } from "react-icons/rx"
// import { AiOutlineAppstore } from "react-icons/ai"
// import { IoAppsOutline } from "react-icons/io5"
// import { MdOutlineFeedback } from "react-icons/md"
// import { FiInfo, FiBox } from "react-icons/fi"
import { SlArrowRight } from "react-icons/sl"
import CapabilitiesIcon from "../assets/box-open_9239283.png"
import GoalsIcon from "../assets/bullseye-arrow_9795295.png"
import ProcessesIcon from "../assets/process.png"
import DashboardIcon from "../assets/square-poll-vertical_9795430.png"
import SettingsIcon from "../assets/setting-the-table.png"
import AppsIcon from "../assets/categories.png"
import ReportIcon from "../assets/alert-sign.png"
import { PiInfoThin, PiSlidersThin, PiChartLineUpThin, PiStackLight, PiPackageThin } from "react-icons/pi"
// import { FaRegFolder } from "react-icons/fa6"
// import { ReactComponent as ScenariosIcon } from "../assets/scenarios.svg"
// import { ReactComponent as ActivitiesIcon } from "../assets/activities.svg"
// import { PiSlidersHorizontalLight } from "react-icons/pi"
// import { ReactComponent as GoalsIcon } from "../assets/goals.svg"
// import { VscOpenPreview } from "react-icons/vsc"
// import { GoGear, GoBook } from "react-icons/go"
// import { RiShoppingBag3Line } from "react-icons/ri"
// import { CiSliderHorizontal } from "react-icons/ci"
// import { CiSettings } from "react-icons/ci"



const Navigation = ({ setNav, newFeedback, access }) => {
	const [isFeedbackActive, setIsFeedbackActive] = useState(false)
	const { Menu } = access
	const { Activities, Applications, Capabilities, Dashboard, Feedback, Goals, Processes, Products, Reports, Scenarios, Settings } = Menu
	
	return (
		<div className="mini-navigation">
			{isFeedbackActive && <ModalFeedback newFeedback={newFeedback} closeModal={() => { setIsFeedbackActive(false) }} />}
			<div className="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased text-gray-800">
				<div className="fixed flex flex-col top-0 left-0 h-full border-r pl-[20px] bg-white w-[82px]">
					<div className="flex items-center justify-center h-14 mt-5 mb-7 pr-5">
						<SlArrowRight className="text-3xl text-black cursor-pointer" onClick={() => {
							setNav("max")
						}}/>
					</div>
					<div className="overflow-y-auto overflow-x-hidden flex-grow">
						<ul className="nav_min">
							<li>
								<NavLink 
									className={!Dashboard ? "default-bg disabled" : "default-bg"}
									to="/"
								>
									{/* <FaRegFolder className="text-xl text-black" alt="dashboard" /> */}
									<img src={DashboardIcon} className="h-6 cursor-pointer" alt="dashboard" />
								</NavLink>
							</li>
							<li>
								<NavLink 
									className={!Goals ? "default-bg disabled" : "default-bg"}
									to="goals"
								>
									{/* <GoalsIcon className="h-6 rotate-image text-black" alt="goals" /> */}
									<img src={GoalsIcon} className="h-6 cursor-pointer" alt="goals" />
								</NavLink>
							</li>
							<li>
								<NavLink 
									className={!Capabilities ? "default-bg disabled" : "default-bg"}
									to="capabilities"
								>
									{/* <FiBox className="text-xl text-black" alt="capabilities" /> */}
									<img src={CapabilitiesIcon} className="h-6 cursor-pointer" alt="capabilities" />
								</NavLink>
							</li>
							<li>
								<NavLink 
									className={!Activities ? "default-bg disabled" : "default-bg"}
									to="activities"
								>
									<PiSlidersThin className="h-9 w-7 text-black" alt="activities" />
								</NavLink>
							</li>
							<li>
								<NavLink 
									className={!Products ? "default-bg disabled" : "default-bg"}
									to="products"
								>
									<PiPackageThin className="h-8 w-7 text-black" alt="products" />
								</NavLink>
							</li>
							<li>
								<NavLink 
									className={!Scenarios ? "default-bg disabled" : "default-bg"}
									to="scenarios"
								>
									{/* <ScenariosIcon className="h-5 text-black" alt="scenarios" /> */}
									<PiStackLight className="h-8 w-7 text-black" alt="scenarios" />
								</NavLink>
							</li>
							<li>
								<NavLink 
									className={!Processes ? "default-bg disabled" : "default-bg"}
									to="processes"
								>
									{/* <RxBackpack className="text-xl text-black" alt="processes" /> */}
									<img src={ProcessesIcon} className="h-7 cursor-pointer" alt="processes"/>
								</NavLink>
							</li>
							<li>
								<NavLink 
									className={!Applications ? "default-bg disabled" : "default-bg"}
									to="applications"
								>
									{/* <AiOutlineAppstore className="text-xl text-black" alt="applications" /> */}
									{/* <IoAppsOutline className="h-7 w-7 text-black" alt="applications" /> */}
									<img src={AppsIcon} className="h-7 cursor-pointer" alt="application" />
								</NavLink>
							</li>
							<li>
								<NavLink 
									className={!Reports ? "default-bg disabled" : "default-bg"}
									to="reports"
								>
									<PiChartLineUpThin className="h-8 w-7 text-black" alt="reports" />
								</NavLink>
							</li>
							<li>
								<NavLink 
									className={!Settings ? "default-bg disabled" : "default-bg"} 
									to="settings"
									
								>
									{/* <CiSettings className="h-8 w-8 text-black" alt="settings" /> */}
									<img src={SettingsIcon} className="h-7 cursor-pointer" alt="settings" />
								</NavLink>
							</li>
							<li>
								<NavLink 
									className="default-bg cursor-pointer" 
									to="about"
								>
									<PiInfoThin className="h-8 w-8 text-black" alt="about" />
								</NavLink>
							</li>
							<li>
								<div 
									className={!Feedback ? "default-bg cursor-pointer disabled" : "default-bg cursor-pointer" }
									onClick={() => setIsFeedbackActive(() => !isFeedbackActive)}
								>

									<img src={ReportIcon} className="h-8 cursor-pointer" alt="feedback" />
									{/* <PiInfoThin className="text-xl text-black" alt="feedback" /> */}
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Navigation