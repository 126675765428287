import { connect } from "react-redux";
import { fetched, loading, relationship, goalType, activityType, capabilityType, organization, successMessage, errorMessage } from '../../store/reducers/selectors'
import { getRelationship, getGoalType, getActivityType, getCapabilityType, getOrganization, editOrganization, newInvite, clearSuccessMessage, clearErrorMessage } from "../../api/actions"
import { createStructuredSelector } from "reselect";
import AboutPage from './AboutPage'

const mapStateToProp = createStructuredSelector({
    fetched,
    loading,
    relationship,
    goalType,
    activityType,
    capabilityType,
    organization,
    successMessage,
    errorMessage
})

const mapDispatchToProps = {
    getRelationship,
    getGoalType,
    getActivityType,
    getCapabilityType,
    getOrganization,
    editOrganization,
    newInvite,
    clearSuccessMessage,
    clearErrorMessage
}

export default connect(mapStateToProp, mapDispatchToProps)(AboutPage);