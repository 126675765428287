import React, { useEffect, useState } from "react";
import DirectoryAll from "../../components/Directories/DirectoryAll";
import DirectoriesManagement from "../../components/Directories/DirectoriesManagement";
import DirectoryCapabilities from "../../components/Directories/DirectoryCapabilities";
import DirectoryGoals from "../../components/Directories/DirectoryGoals";
import DirectoryActivities from "../../components/Directories/DirectoryActivities";
import DirectoryProducts from "../../components/Directories/DirectoryProducts";
import DirectoryScenarios from "../../components/Directories/DirectoryScenarios";
import FacebookSpinner from "../../components/ui/spinners/FacebookSpinner";

const DirectoriesPage = ({
  loading,
  fetched,
  getCapability,
  getGoal,
  getActivity,
  getProduct,
  getScenario,
  editGoal,
  editActivity,
  capability,
  goal,
  activity,
  scenario,
  product,
  access,
}) => {
  const [active, setActive] = useState("Capabilities");

  useEffect(() => {
    getCapability();
    getGoal();
    getActivity();
    getProduct();
    getScenario();
  }, []);

  if (!fetched || loading) {
    return (
      <div className="spinner-container">
        <FacebookSpinner />
      </div>
    );
  }
  return (
    <>
      <DirectoriesManagement
        active={active}
        setActive={setActive}
        access={access}
      />
      {/* {active === 'All' && <DirectoryAll editGoal={editGoal} editActivity={editActivity} capability={capability} goal={goal} activity={activity} product={product} scenario={scenario} />} */}
      {active === "Capabilities" && (
        <DirectoryCapabilities capability={capability} />
      )}
      {active === "Goals" && <DirectoryGoals goal={goal} />}
      {active === "Activities" && <DirectoryActivities activity={activity} />}
      {active === "Products" && <DirectoryProducts product={product} />}
      {active === "Scenarios" && <DirectoryScenarios scenario={scenario} />}
    </>
  );
};

export default DirectoriesPage;
