import React, { useState } from "react";

import { VscGear } from "react-icons/vsc";
import { VscClearAll } from "react-icons/vsc";

import DateFilter from "../headerFilters/DateFilter";

const CapabilitiesTableHeaderItem = ({
  sortData,
  name,
  value,
  activeSort,
  setSortingStartDate,
  setSortingEndDate,
  setSortingName,
  setSortingParent,
  setSortingProperty,
  setSortingLayout,
  setSortingAddress,
  setSortingLane,
  setSortingDescription,
}) => {
  const [filter, setFilter] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [sortName, setSortName] = useState("");
  const [l1, setL1] = useState();
  const [l2, setL2] = useState();
  const [l3, setL3] = useState();
  const [lane, setLane] = useState("");
  const [layout, setLayout] = useState("");
  const [property, setProperty] = useState("");
  const [description, setDescription] = useState("");
  const [parent, setParent] = useState("");
  const [noParent, setNoParent] = useState(false);

  let headerItemType = "";
  let headerItemFilterActive = "";

  const Checkbox = ({ type = "checkbox", name, checked = false, onChange }) => {
    return (
      <input type={type} name={name} checked={checked} onChange={onChange} />
    );
  };

  const checkboxesProperties = [
    {
      name: "",
      key: "empty",
      label: "",
    },
    {
      name: "Support",
      key: "Support",
      label: "Support",
    },
    {
      name: "MDM",
      key: "MDM",
      label: "MDM",
    },
    {
      name: "ESG",
      key: "ESG",
      label: "ESG",
    },
    {
      name: "Strategic",
      key: "Strategic",
      label: "Strategic",
    },
    {
      name: "Learning",
      key: "Learning",
      label: "Learning",
    },
    {
      name: "Planning",
      key: "Planning",
      label: "Planning",
    },
    {
      name: "Execution",
      key: "Execution",
      label: "Execution",
    },
  ];

  const checkboxesLayouts = [
    {
      name: "horizontal",
      key: "horizontal",
      label: "horizontal",
    },
    {
      name: "vertical",
      key: "vertical",
      label: "vertical",
    },

    { name: "backlog", key: "backlog", label: "backlog" },
  ];

  const setFieldClear = () => {
    setFilter(!filter);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" || e.key === "Escape") {
      setFilter(!filter);
    }
  };

  const closeFilter = (e) => {
    if (e.key === "Escape") {
      setFilter(!filter);
    }
  };

  const nameChangeHandler = (e) => {
    setSortName(e.target.value);
    setSortingName(e.target.value);
  };

  const descriptionChangeHandler = (e) => {
    setDescription(e.target.value);
    setSortingDescription(e.target.value);
  };

  const laneChangeHandler = (e) => {
    setLane(e.target.value);
    setSortingLane(e.target.value);
  };

  const startDateChangeHandler = (e) => {
    setStartDate(e.target.value);
    setSortingStartDate(e.target.value);
    setFilter(!filter);
  };

  const endDateChangeHandler = (e) => {
    setEndDate(e.target.value);
    setSortingEndDate(e.target.value);
    setFilter(!filter);
  };

  const l1ChangeHandler = (e) => {
    setL1(e.target.value);
    setSortingAddress({ l1: e.target.value, l2: l2, l3: l3 });
  };

  const l2ChangeHandler = (e) => {
    setL2(e.target.value);
    setSortingAddress({ l1: l1, l2: e.target.value, l3: l3 });
  };

  const l3ChangeHandler = (e) => {
    setL3(e.target.value);
    setSortingAddress({ l1: l1, l2: l2, l3: e.target.value });
  };

  const layoutChangeHandler = (e) => {
    setLayout({ ...layout, [e.target.name]: e.target.checked });
    setSortingLayout({ ...layout, [e.target.name]: e.target.checked });
  };

  const propertyChangeHandler = (e) => {
    setProperty({ ...property, [e.target.name]: e.target.checked });
    setSortingProperty({ ...property, [e.target.name]: e.target.checked });
  };

  const parentChangeHandler = (e) => {
    setParent(e.target.value);
    setSortingParent(e.target.value);
  };

  const noParentChangeHandler = (value) => {
    setParent(value);
    setSortingParent(value);
  };

  if (value === "start-date") {
    headerItemFilterActive = startDate;
    headerItemType = (
      <DateFilter date={startDate} onChangeFunc={startDateChangeHandler} />
    );
  }
  if (value === "end-date") {
    headerItemFilterActive = endDate;
    headerItemType = (
      <DateFilter date={endDate} onChangeFunc={endDateChangeHandler} />
    );
  }

  if (value === "address") {
    headerItemFilterActive = l1;
    headerItemType = (
      <div className="address-sort">
        <input
          className="h-11 w-72 shadow rounded py-2 px-3"
          value={l1}
          type="number"
          min="0"
          placeholder="L1"
          onKeyDown={handleKeyPress}
          onChange={l1ChangeHandler}
        ></input>
        <input
          className="h-11 w-72 shadow rounded py-2 px-3"
          value={l2}
          type="number"
          min="0"
          placeholder="L2"
          onKeyDown={handleKeyPress}
          onChange={l2ChangeHandler}
        ></input>
        <input
          className="h-11 w-72 shadow rounded py-2 px-3"
          value={l3}
          type="number"
          min="0"
          placeholder="L3"
          onKeyDown={handleKeyPress}
          onChange={l3ChangeHandler}
        ></input>
        <button
          className="clear-field"
          onClick={() => {
            setFieldClear();
            setL1("");
            setL2("");
            setL3("");
            setSortingAddress("");
          }}
        >
          <VscClearAll />
        </button>
      </div>
    );
  }
  if (value === "name") {
    headerItemFilterActive = sortName;
    headerItemType = (
      <div className="h-11 w-72 shadow rounded py-2 px-3 filter-input">
        <input
          value={sortName}
          type="text"
          placeholder="Start typing here"
          onChange={nameChangeHandler}
        />
        <button
          className="clear-field"
          onClick={() => {
            setFieldClear();
            setSortName("");
            setSortingName("");
          }}
        >
          <VscClearAll />
        </button>
      </div>
    );
  }
  if (value === "description") {
    headerItemFilterActive = description;
    headerItemType = (
      <div className="h-11 w-72 shadow rounded py-2 px-3 filter-input">
        <input
          value={description}
          type="text"
          placeholder="Start typing here"
          onChange={descriptionChangeHandler}
        />
        <button
          className="clear-field"
          onClick={() => {
            setFieldClear();
            setDescription("");
            setSortingDescription("");
          }}
        >
          <VscClearAll />
        </button>
      </div>
    );
  }
  if (value === "lane") {
    headerItemFilterActive = lane;
    headerItemType = (
      <div className="h-11 w-72 shadow rounded py-2 px-3 filter-input">
        <input
          value={lane}
          type="number"
          min="0"
          placeholder="Lane number"
          onChange={laneChangeHandler}
        />
        <button
          className="clear-field"
          onClick={() => {
            setFieldClear();
            setLane("");
            setSortingLane("");
          }}
        >
          <VscClearAll />
        </button>
      </div>
    );
  }
  if (value === "layout") {
    headerItemFilterActive = layout;
    headerItemType = (
      <div className="filter-select">
        <form className="filter-select__form" onChange={layoutChangeHandler}>
          {checkboxesLayouts.map((item) => (
            <div key={item.key} className="mb-1">
              <Checkbox
                name={item.name}
                checked={layout[item.name]}
                onChange={layoutChangeHandler}
              />
              <label>{item.name}</label>
            </div>
          ))}
        </form>
        <button
          className="clear-field"
          onClick={() => {
            setFieldClear();
            setLayout("");
            setSortingLayout("");
          }}
        >
          <VscClearAll />
        </button>
      </div>
    );
  }
  if (value === "property") {
    headerItemFilterActive = property;
    headerItemType = (
      <div className="filter-select">
        <form className="filter-select__form" onChange={propertyChangeHandler}>
          {checkboxesProperties.map((item) => (
            <div key={item.key} className="mb-1">
              <Checkbox
                name={item.name}
                checked={property[item.name]}
                onChange={propertyChangeHandler}
              />
              <label>{item.name}</label>
            </div>
          ))}
        </form>
        <button
          className="clear-field"
          onClick={() => {
            setFieldClear();
            setProperty("");
            setSortingProperty("");
          }}
        >
          <VscClearAll />
        </button>
      </div>
    );
  }
  if (value === "parent") {
    headerItemFilterActive = parent;
    headerItemType = (
      <div className="h-11 w-72 shadow rounded py-2 px-3 filter-input-capability-parent">
        <form className="filter-select__form flex justify-start w-[100%]">
          <input
            type="checkbox"
            checked={noParent}
            onChange={() => {
              setNoParent(!noParent);
              noParentChangeHandler(!noParent && -2);
            }}
          />
          <label>No parent</label>
        </form>
        <div className="parent-num">
          <input
            className={noParent ? "disabled" : null}
            value={parent}
            type="number"
            min="-1"
            placeholder="Parent number"
            onChange={parentChangeHandler}
            disabled={noParent}
            onKeyDown={handleKeyPress}
          ></input>
          <button
            className="clear-field"
            onClick={() => {
              setFieldClear();
              setParent("");
              setSortingParent("");
              setNoParent(false);
            }}
          >
            <VscClearAll />
          </button>
        </div>
      </div>
    );
  }

  let uniqueStyling;

  if (value === "id") {
    uniqueStyling = "header-item_id";
  }
  if (value === "name") {
    uniqueStyling = "header-item_name";
  }
  if (value === "link") {
    uniqueStyling = "header-item_link";
  }

  return (
    <th className={"table-header-item " + uniqueStyling}>
      <div
        className="table-header-item__container"
        tabIndex={-1}
        onKeyDown={closeFilter}
      >
        {filter && headerItemType}
        <button
          onClick={() => {
            sortData(value);
          }}
        >
          {name}
        </button>
        {activeSort.key !== value && <span style={{ marginRight: 12 }}></span>}
        {activeSort.key === value && activeSort.inc && (
          <span style={{ marginBottom: 2 }}>▲</span>
        )}
        {activeSort.key === value && !activeSort.inc && (
          <span style={{ marginBottom: 3 }}>▼</span>
        )}
        {value !== "id" && value !== "link" && (
          <VscGear
            className={
              headerItemFilterActive
                ? "text-2xl cursor-pointer capability-filter text-red-400"
                : filter
                ? "text-2xl cursor-pointer capability-filter filter-active"
                : "text-2xl cursor-pointer capability-filter"
            }
            onClick={() => {
              setFilter(!filter);
            }}
          />
        )}
      </div>
    </th>
  );
};

export default CapabilitiesTableHeaderItem;
