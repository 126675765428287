import ThirdLevel from "./ThirdLevel"
import { wrapLabel, getCenterX } from './handlers/handlers'


const SecondLevel = ({ item, thirdLevels, xPos, yPos }) => {
	const firstLevelWidth = 120
	const firstLevelHeight = 100
	const secondLevelWidth = 100
	const secondLevelHeight = 100
	const thirdLevelWidth = 80
	const thirdLevelHeight = 80
	const levelDistance = 10
	const textHeightPosition = 30
	const fontSize = 12

	const relatedThirdLevel = thirdLevels.filter(i3 => i3.second_level === item.second_level && i3.first_level === item.first_level)
	const secondLevelHeightDynamic = (relatedThirdLevel.length * secondLevelHeight) + secondLevelHeight

	let yPosThirdLevel = yPos - 100

	const labels = wrapLabel(item.name, 60)
	const wrappedText = labels.map((word, index) => {
		const centerX = getCenterX(word, xPos, firstLevelWidth)
		yPosThirdLevel += 10

		return (
			<tspan color='black' x={centerX} dy={index === 0 ? 0 : fontSize}>
				{word}
			</tspan>
		)
	})
	
	const addressText = item.first_level + '.' + item.second_level + '.' + item.third_level
	const addressXPos = getCenterX(addressText, xPos, secondLevelWidth + 20)

	return ( 
		<g color='black'>
			<rect
				width="100"
				height={secondLevelHeightDynamic}
				fill="#C9D5F2"
				rx="8"
				stroke="#1E1E1E"
				key={item.id}
				x={xPos + 10}
				y={yPos}
				border="none"
			/>
			{/* className={heatmapActive && isHeatmapNotDot ? "second-level-capability " + getMaturityColor(item, 2) : "second-level-capability no-maturity-color"} */}
			{/* {heatmapActive && <i className={"maturity-min " + getMaturityColor(item, 2)} />} */}
			<text color='black' x={addressXPos} y={yPos + 20} fontWeight="normal" fontSize="12">
				<tspan>{addressText}</tspan>
			</text>
			<text color='black' x={xPos + 10} y={yPos + 32} fontWeight="normal" fontSize="12">
				{wrappedText}
			</text>
			{relatedThirdLevel.map((i3) => {
				yPosThirdLevel += thirdLevelHeight + levelDistance
				return(
					<ThirdLevel 
						item={i3}
						// heatmapActive={heatmapActive}
						// isHeatmapNotDot={isHeatmapNotDot}
						parent={item}
						xPos={xPos}
						yPos={yPosThirdLevel}
					/>
				)
			})}
		</g>
	 )
}
 
export default SecondLevel